import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, Modal, ModalBody, FormGroup,
    Alert, FormSelect} from "shards-react";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DateInput } from '@opuscapita/react-dates';
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { sortDescendingByAlphanumerically } from "../../utils/sortDescending";
import { ApiUrls } from "../../environments/apiUrls";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { getSelectedDateFromDatePicker } from '../../utils/formatDateFromDatePicker';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ViewDeletedItemsAndPallets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isValidStartDate: false,
            isValidEndDate: false,
            isEndDateDisabled: true,
            isConfirmShowing: false,
            isDataLoading: false,
            isSearchBtnPressed: false,
            isProcessing: false,
            theme: "",
            response: "",
            startDate: "",
            endDate: "",
            errorResponseForStartDate: "",
            errorResponseForEndDate: "",
            today: "",
            listStock: [],
            listStockPrint: [],
            selectedOption: [],
        };
    }

    componentDidMount() {
        this.getTodayDate();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const columnsListStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'pallet_number',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Description',
                    formatter: this.getDescription,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.getExpiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Category',
                    dataField: 'category',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Date deleted',
                    formatter: this.getDateDeleted,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Undelete',
                    formatter: this.doUndelete,
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];
        const { isResponse, theme, response, startDate, today,
            errorResponseForStartDate, endDate, isEndDateDisabled,
            errorResponseForEndDate, isDataLoading, isSearchBtnPressed,
            listStock, isConfirmShowing, selectedOption, isProcessing } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>View Deleted Items and Pallets</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>VIEW DELETED ITEMS AND PALLETS</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#startDate">Date from :<span className="error-txt">*</span></label>
                                        <DateInput
                                            id="#startDate"
                                            value={startDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setStartDate}
                                            max={today}
                                            modifiers={{
                                                disabled: { after: new Date() }
                                            }}
                                        />
                                        <span className="error-txt">{errorResponseForStartDate}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#endDate">Date to :<span className="error-txt">*</span></label>
                                        <DateInput
                                            id="#endDate"
                                            value={endDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setEndDate}
                                            min={startDate}
                                            max={today}
                                            disabled={isEndDateDisabled}
                                            modifiers={{
                                                disabled: {
                                                    before: startDate,
                                                    after: new Date()
                                                }
                                            }}
                                        />
                                        <span className="error-txt">{errorResponseForEndDate}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (
                                <>
                                    {isSearchBtnPressed && (
                                        <>
                                            {listStock.length != 0 && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6"></Col>
                                                        <Col sm="12" md="6" lg="6">
                                                            <FormGroup>
                                                                <label style={{ fontSize: "large" }} htmlFor="#type">Sorted by :<span className="error-txt"></span></label>
                                                                <FormSelect size="lg" onChange={this.sortListStock}>
                                                                    <option value=""></option>
                                                                    <option value="ascCategory">Category(ascending)</option>
                                                                    <option value="desCategory">Category(descending)</option>
                                                                    <option value="ascDateDeleted">Date deleted(ascending)</option>
                                                                    <option value="desDateDeleted">Date deleted(descending)</option>
                                                                    <option value="ascDescription">Description(ascending)</option>
                                                                    <option value="desDescription">Description(descending)</option>
                                                                    <option value="ascPalletNumber">Pallet number(ascending)</option>
                                                                    <option value="desPalletNumber">Pallet number(descending)</option>
                                                                </FormSelect>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={listStock}
                                                        columns={columnsListStock}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                    <br />
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.handlePrint}>Print</Button>
                                                        </Col>
                                                        <Col sm="12" md="6" lg="6"></Col>
                                                    </Row>
                                                </>
                                            )}
                                            {listStock.length == 0 && (
                                                <><Alert theme="warning">No Data Found</Alert></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmShowing && (
                    <Modal open={isConfirmShowing} toggle={() => this.setState({ isConfirmShowing: !isConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>UNDELETE PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>
                                        You are about to undelete {selectedOption.pallet_number} pallet and move to pending. Do you want to proceed?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col >
                                    <Button theme="success" size="lg" onClick={this.handleYesBtn} disabled={isProcessing}>{isProcessing ? "Loading..." : "Yes"}</Button>

                                </Col>
                                <Col>
                                    <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ isConfirmShowing: false, selectedOption: [] })}>No</Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    handleSearch = () => {
        this.validateStartDate();
        this.validateEndDate();
        const { startDate, endDate, isValidStartDate, isValidEndDate } = this.state;
        let startDateInIsoFormat = getSelectedDateFromDatePicker(startDate);
        let endDateInIsoFormat = getSelectedDateFromDatePicker(endDate);
        if (isValidStartDate && isValidEndDate) {
            this.setState({ isDataLoading: true, listStock: [], isSearchBtnPressed: true });
            axios.get(`${ApiUrls.viewDeletedItemsAndPallets}?startDate=${startDateInIsoFormat}&endDate=${endDateInIsoFormat}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let listStock = JSON.parse(result.data);
                    listStock = sortByAlphanumerically(listStock, 'pallet_number');
                    this.setState({ listStock, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
    }

    doUndelete = (cell, row) => {
        return row.is_undelete ? (<Button theme="danger" onClick={e => this.handleUndelete(e, row)} block>Undelete</Button>) : (<></>);
    }

    getExpiryDate = (cell, row) => {
        let expiryDate = row.expiry_date;
        let date = expiryDate ? getDateInGivenFormat(expiryDate.slice(0, 10)) : '';
        return (<div>{date}</div>);
    }

    getDateDeleted = (cell, row) => {
        let dateDeleted = row.date_deleted;
        let date = dateDeleted ? getDateInGivenFormat(dateDeleted.slice(0, 10)) : '';
        return (<div>{date}</div>)
    }

    getDescription = (cell, row) => {
        return (row.description != null) ? (<div>{row.description} ({row.quantity})</div>) : (<div></div>);
    }

    handleUndelete = (e, row) => {
        this.setState({
            selectedOption: row, isConfirmShowing: true,
            isResponse: false, response: "", isProcessing: false,
        });
    }

    handlePrint = () => {
        let listStockToPrint = [];
        const { listStock } = this.state;
        listStock.map((box) => {
            if (box.is_undelete) {
                var index = listStock.findIndex(x => x.pallet_number === box.pallet_number && !x.is_undelete);
                if (index == -1)
                    listStockToPrint.push(box);
            } else {
                listStockToPrint.push(box);
            }
        })
        let printWindow = window.open('', '', 'height=500, width=500');
        printWindow.document.write(`
            <html>
            <body>
                <h1>VIEW DELETED ITEMS AND PALLETS</h1>
                <br>
                <table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">
                  <tr>
                      <th style="border:1px solid black; border-collapse: collapse">Pallet number</th>
                      <th style="border:1px solid black; border-collapse: collapse">Description</th>
                      <th style="border:1px solid black; border-collapse: collapse">Expiry date</th>
                      <th style="border:1px solid black; border-collapse: collapse">Category</th> 
                      <th style="border:1px solid black; border-collapse: collapse">Date deleted</th>
                  </tr>
        `);
        listStockToPrint.map((box) => {
            if (box.is_undelete) {
                printWindow.document.write(`
                    <tr>
                        <td style="border:1px solid black; border-collapse: collapse">${box.pallet_number}</td>
                        <td style="border:1px solid black; border-collapse: collapse"></td>
                        <td style="border:1px solid black; border-collapse: collapse"></td>
                        <td style="border:1px solid black; border-collapse: collapse"></td>
                        <td style="border:1px solid black; border-collapse: collapse">${getDateInGivenFormat(box.date_deleted?.slice(0, 10))}</td>
                    </tr>
                `);
            } else {
                printWindow.document.write(`
                    <tr>
                        <td style="border:1px solid black; border-collapse: collapse">${box.pallet_number}</td>
                        <td style="border:1px solid black; border-collapse: collapse">${box.description} (${box.quantity})</td>
                        <td style="border:1px solid black; border-collapse: collapse">${getDateInGivenFormat(box.expiry_date?.slice(0, 10))}</td>
                        <td style="border:1px solid black; border-collapse: collapse">${box.category}</td>
                        <td style="border:1px solid black; border-collapse: collapse">${getDateInGivenFormat(box.date_deleted?.slice(0, 10))}</td>
                    </tr>
                `);
            }
        });
        printWindow.document.write(`
            </table>
          </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
    }

    sortListStock = ({ target }) => {
        this.setState({ isDataLoading: true });
        let onlySortedPallets = [];
        let onlyPallets = [];
        let sortedListStock = [];
        const { listStock } = this.state;
        let listStocks = sortByAlphanumerically(listStock, 'description');
        listStocks.map((stock) => {
            if (stock.is_undelete)
                onlyPallets.push(stock);
        });
        switch (target.value) {
            case "ascDateDeleted":
                onlySortedPallets = sortByAlphanumerically(onlyPallets, 'date_deleted');
                break;
            case "desDateDeleted":
                onlySortedPallets = sortDescendingByAlphanumerically(onlyPallets, 'date_deleted');
                break;
            case "ascCategory":
                onlySortedPallets = sortByAlphanumerically(onlyPallets, 'category');
                break;
            case "desCategory":
                onlySortedPallets = sortDescendingByAlphanumerically(onlyPallets, 'category');
                break;
            case "ascDescription":
                onlySortedPallets = sortByAlphanumerically(onlyPallets, 'pallet_number');
                break;
            case "desDescription":
                onlySortedPallets = sortDescendingByAlphanumerically(onlyPallets, 'pallet_number');
                break;
            case "ascPalletNumber":
                onlySortedPallets = sortByAlphanumerically(onlyPallets, 'pallet_number');
                break;
            case "desPalletNumber":
                onlySortedPallets = sortDescendingByAlphanumerically(onlyPallets, 'pallet_number');
                break;
        }
        onlySortedPallets.map((pallet) => {
            sortedListStock.push(pallet);
            listStocks.map((listStock) => {
                if (!listStock.is_undelete && listStock.pallet_number === pallet.pallet_number)
                    sortedListStock.push(listStock);
            });
        })
        this.setState({ isDataLoading: false, listStock: sortedListStock });
    }

    getTodayDate = () => {
        let date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        let today = [year, month, day].join('-');;
        this.setState({ today: new Date(today) });
    }

    setStartDate = (value) => {
        this.setState({ startDate: value });
        const { today } = this.state;
        if (!value)
            this.setState({ errorResponseForStartDate: "Please enter date from", isValidStartDate: false, isEndDateDisabled: true, endDate: "", isValidEndDate: false });
        else if (value > today)
            this.setState({ errorResponseForStartDate: "Please enter past date", isValidStartDate: false, isEndDateDisabled: true, endDate: "", isValidEndDate: false });
        else
            this.setState({ errorResponseForStartDate: "", isValidStartDate: true, isEndDateDisabled: false, endDate: today, isValidEndDate: true });
    }

    validateStartDate = () => {
        const { startDate, today } = this.state;
        if (!startDate)
            this.setState({ errorResponseForStartDate: "Please enter date from", isValidStartDate: false });
        else if (startDate > today)
            this.setState({ errorResponseForStartDate: "Please enter past date", isValidStartDate: false });
        else
            this.setState({ errorResponseForStartDate: "", isValidStartDate: true });
    }

    setEndDate = (value) => {
        this.setState({ endDate: value });
        const { startDate, today } = this.state;
        if (!value)
            this.setState({ errorResponseForEndDate: "Please enter date to", isValidEndDate: false });
        else if (value < startDate)
            this.setState({ errorResponseForEndDate: "Please enter future date", isValidEndDate: false });
        else if (value > today)
            this.setState({ errorResponseForEndDate: "Please enter past date", isValidEndDate: false });
        else
            this.setState({ errorResponseForEndDate: "", isValidEndDate: true });
    }

    validateEndDate = () => {
        const { endDate, startDate, today } = this.state;
        if (!endDate)
            this.setState({ responseExpiryDate: "Please enter date to ", isValidEndDate: false });
        else if (endDate < startDate)
            this.setState({ responseExpiryDate: "Please enter future date", isValidEndDate: false });
        else if (endDate > today)
            this.setState({ errorResponseForEndDate: "Please enter past date", isValidEndDate: false });
        else
            this.setState({ errorResponseForEndDate: "", isValidEndDate: true });
    }

    handleYesBtn = () => {
        this.setState({ isResponse: false, theme: "", response: "", isProcessing: true });
        const { startDate, endDate, selectedOption, } = this.state;
        let startDateInIsoFormat = getSelectedDateFromDatePicker(startDate);
        let endDateInIsoFormat = getSelectedDateFromDatePicker(endDate);
        axios.post(`${ApiUrls.undeletePallet}?palletNumber=${selectedOption.pallet_number}`, {}, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success",
                    response: result.messageText, isDataLoading: true,
                    isConfirmShowing: false, listStock: [], isSearchBtnPressed: true
                });
                axios.get(`${ApiUrls.viewDeletedItemsAndPallets}?startDate=${startDateInIsoFormat}&endDate=${endDateInIsoFormat}`, authorizationHeader).then(({ data: result }) => {
                    if (result.isSuccess) {
                        let listStock = JSON.parse(result.data);
                        listStock = sortByAlphanumerically(listStock, 'pallet_number');
                        this.setState({ listStock, isDataLoading: false });
                    }
                }).catch((error) => {
                    this.showError(error);
                });
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: result.Message,
                    isConfirmShowing: false, isProcessing: false
                });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isConfirmShowing: false });
        }
    }
}