import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem
} from "shards-react";

export class BottleRoom extends Component {

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const BlueButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#0000FF",
            borderColor: "#0000FF",
        };

        const OrangeButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#FF4500",
            borderColor: "#FF0000"
        };

        const GreenButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#708B39",
            borderColor: "#708B39",
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Bottle Room Interface</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop:"2%"}}>
                        <CardBody>

                            <Row>
                                <Col sm="12" lg="6" lg="6">
                                    <FormGroup>
                                        <Button style={OrangeButton} size="lg" onClick={() => window.location.href = "/stock-in"}>Stock in</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6" lg="6">
                                    <FormGroup>
                                        <Button style={OrangeButton} size="lg" onClick={() => window.location.href = "/report-on-current-stock"}>Report on Current Stock​</Button>	
                                    </FormGroup>
                                </Col>                                
                            </Row>

                            <Row>
                                <Col sm="12" lg="6" lg="6">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/start-new-pallet/?location=bottle"}>Start New Pallet​</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6" lg="6">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/finish-pallet/?location=bottle"}>Finish Pallet</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            

                            <Row>
                                <Col sm="12" lg="6" lg="6">
                                    <FormGroup>
                                        <Button style={GreenButton} size="lg" onClick={() => window.location.href = "/put-stock-on-bottle-room"}>Put Stock on Pallet​</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6" lg="6">
                                    <FormGroup>
                                        <Button style={GreenButton} size="lg" onClick={() => window.location.href = "/edit-pallet/?location=bottle"}>Edit a Pallet</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
            </>
            );
    }
}