import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput
} from "shards-react";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { getPalletCategoryNameWithId, FeaturePalletTypes } from "../../helpers/palletHelper"

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class EditPallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isPalletsLoading: false,
            isErrorInPallet: false,
            isDataLoading: false,
            viewPalletContents: false,
            isRemoveBoxesBtnDisabled: true,
            showConfirm: false,
            isJSpallet: false,
            isPalletCategoriesLoading: false,
            theme: "",
            response: "",
            palletquery: "",
            numberOfBoxes: "",
            totalWeight: "",
            locationId: "",
            location: "",
            removeBtnText: "",
            addBtnText: "",
            boxContents: [],
            palletInfo: [],
            selectedPallet: [],
            pallets: [],
            palletCategories: new Map()
        };
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('location')
        this.setState({ location: location, isPalletCategoriesLoading: true });
        if (location == "warehouse") {
            this.setState({ locationId: "", removeBtnText: "Remove Boxes", addBtnText: "Add Boxes", });
        }
        else if (location == "medical") {
            this.setState({ locationId: 8, removeBtnText: "Remove Boxes", addBtnText: "Add Boxes", });
        }
        else if (location == "food") {
            this.setState({ locationId: 9, removeBtnText: "Remove Boxes/Items", addBtnText: "Put Boxes/Items", });
        }
        else if (location == "bottle") {
            this.setState({ locationId: 5, removeBtnText: "Remove Bottle Medicine", addBtnText: "Put Bottle Medicine", });
        }
        this.getPalletCategories();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const columns =
            [
                {
                    text: 'Content',
                    formatter: this.description,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Description',
                    dataField: 'description_content',
                    hidden: !this.state.isJSpallet,
                    style: {
                        fontWeight: 'normal'
                    }
                },

                {
                    text: 'Expiry date',
                    formatter: this.expiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Weight',
                    formatter: this.Weight,
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];
        const bottleColumns =
            [
                {
                    text: 'Description',
                    formatter: this.description,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Batch number',
                    dataField: 'batchnumber',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.expiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];
        const {
            location,
            isResponse,
            theme,
            response,
            selectedPallet,
            pallets,
            isPalletsLoading,
            viewPalletContents,
            isDataLoading,
            palletInfo,
            boxContents,
            isRemoveBoxesBtnDisabled,
            removeBtnText,
            addBtnText,
            showConfirm,
            isJSpallet,
            numberOfBoxes,
            totalWeight,
            palletCategories,
        } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            {location == "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "medical" && (
                                <BreadcrumbItem>
                                    <a href="/medical-disposables">Medical Disposable Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "food" && (
                                <BreadcrumbItem>
                                    <a href="/food">Food Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "bottle" && (
                                <BreadcrumbItem>
                                    <a href="/bottle-room">Bottle Room Interface</a>
                                </BreadcrumbItem>
                            )}
                            <BreadcrumbItem active>Edit a Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>EDIT A PALLET</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#pallet">Select a pallet</label>
                                        <AsyncTypeahead size="lg" value={selectedPallet} onChange={this.setPallet} options={pallets} isLoading={isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {viewPalletContents && (
                                        <div>
                                            {(isDataLoading && isPalletsLoading) &&(
                                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                                            )}
                                            {(!isDataLoading && !isPalletsLoading) && (
                                                <div style={{ width: "100%" }}>
                                                    { palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicinesAndCreams && (
                                                        <>
                                                            {boxContents.length >= 1 && (
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={boxContents}
                                                                    columns={bottleColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            )}
                                                            {boxContents == 0 && (
                                                                <Alert theme="warning">Pallet is Empty</Alert>
                                                            )}
                                                            <br />
                                                            <Row>
                                                                <Col sm="12" md="4" lg="4">
                                                                    <Button style={{ margin: "1%" }} size="lg" block disabled={isRemoveBoxesBtnDisabled} onClick={e => this.onRemoveBoxesBtnClick(e)}>{removeBtnText}</Button>
                                                                </Col>
                                                                <Col sm="12" md="4" lg="4">
                                                                    <Button style={{ margin: "1%" }} size="lg" block onClick={e => this.onAddBoxesBtnClick(e)}>{addBtnText}</Button>
                                                                </Col>
                                                                <Col sm="12" md="4" lg="4">
                                                                    <Button style={{ margin: "1%" }} size="lg" block onClick={this.onConfirmClick}>Print</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                    { ((palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicalDisposables)
                                                        || (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Food)) && (
                                                        <>
                                                                {boxContents.length >= 1 && (
                                                                    <BootstrapTable
                                                                        keyField='id'
                                                                        data={boxContents}
                                                                        columns={columns}
                                                                        wrapperClasses="table-responsive"
                                                                        pagination={paginationFactory()}
                                                                    />
                                                                )}
                                                                {boxContents == 0 && (
                                                                    <Alert theme="warning">Pallet is Empty</Alert>
                                                                )}
                                                                <br />
                                                                <Row>
                                                                    <Col sm="12" md="4" lg="4">
                                                                        <Button style={{ margin: "1%" }} size="lg" block disabled={isRemoveBoxesBtnDisabled} onClick={e => this.onRemoveBoxesBtnClick(e)}>{removeBtnText}</Button>
                                                                    </Col>
                                                                    <Col sm="12" md="4" lg="4">
                                                                        <Button style={{ margin: "1%" }} size="lg" block onClick={e => this.onAddBoxesBtnClick(e)}>{addBtnText}</Button>
                                                                    </Col>
                                                                    <Col sm="12" md="4" lg="4">
                                                                        <Button style={{ margin: "1%" }} size="lg" block onClick={this.onConfirmClick}>Print</Button>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                    )}
                                                    { palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Other && (
                                                        <>
                                                                {console.log(palletInfo)}
                                                                <Row>
                                                                    <Col sm="12" md="10" lg="8">
                                                                        <FormGroup>
                                                                            <label style={{ fontSize: "large" }} htmlFor="#Description">Description :</label>
                                                                            <FormInput size="lg" type="text" id="#Description" value={palletInfo.Description} disabled />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="12" md="10" lg="8">
                                                                        <FormGroup>
                                                                            <label style={{ fontSize: "large" }} htmlFor="#Weight">Weight (kg):</label>
                                                                            <FormInput size="lg" type="text" id="#Weight" value={palletInfo.TotalWeight} disabled />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="12" md="10" lg="8">
                                                                        <FormGroup>
                                                                            <label style={{ fontSize: "large" }} htmlFor="#LocationStored">Location Stored :</label>
                                                                            <FormInput size="lg" type="text" id="#LocationStored" value={palletInfo.StorageLocation} disabled />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <br />
                                                                <Row>
                                                                    <Col sm="12" md="4" lg="4">
                                                                        <Button style={{ margin: "1%" }} size="lg" block disabled onClick={e => this.onRemoveBoxesBtnClick(e)}>{removeBtnText}</Button>
                                                                    </Col>
                                                                    <Col sm="12" md="4" lg="4">
                                                                        <Button style={{ margin: "1%" }} size="lg" block disabled onClick={e => this.onAddBoxesBtnClick(e)}>{addBtnText}</Button>
                                                                    </Col>
                                                                    <Col sm="12" md="4" lg="4">
                                                                        <Button style={{ margin: "1%" }} size="lg" block onClick={this.onConfirmClick}>Print</Button>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {showConfirm && (
                    <Modal open={showConfirm} toggle={() => this.setState({ showConfirm: !showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>Pallet Number : {selectedPallet[0].id}</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicinesAndCreams && (<>
                                        <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                            <table style={{ width: "100%", border: "1px solid black" }}>
                                                <tr style={{ border: "1px solid black" }}>
                                                    <th style={{ border: "1px solid black", fontSize: "18px" }}>Description</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px" }}>Batch number</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px" }}>Expiry date</th>
                                                </tr>
                                                {boxContents.map((box) => {
                                                    return (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black", fontSize: "15px" }}>{box.description}( {box.numberofboxes} )</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px" }}>{box.batchnumber}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px" }}>{getDateInGivenFormat(box.expirydate?.slice(0, 10))}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </div>
                                    </>)}
                                    {((palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicalDisposables)
                                        || (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Food)) && (<>
                                        <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                            <table style={{ width: "100%", border: "1px solid black" }}>
                                                <tr style={{ border: "1px solid black" }}>
                                                    <th style={{ border: "1px solid black", fontSize: "18px" }}>Content</th>
                                                    {isJSpallet && (<><th style={{ border: "1px solid black", fontSize: "18px" }}>Description</th></>)}
                                                    <th style={{ border: "1px solid black", fontSize: "18px" }}>Expiry date</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px" }}>Weight</th>
                                                </tr>
                                                {boxContents.map((box) => {
                                                    return (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black", fontSize: "15px" }}>{box.description}( {box.numberofboxes} )</td>
                                                            {isJSpallet && (<><td style={{ border: "1px solid black", fontSize: "15px" }}>{box.description_content}</td></>)}
                                                            <td style={{ border: "1px solid black", fontSize: "15px" }}>{getDateInGivenFormat(box.expirydate?.slice(0, 10))}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px" }}>{box.weight} kg</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Number of boxes : {numberOfBoxes} </label>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Total weight : {totalWeight} kg </label>
                                        </div>
                                    </>)}
                                    {palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Other && (<>
                                        <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Description : {palletInfo.Description}</label>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Weight : {palletInfo.TotalWeight} kg</label>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Location stored : {palletInfo.StorageLocation}</label>
                                        </div>
                                    </>)}
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.print} >Print</Button>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ showConfirm: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getPalletCategories = () => {
        axios.get(ApiUrls.getPalletCategories, authorizationHeader).then(({ data: result}) => {
            if (result.isSuccess) {
                let palletCategories = JSON.parse(result.data);
                console.log(palletCategories);
                let palletCategoryIdWithFeatureTypeId = getPalletCategoryNameWithId(palletCategories);
                console.log(palletCategoryIdWithFeatureTypeId);
                if (palletCategoryIdWithFeatureTypeId.size !== 0) {
                    this.setState({ palletCategories: palletCategoryIdWithFeatureTypeId, isPalletCategoriesLoading: false });
                } else {
                    this.setState({ palletCategories: new Map(), isPalletCategoriesLoading: true });
                }
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    description = (cell, row) => {
        console.log(row)
        return (<div>
            {row.description} ({row.numberofboxes})
        </div>)
    }

    expiryDate = (cell, row) => {
        var expiryDate = row.expirydate;
        var date;
        expiryDate ? date = getDateInGivenFormat(expiryDate.slice(0, 10)) : date = '';
        return (<div>{date}</div>)
    }

    Weight = (cell, row) => {
        return (<div>{row.totalweight}kg</div>)
    }

    print = (value) => {
        var divContents = document.getElementById("PrintArea").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > <h1>Pallet Number :' + this.state.selectedPallet[0].id + '</h1><br>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        this.setState({ showConfirm: false })
    }

    onConfirmClick = (value) => {
        this.setState({ showConfirm: true })
    }

    onRemoveBoxesBtnClick = (e) => {
        const { location, selectedPallet } = this.state;
        if (location) {
            window.location.href = `/remove-boxes/?pallet=${selectedPallet[0].id}&location=${location}`;
        }
    };

    onAddBoxesBtnClick = (e) => {
        const { location, isPalletCategoriesLoading, palletCategories, palletInfo, selectedPallet } = this.state;
        if (!isPalletCategoriesLoading) {
            if (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicinesAndCreams) {
                let storageLocation = (location == "warehouse") ? location : "bottle";
                window.location.href = `/put-stock-on-bottle-room/?pallet=${selectedPallet[0].id}&location=${storageLocation}`;
                return;
            }
            if (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicalDisposables) {
                let storageLocation = (location == "warehouse") ? location : "medical";
                window.location.href = `/add-boxes-to-pallet/?pallet=${selectedPallet[0].id}&location=${storageLocation}`;
                return;
            }
            if (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Food) {
                let storageLocation = (location == "warehouse") ? location : "food";
                window.location.href = `/put-food-on-pallet/?pallet=${selectedPallet[0].id}&location=${storageLocation}`;
                return;
            }
        } 
    };

    setPallet = (value) => {
        this.setState({ selectedPallet: value, isErrorInPallet: false, isResponse: false, theme: "", response: "" })
        if (this.state.selectedPallet.length === 0) {
            this.setState({ isErrorInPallet: false, isResponse: false, theme: "", response: "", viewPalletContents: true, isDataLoading: true })
            axios.get(`${ApiUrls.viewPalletContents}?palletNumber=${value[0].id}&location=${this.state.location}`, authorizationHeader).then((response) => {
                var palletContent = JSON.parse(response.data.data);
                var boxContents = palletContent.PalletBoxContents;
                var palletInfo = palletContent.PalletInfo;
                (palletInfo.PalletCategory == 10) ? this.setState({ isJSpallet: true }) : this.setState({ isJSpallet: false });
                var sortBoxContents = sortByAlphanumerically(boxContents, 'description');
                var numberOfBoxes = 0;
                var totalWeight = 0;
                boxContents.map((box) => {
                    numberOfBoxes += box.numberofboxes;
                    totalWeight += box.totalweight;
                })
                this.setState({ boxContents: sortBoxContents, isDataLoading: false, numberOfBoxes: numberOfBoxes, totalWeight: totalWeight, palletInfo: palletInfo })
                if (boxContents == "") { this.setState({ isRemoveBoxesBtnDisabled: true }) } else { this.setState({ isRemoveBoxesBtnDisabled: false }) }
            }).catch((error) => {
                this.showError(error);
            });

        } else {
            this.setState({ isErrorInPallet: true, isResponse: false, theme: "", response: "", viewPalletContents: false })
        }
    };

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery: palletquery, isResponse: false, theme: "", response: "" });
    };

    handlePalletSearch = palletquery => {
        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.sortOptions,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getEditablePallets}?query=${palletquery}&locId=${this.state.locationId}`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                console.log(pallets);
                const options = pallets.map(i => ({
                    id: i
                }));
                var sortOptions = sortByAlphanumerically(options, 'id');
                console.log(sortOptions);
                var total_count = sortOptions.length;
                return { sortOptions, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}
