import 'bootstrap/dist/css/bootstrap.css';
import "shards-ui/dist/css/shards.min.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { CookiesProvider } from "react-cookie";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <CookiesProvider>
            <App />
            </CookiesProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

