import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Container, Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class AddPalletDonation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            donationNumber: "",

            selectedPallet: [],
            pallets: [],
            isPalletsLoading: false,
            isErrorInPallet: false,
            palletquery: "",

            showConfirm: false,
            isFinishPalletBtnDisabled: true,
            buttonText: "Confirm",
        };
        this.palletNumber = React.createRef();
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const donationNumber = urlParams.get('donationNumber')
        this.setState({ donationNumber: donationNumber });
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };


        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/send-small-donation">Small Donations​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href={"/make-small-donation/?donationNumber="+this.state.donationNumber}>Make a Small Donation​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Add a Pallet​</BreadcrumbItem> 
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>MAKE A SMALL DONATION - PALLET</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#pallet">Select a pallet :</label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            ref={this.palletNumber}
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>

                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />

                                        {(this.state.isErrorInPallet) && (
                                            <span className="error-txt">Please select a pallet</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick} disabled={this.state.isFinishPalletBtnDisabled}>{this.state.buttonText}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirm && (
                    <Modal open={this.state.showConfirm} toggle={() => this.setState({ showConfirm: !this.state.showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>MAKE A SMALL DONATION - PALLET - CONFIRMATION</h2></Col>
                                </Row>
                                <hr />
                                <Row>

                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to add the pallet number {this.state.selectedPallet[0].id} to small donation (Donation number : {this.state.donationNumber})? </label>
                                    </Col>

                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" onClick={this.confirmClick} disabled={this.state.isProcessing ? true : false}>{this.state.isProcessing ? "Loading..." : "Yes"}</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ showConfirm: false })}>No</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )}

            </>
        );
    }

    onConfirmClick = (value) => {
        this.setState({ showConfirm: true })
    }

    setPallet = (value) => {
        this.setState({
            selectedPallet: value, isResponse: false, theme: "", response: "",
        })

        if (value.length == 0) {
            this.setState({
                isErrorInPallet: true, isFinishPalletBtnDisabled: true
            })
        } else {
            this.setState({
                isErrorInPallet: false, isFinishPalletBtnDisabled: false
            })
        }   
    };

    handlePalletInputChange = palletquery => {
        this.setState({
            palletquery, isResponse: false, theme: "",
            response: "", isFinishPalletBtnDisabled: true
        });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };


    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getPalletsForSmallDonation}?query=${palletquery}`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));  
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    confirmClick = (value) => {
        this.setState({ buttonText: "Loading...", isFinishPalletBtnDisabled: true, isProcessing: true })

        var DonationPallet = {
            DonationId: parseInt(this.state.donationNumber),
            PalletNumber: this.state.selectedPallet[0].id.toString(),
        }

        axios.post(ApiUrls.addPalletDonation, DonationPallet, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {                
                this.palletNumber.current.clear();
                this._cache = {};
                this.setState({
                    isResponse: true, theme: "success", response: response.data.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                    isProcessing: false, isErrorInPallet: false
                })
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: response.data.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                    isProcessing: false,isErrorInPallet: false
                })
            }
        }).catch((error) => {
            this.showError(error);           
        });

    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, theme: "danger", response: "Something went wrong",
                buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                isProcessing: false, isErrorInPallet: false
            })
        }
    }
}