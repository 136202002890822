import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { Dashboard } from './Dashboard/Dashboard';
import Login from './User/Login';
import { Layout } from './Layout';

class Home extends Component {
    
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
    }
  render () {
      return (
      <div>
              { this.props.cookies.get("loggedUserInfo") && (
                  <Layout><Dashboard></Dashboard></Layout>
              )}
              { !this.props.cookies.get("loggedUserInfo") && (
                  <Login></Login>
              )}
              </div>
    );
    }
}
export default withCookies(Home);