import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem
} from "shards-react";

export class MakeSmallDonation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            donationNumber:""
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const donationNumber = urlParams.get('donationNumber')
        this.setState({ donationNumber: donationNumber });
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const RedButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#FF0000",
            borderColor: "#FF0000"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/send-small-donation">Small Donations​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Make a small Donation​</BreadcrumbItem>                          
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={RedButton} size="lg" onClick={() => window.location.href = "/add-pallet-donation/?donationNumber=" + this.state.donationNumber}>Add a Pallet to the Small Donation </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={RedButton} size="lg" onClick={() => window.location.href = "/add-non-pallet-donation/?donationNumber=" + this.state.donationNumber}>Add a Non-Pallet item to the Small Donation ​</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={RedButton} size="lg" onClick={() => window.location.href = "/add-item-donation/?donationNumber=" + this.state.donationNumber}>Add an item not in the stock record to the Small Donation </Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={RedButton} size="lg" onClick={() => window.location.href = "/current-items-donation/?donationNumber=" + this.state.donationNumber}>See all items currently in this Small Donation </Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4"></Col>
                                <Col sm="12" lg="4" lg="4"></Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
            </>
        );
    }
}