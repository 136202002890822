import axios from "axios";
import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
    Alert, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle,
    Col, FormGroup, FormInput, Modal, ModalBody, Row
} from "shards-react";
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { StorageLocation } from "../common/StorageLocation";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class MovePendingPallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            selectedPallet: [],
            pallets: [],
            isPalletsLoading: false,
            palletquery: "",
            locationId: 7,
            currentLocation: [],
            responseSelectedPallet: "",
            validSelectedPallet: false,

            selectedPrimaryLocation: [],
            validPrimaryLocation: false,

            selectedSecondaryLocation: [],
            validSecondaryLocation: false,
            isSecondaryLocationLoading: false,

            weight: "",
            responseWeight: "",
            validWeight: false,

            printConfirmation: false,
            showConfirmation: false,
            yesBtnText: "Yes",
            isYesBtnDisabled: false,

            isFoodContainer: false,
            isBottleRoom: false,
        };
        this.StorageLocation = React.createRef();
    }

    _cache = {};

    componentDidMount() {

    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Choose storage location for  “Pending”  Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>CHOOSE LOCATION FOR “PENDING” PALLET</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }}  htmlFor="#pallet">Select a pallet :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        <span className="error-txt">{this.state.responseSelectedPallet}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {!(this.state.currentLocation.length === 0) && (
                                <div>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#CurrentLocation">Current Location :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#CurrentLocation" value={this.state.currentLocation.PrimaryLocation} disabled />

                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {(this.state.currentLocation.SecondaryStorageLocation) && (
                                        <Row>
                                            <Col sm="12" md="10" lg="8">
                                                <FormGroup>
                                                    <label style={{ fontSize: "large" }} htmlFor="#SecondaryCurrentLocation">Secondary Location :<span className="error-txt"></span></label>
                                                    <FormInput size="lg" type="text" id="#SecondaryCurrentLocation" value={this.state.currentLocation.SecondaryStorageLocation} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    <StorageLocation ref={this.StorageLocation}
                                        getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                        getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                        getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                        getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                        getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                                    />

                                    {this.state.isBottleRoom && (
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                    <label style={{ fontSize: "large" }} htmlFor="#weight">Weight :<span className="error-txt">*</span></label>
                                                    <FormInput size="lg" type="number" id="#weight" value={this.state.weight} onChange={this.setWeight} min="1" />
                                                <span className="error-txt">{this.state.responseWeight}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    )}

                                    {this.state.isFoodContainer && (
                                        <Row>
                                            <Col sm="12" md="10" lg="8">
                                                <FormGroup>
                                                    <label style={{ fontSize: "large" }} htmlFor="#weight">Weight :<span className="error-txt">*</span></label>
                                                    <FormInput size="lg" type="number" id="#weight" value={this.state.weight} onChange={this.setWeight} min="1" />
                                                    <span className="error-txt">{this.state.responseWeight}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </div>
                            )}
                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>CHOOSE LOCATION FOR "PENDING" PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to locate pallet number {this.state.selectedPallet[0].id.toString()}
                                      &nbsp;in location {this.state.selectedPrimaryLocation[0].description}
                                      {this.state.selectedSecondaryLocation.length !== 0 && (<>({this.state.selectedSecondaryLocation[0].Name})</>)}?
                                    </label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}

                {this.state.printConfirmation && (
                    <Modal open={this.state.printConfirmation} toggle={() => this.setState({ printConfirmation: this.state.printConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>

                            <Row>

                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "150px" }}>{this.state.selectedPallet[0].id.toString()}</label>
                                </Col>

                                <Col sm="12" md="12" lg="12">
                                    <label><h5>To Location : {this.state.selectedPrimaryLocation[0].description}</h5></label>
                                </Col>

                                {this.state.weight && (
                                        <Col sm="12" md="12" lg="12">
                                        <label><h5>Weight : {this.state.weight}kg</h5></label>
                                        </Col>
                                )}
                              
                            </Row>
                            <br></br>


                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.printLable} >Print</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoPrintClick} >No</Button>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}

            </>
        );
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value });
        if (value !== null && value.length > 0) {
            if (value[0].id == 5) {
                this.setState({ isBottleRoom: true });
            } else if (value[0].id == 9) {
                this.setState({ isFoodContainer: true });
            } else {
                this.setState({ isBottleRoom: false, isFoodContainer: false });
            }
        } else {
            this.setState({ isBottleRoom: false, isFoodContainer: false });
        }
        
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ validPrimaryLocation: value });

    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ validSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    resetStorageLocation() {
        this.refs.StorageLocation.resetStorageLocation();
    }

    validatePrimaryLocation() {
        this.refs.StorageLocation.validatePrimaryLocation();
    }

    validateSecondaryLocation() {
        this.refs.StorageLocation.validateSecondaryLocation();
    }

    setPallet = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({ selectedPallet: value, responseSelectedPallet: "", validSelectedPallet: true })
            axios.get(`${ApiUrls.getCurrentPalletLocation}?palletNumber=${value[0].id}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var currentLocation = JSON.parse(response.data.data);
                    this.setState({ currentLocation: currentLocation });
                }
            }).catch((error) => {
                this.showError(error);
            });
        } else {
            this.setState({ selectedPallet: [], responseSelectedPallet: "Please select a pallet", validSelectedPallet: false, currentLocation: [] })
        }
    };

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isResponse: false });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getPallets}?query=${palletquery}&locId=${this.state.locationId}&includeDeleted=false`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                console.log(options);
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    setWeight = (value) => {
        this.setState({ weight: value.target.value });
        if (!(value.target.value)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (value.target.value < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    ValidationWeight = () => {
        if (!(this.state.weight)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(this.state.weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (this.state.weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    onConfirmClick = () => {
        this.StorageLocation.current.validatePrimaryLocation();
        this.ValidationWeight();
        if (!this.state.isSecondaryLocationLoading && !this.state.validSecondaryLocation) {
            this.StorageLocation.current.validateSecondaryLocation();
        }
        const { validSelectedPallet, validStorageLocation, validWeight, isFoodContainer, isBottleRoom, validPrimaryLocation, validSecondaryLocation, isSecondaryLocationLoading } = this.state;
        if (validSelectedPallet && validStorageLocation && validPrimaryLocation, validSecondaryLocation && !isSecondaryLocationLoading) {
            if (isFoodContainer || isBottleRoom) {
                if (validWeight) {
                    this.setState({ showConfirmation: true });
                }
            } else {
                this.setState({ showConfirmation: true });
            }
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false});
    }

    onNoPrintClick = () => {
        this.setState({
            selectedPallet: [], pallets: [], isPalletsLoading: false, palletquery: "", currentLocation: [], responseSelectedPallet: "",
            validSelectedPallet: false, selectedStorageLocation: [], responseStorageLocation: "", storageLocationsDescription: "",
            storageLocationsID: "", validStorageLocation: false, weight: "", responseWeight: "", validWeight: false, printConfirmation: false,
            showConfirmation: false, yesBtnText: "Yes", isYesBtnDisabled: false, isFoodContainer: false, isBottleRoom: false,
        });
        this._cache = {};
    }

    onYesClick = () => {
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });
      
        if (this.state.isFoodContainer || this.state.isBottleRoom) {
            if (this.state.selectedSecondaryLocation.length !== 0) { 
                var UpdatePalletLocationInfo = {
                    PalletNumber: this.state.selectedPallet[0].id.toString(),
                    StorageLocationID: parseInt(this.state.selectedPrimaryLocation[0].id),
                    SecondaryStorageLocationID: parseInt(this.state.selectedSecondaryLocation[0].Id),
                    Weight: parseFloat(this.state.weight),
                };
            } else {
                var UpdatePalletLocationInfo = {
                    PalletNumber: this.state.selectedPallet[0].id.toString(),
                    StorageLocationID: parseInt(this.state.selectedPrimaryLocation[0].id),
                    SecondaryStorageLocationID: 0,
                    Weight: parseFloat(this.state.weight),
                };
            }
        } else {
            if (this.state.selectedSecondaryLocation.length !== 0) {
                var UpdatePalletLocationInfo = {
                    PalletNumber: this.state.selectedPallet[0].id.toString(),
                    StorageLocationID: parseInt(this.state.selectedPrimaryLocation[0].id),
                    SecondaryStorageLocationID: parseInt(this.state.selectedSecondaryLocation[0].Id),
                }
            } else {
                var UpdatePalletLocationInfo = {
                    PalletNumber: this.state.selectedPallet[0].id.toString(),
                    StorageLocationID: parseInt(this.state.selectedPrimaryLocation[0].id),
                    SecondaryStorageLocationID: 0,
                }
            }     
        }
        
        axios.post(ApiUrls.updatePalletLocation, UpdatePalletLocationInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isResponse: true, response: "Now Attach the Printed Pallet Labels before shrink wrapping & storing Pallet",
                    theme: "success", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false, printConfirmation: true
                });
            }
            else {
                this.setState({
                    isResponse: true, response: response.data.messageText, theme: "danger", isYesBtnDisabled: false,
                    yesBtnText: "Yes", showConfirmation: false
                });
            }
        }).catch((error) => {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger", isYesBtnDisabled: false, yesBtnText: "Yes",
                showConfirmation: false
            });
            this.showError(error);
        });
    }

    printLable = () => {
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > <p style="font-size: 50vw; display: inline"> ' + this.state.selectedPallet[0].id.toString() + '</p>');
        a.document.write(' <p style="font-size: 5vw"> To Location : ' + this.state.selectedPrimaryLocation[0].description +'</p>');
        if (this.state.selectedSecondaryLocation.length !== 0) {
            a.document.write(' <p style="font-size: 5vw"> Secondary location : ' + this.state.selectedSecondaryLocation[0].Name + '</p>');
        }
        if (this.state.weight) {
            a.document.write(' <p style="font-size: 5vw"> weight : ' + this.state.weight + 'kg</p>');
        }
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        this.setState({
            selectedPallet: [], pallets: [], isPalletsLoading: false, palletquery: "", currentLocation: [], responseSelectedPallet: "",
            validSelectedPallet: false, selectedStorageLocation: [],  responseStorageLocation: "", storageLocationsDescription: "",
            storageLocationsID: "", validStorageLocation: false, weight: "", responseWeight: "", validWeight: false, printConfirmation: false,
            showConfirmation: false, yesBtnText: "Yes", isYesBtnDisabled: false, isFoodContainer: false, isBottleRoom: false,
        });
        this._cache = {};
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}