import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, FormGroup,
    Alert, FormSelect, FormCheckbox
} from "shards-react";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DateInput } from '@opuscapita/react-dates';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { getSelectedDateFromDatePicker } from '../../utils/formatDateFromDatePicker';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ListShippedContainersAndSmallDonations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isCheckedContainers: true,
            isCheckedDonations: true,
            isDisabledContainers: false,
            isDisabledDonations: false,
            isDataLoading: false,
            isChecked: true,
            isCategory: true,
            isCountry: false,
            isSearchBtnPressed: false,
            isListAll: true,
            isContainerList: false,
            isDonationList: false,
            isValidEndDate: false,
            isEndDateDisabled: true,
            isValidStartDate: false,
            theme: "",
            response: "",
            startDate: "",
            errorResponseForStartDate: "",
            endDate: "",
            errorResponseForEndDate: "",
            today: "",
            selectedStartDate: "",
            selectedEndDate: "",
            listStock: [],
            totalsOnlyByProductCategory: [],
            totalsOnlyByCountry: [],
            byProductCategory: [],
            byCountry: [],
            product: [],
            allItemsByCategory: [],
            containersByCategory: [],
            donationsByCategory: [],
            allItemsByCountry: [],
            containersByCountry: [],
            donationsByCountry: [],
            allItemsByCategoryTotalOnly: [],
            containersByCategoryTotalOnly: [],
            donationsByCategoryTotalOnly: [],
            allItemsByCountryTotalOnly: [],
            containersByCountryTotalOnly: [],
            donationsByCountryTotalOnly: [],
        };
    }

    componentDidMount() {
        this.getTodayDate();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const columns =
            [
                {
                    text: 'Item',
                    dataField: 'item_description',
                    style: {
                        fontWeight: 'normal',
                        width: '60%'
                    }
                },
                {
                    text: 'Price',
                    formatter: this.handlePrice,
                    style: {
                        fontWeight: 'normal',
                        width: '40%'
                    }
                },
            ];
        const categoryColumns =
            [
                {
                    text: 'Product category',
                    dataField: 'product_category_description',
                    style: {
                        fontWeight: 'normal',
                        width: '60%'
                    }
                },
                {
                    text: 'Total price',
                    formatter: this.handlePrice,
                    style: {
                        fontWeight: 'normal',
                        width: '40%'
                    }
                },
            ];
        const countryColumns =
            [
                {
                    text: 'Country',
                    dataField: 'country',
                    style: {
                        fontWeight: 'normal',
                        width: '60%'
                    }
                },
                {
                    text: 'Total price',
                    formatter: this.handlePrice,
                    style: {
                        fontWeight: 'normal',
                        width: '40%'
                    }
                },
            ];
        const { isResponse, theme, response, startDate, today, errorResponseForStartDate, endDate,
            isEndDateDisabled, errorResponseForEndDate, isDataLoading, isSearchBtnPressed, isDonationList,
            listStock, isCheckedContainers, isDisabledContainers, isCheckedDonations, isDisabledDonations,
            isChecked, isListAll, isCategory, containersByCategoryTotalOnly, allItemsByCategoryTotalOnly,
            isCountry, allItemsByCountryTotalOnly, allItemsByCategory, allItemsByCountry, isContainerList,
            containersByCountryTotalOnly, containersByCategory, containersByCountry, donationsByCountry,
            donationsByCategoryTotalOnly, donationsByCountryTotalOnly, donationsByCategory } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>List Shipped Containers & Small Donations</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>LIST SHIPPED CONTAINERS & SMALL DONATIONS</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} >Select a date range you want to look at all that has shipped</label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="6" lg="6">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#startDate">From :<span className="error-txt">*</span></label>
                                        <DateInput
                                            id="#startDate"
                                            value={startDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setStartDate}
                                            max={today}
                                            modifiers={{
                                                disabled: { after: new Date() }
                                            }}
                                        />
                                        <span className="error-txt">{errorResponseForStartDate}</span>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6" lg="6">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#endDate">To :<span className="error-txt">*</span></label>
                                        <DateInput
                                            id="#endDate"
                                            value={endDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setEndDate}
                                            max={today}
                                            min={startDate}
                                            disabled={isEndDateDisabled}
                                            modifiers={{
                                                disabled: {
                                                    before: startDate,
                                                    after: new Date()
                                                }
                                            }}
                                        />
                                        <span className="error-txt">{errorResponseForEndDate}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (
                                <>
                                    {isSearchBtnPressed && (
                                        <>
                                            {listStock.length != 0 && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="4" lg="4">
                                                            <FormGroup >
                                                                <FormCheckbox size="lg" checked={isCheckedContainers} disabled={isDisabledContainers} onChange={this.handleCheckForShippedContainers}>Shipped containers</FormCheckbox>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="12" md="4" lg="4">
                                                            <FormGroup >
                                                                <FormCheckbox size="lg" checked={isCheckedDonations} disabled={isDisabledDonations} onChange={this.handleCheckForSmallDonations}>Small donations</FormCheckbox>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="12" md="4" lg="4">
                                                            <FormGroup >
                                                                <FormSelect size="lg" onChange={this.sortListStock}>
                                                                    <option value="Category">By category</option>
                                                                    <option value="Country">By country</option>
                                                                </FormSelect>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <FormGroup >
                                                                <FormCheckbox size="lg" checked={isChecked} onChange={this.handleCheckForTotals} >Totals only</FormCheckbox>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {isListAll && (<>
                                                        {isChecked && (<>
                                                            {isCategory && (<>
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={allItemsByCategoryTotalOnly}
                                                                    columns={categoryColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            </>)}
                                                            {isCountry && (<>
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={allItemsByCountryTotalOnly}
                                                                    columns={countryColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            </>)}
                                                        </>)}
                                                        {!isChecked && (<>
                                                            {isCategory && (<>
                                                                {allItemsByCategory.length != 0 && (
                                                                    <>
                                                                        {allItemsByCategory.map((stock) => {
                                                                            return (
                                                                                <>
                                                                                    <h5><b>{stock.product_category_description}:</b></h5>
                                                                                    <h5>Total {stock.product_category_description} &#163;{stock.total_price.toFixed(2)}</h5>
                                                                                    <BootstrapTable
                                                                                        keyField='id'
                                                                                        data={stock.product}
                                                                                        columns={columns}
                                                                                        wrapperClasses="table-responsive"
                                                                                        pagination={paginationFactory()}
                                                                                    />
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </>)}
                                                            {isCountry && (<>
                                                                {allItemsByCountry.length != 0 && (
                                                                    <>
                                                                        {allItemsByCountry.map((stock) => {
                                                                            return (
                                                                                <>
                                                                                    <h5><b>{stock.country}:</b></h5>
                                                                                    <h5>Total item price sent &#163;{stock.total_price.toFixed(2)}</h5>
                                                                                    <BootstrapTable
                                                                                        keyField='id'
                                                                                        data={stock.product}
                                                                                        columns={columns}
                                                                                        wrapperClasses="table-responsive"
                                                                                        pagination={paginationFactory()}
                                                                                    />
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </>)}
                                                        </>)}
                                                    </>)}
                                                    {isContainerList && (<>
                                                        {isChecked && (<>
                                                            {isCategory && (<>
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={containersByCategoryTotalOnly}
                                                                    columns={categoryColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            </>)}
                                                            {isCountry && (<>
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={containersByCountryTotalOnly}
                                                                    columns={countryColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            </>)}
                                                        </>)}
                                                        {!isChecked && (<>
                                                            {isCategory && (<>
                                                                {containersByCategory.length != 0 && (
                                                                    <>
                                                                        {containersByCategory.map((stock) => {
                                                                            return (
                                                                                <>
                                                                                    <h5><b>{stock.product_category_description}:</b></h5>
                                                                                    <h5>Total {stock.product_category_description} &#163;{stock.total_price.toFixed(2)}</h5>
                                                                                    <BootstrapTable
                                                                                        keyField='id'
                                                                                        data={stock.product}
                                                                                        columns={columns}
                                                                                        wrapperClasses="table-responsive"
                                                                                        pagination={paginationFactory()}
                                                                                    />
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </>)}
                                                            {isCountry && (<>
                                                                {containersByCountry.length != 0 && (
                                                                    <>
                                                                        {containersByCountry.map((stock) => {
                                                                            return (
                                                                                <>
                                                                                    <h5><b>{stock.country}:</b></h5>
                                                                                    <h5>Total item price sent &#163;{stock.total_price.toFixed(2)}</h5>
                                                                                    <BootstrapTable
                                                                                        keyField='id'
                                                                                        data={stock.product}
                                                                                        columns={columns}
                                                                                        wrapperClasses="table-responsive"
                                                                                        pagination={paginationFactory()}
                                                                                    />
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </>)}
                                                        </>)}
                                                    </>)}
                                                    {isDonationList && (<>
                                                        {isChecked && (<>
                                                            {isCategory && (<>
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={donationsByCategoryTotalOnly}
                                                                    columns={categoryColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            </>)}
                                                            {isCountry && (<>
                                                                <BootstrapTable
                                                                    keyField='id'
                                                                    data={donationsByCountryTotalOnly}
                                                                    columns={countryColumns}
                                                                    wrapperClasses="table-responsive"
                                                                    pagination={paginationFactory()}
                                                                />
                                                            </>)}
                                                        </>)}
                                                        {!isChecked && (<>
                                                            {isCategory && (<>
                                                                {donationsByCategory.length != 0 && (
                                                                    <>
                                                                        {donationsByCategory.map((stock) => {
                                                                            return (
                                                                                <>
                                                                                    <h5><b>{stock.product_category_description}:</b></h5>
                                                                                    <h5>Total {stock.product_category_description} &#163;{stock.total_price.toFixed(2)}</h5>
                                                                                    <BootstrapTable
                                                                                        keyField='id'
                                                                                        data={stock.product}
                                                                                        columns={columns}
                                                                                        wrapperClasses="table-responsive"
                                                                                        pagination={paginationFactory()}
                                                                                    />
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </>)}
                                                            {isCountry && (<>
                                                                {donationsByCountry.length != 0 && (
                                                                    <>
                                                                        {donationsByCountry.map((stock) => {
                                                                            return (
                                                                                <>
                                                                                    <h5><b>{stock.country}:</b></h5>
                                                                                    <h5>Total item price sent &#163;{stock.total_price.toFixed(2)}</h5>
                                                                                    <BootstrapTable
                                                                                        keyField='id'
                                                                                        data={stock.product}
                                                                                        columns={columns}
                                                                                        wrapperClasses="table-responsive"
                                                                                        pagination={paginationFactory()}
                                                                                    />
                                                                                    <hr />
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )}
                                                            </>)}
                                                        </>)}
                                                    </>)}
                                                    <br />
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.handlePrint}>Print</Button>
                                                        </Col>
                                                        <Col sm="12" md="6" lg="6">
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {listStock.length == 0 && (
                                                <><Alert theme="warning">No data found</Alert></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
            </>
        );
    }

    handleCheckForTotals = () => {
        this.setState({ isDataLoading: true });
        const { isChecked } = this.state;
        isChecked ? this.setState({ isDataLoading: false, isChecked: false }) : this.setState({ isDataLoading: false, isChecked: true });
    }

    handleCheckForShippedContainers = () => {
        const { isCheckedContainers } = this.state;
        isCheckedContainers
            ? this.setState({ isCheckedContainers: false, isDisabledDonations: true, isListAll: false, isContainerList: false, isDonationList: true })
            : this.setState({ isCheckedContainers: true, isDisabledDonations: false, isListAll: true, isContainerList: false, isDonationList: false });
    }

    handleCheckForSmallDonations = () => {
        const { isCheckedDonations } = this.state;
        isCheckedDonations
            ? this.setState({ isCheckedDonations: false, isDisabledContainers: true, isListAll: false, isContainerList: true, isDonationList: false })
            : this.setState({ isCheckedDonations: true, isDisabledContainers: false, isListAll: true, isContainerList: false, isDonationList: false });
    }

    handleSearch = () => {
        this.validateStartDate();
        this.validateEndDate();
        const { isValidStartDate, isValidEndDate, startDate, endDate } = this.state;
        if (isValidStartDate && isValidEndDate) {
            let startDateInIsoFormat = getSelectedDateFromDatePicker(startDate);
            let endDateInIsoFormat = getSelectedDateFromDatePicker(endDate);
            this.setState({ isDataLoading: true, listStock: [], isSearchBtnPressed: true, totalsOnlyByProductCategory: [], totalsOnlyByCountry: [], byProductCategory: [], byCountry: [] });
            axios.get(`${ApiUrls.listShippedContainersAndSmallDonations}?startDate=${startDateInIsoFormat}&endDate=${endDateInIsoFormat}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let listStock = JSON.parse(result.data);
                    this.setState({ listStock, isDataLoading: false, selectedStartDate: startDate, selectedEndDate: endDate });
                    this.getAllItemsByCategoryTotalOnly();
                    this.getContainersByCategoryTotalOnly();
                    this.getDonationsByCategoryTotalOnly();
                    this.getAllItemsByCountryTotalOnly();
                    this.getContainersByCountryTotalOnly();
                    this.getDonationsByCountryTotalOnly();
                    this.getAllItemsByCategory();
                    this.getContainersByCategory();
                    this.getDonationsByCategory();
                    this.getAllItemsByCountry();
                    this.getContainersByCountry();
                    this.getDonationsByCountry();
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
    }

    getAllItemsByCategoryTotalOnly = () => {
        const allItemsByCategoryTotalOnly = [];
        const { listStock } = this.state;
        listStock.map((stock) => {
            let item = {
                product_category_description: stock.product_category_description,
                product_category_id: stock.product_category_id,
                total_price: stock.total_value,
                product: [],
            };
            let index = allItemsByCategoryTotalOnly.findIndex(x => x.product_category_id === stock.product_category_id);
            index == -1
                ? allItemsByCategoryTotalOnly.push(item)
                : allItemsByCategoryTotalOnly[index].total_price += item.total_price;
        });
        let sortedAllItemsByCategoryTotalOnly = sortByAlphanumerically(allItemsByCategoryTotalOnly, 'product_category_description');
        this.setState({ allItemsByCategoryTotalOnly: sortedAllItemsByCategoryTotalOnly });
    }

    getContainersByCategoryTotalOnly = () => {
        const containersByCategoryTotalOnly = [];
        const { listStock } = this.state;
        listStock.map((stock) => {
            if (stock.is_container == true) {
                let item = {
                    product_category_description: stock.product_category_description,
                    product_category_id: stock.product_category_id,
                    total_price: stock.total_value,
                    product: [],
                };
                let index = containersByCategoryTotalOnly.findIndex(x => x.product_category_id === stock.product_category_id);
                index == -1
                    ? containersByCategoryTotalOnly.push(item)
                    : containersByCategoryTotalOnly[index].total_price += item.total_price;
            }
        });
        let sortedContainersByCategoryTotalOnly = sortByAlphanumerically(containersByCategoryTotalOnly, 'product_category_description');
        this.setState({ containersByCategoryTotalOnly: sortedContainersByCategoryTotalOnly });
    }

    getDonationsByCategoryTotalOnly = () => {
        const donationsByCategoryTotalOnly = [];
        const { listStock } = this.state;
        listStock.map((stock) => {
            if (stock.is_container == false) {
                let item = {
                    product_category_description: stock.product_category_description,
                    product_category_id: stock.product_category_id,
                    total_price: stock.total_value,
                    product: [],
                };
                let index = donationsByCategoryTotalOnly.findIndex(x => x.product_category_id === stock.product_category_id);
                index == -1
                    ? donationsByCategoryTotalOnly.push(item)
                    : donationsByCategoryTotalOnly[index].total_price += item.total_price;
            }
        });
        let sortedDonationsByCategoryTotalOnly = sortByAlphanumerically(donationsByCategoryTotalOnly, 'product_category_description');
        this.setState({ donationsByCategoryTotalOnly: sortedDonationsByCategoryTotalOnly });
    }

    getAllItemsByCountryTotalOnly = () => {
        const allItemsByCountryTotalOnly = [];
        const { listStock } = this.state;
        listStock.map((stock) => {
            let item = {
                country: stock.country,
                sort_country: stock.country.trim().toLowerCase(),
                total_price: stock.total_value,
                product: [],
            };
            let index = allItemsByCountryTotalOnly.findIndex(x => x.sort_country === stock.country.trim().toLowerCase());
            index == -1
                ? allItemsByCountryTotalOnly.push(item)
                : allItemsByCountryTotalOnly[index].total_price += item.total_price;
        });
        let sortedAllItemsByCountryTotalOnly = sortByAlphanumerically(allItemsByCountryTotalOnly, 'sort_country');
        this.setState({ allItemsByCountryTotalOnly: sortedAllItemsByCountryTotalOnly });
    }

    getContainersByCountryTotalOnly = () => {
        const containersByCountryTotalOnly = [];
        const { listStock } = this.state;
        listStock.map((stock) => {
            if (stock.is_container == true) {
                let item = {
                    country: stock.country,
                    sort_country: stock.country.trim().toLowerCase(),
                    total_price: stock.total_value,
                    product: [],
                };
                let index = containersByCountryTotalOnly.findIndex(x => x.sort_country === stock.country.trim().toLowerCase());
                index == -1
                    ? containersByCountryTotalOnly.push(item)
                    : containersByCountryTotalOnly[index].total_price += item.total_price;
            }
        });
        let sortedContainersByCountryTotalOnly = sortByAlphanumerically(containersByCountryTotalOnly, 'sort_country');
        this.setState({ containersByCountryTotalOnly: sortedContainersByCountryTotalOnly });
    }

    getDonationsByCountryTotalOnly = () => {
        const donationsByCountryTotalOnly = [];
        const { listStock } = this.state;
        listStock.map((stock) => {
            if (stock.is_container == false) {
                let item = {
                    country: stock.country,
                    sort_country: stock.country.trim().toLowerCase(),
                    total_price: stock.total_value,
                    product: [],
                };
                let index = donationsByCountryTotalOnly.findIndex(x => x.sort_country === stock.country.trim().toLowerCase());
                index == -1
                    ? donationsByCountryTotalOnly.push(item)
                    : donationsByCountryTotalOnly[index].total_price += item.total_price;
            }
        });
        let sortedDonationsByCountryTotalOnly = sortByAlphanumerically(donationsByCountryTotalOnly, 'sort_country');
        this.setState({ donationsByCountryTotalOnly: sortedDonationsByCountryTotalOnly });
    }

    getAllItemsByCategory = () => {
        const { allItemsByCategoryTotalOnly } = this.state;
        this.setState({ allItemsByCategory: allItemsByCategoryTotalOnly });
        const { allItemsByCategory, listStock } = this.state;
        allItemsByCategory.map((category) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.product_category_id == category.product_category_id) {
                    let item = {
                        item_description: stock.item_description,
                        sort_item_description: stock.item_description.trim().toLowerCase(),
                        item_id: stock.item_id,
                        pallet_category_id: stock.pallet_category_id,
                        total_price: stock.total_value,
                    };
                    let index = product.findIndex(x => x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                    index == -1
                        ? product.push(item)
                        : product[index].total_price += item.total_price;
                }
            });
            let id = allItemsByCategory.findIndex(x => x.product_category_id === category.product_category_id);
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            allItemsByCategory[id].product = sortedProduct;
            this.setState({ product: [] });
        })
        this.setState({ allItemsByCategory });
    }

    getContainersByCategory = () => {
        const { containersByCategoryTotalOnly } = this.state;
        this.setState({ containersByCategory: containersByCategoryTotalOnly });
        const { containersByCategory, listStock} = this.state;
        containersByCategory.map((category) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.is_container == true) {
                    if (stock.product_category_id == category.product_category_id) {
                        let item = {
                            item_description: stock.item_description,
                            sort_item_description: stock.item_description.trim().toLowerCase(),
                            item_id: stock.item_id,
                            pallet_category_id: stock.pallet_category_id,
                            total_price: stock.total_value,
                        };
                        let index = product.findIndex(x => x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                        index == -1
                            ? product.push(item)
                            : product[index].total_price += item.total_price;
                    }
                }
            })
            let id = containersByCategory.findIndex(x => x.product_category_id === category.product_category_id);
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            containersByCategory[id].product = sortedProduct;
            this.setState({ product: [] });
        })
        this.setState({ containersByCategory });
    }

    getDonationsByCategory = () => {
        const { donationsByCategoryTotalOnly} = this.state;
        this.setState({ donationsByCategory: donationsByCategoryTotalOnly });
        const { donationsByCategory, listStock } = this.state;
        donationsByCategory.map((category) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.is_container == false) {
                    if (stock.product_category_id == category.product_category_id) {
                        let item = {
                            item_description: stock.item_description,
                            sort_item_description: stock.item_description.trim().toLowerCase(),
                            item_id: stock.item_id,
                            pallet_category_id: stock.pallet_category_id,
                            total_price: stock.total_value,
                        };
                        let index = product.findIndex(x => x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                        index == -1
                            ? product.push(item)
                            : product[index].total_price += item.total_price;
                    }
                }
            });
            let id = donationsByCategory.findIndex(x => x.product_category_id === category.product_category_id);
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            donationsByCategory[id].product = sortedProduct;
            this.setState({ product: [] });
        })
        this.setState({ donationsByCategory });
    }

    getAllItemsByCountry = () => {
        const { allItemsByCountryTotalOnly } = this.state;
        this.setState({ allItemsByCountry: allItemsByCountryTotalOnly });
        const { allItemsByCountry, listStock} = this.state;
        allItemsByCountry.map((country) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.country.trim().toLowerCase() == country.sort_country) {
                    let item = {
                        item_description: stock.item_description,
                        sort_item_description: stock.item_description.trim().toLowerCase(),
                        item_id: stock.item_id,
                        pallet_category_id: stock.pallet_category_id,
                        total_price: stock.total_value,
                    };
                    let index = product.findIndex(x => x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                    index == -1
                        ? product.push(item)
                        : product[index].total_price += item.total_price;
                }
            })
            let id = allItemsByCountry.findIndex(x => x.sort_country == country.sort_country);
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            allItemsByCountry[id].product = sortedProduct;
            this.setState({ product: [] });
        })
        this.setState({ allItemsByCountry });
    }

    getContainersByCountry = () => {
        const { containersByCountryTotalOnly } = this.state;
        this.setState({ containersByCountry: containersByCountryTotalOnly });
        const { containersByCountry, listStock } = this.state;
        containersByCountry.map((country) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.country.trim().toLowerCase() == country.sort_country) {
                    let item = {
                        item_description: stock.item_description,
                        sort_item_description: stock.item_description.trim().toLowerCase(),
                        item_id: stock.item_id,
                        pallet_category_id: stock.pallet_category_id,
                        total_price: stock.total_value,
                    };
                    let index = product.findIndex(x => x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                    index == -1
                        ? product.push(item)
                        : product[index].total_price += item.total_price;
                }
            })
            let id = containersByCountry.findIndex(x => x.sort_country == country.sort_country);
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            containersByCountry[id].product = sortedProduct;
            this.setState({ product: [] });
        })
        this.setState({ containersByCountry });
    }

    getDonationsByCountry = () => {
        const { donationsByCountryTotalOnly } = this.state;
        this.setState({ donationsByCountry: donationsByCountryTotalOnly });
        const { donationsByCountry, listStock } = this.state;
        donationsByCountry.map((country) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.country.trim().toLowerCase() == country.sort_country) {
                    let item = {
                        item_description: stock.item_description,
                        sort_item_description: stock.item_description.trim().toLowerCase(),
                        item_id: stock.item_id,
                        pallet_category_id: stock.pallet_category_id,
                        total_price: stock.total_value,
                    };
                    let index = product.findIndex(x => x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                    index == -1
                        ? product.push(item)
                        : product[index].total_price += item.total_price;
                }
            })
            let id = donationsByCountry.findIndex(x => x.sort_country == country.sort_country);
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            donationsByCountry[id].product = sortedProduct;
            this.setState({ product: [] });
        })
        this.setState({ donationsByCountry });
    }

    handlePrice = (cell, row) => {
        let totalPrice = row.total_price;
        let price = totalPrice.toFixed(2);
        return (<div> &#163;{price} </div>);
    }

    formatPrice(price) {
        let formatedPrice = "&#163;" + price.toFixed(2);
        return formatedPrice;
    }

    handlePrint = () => {
        let a = window.open('', '', 'height=500, width=500');
        const { selectedStartDate, selectedEndDate, isListAll, isChecked, isCategory, allItemsByCategoryTotalOnly, isCountry,
            allItemsByCountryTotalOnly, allItemsByCategory, allItemsByCountry, isContainerList, containersByCategoryTotalOnly,
            containersByCountryTotalOnly, containersByCategory, containersByCountry, isDonationList, donationsByCategoryTotalOnly,
            donationsByCategory, donationsByCountry } = this.state;
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write('<h1>LIST SHIPPED CONTAINERS & SMALL DONATIONS</h1>');
        a.document.write('<br>');
        a.document.write('<h3>Date range : from ' + getDateInGivenFormat(selectedStartDate) + ' to ' + getDateInGivenFormat(selectedEndDate) + '</h3>');
        if (isListAll) {
            if (isChecked) {
                if (isCategory) {
                    a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                    a.document.write('<tr>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Product category</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Total price</th>');
                    a.document.write('</tr>');
                    a.document.write('</tr>');
                    allItemsByCategoryTotalOnly.map((category) => {
                        a.document.write('<tr>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + category.product_category_description + '</td>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(category.total_price) + '</td>');
                        a.document.write('</tr>');
                    });
                    a.document.write('</table>');
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
                if (isCountry) {
                    a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                    a.document.write('<tr>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Country</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Total price</th>');
                    a.document.write('</tr>');
                    a.document.write('</tr>');
                    allItemsByCountryTotalOnly.map((country) => {
                        a.document.write('<tr>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + country.country + '</td>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(country.total_price) + '</td>');
                        a.document.write('</tr>');
                    });
                    a.document.write('</table>');
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
            }
            else if (isChecked == false) {
                if (isCategory) {
                    allItemsByCategory.map((stock) => {
                        a.document.write('<h3>' + stock.product_category_description + ':</h3>');
                        a.document.write('<h3>Total ' + stock.product_category_description + ' ' + this.formatPrice(stock.total_price) + '</h3>');
                        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                        a.document.write('<tr>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Item</th>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Price</th>');
                        a.document.write('</tr>');
                        a.document.write('</tr>');
                        stock.product.map((stock) => {
                            a.document.write('<tr>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + stock.item_description + '</td>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(stock.total_price) + '</td>');
                            a.document.write('</tr>');
                        });
                        a.document.write('</table>');
                    })
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
                if (isCountry) {
                    allItemsByCountry.map((stock) => {
                        a.document.write('<h3>' + stock.country + ':</h3>');
                        a.document.write('<h3>Total item price sent ' + this.formatPrice(stock.total_price) + '</h3>');
                        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                        a.document.write('<tr>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Item</th>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Price</th>');
                        a.document.write('</tr>');
                        a.document.write('</tr>');
                        stock.product.map((stock) => {
                            a.document.write('<tr>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + stock.item_description + '</td>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(stock.total_price) + '</td>');
                            a.document.write('</tr>');
                        });
                        a.document.write('</table>');
                    })
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
            }
        }
        else if (isContainerList) {
            if (isChecked) {
                if (isCategory) {
                    a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                    a.document.write('<tr>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Product category</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Total price</th>');
                    a.document.write('</tr>');
                    a.document.write('</tr>');
                    containersByCategoryTotalOnly.map((category) => {
                        a.document.write('<tr>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + category.product_category_description + '</td>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(category.total_price) + '</td>');
                        a.document.write('</tr>');
                    });
                    a.document.write('</table>');
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
                if (isCountry) {
                    a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                    a.document.write('<tr>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Country</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Total price</th>');
                    a.document.write('</tr>');
                    a.document.write('</tr>');
                    containersByCountryTotalOnly.map((country) => {
                        a.document.write('<tr>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + country.country + '</td>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(country.total_price) + '</td>');
                        a.document.write('</tr>');
                    });
                    a.document.write('</table>');
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
            }
            else if (isChecked == false) {
                if (isCategory) {
                    containersByCategory.map((stock) => {
                        a.document.write('<h3>' + stock.product_category_description + ':</h3>');
                        a.document.write('<h3>Total ' + stock.product_category_description + ' ' + this.formatPrice(stock.total_price) + '</h3>');
                        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                        a.document.write('<tr>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Item</th>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Price</th>');
                        a.document.write('</tr>');
                        a.document.write('</tr>');
                        stock.product.map((stock) => {
                            a.document.write('<tr>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + stock.item_description + '</td>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(stock.total_price) + '</td>');
                            a.document.write('</tr>');
                        });
                        a.document.write('</table>');
                    })
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
                if (isCountry) {
                    containersByCountry.map((stock) => {
                        a.document.write('<h3>' + stock.country + ':</h3>');
                        a.document.write('<h3>Total item price sent ' + this.formatPrice(stock.total_price) + '</h3>');
                        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                        a.document.write('<tr>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Item</th>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Price</th>');
                        a.document.write('</tr>');
                        a.document.write('</tr>');
                        stock.product.map((stock) => {
                            a.document.write('<tr>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + stock.item_description + '</td>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(stock.total_price) + '</td>');
                            a.document.write('</tr>');
                        });
                        a.document.write('</table>');
                    })
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
            }
        }
        else if (isDonationList) {
            if (isChecked) {
                if (isCategory) {
                    a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                    a.document.write('<tr>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Product category</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Total price</th>');
                    a.document.write('</tr>');
                    a.document.write('</tr>');
                    donationsByCategoryTotalOnly.map((category) => {
                        a.document.write('<tr>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + category.product_category_description + '</td>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(category.total_price) + '</td>');
                        a.document.write('</tr>');
                    });
                    a.document.write('</table>');
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
                if (isCountry) {
                    a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                    a.document.write('<tr>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Country</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Total price</th>');
                    a.document.write('</tr>');
                    a.document.write('</tr>');
                    containersByCountryTotalOnly.map((country) => {
                        a.document.write('<tr>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + country.country + '</td>');
                        a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(country.total_price) + '</td>');
                        a.document.write('</tr>');
                    });
                    a.document.write('</table>');
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
            }
            else if (isChecked == false) {
                if (isCategory) {
                    donationsByCategory.map((stock) => {
                        a.document.write('<h3>' + stock.product_category_description + ':</h3>');
                        a.document.write('<h3>Total ' + stock.product_category_description + ' ' + this.formatPrice(stock.total_price) + '</h3>');
                        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                        a.document.write('<tr>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Item</th>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Price</th>');
                        a.document.write('</tr>');
                        a.document.write('</tr>');
                        stock.product.map((stock) => {
                            a.document.write('<tr>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + stock.item_description + '</td>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(stock.total_price) + '</td>');
                            a.document.write('</tr>');
                        });
                        a.document.write('</table>');
                    })
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
                if (isCountry) {
                    donationsByCountry.map((stock) => {
                        a.document.write('<h3>' + stock.country + ':</h3>');
                        a.document.write('<h3>Total item price sent ' + this.formatPrice(stock.total_price) + '</h3>');
                        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                        a.document.write('<tr>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%" >Item</th>');
                        a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%" >Price</th>');
                        a.document.write('</tr>');
                        a.document.write('</tr>');
                        stock.product.map((stock) => {
                            a.document.write('<tr>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:60%" >' + stock.item_description + '</td>');
                            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:40%" >' + this.formatPrice(stock.total_price) + '</td>');
                            a.document.write('</tr>');
                        });
                        a.document.write('</table>');
                    })
                    a.document.write('</body></html>');
                    a.document.close();
                    a.print();
                }
            }
        }
    }

    sortListStock = ({ target }) => {
        this.setState({ isDataLoading: true })
        if (target.value == "Category") {
            this.setState({ isDataLoading: false, isCategory: true, isCountry: false })
        }
        else if (target.value == "Country") {
            this.setState({ isDataLoading: false, isCategory: false, isCountry: true })
        }
    }

    getTodayDate = () => {
        let date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        let today = [year, month, day].join('-');
        this.setState({ today: new Date(today) });
    }

    setStartDate = (value) => {
        this.setState({ startDate: value });
        const { today } = this.state;
        if (!value)
            this.setState({ errorResponseForStartDate: "Please enter date from", isValidStartDate: false, isEndDateDisabled: true, endDate: "", isValidEndDate: false });
        else if (value > today)
            this.setState({ responseDateOfManufacture: "Please enter past date", isValidStartDate: false, isEndDateDisabled: true, endDate: "", isValidEndDate: false });
        else
            this.setState({ errorResponseForStartDate: "", isValidStartDate: true, isEndDateDisabled: false, endDate: today, isValidEndDate: true });
    }

    validateStartDate = () => {
        const { startDate, today } = this.state;
        if (!startDate)
            this.setState({ errorResponseForStartDate: "Please enter date from", isValidStartDate: false });
        else if (startDate > today)
            this.setState({ errorResponseForStartDate: "Please enter past date", isValidStartDate: false });
        else
            this.setState({ errorResponseForStartDate: "", isValidStartDate: true });
    }

    setEndDate = (value) => {
        this.setState({ endDate: value });
        const { startDate, today } = this.state;
        if (!value)
            this.setState({ errorResponseForEndDate: "Please enter date to", isValidEndDate: false });
        else if (value < startDate)
            this.setState({ errorResponseForEndDate: "Please enter future date", isValidEndDate: false });
        else if (value > today)
            this.setState({ errorResponseForEndDate: "Please enter past date", isValidEndDate: false });
        else
            this.setState({ errorResponseForEndDate: "", isValidEndDate: true });
    }

    validateEndDate = () => {
        const { endDate, startDate, today } = this.state;
        if (!endDate)
            this.setState({ responseExpiryDate: "Please enter date to ", isValidEndDate: false });
        else if (endDate < startDate)
            this.setState({ responseExpiryDate: "Please enter future date", isValidEndDate: false });
        else if (endDate > today)
            this.setState({ errorResponseForEndDate: "Please enter past date", isValidEndDate: false });
        else
            this.setState({ errorResponseForEndDate: "", isValidEndDate: true });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}