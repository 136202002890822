import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, BreadcrumbItem,
    Form, Row,
    Col, FormGroup, FormInput, FormCheckbox, Alert,
    Breadcrumb,
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class EditUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorInSelectModules: false,
            isDataLoading: true,
            isResponse: false,
            isUpdateModulesBtnDisabled: true,
            theme: "",
            response: "",
            modules: [],
            selectModules: [],
            data: [],
            selectedUserId: this.props.location.search.split("=").pop()
        };
    }

    componentDidMount() {
        this.setState({ isDataLoading: true });
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const userId = urlParams.get('userid')
        if (userId) {
            let emailInfo = {
                userid: parseInt(userId)
            }
            axios.post(ApiUrls.getUserInfoByUserID, emailInfo, authorizationHeader).then(({ data: result }) => {
                result.isSuccess
                    ? this.setState({ modules: JSON.parse(result.moduleinfo), data: JSON.parse(result.userinfo), isDataLoading: false })
                    : this.setState({ isDataLoading: false });
            }).catch((error) => {
                this.showError(error);  
            });
        }
    }

    handleCheckboxElement = (e, event) => {
        const { modules } = this.state;
        modules.forEach(module => {
            if (module.moduleid === event.moduleid) 
                event.ischecked ? event.ischecked = false : event.ischecked = true;
        });
        this.setState({ modules, response: "", isResponse: false });
        let modulesCount = modules.reduce((a, { ischecked }) => a + ischecked, 0);
        modulesCount >= 1
            ? this.setState({ isErrorInSelectModules: false, isUpdateModulesBtnDisabled: false })
            : this.setState({ isErrorInSelectModules: true, isUpdateModulesBtnDisabled: true });
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response, isDataLoading,
            data, modules, isErrorInSelectModules, isUpdateModulesBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/Dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/view-users">View Users</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Edit User</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>EDIT USER</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && ( 
                                <Form>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#UserName">Username</label>
                                                <FormInput size="lg" id="#UserName" type="email" value={data.username} placeholder="Email Address" disabled="true" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {!data.isadmin && (
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>                                               
                                                    <FormGroup>
                                                        <label style={{ fontSize: "large" }} htmlFor="#UserName">Assign modules</label><br/>
                                                        {modules.map((module) => {
                                                            return (
                                                                <FormCheckbox size="lg" key={module.moduleid} checked={module.ischecked} onChange={e => this.handleCheckboxElement(e, module)}  {...module}>
                                                                    {module.modulename}
                                                                </FormCheckbox>
                                                            );
                                                        })}
                                                        {isErrorInSelectModules && (<p style={{ color: "red" }} >please select at least one module from above check box</p>)}
                                                    </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        </Row>
                                    )}
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#LastLoggedIn">Last logged in</label>
                                                <FormInput size="lg" id="#LastLoggedIn" type="datetime-local" value={data.lastloggedin} placeholder="Last Logged In" disabled="true" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#LastPasswordResetTime">Last password reset time</label>
                                                <FormInput size="lg" id="#LastPasswordResetTime" type="datetime-local" value={data.lastPasswordresettime} placeholder="last Password Reset Time" disabled="true" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            {!data.isadmin && (
                                                <FormGroup>
                                                    <Button size="lg" onClick={this.onUpdateModulesBtnClick} disabled={isUpdateModulesBtnDisabled} type="submit">{isUpdateModulesBtnDisabled ? "Loading..." : "Update"}</Button>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>                                
                                )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
            </>
        );
    }

    onUpdateModulesBtnClick = (event) => {
        event.preventDefault();
        this.setState({ isUpdateModulesBtnDisabled: true, selectModules: [], isResponse: false, theme: "", response: "" });
        const { modules, selectModules, data } = this.state;
        modules.forEach(module => {
            if (module.ischecked == true)
                selectModules.push(module.moduleid)
            });
        let updatedUserModulesInfo = {
            Username: data.username,
            Modules: selectModules.toString()
        };
        axios.post(ApiUrls.updateUserModules, updatedUserModulesInfo, authorizationHeader).then(({ data: result }) => {
            result.isSuccess
                ? this.setState({ isResponse: true, theme: "success", response: result.messageText, isUpdateModulesBtnDisabled: false })
                : this.setState({ isResponse: true, theme: "danger", response: result.messageText, isUpdateModulesBtnDisabled: false });
        }).catch((error) => {
            this.showError(error);  
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isDataLoading: false, showResetConfirm: false });
        }
    }
}