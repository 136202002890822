import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, Modal, ModalBody, FormGroup,
    Alert, FormInput
} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ShipContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isYesBtnDisabled: false,
            isValidPrimaryLocation: false,
            isValidCountry: false,
            isConfirmationShowing: false,
            theme: "",
            response: "",
            country: "",
            errorResponseForContainer: "",
            errorResponseForCountry: "",
            primaryLocations: [],
            selectedPrimaryLocation: [],
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>]
        };
        this.primaryLocation = React.createRef();
    }

    componentDidMount() {
        this.getContainersNotShipped();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response, selectedPrimaryLocation,
            primaryLocations, emptyLabel, errorResponseForContainer, country,
            errorResponseForCountry, isConfirmationShowing, isYesBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Ship a Container</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>SHIP A CONTAINER</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup style={{ fontSize: "large" }}>
                                        <label htmlFor="#PrimaryLocation">Select which container you wish to ship :<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" selected={selectedPrimaryLocation}
                                            id="id"
                                            labelKey="description"
                                            onChange={this.setPrimaryLocation}
                                            options={primaryLocations}
                                            placeholder=""
                                            ref={this.primaryLocation}
                                            emptyLabel={emptyLabel}
                                        />
                                        <span className="error-txt">{errorResponseForContainer}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup style={{ fontSize: "large" }}>
                                        <label htmlFor="#country">Country :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="text" id="#country" value={country} onChange={this.setCountry} />
                                        <span className="error-txt">{errorResponseForCountry}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSubmit}>Submit</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmationShowing && (
                    <Modal open={isConfirmationShowing} toggle={() => this.setState({ isConfirmationShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>SHIP A CONTAINER-CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Are you sure that you want to ship Container
                                        &nbsp;<b>{selectedPrimaryLocation[0].description}</b> to {country} ?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleYesBtnClick} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.handleNoBtnClick} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getContainersNotShipped = () => {
        axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let primaryLocations = JSON.parse(result.data);
                let sortedPrimaryLocations = sortByAlphanumerically(primaryLocations, 'description');
                this.setState({ primaryLocations: sortedPrimaryLocations, selectedPrimaryLocation: [], emptyLabel: "No matches found" });
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setPrimaryLocation = (value) => {
        (value !== null && value.length > 0)
            ? this.setState({ selectedPrimaryLocation: value, isValidPrimaryLocation: true, errorResponseForContainer: "" })
            : this.setState({ selectedPrimaryLocation: [], secondaryLocations: [], isValidPrimaryLocation: false, errorResponseForContainer: "Please select a container" });
    };

    validatePrimaryLocation = () => {
        const { selectedPrimaryLocation } = this.state;
        (selectedPrimaryLocation !== null && selectedPrimaryLocation.length > 0)
            ? this.setState({ isValidPrimaryLocation: true, errorResponseForContainer: "" })
            : this.setState({ isValidPrimaryLocation: false, errorResponseForContainer: "Please select a container" });
    };

    setCountry = ({ target }) => {
        this.setState({ country: target.value, isResponse: false });
        target.value
            ? this.setState({ isValidCountry: true, errorResponseForCountry: "" })
            : this.setState({ isValidCountry: false, errorResponseForCountry: "Please enter country" });
    }

    validateCountry = () => {
        this.setState({ isResponse: false });
        const { country } = this.state;
        country
            ? this.setState({ isValidCountry: true, errorResponseForCountry: "" })
            : this.setState({ isValidCountry: false, errorResponseForCountry: "Please enter country" });
    }

    handleSubmit = () => {
        this.validatePrimaryLocation();
        this.validateCountry();
        const { isValidPrimaryLocation, isValidCountry } = this.state;
        if (isValidPrimaryLocation && isValidCountry)
            this.setState({ isConfirmationShowing: true });
    }

    handleNoBtnClick = () => {
        this.setState({ isConfirmationShowing: false });
    }

    handleYesBtnClick = () => {
        this.setState({ isYesBtnDisabled: true, isResponse: false });
        const { country, selectedPrimaryLocation } = this.state;
        let listOfShippedContainers = {
            Country: country.toString(),
            PrimaryLocationId: 4,
            SecondaryLocationId: parseInt(selectedPrimaryLocation[0].id)
        };
        axios.post(ApiUrls.shipContainer, listOfShippedContainers, authorizationHeader).then(({ data: result }) => {
            result.isSuccess
                ? window.location.href = "/dashboard"
                : this.setState({ isResponse: true, response: result.messageText, theme: "danger", isYesBtnDisabled: false, isConfirmationShowing: false });
        }).catch((error) => {
            this.showError(error);
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isYesBtnDisabled: false, isConfirmationShowing: false });
        }
    }
}