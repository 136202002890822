export const getSelectedDateFromDatePicker = (selectedDate) => {
    if (selectedDate) {
        let date = new Date(selectedDate);
        let day = date.getDate().toString().padStart(2, "0");
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let dateAndtime = `${year}-${month}-${day}`;
        return dateAndtime;
    } else {
        return "0001-01-01";
    }
}