export const TableNames = Object.freeze({
    tbl_pallet_categories: 1,
    tbl_items_bottle_medicines: 2,
    tbl_items_medical_disposable: 3,
    tbl_items_feeds_and_food: 4,
    tbl_items_non_pallet: 5
});

export const PrimaryKeysOfTables = Object.freeze({
    tbl_pallet_categories: "category_id",
    tbl_items_bottle_medicines: "id",
    tbl_items_medical_disposable: "id",
    tbl_items_feeds_and_food: "id",
    tbl_items_non_pallet: "id"
});