import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, InputGroup,
    InputGroupAddon, FormInput,
} from "shards-react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate"

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class RemoveBoxes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isProcessing: false,
            isResponse: false,
            isDataLoading: false,
            showConfirm: false,
            palletNumber: "",
            id: "",
            numberofboxes: "",
            description: "",
            boxToRemove : "",
            theme: "",
            response: "",
            location: "",
            boxContents: [],
            palletInfo:[],
        }
    };

    componentDidMount() {
        this.setState({ isDataLoading: true })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const palletNumber = urlParams.get('pallet')
        this.setState({ palletNumber: palletNumber });
        const location = urlParams.get('location')
        this.setState({ location: location });
        axios.get(`${ApiUrls.viewPalletContents}?PalletNumber=${palletNumber}&location=${location}`, authorizationHeader).then(({ data : result }) => {
            let palletContent = JSON.parse(result.data);
            let boxContents = palletContent.PalletBoxContents;
            let palletInfo = palletContent.PalletInfo;
            let sortedBoxContents = (location == "medical") ? sortByAlphanumerically(boxContents, 'expirydate') : sortByAlphanumerically(boxContents, 'description');
            this.setState({ boxContents: sortedBoxContents, isDataLoading: false, palletInfo});
        }).catch((error) => {
            this.showError(error);
        });      
    }

    render() {
        const columns =
            [
                {
                    text: 'Description',
                    formatter: this.getDescription,
                    style: {
                        fontWeight: 'normal',
                    }
                },
                {
                    text: 'Expiry Date',
                    formatter: this.getExpiryDate,
                    style: {
                        fontWeight: 'normal',
                    }
                },
                {
                    text: 'Weight',
                    formatter: this.getWeight,
                    style: {
                        fontWeight: 'normal',
                    }
                },
                {
                    text: 'No of boxes to be removed',
                    formatter: this.numberOfBoxesToRemove,
                    style: {
                        fontWeight: 'normal',
                    }
                },
            ];
        const bottleColumns =
            [
                {
                    text: 'Description',
                    formatter: this.getDescription,
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Batch Number',
                    dataField: 'batchnumber',
                    style: {
                        fontWeight: 'normal',
                        width: '15%'
                    }
                },
                {
                    text: 'Expiry Date',
                    formatter: this.getExpiryDate,
                    style: {
                        fontWeight: 'normal',
                        width: '15%'
                    }
                },
                {
                    text: 'No of boxes to be removed',
                    formatter: this.numberOfBoxesToRemove,
                    style: {
                        fontWeight: 'normal',
                        width: '40%'
                    }
                },
            ];
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { location, isResponse, theme, response, palletNumber, isDataLoading, palletInfo,
            boxContents, showConfirm, boxToRemove, description, isProcessing } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            {location == "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "medical" && (
                                <BreadcrumbItem>
                                    <a href="/medical-disposables">Medical Disposable Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "food" && (
                                <BreadcrumbItem>
                                    <a href="/food">Food Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "bottle" && (
                                <BreadcrumbItem>
                                    <a href="/bottle-room">Bottle Room Interface</a>
                                </BreadcrumbItem>
                            )}
                            {location == "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=warehouse">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            {location == "medical" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=medical">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            {location == "food" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=food">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            {location == "bottle" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=bottle">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            <BreadcrumbItem active>Remove Boxes</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>REMOVE BOXES</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <FormGroup>
                                            <Alert theme={theme}>
                                                {response}
                                            </Alert>
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Pallet number : {palletNumber}</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!isDataLoading && (
                                        <div>
                                            {palletInfo.PalletCategory == 1 && (<>
                                                {boxContents.length >= 1 && (
                                                    <div>
                                                        <BootstrapTable
                                                            keyField='id'
                                                            data={boxContents}
                                                            columns={bottleColumns}
                                                            wrapperClasses="table-responsive"
                                                            pagination={paginationFactory()}
                                                        />
                                                    </div>
                                                )}
                                                {boxContents == 0 && (
                                                    <Alert theme="warning">Pallet is Empty</Alert>
                                                )}
                                            </>)}
                                            {palletInfo.PalletCategory != 1 && (<>
                                                {boxContents.length >= 1 && (
                                                    <div>
                                                        <BootstrapTable
                                                            keyField='id'
                                                            data={boxContents}
                                                            columns={columns}
                                                            wrapperClasses="table-responsive"
                                                            pagination={paginationFactory()}
                                                        />
                                                    </div>
                                                )}
                                                {boxContents == 0 && (
                                                    <Alert theme="warning">Pallet is Empty</Alert>
                                                )}
                                            </>)}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {showConfirm && (
                    <Modal open={showConfirm} toggle={() => this.setState({ showConfirm: !showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>EDIT A PALLET – REMOVE BOXES - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>
                                        Do you want to remove {boxToRemove} of {description} item from pallet {palletNumber}?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleRemoveBoxes} disabled={isProcessing}>{isProcessing ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ showConfirm: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    numberOfBoxesToRemove = (cell, row) => {
        return (
            <Row>
                <Col >
                    <InputGroup>
                        <InputGroupAddon type="prepend">
                            <Button theme="primary" onClick={e => this.removeBox(e, row)}>-</Button>
                        </InputGroupAddon>
                        <FormInput style={{ textAlign: "center", maxWidth: "100px" }} size="5" id={row.id} maxLength="5" onChange={e => this.updateStock(e, row)} />
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={e => this.addBox(e, row)}>+</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col >
                    <Button theme="danger" block onClick={e => this.onRemoveBoxesBtnClick(e, row)}>Remove</Button>
                </Col>
            </Row>
        )
    }

    updateStock = ({ target }, row) => {
        const { boxContents } = this.state;
        if (target.value >= 1 && target.value <= row.numberofboxes) {
            boxContents.forEach(item => {
                if (item.id == row.id) {
                    item.numberofboxestoremove = parseInt(target.value);
                    document.getElementById(row.id).value = item.numberofboxestoremove;
                }
            });
        } else {
            boxContents.forEach(item => {
                if (item.id == row.id)
                    document.getElementById(row.id).value = item.numberofboxestoremove;
            });
        }
        this.setState({ boxContents });
    }

    removeBox = (e, row) => {
        const { boxContents } = this.state;
        if (!(row.numberofboxestoremove == 1)) {
            boxContents.forEach(boxContent => {
                if (boxContent.id == row.id) {
                    boxContent.numberofboxestoremove -= 1;
                    document.getElementById(row.id).value = boxContent.numberofboxestoremove;
                }
            });
        }
        this.setState({ boxContents });

    }

    addBox = (e, row) => {
        const { boxContents } = this.state;
        if (!(row.numberofboxestoremove == row.numberofboxes)) {
            boxContents.forEach(boxContent => {
                if (boxContent.id == row.id) {
                    boxContent.numberofboxestoremove += 1;
                    document.getElementById(row.id).value = boxContent.numberofboxestoremove;
                }
            });
        }
        this.setState({ boxContents });
    }


    getExpiryDate = (cell, row) => {
        let expiryDate = row.expirydate;
        let date = expiryDate ? getDateInGivenFormat(expiryDate.slice(0, 10)) : '';
        return (<div>{date}</div>);
    }

    getWeight = (cell, row) => {
        return (<div>{row.totalweight}kg</div>);
    }

    getDescription = (cell, row) => {
        return (<div>
            {row.description} ({row.numberofboxes})
        </div>);
    }

    onRemoveBoxesBtnClick = (e, row) => {
        this.setState({ showConfirm: true, id: row.id, numberofboxes: row.numberofboxes, description: row.description, isResponse: false, boxToRemove: row.numberofboxestoremove });
    }

    handleRemoveBoxes = () => {
        const { location, palletNumber, id, boxToRemove } = this.state;
        if (location == "bottle") {
            this.setState({ isProcessing: true, isDataLoading: true });
            let bottleRoomPalletContentList = {
                PalletNumber: palletNumber,
                StockId: id,
                Quantity: parseInt(boxToRemove),
            };
            axios.post(ApiUrls.removeBottleRoomStockOnPallet, bottleRoomPalletContentList, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    this.setState({ isResponse: true, theme: "success", response: result.messageText, isProcessing: false, showConfirm: false });
                    axios.get(`${ApiUrls.viewPalletContents}?PalletNumber=${palletNumber}&location=${location}`, authorizationHeader).then(({ data: result }) => {
                        let palletContent = JSON.parse(result.data);
                        let boxContents = palletContent.PalletBoxContents;
                        let palletInfo = palletContent.PalletInfo;
                        let sortedBoxContents = sortByAlphanumerically(boxContents, 'description');
                        this.setState({ boxContents: sortedBoxContents, isDataLoading: false, palletInfo });
                    }).catch((error) => {
                        this.showError(error);
                    });
                }
                else {
                    this.setState({ isResponse: true, theme: "danger", response: result.messageText, isProcessing: false, showConfirm: false });
                }
            }).catch((error) => {
                this.showError(error); 
            });
        }
        else {
            this.setState({ isProcessing: true });
            const { palletInfo} = this.state;
            let boxInfoToRemove = {
                Id: id,
                PalletNumber: palletNumber,
                QuantityToRemove: parseInt(boxToRemove),
                BoxCategory: palletInfo.PalletCategory
            };
            axios.post(ApiUrls.removeBoxesFromPallet, boxInfoToRemove, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    this.setState({ isResponse: true, theme: "success", response: result.messageText, isProcessing: false, showConfirm: false });
                    axios.get(`${ApiUrls.viewPalletContents}?PalletNumber=${palletNumber}&location=${location}`, authorizationHeader).then(({ data: result }) => {
                        let palletContent = JSON.parse(result.data);
                        let boxContents = palletContent.PalletBoxContents;
                        let sortedBoxContents = (location == "medical") ? sortByAlphanumerically(boxContents, 'expirydate') : sortByAlphanumerically(boxContents, 'description');
                        this.setState({ boxContents: sortedBoxContents, isDataLoading: false });
                    });
                }
                else {
                    this.setState({ isResponse: true, theme: "danger", response: result.messageText, isProcessing: false, showConfirm: false });
                }
            }).catch((error) => {
                this.showError(error);       
            });
        }
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, theme: "danger", response: "Something went wrong", isProcessing: false, showConfirm: false });
        }
    }
}