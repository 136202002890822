import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, FormInput, Alert,
} from "shards-react";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class SearchForAnyItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isError: false,
            isYesBtnDisabled: false,
            isErrorInSelectedItem: false,
            isDataLoading: false,
            isSearchBtnPressed: false,
            isConfirmationShowing: false,
            isProcessing: false,
            isItemSeleted: false,
            isMoveItemHidden: true,
            isValidMedicalPalletNumber: false,
            isShipped: true,
            theme: "",
            response: "",
            errorMessage: "",
            movePalletNumber: "",
            id: "",
            selectedQuantity: "",
            selectedCurrentPallet: "",
            selectedId: "",
            selectedItemName: "",
            selectedItemNameId: "",
            selectedItemType: "",
            errorResponseForMedicalPalletNumber: "",
            location: "",
            query: "",
            successMessage: "",
            palletNumber: "",
            listOfStockItem: [],
            relatedPalletNumbers: [],
            selectedItem: [],
            allItems: [],
            selectedPalletNumber: [],
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],
        };
        this.medicalPalletNumber = React.createRef();
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('location')
        this.setState({ location });
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px",
        };
        const {
            isMoveItemHidden, location, isResponse, theme, isProcessing,
            response, selectedItem, allItems, isLoading, isErrorInSelectedItem,
            isDataLoading, isSearchBtnPressed, listOfStockItem, isItemSeleted,
            selectedItemName, selectedQuantity, selectedCurrentPallet, isShipped,
            selectedPalletNumber, relatedPalletNumbers, emptyLabel,
            errorResponseForMedicalPalletNumber, isConfirmationShowing  } = this.state;
        const columnsMedicalItem =
            [
                {
                    text: 'Quantity',
                    dataField: 'quantity',
                    style: {
                        fontWeight: 'normal',
                        width: '10%'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.customizeExpiryDate,
                    style: {
                        fontWeight: 'normal',
                        width: '20%'
                    }
                },
                {
                    text: 'Pallet number',
                    dataField: 'pallet_number',
                    style: {
                        fontWeight: 'normal',
                        width: '20%'
                    }
                },
                {
                    text: 'Storage location',
                    dataField: 'storage_location',
                    style: {
                        fontWeight: 'normal',
                        width: '40%'
                    }
                },
                {
                    text: 'Move',
                    formatter: this.moveItem,
                    hidden: isMoveItemHidden,
                    style: {
                        fontWeight: 'normal',
                        width: '10%'
                    }
                },
            ];
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            {location === "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                            )}
                            <BreadcrumbItem active>Search for Any Item</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>SEARCH FOR ANY ITEM</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#allItems">Select item :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={selectedItem} options={allItems} onChange={this.setItem} isLoading={isLoading}
                                            id="boxcontent-typeahead"
                                            labelKey="description"
                                            minLength={3}
                                            onInputChange={this.handleInputChange}
                                            onPaginate={this.handlePagination}
                                            onSearch={this.handleSearch}
                                            placeholder="Search for all items"
                                            ref={this.boxContents}
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.description}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        {isErrorInSelectedItem && (
                                            <span className="error-txt">Please select a item</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearchBtn}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (
                                <>
                                    {isSearchBtnPressed && (
                                        <>
                                            {listOfStockItem.length != 0 && (
                                                <>
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={listOfStockItem}
                                                        columns={columnsMedicalItem}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                </>
                                            )}
                                            {listOfStockItem.length == 0 && (
                                                <><Alert theme="warning">No data found</Alert></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {isItemSeleted && (
                                <div>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#ItemName">Item name :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#ItemName" value={selectedItemName} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#Quantity">Quantity :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#Quantity" value={selectedQuantity} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#CurrentPallet">Current pallet :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#CurrentPallet" value={selectedCurrentPallet} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {!isShipped && (<>
                                        <Row>
                                            <Col sm="12" md="10" lg="8">
                                                <FormGroup>
                                                    <label style={{ fontSize: "large" }} htmlFor="#Quantity">Move to pallet :<span className="error-txt"></span></label>
                                                    <Typeahead size="lg" selected={selectedPalletNumber}
                                                        id="id"
                                                        labelKey="description"
                                                        onChange={this.setMedicalPalletNumber}
                                                        options={relatedPalletNumbers}
                                                        placeholder=""
                                                        ref={this.medicalPalletNumber}
                                                        emptyLabel={emptyLabel}
                                                    />
                                                    <span className="error-txt">{errorResponseForMedicalPalletNumber}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12" md="10" lg="8">
                                                <FormGroup>
                                                    <Button theme="primary" size="lg" onClick={this.confirmMedicalItemsMove}>Confirm</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>)}
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmationShowing && (
                    <Modal open={isConfirmationShowing} toggle={() => this.setState({ isConfirmationShowing: !isConfirmationShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>SEARCH FOR ANY ITEM - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>
                                        Do you want to remove these items from their current storage locations and place them instead on Pallet {selectedPalletNumber[0]}?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleMedicalItemsMove} disabled={isProcessing ? true : false}>{isProcessing ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ isConfirmationShowing: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    customizeExpiryDate = (cell, row) => {
        let expiryDate = row.expiry_date;
        let date = expiryDate ? getDateInGivenFormat(expiryDate.slice(0, 10)) : '';
        return (<div>{date}</div>);
    }

    moveItem = (cell, row) => {
        if (!row.is_Shipped && !row.is_donation && !row.is_donation_finished && !row.is_stock)
            return (<div><Button theme="danger" onClick={() => this.getOnlyRelatedPalletNumbers(row)}>Move</Button></div>);
        else
            return (<div><Button theme="danger" disabled={true} >Move</Button></div>);
    }

    getOnlyRelatedPalletNumbers = (row) => {
        this.setState({ selectedQuantity: row.quantity, isItemSeleted: true, selectedCurrentPallet: row.pallet_number, selectedId: row.id, isResponse: false, isShipped: row.is_shipped })
        const { selectedItemType } = this.state;
        axios.get(`${ApiUrls.getOnlyRelatedPalletNumbers}?itemType=${selectedItemType}`, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let relatedPalletNumbers = JSON.parse(result.data);
                const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });
                let sortedPalletNumbers = relatedPalletNumbers.sort(sortAlphaNum);
                this.setState({ relatedPalletNumbers: sortedPalletNumbers, selectedPalletNumber: [], emptyLabel: "No matches found" });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    setItem = (value) => {
        (value !== null && value.length > 0)
            ? this.setState({ selectedItem: value, isErrorInSelectedItem: false, isResponse: false })
            : this.setState({ selectedItem: "", isErrorInSelectedItem: true, isResponse: false });
    }

    handleInputChange = (query) => {
        this.setState({ query, isError: false, errorMessage: "", successMessage: "" });
    };

    handleSearch = (query) => {
        if (this._cache[query]) {
            this.setState({ allItems: this._cache[query].options });
            return;
        }
        this.setState({ isLoading: true });
        this.getSearchResults(query).then(resp => {
            this._cache[query] = { ...resp, page: 1 };
            this.setState({
                isLoading: false,
                allItems: resp.options,
            });
        });
    };

    getSearchResults(query, page = 1) {
        const itemsPerPage = 20;
        return axios.get(`${ApiUrls.getAllItems}?query=${query}&perpage=${itemsPerPage}`, authorizationHeader).then(({data: result}) => {
            let allItems = JSON.parse(result.data);
            let options = allItems.map(i => ({
                id: i.id,
                description: i.description,
                ItemType: i.item_type
            }));
            options = sortByAlphanumerically(options, 'description');
            let totalCount = options.length;
            return { options, totalCount };
        }).catch((error) => {
            this.showError(error);
        });
    }

    handleSearchBtn = () => {
        this.setState({ isResponse: false, isItemSeleted: false, });
        const itemType = {
            BottleMedicines: 0,
            MedicalDisposable: 1,
            FeedsAndFood: 2,
            NonPalletItems: 3,
            ItemTypesNotRecordedInStock: 4,
        };
        const { selectedItem, selectedItemType } = this.state;
        if (selectedItem.length !== 0) {
            this.setState({ isDataLoading: true, listStock: [], isSearchBtnPressed: true, responseSearch: "", isErrorInSelectedItem: false });
            let item = selectedItem[0];
            axios.post(`${ApiUrls.searchForAnyItem}`, item, authorizationHeader).then(({ data: result}) => {
                if (result.isSuccess) {
                    let listOfStockItem = JSON.parse(result.data);
                    listOfStockItem = sortByAlphanumerically(listOfStockItem, 'pallet_number');
                    this.setState({ listOfStockItem, isDataLoading: false, selectedItemName: item.description, selectedItemNameId: item.id, selectedItemType: item.ItemType });
                    (selectedItemType === itemType.BottleMedicines || selectedItemType === itemType.MedicalDisposable || selectedItemType === itemType.FeedsAndFood)
                        ? this.setState({ isMoveItemHidden: false })
                        : this.setState({ isMoveItemHidden: true });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
        else {
            this.setState({ isErrorInSelectedItem: true, selectedItemName: "", selectedItemNameId: "" });
        }
    }

    confirmMedicalItemsMove = (value) => {
        value.preventDefault();
        this.validateMedicalPalletNumber();
        const { isValidMedicalPalletNumber } = this.state;
        if (isValidMedicalPalletNumber)
            this.setState({ isConfirmationShowing: true, isResponse: false });
    }

    handleMedicalItemsMove = () => {
        this.setState({ isProcessing: true });
        const { selectedId, selectedPalletNumber, selectedItemType, selectedItem } = this.state;
        axios.post(`${ApiUrls.moveItemTonewPallet}?id=${parseInt(selectedId)}&palletNumber=${selectedPalletNumber[0]}&itemType=${selectedItemType}`, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success", response: result.messageText, isProcessing: false, isConfirmationShowing: false, isDataLoading: true, isItemSeleted: false,
                    selectedPalletNumber: [], isValidMedicalPalletNumber: false
                });
                let item = selectedItem[0];
                axios.post(`${ApiUrls.searchForAnyItem}`, item, authorizationHeader).then(({ data: result}) => {
                    let listOfStockItem = JSON.parse(result.data);
                    listOfStockItem = sortByAlphanumerically(listOfStockItem, 'pallet_number');
                    this.setState({ listOfStockItem, isDataLoading: false, selectedPrimaryLocation: [] })
                }).catch((error) => {
                    this.showError(error);
                });
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: result.messageText, isProcessing: false, isConfirmationShowing: false, isItemSeleted: false,
                    selectedPalletNumber: [], isValidMedicalPalletNumber: false
                });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    setMedicalPalletNumber = (value) => {
        (value !== null && value.length > 0)
            ? this.setState({ errorResponseForMedicalPalletNumber: "", selectedPalletNumber: value, isValidMedicalPalletNumber: true })
            : this.setState({ errorResponseForMedicalPalletNumber: "Please select a pallet", selectedPalletNumber: [], isValidMedicalPalletNumber: false });
    }

    validateMedicalPalletNumber = () => {
        const { selectedPalletNumber } = this.state;
        (selectedPalletNumber !== null && selectedPalletNumber.length > 0)
            ? this.setState({ errorResponseForMedicalPalletNumber: "" })
            : this.setState({ errorResponseForMedicalPalletNumber: "Please select a pallet" });
    };

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger",
                isProcessing: false, isConfirmationShowing: false, isItemSeleted: false,
                selectedPalletNumber: [], isValidMedicalPalletNumber: false
            });
        }
    }
}