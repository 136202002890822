import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, Alert, Modal, ModalBody, FormSelect
} from "shards-react";
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Typeahead } from 'react-bootstrap-typeahead';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ListAllocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
            isResponse: false,
            isSearchBtnPressed: false,
            isList: false,
            isPrioritize: false,
            isViewContainer: false,
            isValidPrimaryLocation: false,
            isConfirmShowing: false,
            isNonPalletConfirmShowing: false,
            isPrioritizePalletConfirmShowing: false,
            isPrioritizeNonPalletConfirmShowing: false,
            isYesBtnDisabled: false,
            theme: "",
            response: "",
            errorResponseForPrimaryLocation: "",
            description: "",
            palletNumber: "",
            nonPalletItemsInStock: [],
            palletsInStock: [],
            palletsAndNonPalletItemsInStock: [],
            selectedContainer: [],
            primaryLocations: [],
            selectedPrimaryLocation: [],
            priorityPalletsInStock: [],
            priorityNonPalletItemsInStock: [],
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],
            isExportBtnDisabled: false
        };
        this.primaryLocation = React.createRef();
    }

    componentDidMount() {
        this.getPrimaryLocations();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('location')
        if (location == "viewcontainer")
            this.setState({ isList: false, isPrioritize: false, isViewContainer: true });
        else if (location == "prioritize")
            this.setState({ isList: false, isPrioritize: true, isViewContainer: false });
        else
            this.setState({ isList: true, isPrioritize: false, isViewContainer: false });
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { SearchBar } = Search;
        const { isList, isPrioritize, isViewContainer, nonPalletItemsInStock,
            palletsAndNonPalletItemsInStock, palletsInStock, isResponse, theme,
            response, selectedPrimaryLocation, primaryLocations, emptyLabel,
            errorResponseForPrimaryLocation, isDataLoading, isSearchBtnPressed,
            isConfirmShowing, palletNumber, selectedContainer, isNonPalletConfirmShowing,
            description, isYesBtnDisabled, priorityPalletsInStock, priorityNonPalletItemsInStock,
            isPrioritizePalletConfirmShowing, isPrioritizeNonPalletConfirmShowing, isExportBtnDisabled } = this.state;
        const columnsPalletsInStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'PalletNumber',
                    style: {
                        fontWeight: 'normal',
                        width: '75%'
                    }
                },
                {
                    text: 'Remove pallet',
                    formatter: this.removePallets,
                    hidden: !isList,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
                {
                    text: 'Priority',
                    formatter: this.addPriority,
                    hidden: !isPrioritize,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
                {
                    text: 'Priority',
                    formatter: this.priorityStatus,
                    hidden: !isViewContainer,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
            ];
        const columnsNonPalletItemsInStock =
            [
                {
                    text: 'Description',
                    dataField: 'description_with_qty',
                    style: {
                        fontWeight: 'normal',
                        width: '75%'
                    }
                },
                {
                    text: 'Remove non-pallet',
                    formatter: this.removeNonPallets,
                    hidden: !isList,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'

                    }
                },
                {
                    text: 'Priority',
                    formatter: this.addPriorityForNonPallets,
                    hidden: !isPrioritize,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'

                    }
                },
                {
                    text: 'Priority',
                    formatter: this.priorityStatusForNonPallets,
                    hidden: !isViewContainer,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'

                    }
                },
            ];
        const columnsPalletsAndNonPalletItemsInStock =
            [
                {
                    text: 'Items',
                    dataField: 'PalletNumber',
                    style: {
                        fontWeight: 'normal',
                        width: '75%'
                    }
                },
                {
                    text: 'Priority',
                    formatter: this.priorityStatusForPalletsAndNonPallets,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'

                    }
                },
            ];
        const palletsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="PalletNumber"
                    columns={columnsPalletsInStock}
                    data={palletsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
        const nonPalletItemsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="id"
                    columns={columnsNonPalletItemsInStock}
                    data={nonPalletItemsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
        const palletsAndNonPalletItemsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="PalletNumber"
                    columns={columnsPalletsAndNonPalletItemsInStock}
                    data={palletsAndNonPalletItemsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            {isList && (<>
                                <BreadcrumbItem active>List Allocations</BreadcrumbItem>
                            </>)}
                            {isPrioritize && (<>
                                <BreadcrumbItem active>Prioritize Allocations</BreadcrumbItem>
                            </>)}
                            {isViewContainer && (<>
                                <BreadcrumbItem><a href="/warehouse">Warehouse Interface</a></BreadcrumbItem>
                                <BreadcrumbItem active>View Container Allocations</BreadcrumbItem>
                            </>)}
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isList && (<>
                                        <h4>LIST ALLOCATIONS</h4>
                                    </>)}
                                    {isPrioritize && (<>
                                        <h4>PRIORITIZE ALLOCATIONS</h4>
                                    </>)}

                                    {isViewContainer && (<>
                                        <h4>VIEW CONTAINER ALLOCATIONS</h4>
                                    </>)}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#PrimaryLocation">Select container:<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" selected={selectedPrimaryLocation}
                                            id="id"
                                            labelKey="description"
                                            onChange={this.setPrimaryLocation}
                                            options={primaryLocations}
                                            placeholder=""
                                            ref={this.primaryLocation}
                                            emptyLabel={emptyLabel}
                                        />
                                        <span className="error-txt">{errorResponseForPrimaryLocation}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" type="submit" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!isDataLoading && (
                                        <div>
                                            {isSearchBtnPressed && !isViewContainer && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5>PALLETS</h5>
                                                        </Col>
                                                    </Row>
                                                    {palletsInStock.length != 0 && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {palletsInStockTable}
                                                            </PaginationProvider>
                                                            {isPrioritize && (<>
                                                                <Row>
                                                                    <Col sm="12" md="12" lg="12">
                                                                        <FormGroup>
                                                                            <Button theme="primary" style={{ float: "right" }} size="lg" type="submit" onClick={this.handlePriorityPalletUpdate}>Update</Button>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>)}
                                                        </>
                                                    )}
                                                    {palletsInStock.length == 0 && (
                                                        <><Alert theme="warning">No Data Found</Alert></>
                                                    )}
                                                    <hr />
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5>NON-PALLET ITEM</h5>
                                                        </Col>
                                                    </Row>
                                                    {nonPalletItemsInStock.length != 0 && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {nonPalletItemsInStockTable}
                                                            </PaginationProvider>
                                                            {isPrioritize && (<>
                                                                <Row>
                                                                    <Col sm="12" md="12" lg="12">
                                                                        <FormGroup>
                                                                            <Button theme="primary" size="lg" style={{ float: "right" }} type="submit" onClick={this.handlePriorityNonPalletUpdate}>Update</Button>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>)}
                                                        </>
                                                    )}
                                                    {nonPalletItemsInStock.length == 0 && (
                                                        <><Alert theme="warning">No Data Found</Alert></>
                                                    )}
                                                    {(palletsInStock.length != 0 || nonPalletItemsInStock.length) != 0 && (
                                                        <>
                                                            <Row>
                                                                <Col sm="12" md="6" lg="6">
                                                                    <Button style={{ margin: "1%" }} size="lg" block onClick={this.handlePrint}>Print</Button>
                                                                </Col>
                                                                <Col sm="12" md="6" lg="6">
                                                                    <Button style={{ margin: "1%" }} size="lg" block onClick={this.exportTableToExcel} disabled={isExportBtnDisabled}>{isExportBtnDisabled ? "Loading..." : "Export"}</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {isSearchBtnPressed && isViewContainer && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5></h5>
                                                        </Col>
                                                    </Row>
                                                    {(palletsInStock.length != 0 || nonPalletItemsInStock.length != 0) && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {palletsAndNonPalletItemsInStockTable}
                                                            </PaginationProvider>
                                                        </>
                                                    )}
                                                    {palletsInStock.length == 0 && nonPalletItemsInStock.length == 0 && (
                                                        <><Alert theme="warning">No Data Found</Alert></>
                                                    )}
                                                    <hr />
                                                    {(palletsInStock.length != 0 || nonPalletItemsInStock.length) != 0 && (
                                                        <>
                                                            <Row>
                                                                <Col sm="12" md="6" lg="6">
                                                                    <Button style={{ margin: "1%" }} size="lg" block onClick={this.handlePrint}>Print</Button>
                                                                </Col>
                                                                <Col sm="12" md="6" lg="6">
                                                                    <Button style={{ margin: "1%" }} size="lg" block onClick={this.exportTableToExcel} disabled={isExportBtnDisabled}>{isExportBtnDisabled ? "Loading..." : "Export"}</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmShowing && (
                    <Modal open={isConfirmShowing} toggle={() => this.setState({ isConfirmShowing: !isConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>REMOVE PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Are you sure that you want to remove {palletNumber} from {selectedContainer[0].description}?</label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handlePalletRemove} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ isConfirmShowing: false })} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
                {isNonPalletConfirmShowing && (
                    <Modal open={isNonPalletConfirmShowing} toggle={() => this.setState({ isConfirmShowing: !isNonPalletConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>REMOVE NON-PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Are you sure that you want to remove {description} from {selectedContainer[0].description}?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleNonPalletRemove} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ isNonPalletConfirmShowing: false })} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
                {isPrioritizePalletConfirmShowing && (
                    <Modal open={isPrioritizePalletConfirmShowing} toggle={() => this.setState({ isConfirmShowing: !isPrioritizePalletConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>PRIORITIZE ALLOCATIONS - PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to update the Priority level(s) as follows?</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse" }}>
                                        <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                            <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Pallet number</th>
                                            <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Previous priority</th>
                                            <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Selected priority</th>
                                        </tr>
                                        {priorityPalletsInStock.map((pallet) => {
                                            return (
                                                <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{pallet.PalletNumberOrId}</td>
                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{this.getPriority(pallet.Priority)}</td>
                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{this.getPriority(pallet.SelectPriority)}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handlePalletPrioritizeAllocations} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ isPrioritizePalletConfirmShowing: false })} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
                {isPrioritizeNonPalletConfirmShowing && (
                    <Modal open={isPrioritizeNonPalletConfirmShowing} toggle={() => this.setState({ isConfirmShowing: !isPrioritizeNonPalletConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>PRIORITIZE ALLOCATIONS - NON-PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to update the Priority level(s) as follows?</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse" }}>
                                        <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                            <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Description</th>
                                            <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Previous priority</th>
                                            <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Selected priority</th>
                                        </tr>
                                        {priorityNonPalletItemsInStock.map((nonPallet) => {
                                            return (
                                                <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{nonPallet.Description}</td>
                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{this.getPriority(nonPallet.Priority)}</td>
                                                    <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{this.getPriority(nonPallet.SelectPriority)}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleNonPalletPrioritizeAllocations} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ isPrioritizeNonPalletConfirmShowing: false })} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    removePallets = (cell, row) => {
        return (
            <Row>
                <Col >
                    <Button theme="danger" block onClick={() => this.handleRemovePallet(row)}>Remove</Button>
                </Col>
            </Row>
        )
    }

    removeNonPallets = (cell, row) => {
        return (
            <Row>
                <Col >
                    <Button theme="danger" block onClick={() => this.handleRemoveNonPallet(row)}>Remove</Button>
                </Col>
            </Row>
        )
    }

    addPriority = (cell, row) => {
        return (
            <Row>
                <Col sm="12" md="12" lg="12" >
                    <FormGroup>
                        <FormSelect onChange={e => this.setPriority(e, row)} id={row.PalletNumber} style={{ background: 'white', fontSize: 'inherit' }} >
                            <option value="1" >High</option>
                            <option value="2" >Medium</option>
                            <option value="3" >Low</option>
                            <option value="0" >None</option>
                        </FormSelect>
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    addPriorityForNonPallets = (cell, row) => {
        return (
            <Row>
                <Col sm="12" md="12" lg="12" >
                    <FormGroup>
                        <FormSelect onChange={e => this.setPriorityForNonPallets(e, row)} id={row.id} style={{ background: 'white', fontSize: 'inherit' }} >
                            <option value="1" >High</option>
                            <option value="2" >Medium</option>
                            <option value="3" >Low</option>
                            <option value="0" >None</option>
                        </FormSelect>
                    </FormGroup>
                </Col>
            </Row>
        )
    }

    priorityStatus = (cell, row) => {
        return (<div>{this.getPriority(row.Priority)}</div>)
    }

    priorityStatusForNonPallets = (cell, row) => {
        return (<div>{this.getPriority(row.priority)}</div>)
    }

    priorityStatusForPalletsAndNonPallets = (cell, row) => {
        return (<div>{(row.Priority !== null && row.Priority !== undefined) ? this.getPriority(row.Priority) : this.getPriority(row.priority)}</div>)
    }

    handleRemovePallet = (row) => {
        this.setState({ isConfirmShowing: true, palletNumber: row.PalletNumber, isResponse: false });
    }

    handleRemoveNonPallet = (row) => {
        this.setState({ isNonPalletConfirmShowing: true, id: row.id, description: row.description, isResponse: false });
    }

    getPrimaryLocations = () => {
        axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let primaryLocations = JSON.parse(result.data);
                let sortedPrimaryLocations = sortByAlphanumerically(primaryLocations, "description");
                this.setState({ primaryLocations: sortedPrimaryLocations, emptyLabel: "No matches found" });
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setPriority = (value, row) => {
        this.setState({ isResponse: false });
        const { palletsInStock } = this.state;
        palletsInStock.forEach(pallet => {
            if (pallet.PalletNumber == row.PalletNumber) {
                pallet.select_priority = parseInt(value.target.value);
                document.getElementById(row.PalletNumber).value = value.target.value;
            }
        });
    };

    setPriorityForNonPallets = (value, row) => {
        this.setState({ isResponse: false }); this.setState({ isResponse: false });
        const { nonPalletItemsInStock } = this.state;
        nonPalletItemsInStock.forEach(nonPallet => {
            if (nonPallet.id == row.id) {
                nonPallet.select_priority = parseInt(value.target.value);
                document.getElementById(row.id).value = value.target.value;
            }
        })
    };

    handlePriorityPalletUpdate = () => {
        const { palletsInStock } = this.state;
        let priorityPalletsInStock = [];
        palletsInStock.forEach(pallet => {
            if (pallet.Priority != pallet.select_priority) {
                let priorityPallet = {
                    PalletNumberOrId: pallet.PalletNumber,
                    Priority: pallet.Priority,
                    SelectPriority: pallet.select_priority,
                };
                priorityPalletsInStock.push(priorityPallet);
            }
        })
        this.setState({ priorityPalletsInStock });
        if (priorityPalletsInStock.length > 0)
            this.setState({ isPrioritizePalletConfirmShowing: true, isResponse: false });
    }

    handlePriorityNonPalletUpdate = () => {
        const { nonPalletItemsInStock } = this.state;
        let priorityNonPalletItemsInStock = [];
        nonPalletItemsInStock.forEach(nonPallet => {
            if (nonPallet.priority != nonPallet.select_priority) {
                let priorityNonPallet = {
                    PalletNumberOrId: nonPallet.id.toString(),
                    Priority: nonPallet.priority,
                    SelectPriority: nonPallet.select_priority,
                    Description: nonPallet.description_with_qty,
                };
                priorityNonPalletItemsInStock.push(priorityNonPallet);
            }
        })
        this.setState({ priorityNonPalletItemsInStock });
        if (priorityNonPalletItemsInStock.length > 0)
            this.setState({ isPrioritizeNonPalletConfirmShowing: true, isResponse: false });
    }

    setPrimaryLocation = (value) => {
        this.setState({ isResponse: false });
        (value !== null && value.length > 0)
            ? this.setState({ errorResponseForPrimaryLocation: "", selectedPrimaryLocation: value, isValidPrimaryLocation: true })
            : this.setState({ errorResponseForPrimaryLocation: "Please select a container", selectedPrimaryLocation: [], secondaryLocations: [], isValidPrimaryLocation: false });
    };

    validatePrimaryLocation = () => {
        this.setState({ isResponse: false });
        const { selectedPrimaryLocation } = this.state;
        (selectedPrimaryLocation !== null && selectedPrimaryLocation.length > 0)
            ? this.setState({ errorResponseForPrimaryLocation: "" })
            : this.setState({ errorResponseForPrimaryLocation: "Please select a container" });
    };

    searchAllocations = () => {
        this.setState({ isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPressed: true });
        const { selectedPrimaryLocation, isViewContainer, isPrioritize, palletsInStock, nonPalletItemsInStock } = this.state;
        let locationId = parseInt(selectedPrimaryLocation[0].id);
        this.setState({ selectedContainer: selectedPrimaryLocation });
        axios.get(`${ApiUrls.getAllocatedNonPalletsByContainer}?container=${locationId}`, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let nonPalletItemsInStock = JSON.parse(result.data);
                let priorityNonPalletItemsInStock = [];
                nonPalletItemsInStock.forEach(item => {
                    let nonPallet = {
                        container_allocation: item.container_allocation,
                        description: item.description,
                        description_with_qty: item.description_with_qty,
                        id: item.id,
                        non_pallet_items_in_stock_id: item.non_pallet_items_in_stock_id,
                        number_of_item: item.number_of_item,
                        priority: item.priority,
                        select_priority: item.priority,
                        sort_index: this.getSortIndex(item.priority)
                    };
                    priorityNonPalletItemsInStock.push(nonPallet);
                });
                let sortedNonPalletItemsInStock = sortByAlphanumerically(priorityNonPalletItemsInStock, 'description_with_qty');
                this.setState({ nonPalletItemsInStock: sortedNonPalletItemsInStock });
            }
            axios.get(`${ApiUrls.getAllocatedPalletsInStockByContainer}?container=${locationId}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let palletsInStock = JSON.parse(result.data);
                    let priorityPalletsInStock = [];
                    palletsInStock.forEach(item => {
                        let pallet = {
                            ContainerAllocation: item.ContainerAllocation,
                            DateCreated: item.DateCreated,
                            DeletedOn: item.DeletedOn,
                            Description: item.Description,
                            EarliestExpiryDate: item.EarliestExpiryDate,
                            FinishOn: item.FinishOn,
                            IsDeleted: item.IsDeleted,
                            IsFinish: item.IsFinish,
                            ModifiedBy: item.ModifiedBy,
                            ModifiedOn: item.ModifiedOn,
                            PalletCategory: item.PalletCategory,
                            PalletNumber: item.PalletNumber,
                            Priority: item.Priority,
                            select_priority: item.Priority,
                            SecondaryLocationId: item.SecondaryLocationId,
                            StorageLocationId: item.StorageLocationId,
                            TotalValue: item.TotalValue,
                            TotalWeight: item.TotalWeight,
                            WholesaleValue: item.WholesaleValue,
                            sort_index: this.getSortIndex(item.Priority),
                        };
                        priorityPalletsInStock.push(pallet);
                    });
                    let sortedPalletsInStock = sortByAlphanumerically(priorityPalletsInStock, 'PalletNumber');
                    this.setState({ palletsInStock: sortedPalletsInStock });
                }
                isViewContainer ? this.sortPalletsAndNonPalletItemsInStock() : this.setState({ isDataLoading: false });
                if (isPrioritize) {
                    palletsInStock.forEach(pallet => {
                        if (document.getElementById(pallet.PalletNumber))
                            document.getElementById(pallet.PalletNumber).value = pallet.select_priority;
                    });
                    nonPalletItemsInStock.forEach(nonPallet => {
                        document.getElementById(nonPallet.id).value = nonPallet.select_priority;
                    });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }).catch((error) => {
            this.showError(error);
        });
    }

    sortPalletsAndNonPalletItemsInStock = () => {
        let sortJsonArray = require('sort-json-array');
        let alphabaticallySortedPalletsAndNonPalletItemsInStock = [];
        const { palletsInStock, nonPalletItemsInStock } = this.state;
        let palletsAndNonPalletItemsInStock = [...palletsInStock, ...nonPalletItemsInStock.map((data) => { return { ...data, PalletNumber: data.description_with_qty } })];
        let sortPalletsAndNonPalletItemsInStock = sortJsonArray(palletsAndNonPalletItemsInStock, 'sort_index');
        alphabaticallySortedPalletsAndNonPalletItemsInStock = [
            ...this.sortItemsAlphabaticalAfterPrimarySort(0, sortPalletsAndNonPalletItemsInStock),
            ...this.sortItemsAlphabaticalAfterPrimarySort(1, sortPalletsAndNonPalletItemsInStock),
            ...this.sortItemsAlphabaticalAfterPrimarySort(2, sortPalletsAndNonPalletItemsInStock),
            ...this.sortItemsAlphabaticalAfterPrimarySort(3, sortPalletsAndNonPalletItemsInStock)
        ];
        this.setState({ palletsAndNonPalletItemsInStock: alphabaticallySortedPalletsAndNonPalletItemsInStock, isDataLoading: false });
    }

    sortItemsAlphabaticalAfterPrimarySort = (sortIndex, primarySortArray) => {
        let filteredArrayFromSortIndex = primarySortArray.filter((element) => { return element.sort_index == sortIndex });
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        const secondarySortedArray = filteredArrayFromSortIndex.sort((a, b) => collator.compare(a.PalletNumber, b.PalletNumber));
        return secondarySortedArray;
    }

    handleSearch = (value) => {
        value.preventDefault();
        this.validatePrimaryLocation();
        this.setState({ isResponse: false });
        const { isValidPrimaryLocation } = this.state;
        if (isValidPrimaryLocation)
            this.searchAllocations();
    }

    handleNonPalletRemove = () => {
        this.setState({ isYesBtnDisabled: true, isResponse: false });
        const { id, selectedContainer } = this.state;
        axios.post(`${ApiUrls.removeNonPalletContainerAllocate}?id=${parseInt(id)}`, {}, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "success", response: result.messageText,
                    isNonPalletConfirmShowing: false, isYesBtnDisabled: false,
                });
                this.setState({ isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPressed: true })
                let locationId = parseInt(selectedContainer[0].id);
                axios.get(`${ApiUrls.getAllocatedNonPalletsByContainer}?container=${locationId}`, authorizationHeader).then(({ data: result }) => {
                    if (result.isSuccess) {
                        let nonPalletItemsInStock = JSON.parse(result.data);
                        let sortedNonPalletItemsInStock = sortByAlphanumerically(nonPalletItemsInStock, 'description');
                        this.setState({ nonPalletItemsInStock: sortedNonPalletItemsInStock });
                    }
                    axios.get(`${ApiUrls.getAllocatedPalletsInStockByContainer}?container=${locationId}`, authorizationHeader).then(({ data: result }) => {
                        if (result.isSuccess) {
                            let palletsInStock = JSON.parse(result.data);
                            let sortedPalletsInStock = sortByAlphanumerically(palletsInStock, 'PalletNumber');
                            this.setState({ palletsInStock: sortedPalletsInStock, isDataLoading: false });
                        }
                    }).catch((error) => {
                        this.showError(error);
                    });
                }).catch((error) => {
                    this.showError(error);
                });
            }
            else {
                this.setState({ selectedPallet: [], isResponse: true, theme: "danger", response: result.messageText, isNonPalletConfirmShowing: false, isYesBtnDisabled: false, })
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    handlePalletRemove = () => {
        this.setState({ isYesBtnDisabled: true, isResponse: false });
        const { palletNumber, selectedContainer } = this.state;
        let finishedPalletInfo = {
            PalletNumber: palletNumber.toString(),
        };
        axios.post(ApiUrls.removeContainerAllocate, finishedPalletInfo, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "success", response: result.messageText,
                    isConfirmShowing: false, isYesBtnDisabled: false,
                });
                this.setState({ isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPressed: true });
                let locationId = parseInt(selectedContainer[0].id);
                axios.get(`${ApiUrls.getAllocatedNonPalletsByContainer}?container=${locationId}`, authorizationHeader).then(({ data: result }) => {
                    if (result.isSuccess) {
                        let nonPalletItemsInStock = JSON.parse(result.data);
                        let sortedNonPalletItemsInStock = sortByAlphanumerically(nonPalletItemsInStock, 'description_with_qty');
                        this.setState({ nonPalletItemsInStock: sortedNonPalletItemsInStock });
                    }
                    axios.get(`${ApiUrls.getAllocatedPalletsInStockByContainer}?container=${locationId}`, authorizationHeader).then(({ data: result }) => {
                        if (result.isSuccess) {
                            let palletsInStock = JSON.parse(result.data);
                            let sortedPalletsInStock = sortByAlphanumerically(palletsInStock, 'PalletNumber');
                            this.setState({ palletsInStock: sortedPalletsInStock, isDataLoading: false });
                        }
                    }).catch((error) => {
                        this.showError(error);
                    });
                }).catch((error) => {
                    this.showError(error);
                });
            }
            else {
                this.setState({ selectedPallet: [], isResponse: true, theme: "danger", response: result.messageText, isYesBtnDisabled: true, isConfirmShowing: false, })
            }
        }).catch((error) => {
            this.showError(error);
            this.setState({ selectedPallet: [], isResponse: true, theme: "danger", response: "Something went wrong", isYesBtnDisabled: true, isConfirmShowing: false, })
        });
    }

    handlePrint = () => {
        const { selectedContainer, isViewContainer, palletsAndNonPalletItemsInStock, palletsInStock, nonPalletItemsInStock } = this.state;
        let a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write('<h1>LIST ALLOCATIONS</h1>');
        a.document.write('<h3>Container name : ' + selectedContainer[0].name + '</h3>');
        if (isViewContainer) {
            a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            a.document.write('<tr>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Items</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Priority</th>');
            a.document.write('</tr>');
            palletsAndNonPalletItemsInStock.map((box) => {
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" > &nbsp; ' + box.PalletNumber + '</td>');
                let priorityStatus = (box.Priority !== null && box.Priority !== undefined) ? this.getPriority(box.Priority) : this.getPriority(box.priority);
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" > &nbsp; ' + priorityStatus + '</td>');
                a.document.write('</tr>');
            });
            a.document.write('</table>');
        } else {
            a.document.write('<h3>PALLETS</h3>');
            a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            a.document.write('<tr>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Pallet number</th>');
            a.document.write('</tr>');
            palletsInStock.map((box) => {
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" > &nbsp; ' + box.PalletNumber + '</td>');
                a.document.write('</tr>');
            });
            a.document.write('</table>');
            a.document.write('<br>');
            a.document.write('<h3>NON-PALLETS</h3>');
            a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            a.document.write('<tr>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Description </th>');
            a.document.write('</tr>');
            nonPalletItemsInStock.map((box) => {
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" > &nbsp; ' + box.description_with_qty + '</td>');
                a.document.write('</tr>');
            });
            a.document.write('</table>');
        }
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    exportTableToExcel = () => {
        const { palletsInStock } = this.state;
        console.log(palletsInStock);
        this.setState({ isExportBtnDisabled: true });
        let palletNumbers = [];
        palletsInStock.forEach((item) => { palletNumbers.push(item.PalletNumber) });
        //get pallet contents
        if (palletNumbers && palletNumbers.length) {
            axios.get(`${ApiUrls.viewPalletsContents}?pallets=${palletNumbers.join(',')}`, authorizationHeader).then((response) => {
                var palletBoxContent = JSON.parse(response.data.data);
                console.log(palletBoxContent);
                this.createExcelSheet(palletBoxContent);
            }).catch((error) => {
                this.showError(error);
            });
        } else {
            this.createExcelSheet();
        }
    }

    createExcelSheet = (palletBoxContent = []) => {
        const { palletsInStock, nonPalletItemsInStock, palletsAndNonPalletItemsInStock, selectedContainer, isViewContainer } = this.state;
        this.setState({ isExportBtnDisabled: false });
        let tabText = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>';
        tabText = tabText + '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">';
        tabText = tabText + '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Jacobs Well</Author><Created>1632198631866</Created></DocumentProperties>';
        tabText = tabText + '<Styles><Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style><Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style></Styles>';
        // pallet & non-pallets worksheet
        if (isViewContainer) {
            tabText = tabText + '<Worksheet ss:Name="PALLETS & NON-PALLETS">';
            tabText = tabText + '<Table>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Container: ' + selectedContainer[0].name + '</Data></Cell>';
            tabText = tabText + '</Row>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String"></Data></Cell>';
            tabText = tabText + '</Row>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Items</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String">Priority</Data></Cell>';
            tabText = tabText + '</Row>';
            palletsAndNonPalletItemsInStock.map((box) => {
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + box.PalletNumber + '</Data></Cell>';
                let priorityStatusOfPalletsAndNonPallets = (box.Priority !== null && box.Priority !== undefined) ? this.getPriority(box.Priority) : this.getPriority(box.priority);
                tabText = tabText + '<Cell><Data ss:Type="String">' + priorityStatusOfPalletsAndNonPallets + '</Data></Cell>';
                tabText = tabText + '</Row>';
            });
            tabText = tabText + '</Table>';
            tabText = tabText + '</Worksheet>';
        } else {
            // pallet & non-pallets worksheets
            // pallet worksheet
            tabText = tabText + '<Worksheet ss:Name="PALLETS">';
            tabText = tabText + '<Table>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Container: ' + selectedContainer[0].name + '</Data></Cell>';
            tabText = tabText + '</Row>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String"></Data></Cell>';
            tabText = tabText + '</Row>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Pallet number</Data></Cell>';
            tabText = tabText + '</Row>';
            palletsInStock.map((box) => {
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + box.PalletNumber + '</Data></Cell>';
                tabText = tabText + '</Row>';
            });
            tabText = tabText + '</Table>';
            tabText = tabText + '</Worksheet>';
            // non-pallets worksheet
            tabText = tabText + '<Worksheet ss:Name="NON-PALLETS">';
            tabText = tabText + '<Table>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Description</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String">Quantity</Data></Cell>';
            tabText = tabText + '</Row>';
            nonPalletItemsInStock.map((box) => {
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + box.description + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + box.number_of_item + '</Data></Cell>';
                tabText = tabText + '</Row>';
            })
            tabText = tabText + '</Table>';
            tabText = tabText + '</Worksheet>';
        }
        // Detailed list worksheet
        if (palletBoxContent && palletBoxContent.length) {
            tabText = tabText + '<Worksheet ss:Name="DetailedList">';
            tabText = tabText + '<Table>';
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Pallet number/Non Pallet item</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String">Content</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String">Expiry date</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String">Weight</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String">Unit price (£)</Data></Cell>';
            tabText = tabText + '</Row>';
            palletBoxContent.map((item) => {
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + item.palletNumber + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + item.description + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + getDateInGivenFormat(item.expiryDate) + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="Number">' + item.weight.toFixed(2) + '</Data></Cell>';
                tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + item.price.toFixed(2) + '</Data></Cell>';
                tabText = tabText + '</Row>';
            });
            nonPalletItemsInStock.map((box) => {
                let descriptiontext = box.description + '(' + box.number_of_item + ')';
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + descriptiontext + '</Data></Cell>';
                tabText = tabText + '</Row>';
            });
            tabText = tabText + '</Table>';
            tabText = tabText + '</Worksheet>';
        }
        tabText = tabText + '</Workbook>';
        let downloadLink;
        let dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let tableHTML = tabText;
        let filename = "List Allocation " + selectedContainer[0].name;
        filename = filename ? filename + '.xls' : 'excel_data.xls';
        downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        if (navigator.msSaveOrOpenBlob) {
            let blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
            downloadLink.download = filename;
            downloadLink.click();
        }
    }

    handlePalletPrioritizeAllocations = () => {
        this.setState({ isYesBtnDisabled: true, isResponse: false });
        const { priorityPalletsInStock } = this.state;
        axios.post(`${ApiUrls.updatePriorityPallets}`, priorityPalletsInStock, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "success", response: result.messageText,
                    isPrioritizePalletConfirmShowing: false, isYesBtnDisabled: false,
                });
                this.searchAllocations();
            }
            else {
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "danger", response: result.messageText,
                    isPrioritizePalletConfirmShowing: false, isYesBtnDisabled: false,
                });
            }
        }).catch(() => {
            this.setState({
                selectedPallet: [], isResponse: true, theme: "danger", response: "Something went wrong",
                isPrioritizePalletConfirmShowing: false, isYesBtnDisabled: false,
            });
        });
    }

    handleNonPalletPrioritizeAllocations = () => {
        this.setState({ isYesBtnDisabled: true, isResponse: false });
        const { priorityNonPalletItemsInStock } = this.state;
        axios.post(`${ApiUrls.updatePriorityNonPallets}`, priorityNonPalletItemsInStock, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "success", response: result.messageText,
                    isPrioritizeNonPalletConfirmShowing: false, isYesBtnDisabled: false,
                });
                this.searchAllocations();
            }
            else {
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "danger", response: result.messageText,
                    isPrioritizeNonPalletConfirmShowing: false, isYesBtnDisabled: false,
                });
            }
        }).catch(() => {
            this.setState({
                selectedPallet: [], isResponse: true, theme: "danger", response: "Something went wrong",
                isPrioritizeNonPalletConfirmShowing: false, isYesBtnDisabled: false,
            });
        });
    }

    getPriority(statusId) {
        let priority = "";
        if (statusId == 1) { priority = "High" }
        else if (statusId == 2) { priority = "Medium" }
        else if (statusId == 3) { priority = "Low" }
        else if (statusId == 0) { priority = "None" }
        else { priority = "" }
        return priority;
    }

    getSortIndex(priorityStatusId) {
        let sortIndex = 0;
        if (priorityStatusId == 1) { sortIndex = 0 }
        else if (priorityStatusId == 2) { sortIndex = 1 }
        else if (priorityStatusId == 3) { sortIndex = 2 }
        else if (priorityStatusId == 0) { sortIndex = 3 }
        else { sortIndex = "" }
        return sortIndex;
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isNonPalletConfirmShowing: false, isYesBtnDisabled: false });
        }
    }
}