import React from "react";
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup, Container, Row, Col, Modal, ModalBody, Button,
    Collapse
} from "shards-react";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class MainNavbar extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);

        const { cookies } = props;

        var token = localStorage.getItem("Token")

        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        var decryptToken = JSON.parse(jsonPayload);
        var userDetail = JSON.parse(decryptToken.UserDetail)
        var loggedUser = userDetail.Username;

        this.state = {
            
            showLogoutConfirm:false,
            collapseOpen: false,
            visible: false,
            loggedUser:loggedUser
        };
    }
    toggleUserActions = () => {
        this.setState({
            visible: !this.state.visible
        });
    }

    toggleNavbar = () => {
        this.setState({
            ...this.state,
            ...{
                collapseOpen: !this.state.collapseOpen
            }
        });
    }

    render() {
        return (
            <div style={{
                position: "sticky", top: "0", zIndex: "1020"
            }}>
                <Navbar type="dark" theme="primary" expand={true}>
                    <NavbarBrand href="/dashboard"><i className="fa fa-home"></i> Home</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} />

                    <Collapse open={this.state.collapseOpen} navbar>
                       
                        <Nav navbar className="ml-auto">
                            <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
                                <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
                                    <span className="d-md-inline-block" style={{ color: "white" }}>logged in as {this.state.loggedUser}</span>
                                </DropdownToggle>
                                <Collapse tag={DropdownMenu} right small open={this.state.visible}>
                                    <DropdownItem onClick={() => window.location.href = "/change-password"}>
                                        <i className="material-icons">&#xE896;</i> Change Password
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={ ()=>this.onLogoutClick()} className="text-danger">
                                        <i className="material-icons text-danger">&#xE879;</i> Logout
                                    </DropdownItem>
                                </Collapse>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
                {this.state.showLogoutConfirm && (
                    <Modal open={this.state.showLogoutConfirm} toggle={() => this.setState({ showLogoutConfirm: !this.state.showLogoutConfirm })}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>Confirm</h2></Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Are you sure you want to Logout?</label>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" onClick={this.confirmLogout}>Yes</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ showLogoutConfirm:false})}>No</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                    
                    )}
            </div>
        );
    }
    onDropdownItemClick = (value) => {
        window.location.href = value;
    }
    onLogoutClick = () => {
        this.setState({ showLogoutConfirm: true })
    }
    confirmLogout = () => {
        const { cookies } = this.props;
        cookies.remove("loggedUserInfo");
        this.setState({ showLogoutConfirm: false })
        window.localStorage.removeItem("Token");
        window.location.href = "/login";
    }
}
export default withCookies(MainNavbar);