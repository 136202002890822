import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, Modal, ModalBody, FormGroup,
    Alert, FormInput, Form, FormTextarea
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class SendSmallDonation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            donationNumber: "",

            name: "",
            responseName: "",
            validName: false,

            address: "",
            responseAddress: "",
            validAddress: false,

            country: "",
            responseCountry: "",
            validCountry: false,

            notes: "",
            responseNotes: "",
            validNotes: false,

            printConfirmation: false,
            showConfirmation: false,
            today: "",
            yesBtnText: "Yes",
            palletNumber: "",
            isYesBtnDisabled: false,

            location: "",

        };
        this.StorageLocation = React.createRef();
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('location')
        this.setState({ location: location, isDonationLoading: true });

        this.getNextDonationNumber();
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            {this.state.location == "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                            )}
                            <BreadcrumbItem active>Small Donations​</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>SEND A SMALL DONATION</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Form>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup style={{ fontSize: "large" }}>
                                            <label htmlFor="#donationNumber">Donation number :</label>
                                            <FormInput size="lg" type="text" id="#donationNumber" value={this.state.donationNumber} disabled />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup style={{ fontSize: "large" }}>
                                            <label htmlFor="#name">Name of recipient/organisation :<span className="error-txt">*</span></label>
                                            <FormInput size="lg" type="text" id="#name" value={this.state.name} onChange={this.setName} />
                                            <span className="error-txt">{this.state.responseName}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup style={{ fontSize: "large" }}>
                                            <label htmlFor="#address">Address of recipient :<span className="error-txt">*</span></label>
                                            <FormTextarea size="lg" type="text" id="#address" value={this.state.address} onChange={this.setAddress} />
                                            <span className="error-txt">{this.state.responseAddress}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup style={{ fontSize: "large" }}>
                                            <label htmlFor="#country">Country of recipient :<span className="error-txt">*</span></label>
                                            <FormInput size="lg" type="text" id="#country" value={this.state.country} onChange={this.setCountry} />
                                            <span className="error-txt">{this.state.responseCountry}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup style={{ fontSize: "large" }}>
                                            <label htmlFor="#notes">Notes :<span className="error-txt">*</span></label>
                                            <FormTextarea size="lg" type="text" id="#notes" value={this.state.notes} onChange={this.setNotes} />
                                            <span className="error-txt">{this.state.responseNotes}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Form>


                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>CREATE SMALL DONATION - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>{`Do you want to create donation number ${this.state.donationNumber} to ${this.state.name} which will be sent to ${this.state.country}?`}</label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>

                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getNextDonationNumber = () => {
        axios.get(ApiUrls.getNextDonationNumber, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var donationNumber = JSON.parse(response.data.data);
                this.setState({ donationNumber: donationNumber });
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    //----------------------------------------

    setName = (value) => {
        this.setState({ name: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseName: "Please enter name", validName: false });
        } else {
            this.setState({ responseName: "", validName: true });
        }
    }

    validateName = () => {
        this.setState({ isResponse: false });
        if (!(this.state.name)) {
            this.setState({ responseName: "Please enter name", validName: false });
        } else {
            this.setState({ responseName: "", validName: true });
        }
    }

    //----------------------------------------

    setAddress = (value) => {
        this.setState({ address: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseAddress: "Please enter address", validAddress: false });
        } else {
            this.setState({ responseAddress: "", validAddress: true });
        }
    }

    validateAddress = () => {
        this.setState({ isResponse: false });
        if (!(this.state.address)) {
            this.setState({ responseAddress: "Please enter address", validAddress: false });
        } else {
            this.setState({ responseAddress: "", validAddress: true });
        }
    }

    //----------------------------------------
    setCountry = (value) => {
        this.setState({ country: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseCountry: "Please enter country", validCountry: false });
        } else {
            this.setState({ responseCountry: "", validCountry: true });
        }
    }

    validateCountry = () => {
        this.setState({ isResponse: false });
        if (!(this.state.country)) {
            this.setState({ responseCountry: "Please enter country", validCountry: false });
        } else {
            this.setState({ responseCountry: "", validCountry: true });
        }
    }

    //----------------------------------------

    setNotes = (value) => {
        this.setState({ notes: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNotes: "Please enter notes", validNotes: false });
        } else {
            this.setState({ responseNotes: "", validNotes: true });
        }
    }

    validateNotes = () => {
        this.setState({ isResponse: false });
        if (!(this.state.notes)) {
            this.setState({ responseNotes: "Please enter notes", validNotes: false });
        } else {
            this.setState({ responseNotes: "", validNotes: true });
        }
    }

    //----------------------------------------

    onConfirmClick = () => {
        this.validateName();
        this.validateAddress();
        this.validateCountry();
        this.validateNotes();
        const { validName, validAddress, validCountry, validNotes, donationNumber } = this.state;
        if (validName && validAddress && validCountry && validNotes && donationNumber) {
            this.setState({ showConfirmation: true });
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false });
    }

    onYesClick = () => {
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });
        var Donation = {
            Id: parseInt(this.state.donationNumber),
            Name: this.state.name,
            Address: this.state.address,
            Country: this.state.country,
            Notes: this.state.notes,
        };

        axios.post(ApiUrls.addDonation, Donation, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                window.location.href = "/make-small-donation/?donationNumber=" + this.state.donationNumber;
            }
            else {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false,
                    isResponse: true, theme: "danger", response: response.data.messageText,
                });
            }

        }).catch((error) => {
            this.showError(error);            
        });
    }
    //-------------------------------

    showError = (error) => {
        console.log(error)
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, theme: "danger", response: "Something went wrong",
                isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
            });
        }
    }

}