import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Container, Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, FormInput, Alert
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class AddItemDonation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            donationNumber: "",

            selectedItem: [],
            items: [],
            isItemsLoading: false,
            itemQuery: "",
            responseSelectedItem: "",
            validSelectedItem: false,

            quantity: "",
            responseQuantity: "",
            validQuantity: false,

            showConfirm: false,
            isFinishPalletBtnDisabled: true,
            buttonText: "Confirm",
            isProcessing: false,
        };
        this.Item = React.createRef();
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const donationNumber = urlParams.get('donationNumber')
        this.setState({ donationNumber: donationNumber });
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };


        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/send-small-donation">Small Donations​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href={"/make-small-donation/?donationNumber=" + this.state.donationNumber}>Make a Small Donation​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Item Not In Stock​</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>MAKE A SMALL DONATION - ITEM NOT IN STOCK</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>


                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#Item">Select an item not in the stock record :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedItem} onChange={this.setItem} options={this.state.items} isLoading={this.state.isItemsLoading}
                                            id="Id"
                                            labelKey="Description"
                                            minLength={3}
                                            onInputChange={this.handleItemInputChange}
                                            onSearch={this.handleItemSearch}
                                            ref={this.Item}
                                            placeholder="Search for item not in the stock record"
                                            renderMenuItemChildren={option => (
                                                <div key={option.Id}>
                                                    <span>{option.Description}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        <span className="error-txt">{this.state.responseSelectedItem}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#Quantity">Quantity :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#Quantity" value={this.state.quantity} onChange={this.setQuantity} min="1" />
                                        <span className="error-txt">{this.state.responseQuantity}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" type="submit" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirm && (
                    <Modal open={this.state.showConfirm} toggle={() => this.setState({ showConfirm: !this.state.showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>MAKE A SMALL DONATION - ITEM NOT IN STOCK - CONFIRMATION</h2></Col>
                                </Row>
                                <hr />
                                <Row>

                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to add {this.state.quantity} of {this.state.selectedItem[0].Description} to small donation(Donation number : {this.state.donationNumber})? </label>
                                    </Col>

                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" onClick={this.confirmClick} disabled={this.state.isProcessing ? true : false}>{this.state.isProcessing ? "Loading..." : "Yes"}</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ showConfirm: false })}>No</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )}

            </>
        );
    }

    //-----------------------------------------------

    handleItemInputChange = itemQuery => {
        this.setState({ itemQuery, isResponse: false });
    };

    handleItemSearch = itemQuery => {
        if (this._cache[itemQuery]) {
            this.setState({ bottleMedicines: this._cache[itemQuery].options });
            return;
        }

        this.setState({ isItemsLoading: true });
        this.makeAndHandleRequestToGetItems(itemQuery).then(resp => {
            this._cache[itemQuery] = { ...resp, page: 1 };
            this.setState({
                isItemsLoading: false,
                items: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetItems(itemQuery, page = 1) {
        return axios.get(`${ApiUrls.getItemTypesNotRecordedInStock}?description=${itemQuery}`, authorizationHeader)
            .then(response => {
                var items = JSON.parse(response.data.data);
                var options = items.map(i => ({
                    Id: i.Id,
                    Description: i.Description,
                }));
                options = sortByAlphanumerically(options, 'Description');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    setItem = (value) => {
        this.setState({ isResponse: false })
        if (value !== null && value.length > 0) {
            this.setState({
                selectedItem: value, responseSelectedItem: "",
                validSelectedItem: true,
            })
        } else {
            this.setState({
                selectedItem: [], responseSelectedItem: "Please select an item not in the stock",
                validSelectedItem: true,
            })
        }
    };

    validateItem = () => {
        if (this.state.selectedItem.length === 0) {
            this.setState({ responseSelectedItem: "Please select an item not in the stock ", validSelectedItem: false });
        } else {
            this.setState({ responseSelectedItem: "", validSelectedItem: true });
        }
    };

    //---------------------------------------------------------------

    setQuantity = (value) => {
        this.setState({ quantity: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseQuantity: "Please enter correct quantity", validQuantity: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseQuantity: "Please enter number", validQuantity: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseQuantity: "Please enter positive number", validQuantity: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseQuantity: "Please enter full number", validQuantity: false });
        } else {
            this.setState({ responseQuantity: "", validQuantity: true });
        }
    }

    validateQuantity = () => {
        if (!(this.state.quantity)) {
            this.setState({ responseQuantity: "Please enter correct quantity", validQuantity: false });
        } else if (isNaN(this.state.quantity)) {
            this.setState({ responseQuantity: "Please enter number", validQuantity: false });
        } else if (parseFloat(this.state.quantity) <= 0) {
            this.setState({ responseQuantity: "Please enter positive number", validQuantity: false });
        } else if (Number.isInteger(parseFloat(this.state.quantity)) == false) {
            this.setState({ responseQuantity: "Please enter full number", validQuantity: false });
        } else {
            this.setState({ responseQuantity: "", validQuantity: true });
        }
    }

    //----------------------------------------------------

    onConfirmClick = (value) => {
        this.validateItem();
        this.validateQuantity();
        if (this.state.validSelectedItem && this.state.validQuantity) {
            this.setState({ showConfirm: true })
        }      
    }

    confirmClick = (value) => {
        this.setState({ buttonText: "Loading...", isFinishPalletBtnDisabled: true, isProcessing: true })
        var DonationItemNotInStock = {
            DonationId: parseInt(this.state.donationNumber),
            ItemId: parseInt(this.state.selectedItem[0].Id),
            Quantity: parseInt(this.state.quantity),
        }

        axios.post(ApiUrls.addItemNotInStockDonation, DonationItemNotInStock, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.Item.current.clear();
                this._cache = {};
                this.setState({
                    isResponse: true, theme: "success", response: response.data.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                    selectedItem: [], items: [], isItemsLoading: false,
                    itemQuery: "", responseSelectedItem: "", validSelectedItem: false,
                    quantity: "", responseQuantity: "", validQuantity: false, isProcessing:false
                })
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: response.data.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false, isProcessing: false
                })
            }
        }).catch((error) => {
            this.showError(error);           
        });

    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, theme: "danger", response: "Something went wrong",
                buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false, isProcessing: false
            })
        }
    }

}