import React from "react";
import { Container } from "shards-react";
import { withCookies } from 'react-cookie';
import axios from "axios";
import { ApiUrls } from "../environments/apiUrls";

class Footer extends React.Component {

    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            versionNumber:"",
        };
    }

    componentDidMount() {
        this.Version();
    }

    render() {
        const footer = {
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "2.5rem",
            paddingTop: "2%",
        };

        return (

            <Container>
                <footer style={footer}>
                    <center>
                        <nav className="navbar navbar-expand-sm  navbar-dark d-flex justify-content-center" style={{ backgroundColor: "#868e96bf" }}>
                            <a style={{ color: "white", fontSize: "12px" }}>COPYRIGHT &copy; JACOBS WELL 2021 VERSION {this.state.versionNumber} </a>
                        </nav>
                    </center>
                </footer>
            </Container>

        );
    }

    Version = () => {
        axios.get(ApiUrls.version).then((response) => {
            if (response.data.isSuccess) {
                var versionNumber = JSON.parse(response.data.data);
                this.setState({ versionNumber: versionNumber.Version });
            }
        })
    };

}
export default withCookies(Footer);