export const ApiUrls = {
  //variable naming must follow camelCase
  processLogin: "/api/user/login",
  passwordReset: "/api/user/resetpassword",
  updatePassword: "/api/user/updatepassword",
  getModules: "/api/modules",
  inviteUser: "/api/user/invite",
  removeUser: "/api/user/remove",
  acceptUserInvitation: "/api/user/newuser",
  changePassword: "/api/user/changepassword",
  getPalletCategories: "/api/pallet/categories",
  getPalletNumber: "/api/pallet/number",
  createPallet: "/api/pallet/create",
  getBoxContents: "/api/boxcontents",
  getPallets: "/api/pallets",
  putBoxOnPallet: "/api/box/putboxonpallet",
  getAllActiveUsers: "/api/user/activeusers",
  updateUserStatusToDeactivateUser: "/api/user/remove",
  getUserInfoByUserID: "/api/user",
  updateUserModules: "/api/user/updatemodules",
  finishPallet: "/api/pallet/finish",
  viewPalletContents: "/api/pallets/viewcontents",
  removeBoxesFromPallet: "/api/box/removeboxesfrompallet",
  getRelevantPalletCategories: "/api/pallet/getrelevantpalletcategories",
  getStorageLocations: "/api/location/locations",
  getCurrentPalletLocation: "/api/location/getcurrentpalletlocation",
  updatePalletLocation: "/api/pallet/updatepalletlocation",
  getNonPalletItems: "/api/nonpalletitem/nonpalletitems",
  addNonPalletItemToStock: "/api/nonpalletitem/addstock",
  version: "/api/version",
  getSecondaryLocListByPrimaryLocId:
    "/api/secondaryloc/getsecondaryloclistbyprimarylocid",
  getNonPalletItemsInStockByLocId:
    "/api/nonpalletitem/getnonpalletitemsinstockbylocid",
  updateNonPalletItemToStock: "/api/nonpalletitem/updatenonpalletitemtostock",
  getNonPalletItemsInStockGroupByLocId:
    "/api/nonpalletitem/itemsinstockbylocation",
  getPalletsInStockGroupByLocId: "/api/pallet/getpalletsinstockgroupbylocid",
  deleteNonPalletItemInStock: "api/nonpalletitem/deletestock",
  deletePalletInStock: "api/pallet/deletepallet",
  getFeedsAndFoodItems: "api/feedsandfooditem/feedsandfooditems",
  addFeedsAndFoodItemsToStock: "api/feedsandfooditem/addstock",
  getBottleMedicines: "api/bottlemedicines/BottleMedicine",
  addBottleRoomItemsToStock: "api/bottlemedicines/addstock",
  viewBottleRoomItemsInStock: "api/bottlemedicines/viewcontents",
  addBottleRoomStockOnPallet: "api/bottlemedicines/addstockonpallet",
  removeBottleRoomStockOnPallet: "api/bottlemedicines/removestockonpallet",
  getNextDonationNumber: "api/donation/number",
  addDonation: "api/donation/adddonation",
  addPalletDonation: "api/donation/addpallet",
  addNonPalletDonation: "api/donation/addnonpallet",
  getItemTypesNotRecordedInStock: "api/donation/getitemtypesnotrecordedinstock",
  addItemNotInStockDonation: "api/donation/additemnotinstock",
  getpalletsDonation: "api/donation/getpallets",
  getNonPalletsDonation: "api/donation/getnonpallets",
  getItemNotInStocksDonation: "api/donation/getitemnotinstocks",
  FinishDonation: "api/donation/finishdonation",
  DonationInfo: "api/donation/donationinfo",
  getDonationNotFinish: "api/donation/getdonationnotfinish",
  addStorageLocation: "api/location/addstoragelocation",
  updateStorageLocation: "api/location/updatestoragelocation",
  GeStorageLocationIsEditable: "api/location/locationsiseditable",
  getAllLocationsAsync: "api/location/alllocations",
  listStock: "/api/pallet/liststock",
  viewDeletedItemsAndPallets: "api/pallet/viewdeleteditemsandpallets",
  drugRecall: "api/pallet/drugrecall",
  getFoodList: "api/pallet/foodlist",
  shipContainer: "api/container/ship",
  getAllocatedPalletsInStockByContainer:
    "api/pallet/getallocatedpalletsinstockbycontainer",
  searchForMedicalItem: "api/pallet/searchformedicalitem",
  getOnlyMedicalPalletNumber: "api/pallet/getonlymedicalpalletnumber",
  moveItemTonewPallet: "api/pallet/moveitemtonewpallet",
  removeContainerAllocate: "api/pallet/removepalletcontainerallocate",
  getAllocatedNonPalletsByContainer:
    "api/nonpalletitem/getallocatednonpalletsbycontainer",
  removeNonPalletContainerAllocate:
    "api/nonpalletitem/removenonpalletcontainerallocate",
  viewPalletListAllocations: "api/pallet/viewpalletlistallocations",
  addPalletListAllocations: "api/pallet/addpalletlistallocations",
  viewNonPalletListAllocations:
    "api/nonpalletitem/viewnonpalletlistallocations",
  addNonPalletListAllocations: "api/nonpalletitem/addnonpalletlistallocations",
  getContainersNotShipped: "api/location/getcontainersnotshipped",
  listShippedContainers: "api/container/listshippedcontainers",
  updateWholesaleValue: "api/container/updatewholesalevalue",
  getTotalAndWholesaleValueByContainer:
    "api/container/total-and-wholesale-value",
  exportlist: "api/container/exportlist",
  getContainerAdjustPrices: "api/container/getcontaineradjustprices",
  getEditablePallets: "api/pallet/geteditablepallets",
  listShippedContainersAndSmallDonations:
    "api/container/listshippedcontainersandsmalldonations",
  updatePriorityPallets: "api/pallet/updatepriority",
  updatePriorityNonPallets: "api/nonpalletitem/updatepriority",
  getUnfinishedPalletsAsync: "api/pallet/getunfinishedpallets",
  undeletePallet: "api/pallet/undelete",
  getAllItems: "api/pallet/getallitems",
  searchForAnyItem: "api/pallet/searchforanyitem",
  getOnlyRelatedPalletNumbers: "api/pallet/getonlyrelatedpalletnumber",
  updatePalletWeight: "api/pallet/weight",
  getPalletsForSmallDonation: "api/pallet/small-donation",
  viewPalletsContents: "/api/pallets/contents",
  getAllPalletCategories: "api/maintenance/pallet-categories",
  getAllFeedsAndFoodItems: "api/maintenance/all-feeds-and-food-items",
  getAllBottleMedicineItems: "api/maintenance/all-bottle-medicine-items",
  getAllMedicalDisposableItems: "api/maintenance/all-medical-disposable-items",
  getAllNonPalletItems: "api/maintenance/all-non-pallet-items",
  removePalletCategoryItem: "api/maintenance/remove-pallet-category-item",
  removeFeedsAndFoodItem: "api/maintenance/remove-feeds-and-food-item",
  removeBottleMedicineItem: "api/maintenance/remove-bottle-medicine-item",
  removeMedicalDisposableItem: "api/maintenance/remove-medical-disposable-item",
  removenonPalletItem: "api/maintenance/remove-non-pallet-item",
  addNewPalletCategoryItem: "api/maintenance/new-pallet-category-item",
  addNewFeedsAndFoodItem: "api/maintenance/new-feeds-and-food-item",
  addNewBottleMedicineItem: "api/maintenance/new-bottle-medicine-item",
  addNewMedicalDisposableItem: "api/maintenance/new-medical-disposable-item",
  addNewNonPalletItem: "api/maintenance/new-non-pallet-item",
  updatePalletCategoryItem: "api/maintenance/pallet-category-item",
  updateFeedsAndFoodItem: "api/maintenance/feeds-and-food-item",
  updateBottleMedicineItem: "api/maintenance/bottle-medicine-item",
  updateMedicalDisposableItem: "api/maintenance/medical-disposable-item",
  updateNonPalletItem: "api/maintenance/non-pallet-item",
  getFeatureTypes: "api/maintenance/feature-types",
  getProductCategories: "api/maintenance/product-categories",
  getShippedContainers: "api/container/listallshippedcontainers",
  unshipContainer: "api/container/unship",
};
