import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router";
import { Layout } from "./components/Layout";
import Home from "./components/Home";
import "./custom.css";
import { withCookies, Cookies } from "react-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Styles/shards-dashboards.1.1.0.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { instanceOf } from "prop-types";
//user management
import Login from "./components/User/Login";
import { ResetPassword } from "./components/User/ResetPassword";
import { UpdatePassword } from "./components/User/UpdatePassword";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { InviteUser } from "./components/Admin/InviteUser";
import { AcceptUserInvitation } from "./components/User/AcceptUserInvitation";
import { ChangePassword } from "./components/User/ChangePassword";
import { ViewUsers } from "./components/Admin/ViewUsers";
import { EditUser } from "./components/Admin/EditUser";
import { CreateNewContainer } from "./components/Admin/CreateNewContainer";
import { RenameContainer } from "./components/Admin/RenameContainer";
import { ListStock } from "./components/Admin/ListStock";
import { ViewDeletedItemsAndPallets } from "./components/Admin/viewDeletedItemsAndPallets";
import { DrugRecall } from "./components/Admin/DrugRecall";
import { FoodList } from "./components/Admin/FoodList";
import { ShipContainer } from "./components/Admin/ShipContainer";
import { ListAllocations } from "./components/Admin/ListAllocations";
import { SearchForAnyItem } from "./components/Admin/SearchForAnyItem";
import { AllocateStock } from "./components/Admin/AllocateStock";
import { ListShippedContainersAndSmallDonations } from "./components/Admin/ListShippedContainersAndSmallDonations";
import { AdjustPrices } from "./components/Admin/AdjustPrices";
import { Exportlist } from "./components/Admin/Exportlist";
import { Maintainenace } from "./components/Admin/Maintenance";
import { UnshipContainer } from "./components/Admin/UnshipContainer";
//Medical Disposables Interface
import { MedicalDisposable } from "./components/MedicalDisposablesInterface/MedicalDisposables";
import { NewPalletMedical } from "./components/MedicalDisposablesInterface/NewPalletMedical";
import { PutBoxOnPallet } from "./components/MedicalDisposablesInterface/PutBoxOnPallet";
//warehouse interface
import { Warehouse } from "./components/WarehouseInterface/Warehouse";
import { NewPalletWarehouse } from "./components/WarehouseInterface/NewPalletWarehouse";
import { MovePallet } from "./components/WarehouseInterface/MovePallet";
import { MovePendingPallet } from "./components/WarehouseInterface/MovePendingPallet";
import { SearchForPallet } from "./components/WarehouseInterface/SearchForPallet";
import { PalletContents } from "./components/WarehouseInterface/PalletContents";
import { StoreNonPalletItem } from "./components/WarehouseInterface/StoreNonPalletItem";
import { RelocateNonPalletItem } from "./components/WarehouseInterface/RelocateNonPalletItem";
import { ReportOnStorageLocations } from "./components/WarehouseInterface/ReportOnStorageLocations";
import { DeletePalletOrItem } from "./components/WarehouseInterface/DeletePalletOrItem";
//Food Interface
import { Food } from "./components/FoodInterface/Food";
import { PutFoodOnPallet } from "./components/FoodInterface/PutFoodOnPallet";
//Bottle room interface
import { BottleRoom } from "./components/BottleRoom/BottleRoom";
import { StockIn } from "./components/BottleRoom/StockIn";
import { PutStockOnBottleRoom } from "./components/BottleRoom/PutStockOnBottleRoom";
import { ReportOnCurrentStock } from "./components/BottleRoom/ReportOnCurrentStock";
//Donation
import { SendSmallDonation } from "./components/donation/SendSmallDonation";
import { MakeSmallDonation } from "./components/donation/MakeSmallDonation";
import { AddPalletDonation } from "./components/donation/AddPalletDonation";
import { AddNonPalletDonation } from "./components/donation/AddNonPalletDonation";
import { AddItemDonation } from "./components/donation/AddItemDonation";
import { CurrentItemsDonation } from "./components/donation/CurrentItemsDonation";
//common
import { EditPallet } from "./components/common/EditPallet";
import { RemoveBoxes } from "./components/common/RemoveBoxes";
import { AddBoxesToPallet } from "./components/common/AddBoxesToPallet";
import { StartNewPallet } from "./components/common/StartNewPallet";
import { FinishPallet } from "./components/common/FinishPallet";

class App extends Component {
  static displayName = App.name;

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/update-password" component={UpdatePassword} />
        <Route
          exact
          path="/accept-user-invitation"
          component={AcceptUserInvitation}
        />
        {localStorage.getItem("Token") ? (
          <Layout>
            <Route exact path="/home" component={Home} layout={Layout} />
            <Route
              exact
              path="/dashboard"
              component={Dashboard}
              layout={Layout}
            />
            {/*<Route path='/Dashboard' component={Dashboard} layout={Layout} />*/}
            <Route
              exact
              path="/invite-user"
              component={InviteUser}
              layout={Layout}
            />
            <Route
              exact
              path="/view-users"
              component={ViewUsers}
              layout={Layout}
            />
            <Route
              exact
              path="/edit-user"
              component={EditUser}
              layout={Layout}
            />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route
              exact
              path="/create-new-container"
              component={CreateNewContainer}
            />
            <Route
              exact
              path="/rename-a-container"
              component={RenameContainer}
            />
            <Route exact path="/list-stock" component={ListStock} />
            <Route
              exact
              path="/view-deleted-items-and-pallets"
              component={ViewDeletedItemsAndPallets}
            />
            <Route exact path="/drug-recall" component={DrugRecall} />
            <Route exact path="/food-list" component={FoodList} />
            <Route exact path="/ship-a-container" component={ShipContainer} />
            <Route exact path="/list-allocations" component={ListAllocations} />
            <Route
              exact
              path="/search-for-any-item"
              component={SearchForAnyItem}
            />
            <Route exact path="/allocate-stock" component={AllocateStock} />
            <Route
              exact
              path="/list-shipped-containers-and-small-donations"
              component={ListShippedContainersAndSmallDonations}
            />
            <Route exact path="/adjust-prices" component={AdjustPrices} />
            <Route exact path="/export-list" component={Exportlist} />
            <Route exact path="/maintenance" component={Maintainenace} />
            <Route
              exact
              path="/unship-a-container"
              component={UnshipContainer}
            />
            {/*medical disposables interface*/}
            <Route
              exact
              path="/medical-disposables"
              component={MedicalDisposable}
            />
            <Route
              exact
              path="/new-pallet-medical"
              component={NewPalletMedical}
            />
            <Route exact path="/put-box-on-pallet" component={PutBoxOnPallet} />
            {/*warehouse interface*/}
            <Route exact path="/warehouse" component={Warehouse} />
            <Route
              exact
              path="/new-pallet-warehouse"
              component={NewPalletWarehouse}
            />
            <Route exact path="/move-pallet" component={MovePallet} />
            <Route
              exact
              path="/move-pending-pallet"
              component={MovePendingPallet}
            />
            <Route
              exact
              path="/search-for-pallet"
              component={SearchForPallet}
            />
            <Route exact path="/pallet-contents" component={PalletContents} />
            <Route
              exact
              path="/store-non-pallet-item"
              component={StoreNonPalletItem}
            />
            <Route
              exact
              path="/relocate-non-pallet-item"
              component={RelocateNonPalletItem}
            />
            <Route
              exact
              path="/report-on-storage-locations"
              component={ReportOnStorageLocations}
            />
            <Route
              exact
              path="/delete-pallet-or-item"
              component={DeletePalletOrItem}
            />
            {/*Food interface*/}
            <Route exact path="/food" component={Food} />
            <Route
              exact
              path="/put-food-on-pallet"
              component={PutFoodOnPallet}
            />
            {/*Bottle room  interface*/}
            <Route exact path="/bottle-room" component={BottleRoom} />
            <Route exact path="/stock-in" component={StockIn} />
            <Route
              exact
              path="/put-stock-on-bottle-room"
              component={PutStockOnBottleRoom}
            />
            <Route
              exact
              path="/report-on-current-stock"
              component={ReportOnCurrentStock}
            />
            {/*Donation*/}
            <Route
              exact
              path="/send-small-donation"
              component={SendSmallDonation}
            />
            <Route
              exact
              path="/make-small-donation"
              component={MakeSmallDonation}
            />
            <Route
              exact
              path="/add-pallet-donation"
              component={AddPalletDonation}
            />
            <Route
              exact
              path="/add-non-pallet-donation"
              component={AddNonPalletDonation}
            />
            <Route
              exact
              path="/add-item-donation"
              component={AddItemDonation}
            />
            <Route
              exact
              path="/current-items-donation"
              component={CurrentItemsDonation}
            />
            {/*common */}
            <Route
              exact
              path="/add-boxes-to-pallet"
              component={AddBoxesToPallet}
            />
            <Route exact path="/edit-pallet" component={EditPallet} />
            <Route exact path="/remove-boxes" component={RemoveBoxes} />
            <Route exact path="/start-new-pallet" component={StartNewPallet} />
            <Route exact path="/finish-pallet" component={FinishPallet} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
    );
  }
}
export default withCookies(App);
