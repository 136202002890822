import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class RenameContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isYesBtnDisabled: false,
            isValidPrimaryLocation: false,
            isValidContainerName: false,
            isConfirmationShowing: false,
            theme: "",
            response: "",
            containerName: "",
            responsePrimaryLocation: "",
            responseForContainerName: "",
            primaryLocations: [],
            selectedPrimaryLocation: [],
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>]
        };
        this.primaryLocation = React.createRef();
    }

    componentDidMount() {
        this.getContainersNotShipped();
    }

    render() {

        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response, selectedPrimaryLocation,
            primaryLocations, responsePrimaryLocation, containerName,
            responseForContainerName, isConfirmationShowing, isYesBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Rename a Container</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>RE-NAME A CONTAINER</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#PrimaryLocation">Which shipping container do you want to re-name :<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" selected={selectedPrimaryLocation}
                                            id="id"
                                            labelKey="description"
                                            onChange={this.setPrimaryLocation}
                                            options={primaryLocations}
                                            placeholder=""
                                            ref={this.primaryLocation}
                                            emptyLabel={this.state.emptyLabel}
                                        />
                                        <span className="error-txt">{responsePrimaryLocation}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#containerName">Type in the new name that you want to give to the container :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="text" id="#containerName" value={containerName} onChange={this.setContainerName} />
                                        <span className="error-txt">{responseForContainerName}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" type="submit" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmationShowing && (
                    <Modal open={isConfirmationShowing} toggle={() => this.setState({ isConfirmationShowing: isConfirmationShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>RE-NAME A CONTAINER - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to change the name of {selectedPrimaryLocation[0].description} to {containerName} ?</label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleYesBtnClick} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.handleNoBtnClick} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }
    
    getContainersNotShipped = () => {
        axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result}) => {
            if (result.isSuccess) {
                let primaryLocations = JSON.parse(result.data);
                let sortedPrimaryLocations = sortByAlphanumerically(primaryLocations, 'description');
                this.setState({ primaryLocations: sortedPrimaryLocations, selectedPrimaryLocation: [], emptyLabel: "No matches found" });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    setContainerName = ({ target }) => {
        this.setState({ containerName: target.value, isResponse: false });
        if (!target.value)
            this.setState({ responseForContainerName: "Please enter container name", isValidContainerName: false });
        else if (target.value.length >= 100)
            this.setState({ responseForContainerName: " Maximum length is 100 characters", isValidContainerName: false });
        else
            this.setState({ responseForContainerName: "", isValidContainerName: true });
    }

    validateContainerName = () => {
        this.setState({ isResponse: false });
        const { containerName } = this.state;
        if (!containerName) {
            this.setState({ responseForContainerName: "Please enter container name", isValidContainerName: false });
        } else if (containerName.length >= 100) {
            this.setState({ responseForContainerName: " Maximum length is 100 characters", isValidContainerName: false });
        }else {
            this.setState({ responseForContainerName: "", isValidContainerName: true });
        }
    }

    setPrimaryLocation = (value) => {
        (value !== null && value.length > 0)
            ? this.setState({ responsePrimaryLocation: "", selectedPrimaryLocation: value, isValidPrimaryLocation: true })
            : this.setState({ responsePrimaryLocation: "Please select a container", selectedPrimaryLocation: [], secondaryLocations: [], isValidPrimaryLocation: false });
    };

    validatePrimaryLocation = () => {
        if (this.state.selectedPrimaryLocation !== null && this.state.selectedPrimaryLocation.length > 0) {
            this.setState({ responsePrimaryLocation: "" });
        } else {
            this.setState({ responsePrimaryLocation: "Please select a container" });
        }
    };

    onConfirmClick = (value) => {
        value.preventDefault();
        this.validateContainerName();
        this.validatePrimaryLocation();
        const { isValidContainerName, isValidPrimaryLocation, containerName } = this.state;
        if (isValidContainerName && isValidPrimaryLocation)  {
            let name = containerName.trim();
            this.setState({ isConfirmationShowing: true, containerName: name });
        }
    }

    handleNoBtnClick = () => {
        this.setState({ isConfirmationShowing: false })
    }

    handleYesBtnClick = () => {
        this.setState({ isYesBtnDisabled: true });
        let storageLocation = {
            LocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
            Description: this.state.containerName,
        }
        axios.post(ApiUrls.updateStorageLocation, storageLocation, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isYesBtnDisabled: false, isConfirmationShowing: false, response: result.messageText,
                    isResponse: true, theme: "success", containerName: "", responseForContainerName: "", isValidContainerName: false,
                    selectedPrimaryLocation: [], responsePrimaryLocation: "", isValidPrimaryLocation: false,
                });
                this.primaryLocation.current.clear();
                this.getContainersNotShipped();
            }
            else {
                this.setState({
                    isYesBtnDisabled: false, isConfirmationShowing: false, response: result.messageText,
                    isResponse: true, theme: "danger", containerName: "", responseForContainerName: "", isValidContainerName: false,
                });
            }
        }).catch((error) => {
            this.showError(error); 
        });
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger",
                isYesBtnDisabled: false, isConfirmationShowing: false,
            });
        }
    }
}