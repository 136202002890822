import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Form, FormInput, FormGroup, FormCheckbox,
    Alert
} from "shards-react";
import axios from "axios";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { ApiUrls } from "../../environments/apiUrls";
import { Constants } from "../../environments/constants";

class Login extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            username: "",
            password: "",
            isLoginBtnDisabled: true,
            isErrorInLogin: false,
            errorMessage: "",
            buttonText: "Login",
            isRemembered: false
        };
    }
    render() {
        return (
            <Card style={{ maxWidth: "25rem", marginTop: "6%", marginLeft: "auto", marginRight: "auto" }}>
                <CardHeader style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }}>Jacob's Well Stock Control System<br />Login</CardHeader>
                <CardBody>
                    <Form>
                        {this.state.isErrorInLogin && (
                            <FormGroup>
                                <Alert theme="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <label htmlFor="#username">Username</label>
                            <FormInput id="#username" value={this.state.username} placeholder="Username" required onChange={this.onUsernamechanged} />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="#password">Password</label>
                            <FormInput type="password" value={this.state.password} id="#password" placeholder="Password" required onChange={this.onPasswordchanged} />
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                checked={this.state.isRemembered}
                                onChange={e => this.handleChange(e)}>
                                Remember me
                            </FormCheckbox>
                        </FormGroup>
                        <FormGroup>
                            <a href="/reset-password" className="btn btn-link btn-pill align-self-center mr-2">Forgot Password?</a>
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ width: "100%" }} disabled={this.state.isLoginBtnDisabled} onClick={this.onLoginBtnClick} type="submit">{this.state.buttonText}</Button>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        );
    }
    handleChange(e) {

        this.setState({ isRemembered: !this.state.isRemembered });
    }
    onUsernamechanged = (event) => {
        let username = event.target.value;
        if (this.state.password && username) {
            this.setState({ username: username, isLoginBtnDisabled: false, isErrorInLogin: false, errorMessage: "" });
        }
        else {
            this.setState({ username: username, isLoginBtnDisabled: true, isErrorInLogin: false, errorMessage: "" });
        }
    }
    onPasswordchanged = (event) => {
        let password = event.target.value.trim();
        if (this.state.username && password) {
            this.setState({ password: password, isLoginBtnDisabled: false, isErrorInLogin: false, errorMessage: "" });
        }
        else {
            this.setState({ password: password, isLoginBtnDisabled: true, isErrorInLogin: false, errorMessage: "" });
        }
    }
    onLoginBtnClick = (event) => {
        event.preventDefault();
        this.setState({ buttonText: "Loading...", isLoginBtnDisabled: true });
        var loginInfo = {
            Username: this.state.username,
            Password: this.state.password
        }
        axios.post(ApiUrls.processLogin, loginInfo).then((response) => {
            console.log(response);
            if (response.data.isSuccess) {
                var token = response.data.data;

                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                var decryptToken = JSON.parse(jsonPayload);
                var UserDetail = JSON.parse(decryptToken.UserDetail)
                var loggedUser = UserDetail.Username;

                window.localStorage.setItem("Token", response.data.data);
                const { cookies } = this.props;
                if (this.state.isRemembered) {

                    var currentDate = new Date();
                    currentDate.setMonth(currentDate.getMonth() + Constants.cookiesExpiredIn);
                    var expireddate = currentDate;
                    cookies.set('loggedUserInfo', loggedUser, { path: '/', expires: expireddate });
                    window.location.href = "/dashboard";
                }
                else {
                    cookies.remove("loggedUserInfo");
                    window.location.href = "/dashboard";
                }

            }
            else {
                this.setState({ isErrorInLogin: true, errorMessage: response.data.messageText, username: "", password: "", buttonText: "Login", isLoginBtnDisabled: false, isRemembered: false });
            }

        }).catch((error) => {
            this.setState({ isErrorInLogin: true, errorMessage: "Something went wrong", isRemembered: false });
            console.log(error.Message);
        });

    }
}
export default withCookies(Login);