import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, Form,
    Modal, ModalBody, FormInput, Alert, Container, FormRadio
} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class StartNewPallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPrintView: false,
            showConfirmation: false,
            isAdvancedSearch: false,
            isYesBtnDisabled: false,
            isResponse: false,
            isFoodPalletComplex: false,
            isFoodPalletRegular: true,
            selectedPalletCategory: [],
            palletCategories: [],
            bottleMedicinedPalletCategory: [],
            foodPalletCategory: [],
            secBreadCrumbItem: "",
            secBreadCrumbUrl: "",
            location: "",
            storageLocationId: "",
            secondaryStorageLocationId: "",
            urllocation: "",
            palletNumber: "",
            savePalletNumber: "",
            theme: "",
            response: "",
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],
            yesBtnText: "Yes",
        };
        this.Pallet = React.createRef();
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('location');
        this.setState({ urllocation: location });
        if (location == "food" || location == "bottle") {
            this.setPalletCategoryForFoodAndBottleLocations(location);
            return;
        }
        if (location == "medical") {
            this.setState({
                SecBreadCrumbItem: "Medical Disposable Interface", SecBreadCrumbUrl: "/medical-disposables",
                location: "Medical Disposables Room (MDR)", storageLocationId: "8",
                secondaryStorageLocationId: "0",
            });
            let productCategory = "MDISP"
            this.getPalletCategoriesByProductCategory(productCategory);
        }
    }

    render() {
        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const {
            SecBreadCrumbUrl,
            SecBreadCrumbItem,
            isResponse,
            theme,
            response,
            urllocation,
            selectedPalletCategory,
            palletCategories,
            emptyLabel,
            isFoodPalletRegular,
            isFoodPalletComplex,
            palletNumber,
            location,
            showConfirmation,
            yesBtnText,
            isPrintView,
            savePalletNumber,
        } = this.state;

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                <Col sm="12" md="10" lg="8" style={mystyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href={SecBreadCrumbUrl}>{SecBreadCrumbItem}</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Start New Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <h4>CREATE A NEW PALLET</h4>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        {isResponse && (
                                            <Alert theme={theme}>
                                                {response}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#palletType">Type of pallet:</label>
                                            {(urllocation == "medical") && (<>
                                                <Typeahead size="lg" selected={selectedPalletCategory}
                                                    id="palletcategory-typeahead"
                                                    labelKey="description"
                                                    onChange={this.setPalletCategory}
                                                    options={palletCategories}
                                                    placeholder=""
                                                    ref={this.Pallet}
                                                    emptyLabel={emptyLabel}
                                                />
                                                {(selectedPalletCategory === null) && (
                                                    <span className="error-txt">Please select a pallet category</span>
                                                )}
                                            </>)}
                                            {selectedPalletCategory !== null && selectedPalletCategory.length > 0 && (<>
                                                {(urllocation == "food") && (
                                                    <FormGroup>
                                                        <FormInput size="lg" id="#palletcategory" value={selectedPalletCategory[0].description} disabled={true} />
                                                        <div style={{ marginTop: "3%" }}>
                                                            <FormRadio
                                                                name="regular"
                                                                checked={isFoodPalletRegular}
                                                                onChange={() => {
                                                                    this.setFoodTypeToRegular();
                                                                }}
                                                            >
                                                                Regular
                                                        </FormRadio>
                                                            <FormRadio
                                                                name="complex"
                                                                checked={isFoodPalletComplex}
                                                                onChange={() => {
                                                                    this.setFoodTypeToComplex();
                                                                }}
                                                            >
                                                                Complex
                                                        </FormRadio>
                                                        </div>
                                                    </FormGroup>)}
                                                {(urllocation == "bottle") && (<>
                                                    <FormInput size="lg" id="#palletcategory" value={selectedPalletCategory[0].description} disabled={true} />
                                                </>)}
                                            </>)}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {selectedPalletCategory !== null && selectedPalletCategory.length > 0 && (
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#palletNumberLabel">Pallet number: </label>
                                                <FormInput size="lg" id="#palletNumber" value={palletNumber} disabled={true} />
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                )}
                                {selectedPalletCategory !== null && selectedPalletCategory.length > 0 && (
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#palletLocLabel">Location: </label>
                                                <FormInput size="lg" id="#palletLoc" value={location} disabled={true} />
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {showConfirmation && (
                    <Modal open={showConfirmation} toggle={() => this.setState({ showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>CREATE A NEW PALLET - CONFIRMATION</h2></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large" }}>{`Do you want to create Pallet Number ${palletNumber}?`}</label>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6" xs="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" disabled={this.isYesBtnDisabled} onClick={this.onYesClick}>{yesBtnText}</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6" xs="6">
                                        <FormGroup>
                                            <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick}>No</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )}
                {isPrintView && (
                    <Modal open={isPrintView} toggle={() => this.setState({ isPrintView: !isPrintView })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Form>
                                <Container className="dr-example-container" fluid={true}>
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <FormGroup>
                                                <label style={{ fontSize: "100px" }}>{savePalletNumber}</label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="6" lg="6" xs="6">
                                            <FormGroup>
                                                <Button theme="primary" size="lg" onClick={this.printBtnClick}>Print</Button>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" lg="6" xs="6">
                                            <FormGroup>
                                                <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick}>No</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    setFoodTypeToComplex = () => {
        this.setState({ isFoodPalletComplex: true, isFoodPalletRegular: false });
    };

    setFoodTypeToRegular = () => {
        this.setState({ isFoodPalletRegular: true, isFoodPalletComplex: false });
    };

    getPalletCategoriesByProductCategory = (productCategory) => {
        axios.get(`${ApiUrls.getRelevantPalletCategories}?productCategories=${productCategory}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var palletCategories = JSON.parse(response.data.data);
                console.log(palletCategories);
                this.setState({ palletCategories });
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setPalletCategoryForFoodAndBottleLocations = (location) => {
        axios.get(ApiUrls.getPalletCategories, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                let allPalletCategories = JSON.parse(response.data.data);
                console.log(allPalletCategories);
                if (location == "food") {
                    let palletDescription = "Food";
                    let selectedPalletCategory = allPalletCategories?.filter(x => x.description == palletDescription);
                    console.log(selectedPalletCategory);
                    this.setPalletCategory(selectedPalletCategory);
                    this.setState({
                        SecBreadCrumbItem: "Food Interface", SecBreadCrumbUrl: "/food",
                        location: "Food Container (Food)", storageLocationId: "9",
                        secondaryStorageLocationId: "0", foodPalletCategory: selectedPalletCategory
                    });
                    return;
                }
                if (location == "bottle") {
                    let palletDescription = "Bottled Medicine";
                    let selectedPalletCategory = allPalletCategories?.filter(x => x.description == palletDescription);
                    console.log(selectedPalletCategory);
                    this.setPalletCategory(selectedPalletCategory);
                    this.setState({
                        SecBreadCrumbItem: "Bottle Room Interface", SecBreadCrumbUrl: "/bottle-room",
                        location: "Bottle Room (Bot)", storageLocationId: "5",
                        secondaryStorageLocationId: "0", bottleMedicinedPalletCategory: selectedPalletCategory
                    });
                    return;
                }
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setPalletCategory = (value) => {
        console.log(value);
        if (value !== null && value.length > 0) {
            axios.get(`${ApiUrls.getPalletNumber}?id=${value[0].id}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var palletNumber = JSON.parse(response.data.data);
                    this.setState({ palletNumber: palletNumber, selectedPalletCategory: value });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
        else {
            this.setState({ selectedPalletCategory: [] });
        }
    };

    onConfirmClick = () => {
        const { selectedPalletCategory } = this.state;
        if (selectedPalletCategory.length > 0) {
            this.setState({ showConfirmation: true });
        }
        else {
            this.setState({ showConfirmation: false });
        }
    };

    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        const {
            palletNumber,
            storageLocationId,
            secondaryStorageLocationId,
            selectedPalletCategory,
            isFoodPalletComplex,
            urllocation,
            foodPalletCategory,
            bottleMedicinedPalletCategory
        } = this.state;
        let newPalletInfo = {
            CategoryId: selectedPalletCategory[0].category_id,
            PalletNumber: palletNumber,
            StorageLocationId: parseInt(storageLocationId),
            SecondaryStorageLocationId: parseInt(secondaryStorageLocationId),
            IsFoodPalletComplex: isFoodPalletComplex
        };
        console.log(newPalletInfo);
        axios.post(ApiUrls.createPallet, newPalletInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isPrintView: true, isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, isResponse: true, theme: "success", savePalletNumber: palletNumber,
                    response: "Now Display the pallet label above the pallet being created ",
                });
                if (urllocation == "food") {
                    this.setPalletCategory(foodPalletCategory);
                }
                else if (urllocation == "bottle") {
                    this.setPalletCategory(bottleMedicinedPalletCategory);
                }
                else {
                    this.setState({ selectedPalletCategory: [] });
                    this.Pallet.current.clear();
                }
            }
            else {
                this.setState({
                    isPrintView: false, isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false,
                    isResponse: true, theme: "danger", response: response.data.messageText,
                });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false, isPrintView: false });
        const { urllocation } = this.state;
        if (urllocation == "bottle") {
            window.location.href = "/bottle-room";
        }
    }

    printBtnClick = () => {
        var a = window.open('', '', 'height=500, width=500');
        const { urllocation } = this.state;
        a.document.write('<html>');
        a.document.write('<body style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0;"> <h1 style="font-size: 25vw; display: inline; text-align: center; width: 100%;"> ' + this.state.savePalletNumber + '</h1><br>');
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        this.setState({ isPrintView: false });
        if (urllocation == "bottle") {
            window.location.href = "/bottle-room";
        }
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isPrintView: false, isResponse: true, theme: "danger", response: "Something went wrong",
                isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
            });
        }
    }
}