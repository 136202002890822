import axios from "axios";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Alert,
  Breadcrumb,
  Row,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  FormSelect,
} from "shards-react";
import { Typeahead } from "react-bootstrap-typeahead";
import { ApiUrls } from "../../environments/apiUrls";
import { TableNames, PrimaryKeysOfTables } from "../../helpers/tableHelper";

const authorizationHeader = {
  headers: { Authorization: "Bearer " + localStorage.getItem("Token") },
};

export class Maintainenace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: false,
      isResponse: false,
      isSearchBtnPressed: false,
      isValidSelectedTable: false,
      isConfirmationShowing: false,
      isProcessing: false,
      isNewItemAddBtnClicked: false,
      isEditBtnClicked: false,
      theme: "",
      response: "",
      primaryKey: "",
      urlToGetItems: "",
      errorResponseForTableSelection: "",
      columnNameWithDataType: {},
      items: [],
      tables: [],
      selectedTable: [],
      columnsInTable: [],
      requiredFieldsForNewItemAddition: [],
      requiredFieldsForItemUpdate: [],
      requiredColumns: [],
      itemIdToDelete: 0,
      itemIdToEdit: 0,
      emptyLabel: [
        <>
          <i class="fa fa-spinner fa-spin"> </i> Loading
        </>,
      ],
      featureTypes: ([] = []),
      productCategories: ([] = []),
    };
  }

  componentDidMount() {
    this.getRelevantTablesList();
  }

  render() {
    const customStyle = {
      paddingRight: "0px",
      paddingLeft: "0px",
    };
    const { SearchBar } = Search;
    const {
      items,
      isResponse,
      theme,
      response,
      isDataLoading,
      isSearchBtnPressed,
      emptyLabel,
      tables,
      primaryKey,
      selectedTable,
      isValidSelectedTable,
      columnsInTable,
      isConfirmationShowing,
      isProcessing,
      errorResponseForTableSelection,
      isNewItemAddBtnClicked,
      requiredFieldsForNewItemAddition,
      isEditBtnClicked,
      requiredFieldsForItemUpdate,
      featureTypes,
      productCategories,
    } = this.state;

    const itemsInTable = ({ paginationTableProps }) => (
      <div>
        <ToolkitProvider
          keyField={primaryKey}
          columns={columnsInTable}
          data={items}
          search
        >
          {(toolkitprops) => (
            <div>
              <SearchBar {...toolkitprops.searchProps} />
              <BootstrapTable
                {...toolkitprops.baseProps}
                {...paginationTableProps}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );

    return (
      <>
        <Col sm="12" md="1" lg="2" style={customStyle}></Col>
        <Col sm="12" md="10" lg="8" style={customStyle}>
          <CardTitle style={{ marginTop: "2%" }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/dashboard">Home</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Maintenance</BreadcrumbItem>
            </Breadcrumb>
          </CardTitle>
          <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
            <CardBody>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <h4>ITEM LIST MAINTENANCE</h4>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  {isResponse && <Alert theme={theme}>{response}</Alert>}
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <FormGroup>
                    <label style={{ fontSize: "large" }} htmlFor="#table">
                      Select table for modification:
                    </label>
                    <Typeahead
                      size="lg"
                      selected={selectedTable}
                      id="table-typeahead"
                      labelKey="name"
                      onChange={this.setTable}
                      options={tables}
                      placeholder=""
                      ref={this.table}
                      emptyLabel={emptyLabel}
                    />
                    <span className="error-txt">
                      {errorResponseForTableSelection}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <FormGroup>
                    <Button
                      theme="primary"
                      size="lg"
                      disabled={!isValidSelectedTable}
                      onClick={this.handleSearch}
                    >
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  {isDataLoading && (
                    <label style={{ fontWeight: "bold" }}>Loading...</label>
                  )}
                  {!isDataLoading && (
                    <>
                      {isSearchBtnPressed && isValidSelectedTable && (
                        <>
                          <Row>
                            <Col sm="12" md="12" lg="12">
                              <h5>ITEMS IN TABLE</h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="12" md="10" lg="8">
                              <FormGroup>
                                <Button
                                  theme="primary"
                                  size="md"
                                  onClick={this.handleNewItemAddition}
                                >
                                  Add New Item
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          {items.length != 0 && (
                            <>
                              <PaginationProvider
                                pagination={paginationFactory()}
                              >
                                {itemsInTable}
                              </PaginationProvider>
                            </>
                          )}
                          {items.length == 0 && (
                            <>
                              <Alert theme="warning">No data found</Alert>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="1" lg="2" style={customStyle}></Col>
        {isConfirmationShowing && (
          <Modal
            open={isConfirmationShowing}
            toggle={() =>
              this.setState({ isConfirmationShowing: !isConfirmationShowing })
            }
            centered={true}
            className={"custom-modal"}
          >
            <ModalBody>
              <Row>
                <Col>
                  <h2>DELETE AN ITEM - CONFIRMATION</h2>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="12" md="12" lg="12">
                  <label
                    style={{
                      fontSize: "x-large",
                      textAlign: "justify",
                      textJustify: "inter-word",
                    }}
                  >
                    Do you want to delete this item from the table{" "}
                    {selectedTable[0].name}?
                  </label>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col>
                  <Button
                    theme="success"
                    size="lg"
                    onClick={this.handleYesBtn}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Loading..." : "Yes"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    theme="secondary"
                    size="lg"
                    onClick={() =>
                      this.setState({ isConfirmationShowing: false })
                    }
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
        <Col sm="12" md="1" lg="2" style={customStyle}></Col>
        {isNewItemAddBtnClicked && (
          <Modal
            open={isNewItemAddBtnClicked}
            toggle={() =>
              this.setState({ isNewItemAddBtnClicked: !isNewItemAddBtnClicked })
            }
            centered={true}
            className={"custom-modal"}
          >
            <ModalHeader>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <h2>CREATE A NEW ITEM</h2>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              {requiredFieldsForNewItemAddition.map((field) => {
                return (
                  <Row key={field.indexId}>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <label style={{ fontSize: "large" }}>
                          {field.name} :{" "}
                        </label>
                        {field.name === "feature_type_id" && (
                          <FormSelect
                            size="lg"
                            onChange={(event) =>
                              this.setValue(field, event, false)
                            }
                            defaultValue="--Select--"
                          >
                            <option value="">--Select--</option>
                            {featureTypes.map(
                              ({ id, description, selected }) => {
                                return (
                                  <option
                                    key={id}
                                    value={id}
                                    selected={selected}
                                  >
                                    {description}
                                  </option>
                                );
                              }
                            )}
                          </FormSelect>
                        )}
                        {field.name === "product_category" &&
                          this.state.selectedTable[0].name ===
                            "tbl_pallet_categories" && (
                            <FormSelect
                              size="lg"
                              onChange={(event) =>
                                this.setValue(field, event, false)
                              }
                            >
                              <option value="">--Select--</option>
                              {productCategories.map(
                                ({ id, name, code, selected }) => {
                                  return (
                                    <option
                                      key={id}
                                      value={code}
                                      selected={selected}
                                    >
                                      {name}
                                    </option>
                                  );
                                }
                              )}
                            </FormSelect>
                          )}
                        {field.name === "product_category" &&
                          this.state.selectedTable[0].name ===
                            "tbl_items_non_pallet" && (
                            <FormInput
                              size="lg"
                              value={field.input}
                              onChange={(event) =>
                                this.setValue(field, event, false)
                              }
                            />
                          )}
                        {field.name !== "feature_type_id" &&
                          field.name !== "product_category" && (
                            <FormInput
                              size="lg"
                              value={field.input}
                              onChange={(event) =>
                                this.setValue(field, event, false)
                              }
                            />
                          )}

                        {field.isError && (
                          <>
                            <span className="error-txt">
                              {field.errorMessage}
                            </span>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                );
              })}
              <br></br>
              <Row>
                <Col sm="6" md="6" lg="6">
                  <Button
                    theme="success"
                    size="lg"
                    block
                    onClick={this.handleCreateBtn}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Loading..." : "Create"}
                  </Button>
                </Col>
                <Col sm="6" md="6" lg="6">
                  <Button
                    style={{ float: "right" }}
                    block
                    theme="danger"
                    size="lg"
                    onClick={this.handleCancelBtn}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
        {isEditBtnClicked && (
          <Modal
            open={isEditBtnClicked}
            toggle={() =>
              this.setState({ isEditBtnClicked: !isEditBtnClicked })
            }
            centered={true}
            className={"custom-modal"}
          >
            <ModalHeader>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <h2>Edit Item</h2>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              {requiredFieldsForItemUpdate.map((field) => {
                return (
                  <Row key={field.indexId}>
                    <Col sm="12" md="12" lg="12">
                      <FormGroup>
                        <label style={{ fontSize: "large" }}>
                          {field.name} :{" "}
                        </label>
                        {field.name === "feature_type_id" && (
                          <FormSelect
                            size="lg"
                            onChange={(event) =>
                              this.setValue(field, event, true)
                            }
                          >
                            <option value="">--Select--</option>
                            {featureTypes.map(
                              ({ id, description, selected }) => {
                                return (
                                  <option
                                    key={id}
                                    value={id}
                                    selected={selected}
                                  >
                                    {description}
                                  </option>
                                );
                              }
                            )}
                          </FormSelect>
                        )}
                        {field.name === "product_category" &&
                          this.state.selectedTable[0].name ===
                            "tbl_pallet_categories" && (
                            <FormSelect
                              size="lg"
                              onChange={(event) =>
                                this.setValue(field, event, true)
                              }
                            >
                              <option value="">--Select--</option>
                              {productCategories.map(
                                ({ id, name, code, selected }) => {
                                  return (
                                    <option
                                      key={id}
                                      value={code}
                                      selected={selected}
                                    >
                                      {name}
                                    </option>
                                  );
                                }
                              )}
                            </FormSelect>
                          )}
                        {field.name === "product_category" &&
                          this.state.selectedTable[0].name ===
                            "tbl_items_non_pallet" && (
                            <FormInput
                              size="lg"
                              value={field.input}
                              onChange={(event) =>
                                this.setValue(field, event, true)
                              }
                            />
                          )}
                        {field.name !== "feature_type_id" &&
                          field.name !== "product_category" && (
                            <FormInput
                              size="lg"
                              value={field.input}
                              onChange={(event) =>
                                this.setValue(field, event, true)
                              }
                            />
                          )}

                        {field.isError && (
                          <>
                            <span className="error-txt">
                              {field.errorMessage}
                            </span>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                );
              })}
              <br></br>
              <Row>
                <Col sm="6" md="6" lg="6">
                  <Button
                    theme="success"
                    size="lg"
                    block
                    onClick={this.handleUpdateBtn}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Loading..." : "Update"}
                  </Button>
                </Col>
                <Col sm="6" md="6" lg="6">
                  <Button
                    style={{ float: "right" }}
                    block
                    theme="danger"
                    size="lg"
                    onClick={this.handleCancelBtnInEdit}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }

  getRelevantTablesList = () => {
    let tables = [];
    for (let key in TableNames) {
      tables.push({ id: TableNames[key], name: key });
    }
    console.log(tables);
    this.setState({ tables });
  };

  setTable = (table) => {
    console.log(table);
    if (table != null && table.length > 0) {
      let primaryKey = PrimaryKeysOfTables[table[0].name];
      if (table[0].name === "tbl_pallet_categories") {
        this.setState({
          selectedTable: table,
          isValidSelectedTable: false,
          primaryKey,
          errorResponseForTableSelection: "",
          isSearchBtnPressed: false,
        });
        axios
          .get(ApiUrls.getFeatureTypes, authorizationHeader)
          .then((responseForFeatureTypes) => {
            if (responseForFeatureTypes.data.isSuccess) {
              axios
                .get(ApiUrls.getProductCategories, authorizationHeader)
                .then((responseForProductCategories) => {
                  if (responseForProductCategories.data.isSuccess) {
                    this.setState({
                      featureTypes: JSON.parse(
                        responseForFeatureTypes.data.data
                      ),
                      productCategories: JSON.parse(
                        responseForProductCategories.data.data
                      ),
                      isValidSelectedTable: true,
                    });
                  }
                })
                .catch((error) => {
                  this.showError(error);
                });
            }
          })
          .catch((error) => {
            this.showError(error);
          });
      } else {
        this.setState({
          selectedTable: table,
          isValidSelectedTable: true,
          primaryKey,
          errorResponseForTableSelection: "",
          isSearchBtnPressed: false,
        });
      }
      console.log(primaryKey);
      return;
    } else {
      this.setState({
        selectedTable: [],
        isValidSelectedTable: false,
        errorResponseForTableSelection: "Select a table.",
        isSearchBtnPressed: false,
        items: [],
        isDataLoading: false,
      });
    }
  };

  handleSearch = () => {
    try {
      const { selectedTable, isValidSelectedTable } = this.state;
      if (isValidSelectedTable && selectedTable.length > 0) {
        this.setState({ isDataLoading: true });
        let urlToGetItems = this.getUrlToObtainItemsFromRelevantTable(
          selectedTable[0].id
        );
        axios
          .get(urlToGetItems, authorizationHeader)
          .then((response) => {
            if (response.data.isSuccess) {
              let items = JSON.parse(response.data.data);
              console.log(items);
              this.setDataTypes(items);
              this.setColumnsToTable(items);
              this.setFieldsForNewItemAddition(items);
              this.setFieldsForItemUpdate(items);
              this.setState({
                items,
                urlToGetItems,
                isDataLoading: false,
                isSearchBtnPressed: true,
              });
            }
          })
          .catch((error) => {
            this.showError(error);
          });
      }
    } catch (error) {
      this.showError(error);
    }
  };

  getUrlToObtainItemsFromRelevantTable = (tableId) => {
    let urls = {
      [TableNames.tbl_pallet_categories]: ApiUrls.getAllPalletCategories,
      [TableNames.tbl_items_bottle_medicines]:
        ApiUrls.getAllBottleMedicineItems,
      [TableNames.tbl_items_medical_disposable]:
        ApiUrls.getAllMedicalDisposableItems,
      [TableNames.tbl_items_feeds_and_food]: ApiUrls.getAllFeedsAndFoodItems,
      [TableNames.tbl_items_non_pallet]: ApiUrls.getAllNonPalletItems,
    };
    return urls[tableId];
  };

  getUrlToDeleteItemFromRelevantTable = (tableId) => {
    let urls = {
      [TableNames.tbl_pallet_categories]: ApiUrls.removePalletCategoryItem,
      [TableNames.tbl_items_bottle_medicines]: ApiUrls.removeBottleMedicineItem,
      [TableNames.tbl_items_medical_disposable]:
        ApiUrls.removeMedicalDisposableItem,
      [TableNames.tbl_items_feeds_and_food]: ApiUrls.removeFeedsAndFoodItem,
      [TableNames.tbl_items_non_pallet]: ApiUrls.removenonPalletItem,
    };
    return urls[tableId];
  };

  getUrlToAddItemToRelevantTable = (tableId) => {
    let urls = {
      [TableNames.tbl_pallet_categories]: ApiUrls.addNewPalletCategoryItem,
      [TableNames.tbl_items_bottle_medicines]: ApiUrls.addNewBottleMedicineItem,
      [TableNames.tbl_items_medical_disposable]:
        ApiUrls.addNewMedicalDisposableItem,
      [TableNames.tbl_items_feeds_and_food]: ApiUrls.addNewFeedsAndFoodItem,
      [TableNames.tbl_items_non_pallet]: ApiUrls.addNewNonPalletItem,
    };
    return urls[tableId];
  };

  //Conatins JSON property name as key and property name in back-end model as value except primary key of that model.
  getModelNamesToColumnNamesExceptPrimaryKey = (tableId) => {
    let modelNames = {
      [TableNames.tbl_pallet_categories]: {
        id: "CategoryName",
        description: "Description",
        product_category: "ProductCategory",
        price_per_kg: "PricePerKg",
        feature_type_id: "FeatureTypeId",
      },
      [TableNames.tbl_items_bottle_medicines]: {
        description: "Description",
        medical_category: "MedicalCategory",
        cost_price: "CostPrice",
      },
      [TableNames.tbl_items_medical_disposable]: {
        description: "Description",
        cost_price: "CostPrice",
        c88_code: "C88Code",
      },
      [TableNames.tbl_items_feeds_and_food]: {
        description: "Description",
        unit_Price: "UnitPrice",
        c88_code: "C88Code",
        is_complex: "IsComplex",
      },
      [TableNames.tbl_items_non_pallet]: {
        description: "Description",
        price: "Price",
        product_category: "ProductCategory",
        c88_code: "C88Code",
      },
    };
    return modelNames[tableId];
  };

  setColumnsToTable = (tableData) => {
    let columnsInRelevantTable = [];
    if (tableData.length > 0) {
      const actionColumnsInTable = [
        {
          text: "",
          formatter: this.editItems,
          style: {
            fontWeight: "normal",
          },
        },
        {
          text: "",
          formatter: this.deleteItems,
          style: {
            fontWeight: "normal",
          },
        },
      ];
      let columns = Object.keys(tableData[0]);
      for (let column in columns) {
        let tableProps = {};
        tableProps["text"] = columns[column];
        tableProps["dataField"] = columns[column];
        tableProps["style"] = {
          fontWeight: "normal",
        };
        columnsInRelevantTable.push(tableProps);
      }
      let allColumnsInTable = [
        ...columnsInRelevantTable,
        ...actionColumnsInTable,
      ];
      console.log(allColumnsInTable);
      this.setState({ columnsInTable: allColumnsInTable });
    }
  };

  setFieldsForNewItemAddition = (tableData) => {
    const { primaryKey } = this.state;
    let allColumns = Object.keys(tableData[0]);
    if (allColumns != null && allColumns.length > 0) {
      let requiredColumns = allColumns.filter((column) => column != primaryKey);
      console.log(requiredColumns);
      let requiredFieldsForNewItemAddition = [];
      for (let column in requiredColumns) {
        let requiredFieldWithOptions = {};
        requiredFieldWithOptions["indexId"] = column;
        requiredFieldWithOptions["name"] = requiredColumns[column];
        requiredFieldWithOptions["input"] = "";
        requiredFieldWithOptions["errorMessage"] = "";
        requiredFieldWithOptions["isError"] = false;
        requiredFieldWithOptions["type"] = false;
        requiredFieldsForNewItemAddition.push(requiredFieldWithOptions);
      }
      console.log(requiredFieldsForNewItemAddition);
      this.setState({ requiredFieldsForNewItemAddition, requiredColumns });
    }
  };

  setDataTypes = (tableData) => {
    let columnNameWithDataType = { ...tableData[0] };
    Object.keys(columnNameWithDataType).map(function (key, index) {
      columnNameWithDataType[key] = typeof columnNameWithDataType[key];
    });
    console.log(columnNameWithDataType);
    this.setState({ columnNameWithDataType });
  };

  deleteItems = (cell, row) => {
    return (
      <Button
        theme="danger"
        onClick={(e) => this.handleItemDelete(e, row)}
        style={{ width: "100%", height: "100%" }}
      >
        Delete
      </Button>
    );
  };

  handleItemDelete = (e, row) => {
    console.log(row);
    const { primaryKey } = this.state;
    let itemIdToDelete = row[primaryKey];
    if (itemIdToDelete) {
      this.setState({ isConfirmationShowing: true, itemIdToDelete });
    }
  };

  handleYesBtn = () => {
    this.setState({ isProcessing: true });
    const { itemIdToDelete, urlToGetItems, selectedTable } = this.state;
    let removedItemInfo = {
      ItemId: itemIdToDelete,
    };
    let urlToRemoveItem = this.getUrlToDeleteItemFromRelevantTable(
      selectedTable[0].id
    );
    axios
      .post(urlToRemoveItem, removedItemInfo, authorizationHeader)
      .then(({ data: result }) => {
        if (result.isSuccess) {
          this.setState({
            isResponse: true,
            theme: "success",
            response: result.messageText,
            isDataLoading: true,
            items: [],
            isConfirmationShowing: false,
          });
          axios
            .get(urlToGetItems, authorizationHeader)
            .then(({ data: result }) => {
              let items = JSON.parse(result.data);
              this.setState({
                items,
                isDataLoading: false,
                isProcessing: false,
              });
            })
            .catch((error) => {
              this.showError(error);
            });
          setTimeout(() => this.setState({ isResponse: false }), 10000);
        } else {
          this.setState({
            isResponse: true,
            theme: "danger",
            response: result.Message,
            isConfirmationShowing: false,
            isProcessing: false,
          });
        }
      })
      .catch((error) => {
        this.showError(error);
      });
  };

  handleNewItemAddition = () => {
    const { requiredFieldsForNewItemAddition } = this.state;
    let fieldsWihtoutInputData = this.clearFields(
      requiredFieldsForNewItemAddition
    );
    this.setState({
      isNewItemAddBtnClicked: true,
      requiredFieldsForNewItemAddition: fieldsWihtoutInputData,
    });
  };

  setValue = (field, event, isEdit) => {
    if (isEdit) {
      const { requiredFieldsForItemUpdate } = this.state;
      if (
        requiredFieldsForItemUpdate != null &&
        requiredFieldsForItemUpdate.length > 0
      ) {
        const requiredFieldsWithData = requiredFieldsForItemUpdate.map(
          (item) => {
            if (item.indexId == field.indexId) {
              return { ...item, input: event.target.value };
            }
            return item;
          }
        );
        this.setState({ requiredFieldsForItemUpdate: requiredFieldsWithData });
      }
    } else {
      const { requiredFieldsForNewItemAddition } = this.state;
      if (
        requiredFieldsForNewItemAddition != null &&
        requiredFieldsForNewItemAddition.length > 0
      ) {
        const requiredFieldsWithData = requiredFieldsForNewItemAddition.map(
          (item) => {
            if (item.indexId == field.indexId) {
              return { ...item, input: event.target.value };
            }
            return item;
          }
        );
        this.setState({
          requiredFieldsForNewItemAddition: requiredFieldsWithData,
        });
      }
    }
  };

  handleCreateBtn = () => {
    this.setState({ isProcessing: true });
    const { requiredFieldsForNewItemAddition, selectedTable, urlToGetItems } =
      this.state;
    let inputFieldsToValidate = [...requiredFieldsForNewItemAddition];
    let inputFieldsAfterValidation = this.validateInputFields(
      inputFieldsToValidate
    );
    console.log(inputFieldsAfterValidation);
    const isNotValidated = inputFieldsAfterValidation.some(
      (field) => field.isError === true
    );
    if (isNotValidated) {
      this.setState({
        requiredFieldsForNewItemAddition: inputFieldsAfterValidation,
        isProcessing: false,
      });
      return;
    }
    let formData = this.createFormDataFromInputs();
    let modelNamesToColumnNames =
      this.getModelNamesToColumnNamesExceptPrimaryKey(selectedTable[0].id);
    console.log(modelNamesToColumnNames);
    let modelNamesWithData = this.renameKeys(formData, modelNamesToColumnNames);
    console.log(modelNamesWithData);
    let urlToAddItem = this.getUrlToAddItemToRelevantTable(selectedTable[0].id);
    axios
      .post(urlToAddItem, modelNamesWithData, authorizationHeader)
      .then(({ data: result }) => {
        let fieldsWihtoutInputData = this.clearFields(
          requiredFieldsForNewItemAddition
        );
        if (result.isSuccess) {
          this.setState({
            isResponse: true,
            theme: "success",
            response: result.messageText,
            isDataLoading: true,
            items: [],
            isNewItemAddBtnClicked: false,
            requiredFieldsForNewItemAddition: fieldsWihtoutInputData,
          });
          axios
            .get(urlToGetItems, authorizationHeader)
            .then(({ data: result }) => {
              let items = JSON.parse(result.data);
              this.setState({
                items,
                isDataLoading: false,
                isProcessing: false,
              });
            })
            .catch((error) => {
              this.showError(error);
            });
          setTimeout(() => this.setState({ isResponse: false }), 10000);
        } else {
          this.setState({
            isResponse: true,
            theme: "danger",
            response: result.Message,
            isNewItemAddBtnClicked: false,
            isProcessing: false,
            requiredFieldsForNewItemAddition: fieldsWihtoutInputData,
          });
        }
      })
      .catch((error) => {
        this.showError(error);
      });
  };

  validateInputFields = (inputFieldsToValidate) => {
    const { columnNameWithDataType } = this.state;
    inputFieldsToValidate.forEach((element) => {
      if (element.input == null || element.input == "") {
        element["errorMessage"] = "Field can not be empty.";
        element["isError"] = true;
      } else {
        let dataType = columnNameWithDataType[element.name];
        console.log(dataType);
        if (dataType == "number") {
          const parsedValue = parseInt(element.input);
          if (isNaN(parsedValue)) {
            element["errorMessage"] = "Input should be a number.";
            element["isError"] = true;
          } else {
            element["errorMessage"] = "";
            element["isError"] = false;
          }
        } else if (dataType == "boolean") {
          try {
            const parsed = JSON.parse(element.input.toLowerCase());
            if (parsed === true || parsed === false) {
              element["errorMessage"] = "";
              element["isError"] = false;
            } else {
              element["errorMessage"] = "Input should be a true/false value.";
              element["isError"] = true;
            }
          } catch (e) {
            element["errorMessage"] = "Input should be a true/false value.";
            element["isError"] = true;
          }
        } else {
          element["errorMessage"] = "";
          element["isError"] = false;
        }
      }
    });
    return inputFieldsToValidate;
  };

  createFormDataFromInputs = () => {
    const { requiredFieldsForNewItemAddition, columnNameWithDataType } =
      this.state;
    let fieldWithInputData = new Map(
      requiredFieldsForNewItemAddition.map((object) => {
        return [object.name, object.input];
      })
    );
    console.log(fieldWithInputData);
    let itemInfo = Object.fromEntries(fieldWithInputData);
    console.log(itemInfo);
    let formData = Object.keys(itemInfo).reduce(function (object, key) {
      let type = columnNameWithDataType[key];
      if (type == "number") object[key] = parseFloat(itemInfo[key]);
      else if (type == "boolean") object[key] = JSON.parse(itemInfo[key]);
      else object[key] = itemInfo[key];
      return object;
    }, {});
    console.log(formData);
    return formData;
  };

  renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  };

  clearFields = (fieldsWithData) => {
    let fieldsWithDataClone = [...fieldsWithData];
    const clearedFields = fieldsWithDataClone.map((field) => ({
      ...field,
      input: "",
      errorMessage: "",
      isError: false,
    }));
    return clearedFields;
  };

  handleCancelBtn = () => {
    const { requiredFieldsForNewItemAddition } = this.state;
    let fieldsWihtoutInputData = this.clearFields(
      requiredFieldsForNewItemAddition
    );
    this.setState({
      isNewItemAddBtnClicked: false,
      requiredFieldsForNewItemAddition: fieldsWihtoutInputData,
    });
  };

  showError = (error) => {
    console.log(error);
    if (error?.response?.status == 401) {
      window.localStorage.removeItem("Token");
      window.location.href = "/login";
    } else {
      this.setState({
        isResponse: true,
        response: "Something went wrong",
        theme: "danger",
        isPalletProcessing: false,
        isPalletConfirmShowing: false,
      });
    }
  };

  editItems = (cell, row) => {
    return (
      <Button
        theme="info"
        onClick={(e) => this.handleItemEdit(e, row)}
        style={{ width: "100%", height: "100%" }}
      >
        Edit
      </Button>
    );
  };

  handleItemEdit = (e, row) => {
    const {
      requiredFieldsForItemUpdate,
      primaryKey,
      featureTypes,
      selectedTable,
      productCategories,
    } = this.state;
    let featureTypesWithSelectedValue = [];
    let productCategoriesWithSelectedValue = [];
    let fieldsWithSelectedItemData = this.setFieldsWithSelectedItem(
      requiredFieldsForItemUpdate,
      row,
      e
    );
    if (selectedTable[0].name === "tbl_pallet_categories") {
      featureTypesWithSelectedValue = featureTypes.map((type) => ({
        ...type,
        selected:
          fieldsWithSelectedItemData.find((x) => x.name === "feature_type_id")
            .input === type.id
            ? true
            : false,
      }));
      productCategoriesWithSelectedValue = productCategories.map(
        (category) => ({
          ...category,
          selected:
            fieldsWithSelectedItemData.find(
              (x) => x.name === "product_category"
            ).input === category.code
              ? true
              : false,
        })
      );
    }
    console.log(row);
    let itemIdToEdit = row[primaryKey];
    if (itemIdToEdit) {
      this.setState({
        isEditBtnClicked: true,
        itemIdToEdit: itemIdToEdit,
        requiredFieldsForItemUpdate: fieldsWithSelectedItemData,
        featureTypes: featureTypesWithSelectedValue,
        productCategories: productCategoriesWithSelectedValue,
      });
    }
  };

  setFieldsForItemUpdate = (tableData) => {
    const { primaryKey } = this.state;
    let allColumns = Object.keys(tableData[0]);
    if (allColumns != null && allColumns.length > 0) {
      let requiredColumns = allColumns.filter((column) => column != primaryKey);
      console.log(requiredColumns);
      let requiredFieldsForItemUpdate = [];
      for (let column in requiredColumns) {
        let requiredFieldWithOptions = {};
        requiredFieldWithOptions["indexId"] = column;
        requiredFieldWithOptions["name"] = requiredColumns[column];
        requiredFieldWithOptions["input"] = "";
        requiredFieldWithOptions["errorMessage"] = "";
        requiredFieldWithOptions["isError"] = false;
        requiredFieldWithOptions["type"] = false;
        requiredFieldsForItemUpdate.push(requiredFieldWithOptions);
      }
      console.log(requiredFieldsForItemUpdate);
      this.setState({ requiredFieldsForItemUpdate, requiredColumns });
    }
  };

  setFieldsWithSelectedItem = (fieldsWithData, selectedItem, index) => {
    let fieldsWithDataClone = [...fieldsWithData];
    const updatedFields = fieldsWithDataClone.map((field) => ({
      ...field,
      input: selectedItem[field.name],
      errorMessage: "",
      isError: false,
    }));
    return updatedFields;
  };

  handleUpdateBtn = () => {
    this.setState({ isProcessing: true });
    const {
      requiredFieldsForItemUpdate,
      selectedTable,
      urlToGetItems,
      itemIdToEdit,
      featureTypes,
    } = this.state;
    let inputFieldsToValidate = [...requiredFieldsForItemUpdate];
    let inputFieldsAfterValidation = this.validateInputFields(
      inputFieldsToValidate
    );
    console.log(inputFieldsAfterValidation);
    const isNotValidated = inputFieldsAfterValidation.some(
      (field) => field.isError === true
    );
    if (isNotValidated) {
      this.setState({
        requiredFieldsForItemUpdate: inputFieldsAfterValidation,
        isProcessing: false,
      });
      return;
    }
    let formData = this.createFormDataFromUpdatedInputs();
    let modelNamesToColumnNames =
      this.getModelNamesToColumnNamesExceptPrimaryKey(selectedTable[0].id);
    console.log(modelNamesToColumnNames);
    let modelNamesWithData = this.renameKeys(formData, modelNamesToColumnNames);
    //modelNamesWithData.CategoryId = itemIdToEdit;
    console.log(modelNamesWithData);
    let urlToUpdateItem = this.getUrlToUpdateItemInRelevantTable(
      selectedTable[0].id
    );
    axios
      .post(
        `${urlToUpdateItem}/${itemIdToEdit}`,
        modelNamesWithData,
        authorizationHeader
      )
      .then(({ data: result }) => {
        let fieldsWihtoutInputData = this.clearFields(
          requiredFieldsForItemUpdate
        );
        if (result.isSuccess) {
          this.setState({
            isResponse: true,
            theme: "success",
            response: result.messageText,
            isDataLoading: true,
            items: [],
            isEditBtnClicked: false,
            requiredFieldsForItemUpdate: fieldsWihtoutInputData,
          });
          axios
            .get(urlToGetItems, authorizationHeader)
            .then(({ data: result }) => {
              let items = JSON.parse(result.data);
              this.setState({
                items,
                isDataLoading: false,
                isProcessing: false,
              });
            })
            .catch((error) => {
              this.showError(error);
            });
          setTimeout(() => this.setState({ isResponse: false }), 10000);
        } else {
          this.setState({
            isResponse: true,
            theme: "danger",
            response: result.Message,
            isNewItemAddBtnClicked: false,
            isProcessing: false,
            requiredFieldsForNewItemAddition: fieldsWihtoutInputData,
          });
        }
      })
      .catch((error) => {
        this.showError(error);
      });
  };

  createFormDataFromUpdatedInputs = () => {
    const { requiredFieldsForItemUpdate, columnNameWithDataType } = this.state;
    let fieldWithInputData = new Map(
      requiredFieldsForItemUpdate.map((object) => {
        return [object.name, object.input];
      })
    );
    console.log(fieldWithInputData);
    let itemInfo = Object.fromEntries(fieldWithInputData);
    console.log(itemInfo);
    let formData = Object.keys(itemInfo).reduce(function (object, key) {
      let type = columnNameWithDataType[key];
      if (type == "number") object[key] = parseFloat(itemInfo[key]);
      else if (type == "boolean") object[key] = JSON.parse(itemInfo[key]);
      else object[key] = itemInfo[key];
      return object;
    }, {});
    console.log(formData);
    return formData;
  };

  getUrlToUpdateItemInRelevantTable = (tableId) => {
    let urls = {
      [TableNames.tbl_pallet_categories]: ApiUrls.updatePalletCategoryItem,
      [TableNames.tbl_items_bottle_medicines]: ApiUrls.updateBottleMedicineItem,
      [TableNames.tbl_items_medical_disposable]:
        ApiUrls.updateMedicalDisposableItem,
      [TableNames.tbl_items_feeds_and_food]: ApiUrls.updateFeedsAndFoodItem,
      [TableNames.tbl_items_non_pallet]: ApiUrls.updateNonPalletItem,
    };
    return urls[tableId];
  };

  handleCancelBtnInEdit = () => {
    const { requiredFieldsForItemUpdate, featureTypes } = this.state;
    let fieldsWihtoutInputData = this.clearFields(requiredFieldsForItemUpdate);
    this.setState({
      isEditBtnClicked: false,
      requiredFieldsForItemUpdate: fieldsWihtoutInputData,
      featureTypes,
    });
  };
}
