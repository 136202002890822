import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, Alert,
    FormInput, Form, FormTextarea, Modal, ModalBody, FormRadio
} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { StorageLocation } from "../common/StorageLocation";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { DateInput } from '@opuscapita/react-dates';
import { getDateInGivenFormat } from "../../utils/formatDate";
import { getSelectedDateFromDatePicker } from '../../utils/formatDateFromDatePicker';
import { getPalletCategoryNameWithId, FeaturePalletTypes } from "../../helpers/palletHelper";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class NewPalletWarehouse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            validPalletCategory: false,
            isDataLoading: false,
            validDescription: false,
            isDescriptionRequest: false,
            validExpiryDate: false,
            isExpiryDateRequest: false,
            validWeight: false,
            isWeightRequest: false,
            validPrimaryLocation: false,
            validSecondaryLocation: false,
            isSecondaryLocationLoading: false,
            printConfirmation: false,
            showConfirmation: false,
            isYesBtnDisabled: false,
            validFoodPalletTypeSelection: false,
            isPalletCategoriesLoading: false,
            isFoodPalletComplex: false,
            isFoodPalletRegular: true,
            palletCategories: [],
            selectedPalletCategory: [],
            selectedPrimaryLocation: [],
            selectedSecondaryLocation: [],
            theme: "",
            response: "",
            today: "",
            formatDate: "",
            responsePalletCategory: "",
            palletNumber: "",
            description: "",
            responseDescription: "",
            expiryDate: "",
            responseExpiryDate: "",
            weight: "",
            responseWeight: "",
            yesBtnText: "Yes",
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],
            palletCategoryIdsWithFeatureTypeId: new Map()
        };
        this.StorageLocation = React.createRef();
    }

    componentDidMount() {
        this.getPalletCategories();
        this.getTodayDate();
    }

    render() {
        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const {
            isResponse,
            theme,
            response,
            selectedPalletCategory,
            palletCategories,
            emptyLabel,
            isWeightRequest,
            responseExpiryDate,
            responsePalletCategory,
            isDataLoading,
            isPalletCategoriesLoading,
            palletNumber,
            isDescriptionRequest,
            description,
            responseDescription,
            isExpiryDateRequest,
            expiryDate,
            today,
            weight,
            responseWeight,
            showConfirmation,
            palletCategoryIdsWithFeatureTypeId,
            formatDate,
            selectedPrimaryLocation,
            selectedSecondaryLocation,
            isYesBtnDisabled,
            yesBtnText,
            printConfirmation,
            isFoodPalletRegular,
            isFoodPalletComplex
        } = this.state;

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                <Col sm="12" md="10" lg="8" style={mystyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Make New Pallet​</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>CREATE NEW PALLET</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Form>
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup >
                                            <label style={{ fontSize: "large" }} htmlFor="#palletType">Pallet type :<span className="error-txt">*</span></label>
                                            <Typeahead size="lg" selected={selectedPalletCategory}
                                                id="palletcategory-typeahead"
                                                labelKey="description"
                                                onChange={this.setPalletCategory}
                                                options={sortByAlphanumerically(palletCategories, 'description')}
                                                emptyLabel={emptyLabel}
                                                placeholder="" />
                                            <span className="error-txt">{responsePalletCategory}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {isDataLoading && isPalletCategoriesLoading && (
                                    <label style={{ fontWeight: "bold" }}>Loading...</label>
                                )}
                                {!isDataLoading && !isPalletCategoriesLoading && (
                                    <>
                                        {palletNumber && (<>
                                            {palletNumber.includes("FOOD") && (
                                                <Row>
                                                    <Col sm="12" md="10" lg="8">
                                                        <FormGroup>
                                                            <FormRadio
                                                                name="regular"
                                                                checked={isFoodPalletRegular}
                                                                onChange={() => {
                                                                    this.setFoodTypeToRegular();
                                                                }}
                                                            >
                                                                Regular
                                                            </FormRadio>
                                                            <FormRadio
                                                                name="complex"
                                                                checked={isFoodPalletComplex}
                                                                onChange={() => {
                                                                    this.setFoodTypeToComplex();
                                                                }}
                                                            >
                                                                Complex
                                                            </FormRadio>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                <Col sm="12" md="10" lg="8">
                                                    <FormGroup >
                                                        <label style={{ fontSize: "large" }} htmlFor="#PalletNumber">Pallet number :<span className="error-txt"></span></label>
                                                        <FormInput size="lg" type="text" id="#PalletNumber" value={palletNumber} disabled />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {isDescriptionRequest && (
                                                <Row>
                                                    <Col sm="12" md="10" lg="8">
                                                        <FormGroup style={{ fontSize: "large" }}>
                                                            <label htmlFor="#description">Description :<span className="error-txt">*</span></label>
                                                            <FormTextarea size="lg" type="text" id="#description" value={description} onChange={this.setDescription} />
                                                            <span className="error-txt">{responseDescription}</span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )}
                                            {isExpiryDateRequest && (
                                                <Row>
                                                    <Col sm="12" md="10" lg="8">
                                                        <FormGroup style={{ fontSize: "large" }}>
                                                            <label htmlFor="#earliestExpiryDate">Earliest expiry date :<span className="error-txt">*</span></label>
                                                            <DateInput
                                                                id="#earliestExpiryDate"
                                                                value={expiryDate}
                                                                dateFormat="dd/MM/yyyy"
                                                                locale="en"
                                                                onChange={this.setExpiryDate}
                                                                min={today}
                                                                modifiers={{
                                                                    disabled: { before: new Date() }
                                                                }}
                                                            />
                                                            <span className="error-txt">{responseExpiryDate}</span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )}
                                            {isWeightRequest && (
                                                <Row>
                                                    <Col sm="12" md="10" lg="8">
                                                        <FormGroup style={{ fontSize: "large" }}>
                                                            <label htmlFor="#weight">Weight(kg) :<span className="error-txt">*</span></label>
                                                            <FormInput size="lg" type="number" id="#weight" value={weight} onChange={this.setWeight} min="1" />
                                                            <span className="error-txt">{responseWeight}</span>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )}
                                            <StorageLocation ref={this.StorageLocation}
                                                getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                                getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                                getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                                getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                                getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                                            />
                                            <Row>
                                                <Col sm="12" md="10" lg="8">
                                                    <FormGroup>
                                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>
                                        )}
                                    </>
                                )}
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {showConfirmation && (
                    <Modal open={showConfirmation} toggle={() => this.setState({ showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>CREATE NEW PALLET CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    {(palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicalDisposables
                                        || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicinesAndCreams
                                        || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Food
                                    ) &&
                                        (<>
                                            <label style={{ fontSize: "x-large" }}>
                                                Do you want to create pallet number {palletNumber} Creation date {formatDate}
                                                &nbsp;stored in location {selectedPrimaryLocation[0].description}
                                                &nbsp;{selectedSecondaryLocation.length !== 0 && (<>({selectedSecondaryLocation[0].Name})</>)}?
                                            </label>
                                        </>)}
                                    {palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Other &&
                                        (<>
                                            <label style={{ fontSize: "x-large" }}>
                                                Do you want to create a pallet number {palletNumber} of {description}
                                                &nbsp;Creation date {formatDate} weighing {weight}
                                                kg stored in location {selectedPrimaryLocation[0].description}
                                                &nbsp;{selectedSecondaryLocation.length !== 0 && (<>({selectedSecondaryLocation[0].Name})</>)}?
                                            </label>
                                        </>)}
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={isYesBtnDisabled}>{yesBtnText}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
                {printConfirmation && (
                    <Modal open={printConfirmation} toggle={() => this.setState({ showConfirmation: printConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            {(palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicalDisposables
                                || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicinesAndCreams
                                || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Food
                            ) &&
                                (<>
                                    <Row>
                                        <Col sm="12" md="12" lg="12">
                                            <label style={{ fontSize: "150px" }}>{palletNumber}</label>
                                        </Col>
                                        <Col sm="12" md="12" lg="12">
                                            <label><h5>Created on : {formatDate}</h5></label>
                                        </Col>
                                        <Col sm="12" md="12" lg="12">
                                            <label><h5>Location : {selectedPrimaryLocation[0].description}
                                                &nbsp;{selectedSecondaryLocation.length !== 0 && (<>({selectedSecondaryLocation[0].Name})</>)}</h5></label>
                                        </Col>
                                    </Row>
                                </>)}
                            {palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Other &&
                                (<>
                                    <Row>
                                        <Col sm="12" md="12" lg="12">
                                            <label style={{ fontSize: "150px" }}>{palletNumber}</label>
                                        </Col>
                                        <Col sm="12" md="12" lg="12">
                                            <label><h5>Description : {description}</h5></label>
                                        </Col>
                                        <Col sm="12" md="12" lg="12">
                                            <label><h5>Created on : {formatDate}</h5></label>
                                        </Col>
                                        <Col sm="12" md="12" lg="12">
                                            <label><h5>Weight : {weight}kg</h5></label>
                                        </Col>
                                        <Col sm="12" md="12" lg="12">
                                            <label><h5>Location : {selectedPrimaryLocation[0].description}
                                                &nbsp;{selectedSecondaryLocation.length !== 0 && (<>({selectedSecondaryLocation[0].Name})</>)}</h5></label>
                                        </Col>
                                    </Row>
                                </>)}
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.showPrintView} >Print</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClickForPrint} >No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getPalletCategories = () => {
        axios.get(ApiUrls.getPalletCategories, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                let palletCategories = JSON.parse(response.data.data);
                console.log(palletCategories);
                let palletCategoryIdsWithFeatureTypeId = getPalletCategoryNameWithId(palletCategories);
                console.log(palletCategoryIdsWithFeatureTypeId);
                if (palletCategoryIdsWithFeatureTypeId.size !== 0) {
                    this.setState({ palletCategoryIdsWithFeatureTypeId, isPalletCategoriesLoading: false, });
                } else {
                    this.setState({ palletCategoryIdsWithFeatureTypeId: new Map(), isPalletCategoriesLoading: true });
                }
                this.setState({ palletCategories: palletCategories, selectedPalletCategory: [], emptyLabel: "No matches found" });
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setFoodTypeToComplex = () => {
        this.setState({ isFoodPalletComplex: true, isFoodPalletRegular: false });
    };

    setFoodTypeToRegular = () => {
        this.setState({ isFoodPalletRegular: true, isFoodPalletComplex: false });
    };

    getTodayDate = () => {
        var date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var today = [year, month, day].join('-');
        var formatDate = getDateInGivenFormat(date).slice(0, 10)
        this.setState({ today: today, formatDate: formatDate });
        console.log(formatDate);
    }

    setPalletCategory = (value) => {
        console.log(value);
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({ selectedPalletCategory: value, responsePalletCategory: "", validPalletCategory: true, isDataLoading: true });
            axios.get(`${ApiUrls.getPalletNumber}?id=${value[0].id}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var palletNumber = JSON.parse(response.data.data);
                    this.setState({ palletNumber: palletNumber, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });
            const { isPalletCategoriesLoading, palletCategoryIdsWithFeatureTypeId} = this.state;
            if (!isPalletCategoriesLoading) {
                this.setState({
                    description: "", responseDescription: "", validDescription: false,
                    expiryDate: "", responseExpiryDate: "", validExpiryDate: false,
                    weight: "", responseWeight: "", validWeight: false,
                });
                if (palletCategoryIdsWithFeatureTypeId.get(value[0].category_id) == FeaturePalletTypes.MedicalDisposables
                    || palletCategoryIdsWithFeatureTypeId.get(value[0].category_id) == FeaturePalletTypes.MedicinesAndCreams
                    || palletCategoryIdsWithFeatureTypeId.get(value[0].category_id) == FeaturePalletTypes.Food
                ) {
                    this.setState({
                        isDescriptionRequest: false, isExpiryDateRequest: false, isWeightRequest: false, 
                    });
                } else if (palletCategoryIdsWithFeatureTypeId.get(value[0].category_id) == FeaturePalletTypes.Other) {
                    this.setState({
                        isDescriptionRequest: true, isExpiryDateRequest: false, isWeightRequest: true,
                    });
                }
            }
        }
        else {
            this.setState({ selectedPalletCategory: [], responsePalletCategory: "Please select a pallet type", validPalletCategory: false, palletNumber: "" });
        }
    };

    validatePalletCategory = () => {
        this.setState({ isResponse: false });
        if (this.state.selectedPalletCategory.length === 0) {
            this.setState({ responsePalletCategory: "Please select a pallet type", validPalletCategory: false });
        } else {
            this.setState({ responsePalletCategory: "", validPalletCategory: true });
        }
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ validPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ validSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    setDescription = (value) => {
        this.setState({ description: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseDescription: "Please enter description", validDescription: false });
        } else if (value.target.value.length >= 100) {
            this.setState({ responseDescription: " Maximum length is 100 character", validDescription: false });
        } else {
            this.setState({ responseDescription: "", validDescription: true });
        }
    }

    validateDescription = () => {
        this.setState({ isResponse: false });
        if (!(this.state.description)) {
            this.setState({ responseDescription: "Please enter description", validDescription: false });
        } else if (this.state.description.length >= 100) {
            this.setState({ responseDescription: " Maximum length is 100 character", validDescription: false });
        } else {
            this.setState({ responseDescription: "", validDescription: true });
        }
    }

    setExpiryDate = (value) => {
        this.setState({ expiryDate: value, isResponse: false });
        if (!value) {
            this.setState({ responseExpiryDate: "Please enter expiry date", validExpiryDate: false });
        } else if (value <= this.state.today) {
            this.setState({ responseExpiryDate: "Please enter future date", validExpiryDate: false });
        }
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    validateExpiryDate = () => {
        this.setState({ isResponse: false });
        if (!(this.state.expiryDate)) {
            this.setState({ responseExpiryDate: "Please enter expiry date", validExpiryDate: false });
        } else if (this.state.expiryDate <= this.state.today) {
            this.setState({ responseExpiryDate: "Please enter future date", validExpiryDate: false });
        }
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    setWeight = (value) => {
        this.setState({ weight: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (value.target.value < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    validateWeight = () => {
        this.setState({ isResponse: false });
        if (!(this.state.weight)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(this.state.weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (this.state.weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    onConfirmClick = () => {
        const {
            selectedPalletCategory,
            validPalletCategory,
            validDescription,
            validWeight,
            validPrimaryLocation,
            palletNumber,
            validSecondaryLocation,
            isSecondaryLocationLoading,
            palletCategoryIdsWithFeatureTypeId
        } = this.state;
        if (palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicalDisposables
            || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicinesAndCreams
            || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Food
        ) {
            this.validatePalletCategory();
            this.StorageLocation.current.validatePrimaryLocation();
            if (!isSecondaryLocationLoading && !validSecondaryLocation) {
                this.StorageLocation.current.validateSecondaryLocation();
            }
            if (validPalletCategory
                && validPrimaryLocation
                && palletNumber
                && validSecondaryLocation
                && !isSecondaryLocationLoading
            ) {
                this.setState({ showConfirmation: true });
            }
        } else if (palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Other) {
            this.validatePalletCategory();
            this.validateDescription();
            this.validateWeight();
            this.StorageLocation.current.validatePrimaryLocation();
            if (!isSecondaryLocationLoading && !validSecondaryLocation) {
                this.StorageLocation.current.validateSecondaryLocation();
            }
            if (validPalletCategory
                && validDescription
                && validWeight
                && validPrimaryLocation
                && palletNumber
                && validSecondaryLocation
                && !isSecondaryLocationLoading) {
                this.setState({ showConfirmation: true });
            }
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false });
    }

    onYesClick = () => {
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });
        const {
            selectedPalletCategory,
            weight,
            isFoodPalletComplex,
            selectedSecondaryLocation,
            selectedPrimaryLocation,
            palletNumber,
            description
        } = this.state;
        let secondaryLocation = (selectedSecondaryLocation.length != 0) ? parseInt(selectedSecondaryLocation[0].Id) : 0;
        let newPalletInfo = {
            CategoryId: selectedPalletCategory[0].category_id,
            PalletNumber: palletNumber,
            StorageLocationId: parseInt(selectedPrimaryLocation[0].id),
            SecondaryStorageLocationId: secondaryLocation,
            TotalWeight: weight ? weight : null,
            Description: description,
            IsFoodPalletComplex: isFoodPalletComplex
        };
        console.log(newPalletInfo);
        axios.post(ApiUrls.createPallet, newPalletInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({ isResponse: true, response: response.data.messageText, theme: "success", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false, printConfirmation: true });
            }
            else {
                this.setState({ isResponse: true, response: response.data.messageText, theme: "danger", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false, printConfirmation: false });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    showPrintView = () => {
        const {
            selectedPalletCategory,
            selectedSecondaryLocation,
            selectedPrimaryLocation,
            palletCategoryIdsWithFeatureTypeId,
            description,
            weight,
            palletNumber,
            today
        } = this.state;
        let a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > <p style="font-size: 50vw; display: inline"> ' + palletNumber + '</p>');
        let location = (selectedSecondaryLocation.length != 0)
            ? `${selectedPrimaryLocation[0].description}(${selectedSecondaryLocation[0].Name})`
            : selectedPrimaryLocation[0].description;
        if (palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicalDisposables
            || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.MedicinesAndCreams
            || palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Food
        ) {
            a.document.write(' <p style="font-size: 5vw"> Created on  : ' + getDateInGivenFormat(today?.slice(0, 10)) + '</p>');
            a.document.write(' <p style="font-size: 5vw"> Location  : ' + location + '</p>');
        } else if (palletCategoryIdsWithFeatureTypeId.get(selectedPalletCategory[0].category_id) == FeaturePalletTypes.Other) {
            a.document.write(' <p style="font-size: 5vw"> Description : ' + description + '</p>');
            a.document.write(' <p style="font-size: 5vw"> Created on  : ' + getDateInGivenFormat(today?.slice(0, 10)) + '</p>');
            a.document.write(' <p style="font-size: 5vw"> Weight : ' + weight + 'Kg</p>');
            a.document.write(' <p style="font-size: 5vw"> Location  : ' + location + '</p>');
        }
        a.document.write('</body></html>')
        a.print();
        a.document.close();
        window.location.href = "/warehouse";
    }

    onNoClickForPrint = () => {
        window.location.href = "/warehouse";
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger",
                isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false, printConfirmation: false
            });
        }
    }

    getExpiryDateInGivenFormat = () => {
        const { expiryDate } = this.state;
        return getDateInGivenFormat(expiryDate);
    }
}