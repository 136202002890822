import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput
} from "shards-react";
import axios from "axios";
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { DateInput } from '@opuscapita/react-dates';
import { getSelectedDateFromDatePicker } from '../../utils/formatDateFromDatePicker';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class PutFoodOnPallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isPalletsLoading: false,
            validSelectedPallet: false,
            validFeedsAndFoodItem: false,
            validNumberOfItem: false,
            validExpiryDate: false,
            validBatchNumber: false,
            validWeight: false,
            showConfirmation: false,
            isYesBtnDisabled: false,
            selectedPallet: [],
            pallets: [],
            feedsAndFoodItems: [],
            selectedFeedsAndFoodItem: [],
            theme: "",
            response: "",
            palletquery: "",
            responseSelectedPallet: "",
            responseFeedsAndFoodItem: "",
            numberOfItem: "",
            responseNumberOfItem: "",
            expiryDate: "",
            responseExpiryDate: "",
            batchNumber: "",
            responseBatchNumber: "",
            weight: "",
            responseWeight: "",
            today: "",
            palletNumber: "",
            location: "",
            yesBtnText: "Yes",
        };
        this.palletNumber = React.createRef();
        this.FeedsAndFoodItem = React.createRef();
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const palletNumber = urlParams.get('pallet');
        if (palletNumber) {
            console.log(palletNumber);
            this.getFeedsAndFoodItems(palletNumber);
        }
        this.setState({ palletNumber: palletNumber, validSelectedPallet:true });
        const location = urlParams.get('location',);
        this.setState({ location: location });
        this.getTodayDate();
    }

    render() {
        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                <Col sm="12" md="10" lg="8" style={mystyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        {this.state.location == "food" && ( 
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <a href="/dashboard">Home</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/food">Food Interface</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=food">Edit a Pallet</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>Put Items on Pallet</BreadcrumbItem>
                            </Breadcrumb>                            
                        )}
                        {(this.state.location != "food" && this.state.location != "warehouse")  && ( 
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/food">Food Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Put Items on Pallet</BreadcrumbItem>
                        </Breadcrumb>
                        )}
                        {this.state.location == "warehouse" && (
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <a href="/dashboard">Home</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=warehouse">Edit a Pallet</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>Put Items on Pallet</BreadcrumbItem>
                            </Breadcrumb>
                        )}
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>PUT FOOD ON PALLET</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            {!this.state.location && ( 
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#pallet">Select a pallet :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            ref={this.palletNumber}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        <span className="error-txt">{this.state.responseSelectedPallet}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            )}
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#StockContents">Stock contents :<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" value={this.state.selectedFeedsAndFoodItem}
                                            id="Id"
                                            labelKey="Description"
                                            onChange={this.setFeedsAndFoodItems}
                                            options={this.state.feedsAndFoodItems}
                                            placeholder=""
                                            ref={this.FeedsAndFoodItem}
                                        />
                                        <span className="error-txt">{this.state.responseFeedsAndFoodItem}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Number of item(s) :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#NumberOfItem" value={this.state.numberOfItem} onChange={this.setNumberOfItem} min="1" />
                                        <span className="error-txt">{this.state.responseNumberOfItem}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#ExpiryDate">Expiry date :<span className="error-txt">*</span></label>
                                        <DateInput
                                            id="#ExpiryDate"
                                            value={this.state.expiryDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setExpiryDate}
                                            min={this.state.today}
                                            modifiers={{
                                                disabled: { before: new Date() }
                                            }}
                                        />
                                        <span className="error-txt">{this.state.responseExpiryDate}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#BatchNumber">Batch number :<span className="error-txt"></span></label>
                                        <FormInput size="lg" type="text" id="#BatchNumber" value={this.state.batchNumber} onChange={this.setBatchNumber}  />
                                        <span className="error-txt">{this.state.responseBatchNumber}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#weight">Weight per item(kg) :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#weight" value={this.state.weight} onChange={this.setWeight} min="1" />
                                        <span className="error-txt">{this.state.responseWeight}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" type="submit" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>PUT ITEMS ON PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to put {this.state.numberOfItem} of {this.state.selectedFeedsAndFoodItem[0].Description.toString()}
                                        &nbsp;weighing {this.state.weight}kg onto pallet no. {this.state.palletNumber} ? 
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isResponse: false });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getEditablePallets}?query=${palletquery}&locId=9`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });
    }

    setPallet = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            console.log(value);
            this.getFeedsAndFoodItems(value[0].id);
            this.setState({
                selectedPallet: value, responseSelectedPallet: "", palletNumber: value[0].id,
                validSelectedPallet: true,
            });
        } else {
            this.setState({
                selectedPallet: [], responseSelectedPallet: "Please select a pallet number",
                validSelectedPallet: true, palletNumber: "", feedsAndFoodItems: []
            });
        }
    };

    validatePallet = () => {
        if (this.state.selectedPallet.length === 0) {
            this.setState({ responseSelectedPallet: "Please select a pallet number", validSelectedPallet: false });
        } else {
            this.setState({ responseSelectedPallet: "", validSelectedPallet: true });
        }
    };

    getFeedsAndFoodItems = (palletNumber) => {
        axios.get(`${ApiUrls.getFeedsAndFoodItems}?id=${palletNumber}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var FeedsAndFoodItems = JSON.parse(response.data.data);
                var sortFeedsAndFoodItems = sortByAlphanumerically(FeedsAndFoodItems, 'Description');
                this.setState({ feedsAndFoodItems: sortFeedsAndFoodItems });
            }
        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });
    };

    setFeedsAndFoodItems = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({
                selectedFeedsAndFoodItem: value, responseFeedsAndFoodItem: "",
                validFeedsAndFoodItem: true
            });
        } else {
            this.setState({
                selectedFeedsAndFoodItem: [], responseFeedsAndFoodItem: "Please select a stock content",
                validFeedsAndFoodItem: false
            });
        }
    };

    validateFeedsAndFoodItems = () => {
        if (this.state.selectedFeedsAndFoodItem.length === 0) {
            this.setState({ responseFeedsAndFoodItem: "Please select a stock content", validFeedsAndFoodItem: false });
        } else {
            this.setState({ responseFeedsAndFoodItem: "", validFeedsAndFoodItem: true });
        }
    };

    setNumberOfItem = (value) => {
        this.setState({ numberOfItem: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) >= 100000000) {
            this.setState({ responseQuantity: "Quantity is limited to be between 1-10000000", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    validateNumberOfItem = () => {
        if (!(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) >= 100000000) {
            this.setState({ responseQuantity: "Quantity is limited to be between 1-10000000", validNumberOfItem: false });
        }  else if (Number.isInteger(parseFloat(this.state.numberOfItem)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    setWeight = (value) => {
        this.setState({ weight: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (value.target.value < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    validateWeight = () => {
        if (!(this.state.weight)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(this.state.weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (this.state.weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    setExpiryDate = (value) => {
        this.setState({ expiryDate: value, isResponse: false });
        if (!value) {
            this.setState({ responseExpiryDate: "Please enter expiry Date", validExpiryDate: false });
        } else if (value <= this.state.today) {
            this.setState({ responseExpiryDate: "Please enter future Date", validExpiryDate: false });
        }
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    validateExpiryDate = () => {
        this.setState({ isResponse: false });
        if (!(this.state.expiryDate)) {
            this.setState({ responseExpiryDate: "Please enter expiry Date", validExpiryDate: false });
        } else if (this.state.expiryDate <= this.state.today) {
            this.setState({ responseExpiryDate: "Please enter future Date", validExpiryDate: false });
        }
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    setBatchNumber = (value) => {
        this.setState({ batchNumber: value.target.value, isResponse: false });
    }

    getTodayDate = () => {
        var date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var today = [year, month, day].join('-');
        this.setState({ today: new Date(today) });
    }

    onConfirmClick = (value) => {
        value.preventDefault();
        {!this.state.palletNumber && ( 
            this.validatePallet()
        )};
        this.validateFeedsAndFoodItems();
        this.validateNumberOfItem();
        this.validateExpiryDate();
        this.validateWeight();
        this.getTodayDate();
      
        const { validSelectedPallet, validFeedsAndFoodItem, validNumberOfItem, validExpiryDate, validWeight } = this.state;
        if (validSelectedPallet && validFeedsAndFoodItem && validNumberOfItem && validExpiryDate && validWeight) {
            this.setState({ showConfirmation: true });
        }
    }

    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        var FeedsAndFoodItemsInStock = {
            PalletNumber: this.state.palletNumber,
            FeedsAndFoodItemId: this.state.selectedFeedsAndFoodItem[0].Id,
            NumberOfBoxes: this.state.numberOfItem ? parseInt(this.state.numberOfItem) : null,
            ExpiryDate: this.state.expiryDate ? getSelectedDateFromDatePicker(this.state.expiryDate) : null,
            BatchNumber: this.state.batchNumber,
            Weight: this.state.weight ? parseFloat(this.state.weight) : null,
        };
           
        axios.post(ApiUrls.addFeedsAndFoodItemsToStock, FeedsAndFoodItemsInStock, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                if (this.state.location != "food" && this.state.location != "warehouse" ) { this.palletNumber.current.clear();}               
                this.FeedsAndFoodItem.current.clear();
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, response: response.data.messageText,
                    isResponse: true, theme: "success",
                    selectedPallet: [], pallets: [],
                    isPalletsLoading: false, palletquery: "",
                    responseSelectedPallet: "", 
                    selectedFeedsAndFoodItem: [],
                    responseFeedsAndFoodItem: "", validFeedsAndFoodItem: false,
                    numberOfItem: "", responseNumberOfItem: "", validNumberOfItem: false,
                    expiryDate: "", responseExpiryDate: "", validExpiryDate: false,
                    batchNumber: "", responseBatchNumber: "", validBatchNumber: false,
                    weight: "", responseWeight: "", validWeight: false,
                });

                if (this.state.palletNumber) {
                    this.setState({ validSelectedPallet: true });
                } else {
                    this.setState({ validSelectedPallet: false });
                }                    
            }
            else {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, response: response.data.messageText,
                    isResponse: true, theme: "danger"
                });
            }
        }).catch((error) => {
            console.log(error);
            this.showError(error);        
        });
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false })
    }

    showError = (error) => {
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isYesBtnDisabled: false, yesBtnText: "Yes",
                showConfirmation: false, response: "Something went wrong",
                isResponse: true, theme: "danger"
            });
        }
    }
}