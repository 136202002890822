import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, Button, FormInput,
    FormGroup, Alert
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

export class AcceptUserInvitation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: "",
            isResponse: false,
            theme: "",
            response: "",
            valuePassword: "",
            valueNewPassword: "",
            valueConfirmPassword: "",
            buttonText: "Accept Invitation",
            isAcceptInvitationBtnDisabled: false,
        };
    }

    componentDidMount() {
        this.setState({ token: this.props.location.search.split("=").pop() });
    }

    render() {
        return (
            <Card style={{ maxWidth: "25rem", marginTop: "8%", marginLeft: "auto", marginRight: "auto" }}>

                <CardHeader style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }} class="card text-center">Update Password</CardHeader>

                <CardBody>
                    <form>

                        {this.state.isResponse && (
                            <FormGroup>
                                <Alert theme={this.state.theme}>
                                    {this.state.response}
                                </Alert>
                            </FormGroup>
                        )}

                        <FormGroup>
                            <label htmlFor="#Password">Password</label>
                            <FormInput type="password" id="#Password" value={this.state.valuePassword} placeholder="Password" required onChange={this.onPasswordchanged} />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="#NewPassword">New Password</label>
                            <FormInput type="password" id="#NewPassword" value={this.state.valueNewPassword} placeholder="New Password" required onChange={this.onNewPasswordchanged} />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="#ConfirmPassword">Confirm Password</label>
                            <FormInput type="password" id="#ConfirmPassword" value={this.state.valueConfirmPassword} placeholder="Confirm Password" required onChange={this.onConfirmPasswordchanged} />
                        </FormGroup>

                        <FormGroup>
                            <Button style={{ width: "100%" }} onClick={this.onAcceptInvitationBtnClick} disabled={this.state.isAcceptInvitationBtnDisabled} type="submit">{this.state.buttonText}</Button>
                        </FormGroup>


                    </form>
                </CardBody>

            </Card>
        );
    }

    onPasswordchanged = (event) => {
        let password = event.target.value;
        this.setState({ valuePassword: password, isResponse: false });
    }

    onNewPasswordchanged = (event) => {
        let newPassword = event.target.value;
        this.setState({ valueNewPassword: newPassword, isResponse: false });
    }

    onConfirmPasswordchanged = (event) => {
        let confirmPassword = event.target.value;
        this.setState({ valueConfirmPassword: confirmPassword, isResponse: false });
    }

    onAcceptInvitationBtnClick = (event) => {
        event.preventDefault();
        this.setState({ buttonText: "Loading...", isAcceptInvitationBtnDisabled: true, isResponse: false });
        var strongRegex = new RegExp("(?=.{8,})");
        const { valuePassword, valueNewPassword, valueConfirmPassword, token } = this.state;
        if (!token) {
            this.setState({ isResponse: true, theme: "danger", response: "Invitation link is not valid", isAcceptInvitationBtnDisabled: true, buttonText: "Accept Invitation", valuePassword: "", valueNewPassword: "", valueConfirmPassword: "" });
        }
        else if (!valuePassword || !valueNewPassword || !valueConfirmPassword) {
            this.setState({ isResponse: true, theme: "danger", response: "Please fill all required fields", isAcceptInvitationBtnDisabled: false, buttonText: "Accept Invitation" });
        }
        else if (!strongRegex.test(valueNewPassword)) {
            this.setState({ isResponse: true, theme: "danger", response: "password  must be eight characters or longer", isAcceptInvitationBtnDisabled: false, buttonText: "Accept Invitation", valueNewPassword: "", valueConfirmPassword: "" });
        }
        else if (valueNewPassword !== valueConfirmPassword) {
            this.setState({ isResponse: true, theme: "danger", response: "New password and confirm password does not match", isAcceptInvitationBtnDisabled: false, buttonText: "Accept Invitation", valueConfirmPassword: "" });
        }
        else {
            var AcceptUserInvitationInfo = {
                Token: token,
                Password: valuePassword.trim(),
                NewPassword: valueNewPassword.trim(),
            }
            axios.post(ApiUrls.acceptUserInvitation, AcceptUserInvitationInfo).then((response) => {
                if (response.data.isSuccess) {
                    window.location.href = "/login";
                }
                else {
                    this.setState({ isResponse: true, theme: "danger", response: response.data.messageText, isAcceptInvitationBtnDisabled: false, buttonText: "Accept Invitation", valuePassword: "", valueNewPassword: "", valueConfirmPassword: "" });
                }
            }).catch((error) => {
                this.setState({ isResponse: true, theme: "danger", response: "Something went wrong", isAcceptInvitationBtnDisabled: false, buttonText: "Accept Invitation", valuePassword: "", valueNewPassword: "", valueConfirmPassword: "" });
            });
        }
    }
}