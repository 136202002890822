import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput} from "shards-react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class CreateNewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isYesBtnDisabled: false,
            isContainerNameValid: false,
            isConfirmationShowing: false,
            theme: "",
            response: "",
            containerName: "",
            responseContainerName: "",
        };
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response, containerName,
            responseContainerName, isConfirmationShowing, isYesBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Create New Container</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>CREATE NEW CONTAINER </h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#containerName">Type in the name that you want to give to the new container :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="text" id="#containerName" value={containerName} onChange={this.setContainerName} />
                                        <span className="error-txt">{responseContainerName}</span>
                                    </FormGroup>
                                </Col>
                            </Row>                           
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" type="submit" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmationShowing && (
                    <Modal open={isConfirmationShowing} toggle={() => this.setState({ isConfirmationShowing})} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>CREATE NEW CONTAINER - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to create a new container called {containerName}?</label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={isYesBtnDisabled}>Yes</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    setContainerName = ({ target }) => {
        this.setState({ containerName: target.value, isResponse: false });
        if (!(target.value)) {
            this.setState({ responseContainerName: "Please enter container name", isContainerNameValid: false });
        } else if (target.value.length >= 100 ) {
            this.setState({ responseContainerName: " Maximum length is 100 character", isContainerNameValid: false });
        }
        else {
            this.setState({ responseContainerName: "", isContainerNameValid: true });
        }
    }

    validateContainerName = () => {
        this.setState({ isResponse: false });
        const { containerName } = this.state;
        if (!containerName) {
            this.setState({ responseContainerName: "Please enter container name", isContainerNameValid: false });
        } else if (containerName.length >= 100) {
            this.setState({ responseContainerName: " Maximum length is 100 character", isContainerNameValid: false });
        } else {
            this.setState({ responseContainerName: "", isContainerNameValid: true });
        }
    }

    onConfirmClick = (value) => {
        value.preventDefault();
        this.validateContainerName();
        const { isContainerNameValid, containerName } = this.state;
        if (isContainerNameValid) {
            let name = containerName.trim();
            this.setState({ isConfirmationShowing: true, containerName: name });
        }
    }

    onNoClick = () => {
        this.setState({ isConfirmationShowing: false })
    }

    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        const { containerName } = this.state;
        let storageLocation = {
            Description: containerName,
        }
        axios.post(ApiUrls.addStorageLocation, storageLocation, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    isConfirmationShowing: false, response: result.messageText,
                    isResponse: true, theme: "success",
                    containerName: "", responseContainerName: "", isContainerNameValid: false,
                });
            }
            else {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    isConfirmationShowing: false, response: result.messageText,
                    isResponse: true, theme: "danger",
                    containerName: "", responseContainerName: "", isContainerNameValid: false,
                });
            }
        }).catch((error) => {
            this.showError(error); 
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isYesBtnDisabled: false, yesBtnText: "Yes",
                isConfirmationShowing: false, response: "Something went wrong",
                isResponse: true, theme: "danger"});
        }
    }
}