import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput, FormTextarea
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { DateInput } from '@opuscapita/react-dates';
import { getSelectedDateFromDatePicker } from '../../utils/formatDateFromDatePicker';
import { getDateInGivenFormat } from '../../utils/formatDate';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class StockIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isYesBtnDisabled: false,
            isBottleMedicinesLoading: false,
            validSelectedBottleMedicines: false,
            validBatchNumber: false,
            validDateOfManufacture: false,
            validExpiryDate: false,
            validQuantity: false,
            validTandRref: false,
            showConfirmation: false,
            theme: "",
            response: "",
            bottleMedicineQuery: "",
            responseSelectedBottleMedicines: "",
            batchNumber: "",
            responseBatchNumber: "",
            dateOfManufacture: "",
            responseDateOfManufacture: "",
            expiryDate: "",
            responseExpiryDate: "",
            quantity: "",
            responseQuantity: "",
            TandRref: "",
            responseTandRref: "",
            today: "",
            yesBtnText: "Yes",
            bottleMedicines: [],
            selectedBottleMedicine: [],
            bottleMedicineStockItems: []
        };
        this.stockItem = React.createRef();
    }

    componentDidMount() {
        this.getTodayDate();
    }

    _cache = {};

    render() {
        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const queuedStockTableColumns =
            [
                {
                    text: 'Item',
                    formatter: (cell, row) => { return (`${row.item} (${row.quantity})`); },
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Batch number',
                    formatter: (cell, row) => { return row.batchNumber; },
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: (cell, row) => { return getDateInGivenFormat(row.expiryDate); },
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: '',
                    formatter: this.removeQueuedStockItem,
                    style: {
                        width: '10%'
                    }
                }
            ];
        const queuedStockSummaryTableColumns =
            [
                {
                    text: 'Item',
                    formatter: (cell, row) => { return (`${row.item} (${row.quantity})`); },
                    style: {
                        fontWeight: 'normal',
                        width: '50%'
                    }
                },
                {
                    text: 'Batch number',
                    formatter: (cell, row) => { return row.batchNumber; },
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
                {
                    text: 'Quantity',
                    formatter: (cell, row) => { return row.quantity },
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
            ];
        const {
            isResponse,
            theme,
            response,
            selectedBottleMedicine,
            bottleMedicines,
            isBottleMedicinesLoading,
            responseSelectedBottleMedicines,
            batchNumber,
            responseBatchNumber,
            dateOfManufacture,
            today,
            responseDateOfManufacture,
            expiryDate,
            responseExpiryDate,
            quantity,
            responseQuantity,
            TandRref,
            responseTandRref,
            bottleMedicineStockItems,
            isYesBtnDisabled,
            showConfirmation,
            yesBtnText
        } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                <Col sm="12" md="10" lg="8" style={mystyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/bottle-room">Bottle Room Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Stock In</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>STOCK IN</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#StockItem">Stock item in :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={selectedBottleMedicine} onChange={this.setBottleMedicine} options={bottleMedicines} isLoading={isBottleMedicinesLoading}
                                            id="pallet-typeahead"
                                            ref={this.stockItem}
                                            labelKey="description"
                                            minLength={3}
                                            onInputChange={this.handleBottleMedicineInputChange}
                                            onSearch={this.handleBottleMedicineSearch}
                                            placeholder="Search for bottle medicines"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.description}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        <span className="error-txt">{responseSelectedBottleMedicines}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#BatchNumber">Batch number :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="text" id="#BatchNumber" value={batchNumber} onChange={this.setBatchNumber} />
                                        <span className="error-txt">{responseBatchNumber}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#DateOfManufacture">Date of manufacture :<span className="error-txt"></span></label>
                                        <DateInput
                                            id="#DateOfManufacture"
                                            value={dateOfManufacture}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setDateOfManufacture}
                                            max={today}
                                            modifiers={{
                                                disabled: { after: new Date() }
                                            }}
                                        />
                                        <span className="error-txt">{responseDateOfManufacture}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#ExpiryDate">Expiry date :<span className="error-txt">*</span></label>
                                        <DateInput
                                            id="#ExpiryDate"
                                            value={expiryDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setExpiryDate}
                                            min={today}
                                            modifiers={{
                                                disabled: { before: new Date() }
                                            }}
                                        />
                                        <span className="error-txt">{responseExpiryDate}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#Quantity">Quantity :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#Quantity" value={quantity} onChange={this.setQuantity} min="1" />
                                        <span className="error-txt">{responseQuantity}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#TandRref">T & R Ref :<span className="error-txt">*</span></label>
                                        <FormTextarea size="lg" type="text" id="#TandRref" value={TandRref} onChange={this.setTandRref} />
                                        <span className="error-txt">{responseTandRref}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="success" size="lg" type="submit" onClick={this.onAddStockClick}>Add</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {bottleMedicineStockItems.length > 0 && (
                        <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                            <CardBody>
                                <CardTitle>Recently Queued Stocks to Add</CardTitle>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <BootstrapTable
                                            keyField='id'
                                            data={bottleMedicineStockItems}
                                            columns={queuedStockTableColumns}
                                            wrapperClasses="table-responsive"
                                            pagination={paginationFactory()}
                                        />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm="12" md="4" lg="4">
                                        <Button theme="primary" size="lg" type="submit" block onClick={this.onConfirmAddStockClick}>Confirm</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )}
                </Col>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {showConfirmation && (
                    <Modal open={showConfirmation} toggle={() => this.setState({ showConfirmation: showConfirmation })} centered={true} className={"modal-dialog"}>
                        <ModalBody className={"modal-body"}>
                            <Row>
                                <Col><h2>STOCK IN - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>
                                        Do you want to put following item(s) into the Bottle Room Stock?
                                    </label>
                                    <br />
                                    <BootstrapTable
                                        keyField='id'
                                        data={bottleMedicineStockItems}
                                        columns={queuedStockSummaryTableColumns}
                                        wrapperClasses="table-responsive"
                                        pagination={paginationFactory()}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={isYesBtnDisabled}>{yesBtnText}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getTodayDate = () => {
        var date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var today = [year, month, day].join('-');
        this.setState({ today: new Date(today) });
    }

    handleBottleMedicineInputChange = bottleMedicineQuery => {
        this.setState({ bottleMedicineQuery, isResponse: false });
    };

    handleBottleMedicineSearch = bottleMedicineQuery => {
        if (this._cache[bottleMedicineQuery]) {
            this.setState({ bottleMedicines: this._cache[bottleMedicineQuery].options });
            return;
        }

        this.setState({ isBottleMedicinesLoading: true });
        this.makeAndHandleRequestToGetBottleMedicines(bottleMedicineQuery).then(resp => {
            this._cache[bottleMedicineQuery] = { ...resp, page: 1 };
            this.setState({
                isBottleMedicinesLoading: false,
                bottleMedicines: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetBottleMedicines(bottleMedicineQuery, page = 1) {
        return axios.get(`${ApiUrls.getBottleMedicines}?query=${bottleMedicineQuery}&perpage=${PER_PAGE}`, authorizationHeader)
            .then(response => {
                var bottleMedicines = JSON.parse(response.data.data);
                var options = bottleMedicines.map(i => ({
                    id: i.Id,
                    description: i.Description,
                }));
                options = sortByAlphanumerically(options, 'description');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });
    }

    setBottleMedicine = (value) => {
        this.setState({ isResponse: false })
        if (value !== null && value.length > 0) {
            this.setState({
                selectedBottleMedicine: value, responseSelectedBottleMedicines: "",
                validSelectedBottleMedicines: true,
            })
        } else {
            this.setState({
                selectedBottleMedicine: [], responseSelectedBottleMedicines: "Please select a bottle medicines",
                validSelectedBottleMedicines: true,
            })
        }
    };

    validateBottleMedicine = () => {
        const { selectedBottleMedicine } = this.state;
        if (selectedBottleMedicine.length === 0) {
            this.setState({ responseSelectedBottleMedicines: "Please select a bottle medicines", validSelectedBottleMedicines: false });
        } else {
            this.setState({ responseSelectedBottleMedicines: "", validSelectedBottleMedicines: true });
        }
    };

    setBatchNumber = (value) => {
        this.setState({ batchNumber: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseBatchNumber: "Please enter batch number", validBatchNumber: false });
        } else {
            this.setState({ responseBatchNumber: "", validBatchNumber: true });
        }
    }

    validateBatchNumber = () => {
        this.setState({ isResponse: false });
        const { batchNumber } = this.state;
        if (!batchNumber) {
            this.setState({ responseBatchNumber: "Please enter batch number", validBatchNumber: false });
        } else {
            this.setState({ responseBatchNumber: "", validBatchNumber: true });
        }
    }

    setDateOfManufacture = (value) => {
        this.setState({ dateOfManufacture: value, isResponse: false });
        const { today } = this.state;
        if (value >= today) {
            this.setState({ responseDateOfManufacture: "Please enter past date", validDateOfManufacture: false });
        }
        else {
            this.setState({ responseDateOfManufacture: "", validDateOfManufacture: true });
        }
    }

    setExpiryDate = (value) => {
        this.setState({ expiryDate: value, isResponse: false });
        const { today } = this.state;
        if (!value) {
            this.setState({ responseExpiryDate: "Please enter expiry date", validExpiryDate: false });
        } else if (value <= today) {
            this.setState({ responseExpiryDate: "Please enter future date", validExpiryDate: false });
        }
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    validateExpiryDate = () => {
        this.setState({ isResponse: false });
        const { expiryDate, today } = this.state;
        if (!expiryDate) {
            this.setState({ responseExpiryDate: "Please enter expiry date", validExpiryDate: false });
        } else if (expiryDate <= today) {
            this.setState({ responseExpiryDate: "Please enter future date", validExpiryDate: false });
        }
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    setQuantity = (value) => {
        this.setState({ quantity: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseQuantity: "Please enter correct quantity", validQuantity: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseQuantity: "Please enter number", validQuantity: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseQuantity: "Please enter positive number", validQuantity: false });
        } else if (parseFloat(value.target.value) >= 100000000) {
            this.setState({ responseQuantity: "Quantity is limited to be between 1-10000000", validQuantity: false });
        }
        else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseQuantity: "Please enter full number", validQuantity: false });
        } else {
            this.setState({ responseQuantity: "", validQuantity: true });
        }
    }

    validateQuantity = () => {
        const { quantity, } = this.state;
        if (!quantity) {
            this.setState({ responseQuantity: "Please enter correct quantity", validQuantity: false });
        } else if (isNaN(quantity)) {
            this.setState({ responseQuantity: "Please enter number", validQuantity: false });
        } else if (parseFloat(quantity) <= 0) {
            this.setState({ responseQuantity: "Please enter positive number", validQuantity: false });
        } else if (parseFloat(quantity) >= 100000000) {
            this.setState({ responseQuantity: "Quantity is limited to be between 1-10000000", validQuantity: false });
        } else if (Number.isInteger(parseFloat(quantity)) == false) {
            this.setState({ responseQuantity: "Please enter full number", validQuantity: false });
        } else {
            this.setState({ responseQuantity: "", validQuantity: true });
        }
    }

    setTandRref = (value) => {
        this.setState({ TandRref: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseTandRref: "Please enter T & R Ref", validTandRref: false });
        } else {
            this.setState({ responseTandRref: "", validTandRref: true });
        }
    }

    validateTandRref = () => {
        this.setState({ isResponse: false });
        const { TandRref } = this.state;
        if (!TandRref) {
            this.setState({ responseTandRref: "Please enter T & R Ref", validTandRref: false });
        } else {
            this.setState({ responseTandRref: "", validTandRref: true });
        }
    }

    onAddStockClick = (value) => {
        value.preventDefault();
        this.validateBottleMedicine();
        this.validateBatchNumber();
        this.validateExpiryDate();
        this.validateQuantity();
        this.validateTandRref();
        const {
            validSelectedBottleMedicines,
            validBatchNumber,
            validExpiryDate,
            validQuantity,
            validTandRref,
            selectedBottleMedicine,
            batchNumber,
            dateOfManufacture,
            expiryDate,
            quantity,
            TandRref,
            bottleMedicineStockItems
        } = this.state;
        if (validSelectedBottleMedicines &&
            validBatchNumber &&
            validExpiryDate &&
            validQuantity &&
            validTandRref) {
            let bottleMedicineStockItem = {
                id: Date.now() + Math.random(),
                itemId: parseInt(selectedBottleMedicine[0].id),
                item: selectedBottleMedicine[0].description.toString(),
                batchNumber: batchNumber,
                manufactureDate: getSelectedDateFromDatePicker(dateOfManufacture),
                expiryDate: getSelectedDateFromDatePicker(expiryDate),
                quantity: parseInt(quantity),
                TandRref: TandRref
            };
            let stockItems = [...bottleMedicineStockItems, bottleMedicineStockItem];
            console.log(stockItems);
            this.setState({
                bottleMedicineStockItems: stockItems, selectedBottleMedicine: [], dateOfManufacture: "",
                expiryDate: "", quantity: "", TandRref: "", batchNumber: "", bottleMedicines: [], bottleMedicineQuery: "",
                validSelectedBottleMedicines: false, validBatchNumber: false,
                validDateOfManufacture: false, validExpiryDate: false,
                validQuantity: false, validTandRref: false
            });
            this.stockItem.current.clear();
        }
    }

    removeQueuedStockItem = (cell, row) => {
        return (
            <Button theme="danger" onClick={e => this.handleQueuedStockItemRemove(e, row)} style={{ width: "100%", height: "100%" }}>
                X
            </Button>);
    };

    handleQueuedStockItemRemove = (e, row) => {
        console.log(row);
        const { bottleMedicineStockItems } = this.state;
        let queuedStockItems = [...bottleMedicineStockItems];
        queuedStockItems = queuedStockItems.filter(item => item.id !== row.id);
        this.setState({ bottleMedicineStockItems: queuedStockItems });
    };

    onConfirmAddStockClick = () => {
        this.setState({ showConfirmation: true });
    };

    onNoClick = () => {
        this.setState({ showConfirmation: false })
    }

    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        const { bottleMedicineStockItems } = this.state;
        let queuedBottleRoomItemsToStock = bottleMedicineStockItems.map(item => ({
            BottleMedicinesId: item.itemId,
            Quantity: item.quantity,
            BatchNumber: item.batchNumber,
            ExpiryDate: item.expiryDate,
            ManufactureDate: item.manufactureDate,
            TandRref: item.TandRref
        }));
        axios.post(ApiUrls.addBottleRoomItemsToStock, queuedBottleRoomItemsToStock, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, response: response.data.messageText,
                    isResponse: true, theme: "success", bottleMedicineStockItems: [],
                    selectedBottleMedicine: [], bottleMedicines: [], dateOfManufacture: "",
                    expiryDate: "", quantity: "", TandRref: "", batchNumber: "", bottleMedicineQuery: "",
                    validSelectedBottleMedicines: false, validBatchNumber: false,
                    validDateOfManufacture: false, validExpiryDate: false,
                    validQuantity: false, validTandRref: false
                });
            }
            else {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, response: response.data.messageText,
                    isResponse: true, theme: "danger",
                    selectedBottleMedicine: [], bottleMedicines: [], dateOfManufacture: "",
                    expiryDate: "", quantity: "", TandRref: "", batchNumber: "", bottleMedicineQuery: "",
                    validSelectedBottleMedicines: false, validBatchNumber: false,
                    validDateOfManufacture: false, validExpiryDate: false,
                    validQuantity: false, validTandRref: false
                });
            }
            this.stockItem.current.clear();
        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isYesBtnDisabled: false, yesBtnText: "Yes",
                showConfirmation: false, response: "Something went wrong",
                isResponse: true, theme: "danger"
            });
        }
    }
}