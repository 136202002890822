import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, FormGroup,
    Alert, FormInput
} from "shards-react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class DrugRecall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
            isSearchBtnPress: false,
            isBatchNumberValid: false,
            isResponse: false,
            batchNumber: "",
            responseBatchNumber: "",
            today: "",
            theme: "",
            response: "",
            listStock: []
        };
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response, batchNumber,
            responseBatchNumber, isDataLoading, isSearchBtnPress, listStock } = this.state;
        const columnsListStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'pallet_number',
                    style: {
                        fontWeight: 'normal',
                        width:'15%'
                    }
                },
                {
                    text: 'Description',
                    formatter: this.description,
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.expiryDate,
                    style: {
                        fontWeight: 'normal',
                        width: '15%'

                    }
                },             
                {
                    text: 'Batch number',
                    dataField: 'batch_number',
                    style: {
                        fontWeight: 'normal' ,
                        width: '15%'
                    }
                },
                {
                    text: 'Location',
                    dataField: 'location',
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
            ];
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Drug Recall</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>DRUG RECALL</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#batchNumber">Batch number :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="text" id="#batchNumber" value={batchNumber} onChange={this.setBatchNumber} />
                                        <span className="error-txt">{responseBatchNumber}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (
                                <>
                                    {isSearchBtnPress && (
                                        <>
                                            {listStock.length !== 0 && (
                                                <>                                               
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={listStock}
                                                        columns={columnsListStock}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                    <br />
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.onPrintClick}>Print</Button>
                                                        </Col>
                                                        <Col sm="12" md="6" lg="6">
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {listStock.length === 0 && (
                                                <><Alert theme="warning">No Data Found</Alert></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
            </>
        );
    }

    onConfirmClick = () => {
        this.validateBatchNumber();
        const { isBatchNumberValid, batchNumber } = this.state;
        if (isBatchNumberValid) {
            this.setState({ isDataLoading: true, listStock: [], isSearchBtnPress: true })
            axios.get(`${ApiUrls.drugRecall}?batchNumber=${batchNumber}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let listStock = JSON.parse(result.data);
                    let sortedListStock = sortByAlphanumerically(listStock, 'pallet_number');
                    this.setState({ listStock: sortedListStock, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
    }

    expiryDate = (cell, row) => {
        let expiryDate = row.expiry_date;
        return (<div>{expiryDate ? getDateInGivenFormat(expiryDate.slice(0, 10)) : ''}</div>);
    }

    description = (cell, { description, quantity}) => {
        return (<div>
            {description} ({quantity})
        </div>);
    }

    onPrintClick = () => {
        let a = window.open('', '', 'height=500, width=500');
        const { listStock } = this.state;
        a.document.write(`<html>`);
        a.document.write(`<body>`);
        a.document.write(`<h1>DRUG RECALL</h1>`);
        a.document.write(`<br>`);
        a.document.write(`<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">`);
        a.document.write(`<tr>`);
        a.document.write(`<th style="border:1px solid black; border-collapse: collapse">Pallet number</th>`);
        a.document.write(`<th style="border:1px solid black; border-collapse: collapse">Description</th>`);
        a.document.write(`<th style="border:1px solid black; border-collapse: collapse">Expiry date</th>`);
        a.document.write(`<th style="border:1px solid black; border-collapse: collapse">Batch number</th>`);
        a.document.write(`<th style="border:1px solid black; border-collapse: collapse">Location</th>`);
        a.document.write(`</tr>`);
        {
            listStock.map((box) => {
                a.document.write(`<tr key=${box.pallet_number}>`);
                a.document.write(`<td style="border:1px solid black; border-collapse: collapse">${box.pallet_number}</td>`);
                a.document.write(`<td style="border:1px solid black; border-collapse: collapse">${box.description} (${box.quantity})</td>`);
                a.document.write(`<td style="border:1px solid black; border-collapse: collapse">${getDateInGivenFormat(box.expiry_date?.slice(0, 10))}</td>`);
                a.document.write(`<td style="border:1px solid black; border-collapse: collapse">${box.batch_number}</td>`);
                a.document.write(`<td style="border:1px solid black; border-collapse: collapse">${box.location}</td>`);
                a.document.write(`</tr>`);
            })
        }
        a.document.write(`</table>`);
        a.document.write(`</body></html>`);
        a.document.close();
        a.print();
    }
   
    setBatchNumber = ({ target }) => {
        this.setState({ batchNumber: target.value});
        !(target.value)
            ? this.setState({ responseBatchNumber: "Please enter batch number", isBatchNumberValid: false })
            : this.setState({ responseBatchNumber: "", isBatchNumberValid: true });
    }

    validateBatchNumber = () => {
        const { batchNumber } = this.state;
        !(batchNumber)
            ? this.setState({ responseBatchNumber: "Please enter batch number", isBatchNumberValid: false })
            : this.setState({ responseBatchNumber: "", isBatchNumberValid: true });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}