import React, { Component } from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    Button,
    FormGroup, Container, Row, Col, Breadcrumb, BreadcrumbItem, Form, Modal, ModalBody, FormInput, Alert
} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class NewPalletMedical extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPalletCategory: [],
            isPrintView: false,
            showConfirmation: false,
            isAdvancedSearch: false,
            palletNumber: "",
            palletCategories: [],
            isYesBtnDisabled: false,
            yesBtnText: "Yes",
            isResponse: false,
            theme: "",
            response: "",
        };
        this.Pallet = React.createRef();
    }

    componentDidMount() {

        //get the pallet categories
        this.getPalletCategories();
        if (this.state.showConfirmation) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    render() {
        return (
            <Container fluid className="dr-example-container">
                <Card style={{ maxWidth: "50rem", marginTop: "2%", marginLeft: "auto", marginRight: "auto" }}>
                    <CardTitle>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/medical-disposables">Medical Disposable Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Start New Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <CardBody>

                        <Form>
                            <Row>
                                <Col sm="12" lg="12">
                                    <h4>CREATE A NEW PALLET</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6" lg="6">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#palletType">Type of Pallet:</label>
                                        <Typeahead size="lg" value={this.state.selectedPalletCategory}
                                            id="palletcategory-typeahead"
                                            labelKey="description"
                                            onChange={this.setPalletCategory}
                                            options={this.state.palletCategories}
                                            placeholder=""
                                            ref={this.Pallet}
                                        />

                                        {(this.state.selectedPalletCategory === null) && (
                                            <span className="error-txt">Please select a pallet category</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.selectedPalletCategory !== null && this.state.selectedPalletCategory.length > 0 && (
                                <Row>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#palletNumberLabel">Pallet Number: </label>
                                            <FormInput size="lg" id="#palletNumber" value={this.state.palletNumber} disabled={true} />
                                        </FormGroup>

                                    </Col>
                                </Row>
                            )}
                            {this.state.selectedPalletCategory !== null && this.state.selectedPalletCategory.length > 0 && (
                                <Row>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#palletLocLabel">Location: </label>
                                            <FormInput size="lg" id="#palletLoc" value={`Medical Disposables Room(MDR)`} disabled={true} />
                                        </FormGroup>

                                    </Col>
                                </Row>
                            )}

                        </Form>

                        <Form>
                            <Row>
                                <Col sm="12" lg="12">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>


                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>CREATE A NEW PALLET - CONFIRMATION</h2></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large" }}>{`Do you want to create Pallet Number ${this.state.palletNumber}?`}</label>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6" xs="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" disabled={this.isYesBtnDisabled} onClick={this.onYesClick}>{this.state.yesBtnText}</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6" xs="6">
                                        <FormGroup>
                                            <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick}>No</Button>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )
                }
                {this.state.isPrintView && (
                    <Modal open={this.state.isPrintView} toggle={() => this.setState({ isPrintView: !this.state.isPrintView })} centered={true} className={"custom-modal"}>

                        <ModalBody>
                            <Form>
                                <Container className="dr-example-container" fluid={true}>
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <FormGroup>
                                                <label style={{ fontSize: "100px" }}>{this.state.palletNumber}</label>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col sm="6" lg="6" xs="6">
                                            <FormGroup>
                                                <Button theme="primary" size="lg" onClick={this.printBtnClick}>Print</Button>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" lg="6" xs="6">
                                            <FormGroup>
                                                <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick}>No</Button>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </Container>
                            </Form>
                        </ModalBody>

                    </Modal>
                )}
            </Container>

        );
    }

    getPalletCategories = () => {
        axios.get(`${ApiUrls.getRelevantPalletCategories}?productCategories=${"MEDX,MDISP"}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var palletCategories = JSON.parse(response.data.data);
                palletCategories = sortByAlphanumerically(palletCategories, 'description');
                console.log(palletCategories);
                this.setState({ palletCategories: palletCategories });
            }
        }).catch((error) => {
            console.log(error);
            this.showError(error);  
        });
    };

    setPalletCategory = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            axios.get(`${ApiUrls.getPalletNumber}?id=${value[0].id}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var palletNumber = JSON.parse(response.data.data);
                    this.setState({ palletNumber: palletNumber, selectedPalletCategory: value });

                }
            }).catch((error) => {
                console.log(error);
                this.showError(error);  
            });
        }
        else {
            this.setState({ selectedPalletCategory: [] });
        }
    };

    onConfirmClick = () => {
        if (this.state.selectedPalletCategory.length > 0) {
            document.body.style.overflow = 'unset';
            this.setState({
                showConfirmation: true
            });
        }
        else {

            this.setState({
                showConfirmation: false,

            });
        }
    };
    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        //submit details
        var newPalletInfo = {
            PalletNumber: this.state.palletNumber,
            StorageLocationId: 8,
            SecondaryStorageLocationId: 0,
        };
        axios.post(ApiUrls.createPallet, newPalletInfo, authorizationHeader).then((response) => {
            console.log(response);
            if (response.data.isSuccess) {
                this.setState({
                    isPrintView: true, isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, isResponse: true, theme: "success",
                    response: "Now Display the pallet label above the pallet being created ",
                    selectedPalletCategory: [],
                });
                this.Pallet.current.clear();
            }
            else {
                this.setState({
                    isPrintView: false, isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false,
                    isResponse: true, theme: "danger", response: response.data.messageText,
                });
            }

        }).catch((error) => {          
            console.log(error);
            this.showError(error);  
        });
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false, isPrintView: false })
    }

    printBtnClick = () => {
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > <h1 style="font-size: 50vw; display: inline"> ' + this.state.palletNumber + '</h1><br>');
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        this.setState({ isPrintView: false });
    }

    showError = (error) => {
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isPrintView: false, isResponse: true, theme: "danger", response: "Something went wrong",
                isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
            });
        }
    }
}