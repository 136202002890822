import React, { Component } from 'react';
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactExport from "react-export-excel";
import {
    Alert, Badge, Breadcrumb,
    BreadcrumbItem, Button, Card, CardBody, CardTitle,
    Col, FormGroup, Row
} from "shards-react";
import { ApiUrls } from "../../environments/apiUrls";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { StorageLocation } from "../common/StorageLocation";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class FoodList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidPrimaryLocation: false,
            isValidSecondaryLocation: false,
            isSecondaryLocationLoading: false,
            isDataLoading: false,
            isSearchBtnPressed: false,
            isResponse: false,
            errorResponseForSearch: "",
            errorResponseForLocationSelection: '',
            theme: "",
            response: "",
            selectedLocation: [],
            selectedPrimaryLocation: [],
            selectedSecondaryLocation: [],
            listStock: []
        };
        this.storageLocation = React.createRef();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const columnsListStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'pallet_number',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Description',
                    formatter: this.customizeDescription,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.customizeExpiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },               
                {
                    text: 'Location',
                    dataField: 'location',
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];
        const { isResponse, theme, response, selectedLocation, errorResponseForLocationSelection,
            errorResponseForSearch, isDataLoading, isSearchBtnPressed, listStock } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Create Food List</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>FOOD LIST</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <StorageLocation ref={this.storageLocation}
                                getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                            />
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleAdd}>Add</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <Card>
                                        <CardBody style={{ border: "2px solid", borderRadius: "5px", paddingLeft: "0px", paddingRight: "0px" }}>
                                            <FormGroup>
                                                {selectedLocation.map((location) => {
                                                    return (<><Badge outline size="lg" style={{ fontSize: "20px", margin: "1%" }}>{location.location}<i class='fa fa-close' style={{ fontSize: "20px", color: "red" }} onClick={e => this.removeLocation(e, location)}>&nbsp; X</i></Badge> &nbsp;</>)
                                                })}
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <span className="error-txt">{errorResponseForLocationSelection}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <span className="error-txt">{errorResponseForSearch}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (
                                <>
                                    {isSearchBtnPressed && (
                                        <>
                                            {listStock.length != 0 && (
                                                <>
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={listStock}
                                                        columns={columnsListStock}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                    <br />
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <ExcelFile type="button" element={<Button style={{ margin: "1%" }} size="lg" block>Save/Export</Button>} >
                                                                <ExcelSheet data={listStock} name="listStock">
                                                                    <ExcelColumn label="Pallet Number" value="pallet_number" />
                                                                    <ExcelColumn label="C88 Code" value="c88_code" />
                                                                    <ExcelColumn label="Description" value="description" />
                                                                    <ExcelColumn label="Batch Number" value="batch_number" />
                                                                    <ExcelColumn label="Quantity" value="quantity" />
                                                                    <ExcelColumn label="Expiry Date" value={(col) => getDateInGivenFormat(col.expiry_date?.slice(0, 10)) } />                                                                  
                                                                    <ExcelColumn label="Location" value="location" />
                                                                    <ExcelColumn label="Price £" value="price" />
                                                                </ExcelSheet>
                                                            </ExcelFile>
                                                        </Col>
                                                        <Col sm="12" md="6" lg="6">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.handlePrint}>Print</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {listStock.length == 0 && (
                                                <><Alert theme="warning">No Data Found</Alert></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
            </>
        );
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value, errorResponseForSearch: "", errorResponseForLocationSelection: "" });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ isValidPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ isValidSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    handleAdd = () => {
        this.storageLocation.current.validatePrimaryLocation();
        const { isValidPrimaryLocation, isValidSecondaryLocation, isSecondaryLocationLoading, selectedPrimaryLocation, selectedSecondaryLocation, selectedLocation } = this.state;
        if (!isSecondaryLocationLoading && !isValidSecondaryLocation) {
            this.storageLocation.current.validateSecondaryLocation();
        }
        if (isValidPrimaryLocation && isValidSecondaryLocation && !isSecondaryLocationLoading) {
            let location = (selectedSecondaryLocation.length !== 0)
                ?
                {
                    primary_id: parseInt(selectedPrimaryLocation[0].id),
                    secondary_id: parseInt(selectedSecondaryLocation[0].Id),
                    primary_location: selectedPrimaryLocation[0].description,
                    secondary_location: selectedSecondaryLocation[0].Name,
                    location: `${selectedPrimaryLocation[0].description}(${selectedSecondaryLocation[0].Name})`
                }
                :
                {
                    primary_id: parseInt(selectedPrimaryLocation[0].id),
                    secondary_id: 0,
                    primary_location: selectedPrimaryLocation[0].description,
                    secondary_location: null,
                    location: selectedPrimaryLocation[0].description
                };
            let index = selectedLocation.findIndex(x => x.primary_id === location.primary_id && x.secondary_id === location.secondary_id);
            if (index === -1) {
                selectedLocation.push(location);
                this.setState({ selectedLocation, errorResponseForLocationSelection: "" });
            } else {
                this.setState({ errorResponseForLocationSelection: "Location have been already selected" });
            }
            this.storageLocation.current.resetStorageLocation();
        }
    }

    handleSearch = () => {
        const { selectedLocation } = this.state;
        if (selectedLocation.length !== 0) {
            this.setState({ isDataLoading: true, listStock: [], isSearchBtnPressed: true, errorResponseForSearch: "" });
            let location = JSON.stringify(selectedLocation);
            axios.get(`${ApiUrls.getFoodList}?loc=${location}`, authorizationHeader).then(({ data: result}) => {
                if (result.isSuccess) {
                    let listStock = JSON.parse(result.data);
                    let sortedListStock = sortByAlphanumerically(listStock, 'pallet_number');
                    this.setState({ listStock: sortedListStock, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
        else {
            this.setState({ errorResponseForSearch: "Please select location to search" });
        }
    }

    removeLocation = (e, location) => {
        const { selectedLocation } = this.state;
        let index = selectedLocation.findIndex(x => x.primary_id === location.primary_id && x.secondary_id === location.secondary_id);
        selectedLocation.splice(index, 1);
        this.setState({ selectedLocation });
    }

    customizeExpiryDate = (cell, row) => {
        let expiryDate = row.expiry_date;
        let date = expiryDate ? getDateInGivenFormat(expiryDate.slice(0, 10)) : '';
        return (<div>{date}</div>);
    }

    customizeDescription = (cell, row) => {
        return (
            <div>
                {row.description} ({row.quantity})
            </div>
        );
    }

    handlePrint = () => {
        let printWindow = window.open('', '', 'height=500, width=500');
        const { listStock } = this.state;
        printWindow.document.write(`
            <html>
            <body>
                <h1>FOOD LIST</h1>
                <br>
                <table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">
                    <tr>  
                        <th style="border:1px solid black; border-collapse: collapse">Pallet number</th>
                        <th style="border:1px solid black; border-collapse: collapse">Description</th>
                        <th style="border:1px solid black; border-collapse: collapse">Batch number</th>
                        <th style="border:1px solid black; border-collapse: collapse">Expiry date</th>
                        <th style="border:1px solid black; border-collapse: collapse">Location</th>
                        <th style="border:1px solid black; border-collapse: collapse">Price £</th>
                    </tr>
        `);
        listStock.map((box) => {
            printWindow.document.write(`
                <tr>
                    <td style="border:1px solid black; border-collapse: collapse">${box.pallet_number}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.description} (${box.quantity})</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.batch_number}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${getDateInGivenFormat(box.expiry_date?.slice(0, 10))}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.location}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.price.toFixed(2)}</td>
                </tr>
            `);

        });
        printWindow.document.write(`
            </table>
            </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}