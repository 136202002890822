import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, FormGroup,
    Alert, FormCheckbox
} from "shards-react";
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { Typeahead } from 'react-bootstrap-typeahead';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const exchangeRate = 1.13;

export class Exportlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidContainer: false,
            isDataLoading: false,
            isSearchBtnPressed: false,
            isChecked: true,
            isResponse: false,
            totalWeight: 0,
            totalBottleMedicinePalletWeight: 0,
            theme: "",
            response: "",
            responseContainer: "",
            containers: [],
            selectedContainer: [],
            listStock: [],
            exportList: [],
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>]
        };
    }

    componentDidMount() {
        this.getContainers();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const categoryColumns =
            [
                {
                    text: 'Category',
                    formatter: this.getProductCategory,
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Total weight (kg)',
                    formatter: (cell, row) => { return row.weight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '20%'
                    }
                },
                {
                    text: 'Full total value (£)',
                    formatter: (cell, row) => { return row.total_value_in_pound.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
                {
                    text: 'Wholesale total value (£)',
                    formatter: (cell, row) => { return row.wholesale_value_in_pound.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
                {
                    text: 'Full total value (€)',
                    formatter: (cell, row) => { return row.total_value_in_euro.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
                {
                    text: 'Wholesale total value (€)',
                    formatter: (cell, row) => { return row.wholesale_value_in_euro.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
            ];
        const columns =
            [
                {
                    text: 'Item',
                    formatter: this.getItemDescription,
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Weight (kg)',
                    formatter: (cell, row) => { return row.weight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '20%'
                    }
                },
                {
                    text: 'Full total value (£)',
                    formatter: (cell, row) => { return row.total_value_in_pound.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
                {
                    text: 'Wholesale total value (£)',
                    formatter: (cell, row) => { return row.wholesale_value_in_pound.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
                {
                    text: 'Full total value (€)',
                    formatter: (cell, row) => { return row.total_value_in_euro.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
                {
                    text: 'Wholesale total value (€)',
                    formatter: (cell, row) => { return row.wholesale_value_in_euro.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'); },
                    style: {
                        fontWeight: 'normal',
                        width: '12.5%'
                    }
                },
            ];
        const { isResponse, theme, response, selectedContainer,
            containers, emptyLabel, responseContainer, isDataLoading,
            isSearchBtnPressed, listStock, isChecked, exportList, totalWeight } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Create Export List</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>EXPORT LIST</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#Container">Select shipping container:<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" selected={selectedContainer}
                                            id="id"
                                            labelKey="description"
                                            onChange={this.setContainer}
                                            options={containers}
                                            placeholder=""
                                            ref={this.containers}
                                            emptyLabel={emptyLabel}
                                        />
                                        <span className="error-txt">{responseContainer}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (
                                <>
                                    {isSearchBtnPressed && (
                                        <>
                                            {listStock.length !== 0 && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <FormGroup >
                                                                <FormCheckbox size="lg" id="myBtn" checked={isChecked} onChange={this.handleCheck}>Totals only</FormCheckbox>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <h5>Total weight : <b>{totalWeight.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,')}kg</b></h5>
                                                        </Col>
                                                    </Row>
                                                    {isChecked && (
                                                        <BootstrapTable
                                                            keyField='id'
                                                            data={exportList[1]}
                                                            columns={categoryColumns}
                                                            wrapperClasses="table-responsive"
                                                            pagination={paginationFactory()}
                                                        />
                                                    )}
                                                    {!isChecked && (<>
                                                        {exportList[1].length !== 0 && (
                                                            <>
                                                                {exportList[1].map((stock) => {
                                                                    return (
                                                                        <>
                                                                            <h5><b>{stock.product_category_description}:</b></h5>
                                                                            <h5>Total {stock.product_category_description} &#163;{(stock.total_value_in_pound).toFixed(2)}</h5>
                                                                            {stock.product_category_id == 10 && (<h5>Total {stock.product_category_description} weight {(stock.weight).toFixed(2)}kg </h5>)}
                                                                            <BootstrapTable
                                                                                keyField='id'
                                                                                data={stock.product}
                                                                                columns={columns}
                                                                                wrapperClasses="table-responsive"
                                                                                pagination={paginationFactory()}
                                                                            />
                                                                            <hr />
                                                                        </>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                    </>)}
                                                    <br />
                                                    <Row>
                                                        <Col sm="12" md="4" lg="4">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={() => window.location.href = `/adjust-prices?Container=${selectedContainer[0].id}`}>Adjust Prices</Button>
                                                        </Col>
                                                        <Col sm="12" md="4" lg="4">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.onPrintClick}>Print</Button>
                                                        </Col>
                                                        <Col sm="12" md="4" lg="4">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.exportTableToExcel}>Export</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {listStock.length == 0 && (
                                                <><Alert theme="warning">No Data Found</Alert></>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
            </>
        );
    }

    getContainers = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const container = urlParams.get('Container');
        if (container != null) {
            this.setState({ isDataLoading: true, isSearchBtnPressed: true });
        }
        axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let containers = JSON.parse(result.data);
                let sortedContainers = sortByAlphanumerically(containers, 'description');
                this.setState({ containers: sortedContainers, selectedContainer: [], emptyLabel: "No matches found" });
                if (container != null) {
                    sortedContainers.map((result) => {
                        if (result.id == parseInt(container)) {
                            let select = [];
                            select.push(result);
                            this.setState({ selectedContainer: select, isValidContainer: true });
                            this.handleSearch();
                        }
                    });
                }
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setContainer = (value) => {
        this.setState({ isResponse: false });
        (value != null && value.length > 0)
            ? this.setState({ responseContainer: "", selectedContainer: value, isValidContainer: true })
            : this.setState({ responseContainer: "Please select a container", selectedContainer: [], isValidContainer: false });
    };

    validateContainer = () => {
        this.setState({ isResponse: false });
        const { selectedContainer } = this.state;
        (selectedContainer != null && selectedContainer.length > 0)
            ? this.setState({ responseContainer: "" })
            : this.setState({ responseContainer: "Please select a container" });
    };

    handleCheck = () => {
        this.setState({ isDataLoading: true });
        const { isChecked } = this.state;
        isChecked ? this.setState({ isDataLoading: false, isChecked: false }) : this.setState({ isDataLoading: false, isChecked: true });
    }

    handleSearch = () => {
        this.validateContainer();
        const { isValidContainer, selectedContainer } = this.state;
        if (isValidContainer) {
            this.setState({ isDataLoading: true, isSearchBtnPressed: true, exportList: [], listStock: [] });
            axios.get(`${ApiUrls.exportlist}?containerId=${parseInt(selectedContainer[0].id)}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let listStock = JSON.parse(result.data).exportlist;
                    let totalWeight = JSON.parse(result.data).totalWeight;
                    let totalBottleMedicinePalletWeight = JSON.parse(result.data).totalBottleMedicinePalletWeight;
                    this.setState({ listStock, totalWeight, totalBottleMedicinePalletWeight });
                    this.exportlistSummary();
                    this.exportlistByCategoryOnlyTotal();
                    this.exportlistByCategory();
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
    }

    exportlistSummary = () => {
        let totalQuantity = 0;
        let totalValue = 0;
        let wholesaleValue = 0;
        const { listStock } = this.state;
        listStock.map((item) => {
            totalQuantity += item.quantity;
            totalValue += item.total_value;
            wholesaleValue += item.wholesale_value;
        });
        let summary = {
            total_quantity: totalQuantity,
            total_value_in_pound: totalValue,
            wholesale_value_in_pound: wholesaleValue,
            total_value_in_euro: totalValue * exchangeRate,
            wholesale_value_in_euro: wholesaleValue * exchangeRate
        };
        let exportList = [];
        exportList.push(summary);
        this.setState({ exportList });
    }

    exportlistByCategoryOnlyTotal = () => {
        const { listStock, exportList, totalBottleMedicinePalletWeight } = this.state;
        let exportlistByCategory = [];
        listStock.map((stock) => {
            let item = {
                product_category_description: stock.product_category_description,
                product_category_id: stock.product_category_id,
                total_quantity: stock.quantity,
                total_value_in_pound: stock.total_value,
                wholesale_value_in_pound: stock.wholesale_value,
                total_value_in_euro: stock.total_value * exchangeRate,
                wholesale_value_in_euro: stock.wholesale_value * exchangeRate,
                weight: stock.weight,
                product: []
            };
            let index = exportlistByCategory.findIndex(x => x.product_category_id === stock.product_category_id);
            if (index == -1) {
                exportlistByCategory.push(item);
            } else {
                exportlistByCategory[index].total_quantity += item.total_quantity;
                exportlistByCategory[index].total_value_in_pound += item.total_value_in_pound;
                exportlistByCategory[index].wholesale_value_in_pound += item.wholesale_value_in_pound;
                exportlistByCategory[index].total_value_in_euro += item.total_value_in_euro;
                exportlistByCategory[index].wholesale_value_in_euro += item.wholesale_value_in_euro;
                exportlistByCategory[index].weight += item.weight;
            }
        });
        let bottleMedicineContent = exportlistByCategory.find(x => x.product_category_id == 10);
        if (bottleMedicineContent) {
            bottleMedicineContent.weight = totalBottleMedicinePalletWeight;
            exportlistByCategory = exportlistByCategory.map(x => (x.product_category_id == 10) ? bottleMedicineContent : x);
            console.log(exportlistByCategory);
        }
        let sortedExportlistByCategory = sortByAlphanumerically(exportlistByCategory, 'product_category_description');
        exportList.push(sortedExportlistByCategory);
        this.setState({ exportList });
    }

    exportlistByCategory = () => {
        const { exportList, listStock } = this.state;
        exportList[1].map((category) => {
            const product = [];
            listStock.map((stock) => {
                if (stock.product_category_id == category.product_category_id) {
                    let item = {
                        is_pallet: stock.is_pallet,
                        item_description: stock.item_description,
                        sort_item_description: stock.item_description.trim().toLowerCase(),
                        item_id: stock.item_id,
                        pallet_category_id: stock.pallet_category_id,
                        total_quantity: stock.quantity,
                        total_value_in_pound: stock.total_value,
                        wholesale_value_in_pound: stock.wholesale_value,
                        total_value_in_euro: stock.total_value * exchangeRate,
                        wholesale_value_in_euro: stock.wholesale_value * exchangeRate,
                        c88_code: stock.c88_code,
                        batch_number: stock.batch_number,
                        expiry_date: stock.expiry_date,
                        weight: stock.weight
                    };
                    let index = product.findIndex(x => x.is_pallet === stock.is_pallet && x.item_id === stock.item_id && x.pallet_category_id === stock.pallet_category_id);
                    if (index == -1) {
                        product.push(item);
                    } else {
                        product[index].total_quantity += item.total_quantity;
                        product[index].total_value_in_pound += item.total_value_in_pound;
                        product[index].wholesale_value_in_pound += item.wholesale_value_in_pound;
                        product[index].total_value_in_euro += item.total_value_in_euro;
                        product[index].wholesale_value_in_euro += item.wholesale_value_in_euro;
                        product[index].weight += item.weight;
                    }
                }
            });
            let sortedProduct = sortByAlphanumerically(product, 'sort_item_description');
            category.product = sortedProduct;
        });
        this.setState({ exportList, isDataLoading: false });
    }

    getProductCategory = (cell, row) => {
        return (<div> {row.product_category_description} ({row.total_quantity}) </div>);
    }

    getItemDescription = (cell, row) => {
        return (<div> {row.item_description} ({row.total_quantity}) </div>);
    }

    getFormatedPrice(price) {
        return parseFloat(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
    }

    onPrintClick = () => {
        const { selectedContainer, totalWeight, isChecked, exportList } = this.state;
        let a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write('<h1>EXPORT LIST</h1>');
        a.document.write('<h3>Container: ' + selectedContainer[0].description + ' </h3>');
        a.document.write('<h3>Total weight: ' + totalWeight.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') + 'kg</h3>');
        a.document.write('<br>');
        if (isChecked) {
            a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            a.document.write('<tr>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:25%">Category</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:15%">Total weight (kg)</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">Number boxes/ sacks/ pallets</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">Full total value (£)</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">Wholesale total value (£)</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">Full total value (€)</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">Wholesale total value (€)</th>');
            a.document.write('</tr>');
            exportList[1].map((category) => {
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:25%">' + category.product_category_description + '</td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:15%">' + category.weight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:10%">' + category.total_quantity + '</td>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(category.total_value_in_pound) + '</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(category.wholesale_value_in_pound) + '</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(category.total_value_in_euro) + '</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(category.wholesale_value_in_euro) + '</th>');
                a.document.write('</tr>');
            });
            a.document.write('<tr>');
            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:25%"  >Total</td>');
            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:10%"  >' + totalWeight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</td>');
            a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:15%"  >' + exportList[0].total_quantity + '</td>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(exportList[0].total_value_in_pound) + '</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(exportList[0].wholesale_value_in_pound) + '</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(exportList[0].total_value_in_euro) + '</th>');
            a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:12.5%">' + this.getFormatedPrice(exportList[0].wholesale_value_in_euro) + '</th>');
            a.document.write('</tr>');
            a.document.write('</table>');
        }
        else{
            exportList[1].map((stock) => {
                a.document.write('<h3>' + stock.product_category_description + ':</h3>');
                if (stock.product_category_id == 10) a.document.write('<h3> Total ' + stock.product_category_description + ' weight ' + stock.weight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + 'kg</h3>');
                a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
                a.document.write('<tr>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Item</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Weight (kg)</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">C88 code</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">Number boxes/ sacks/ pallets</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">Full total value (£)</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">Wholesale total value (£)</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">Full total value (€)</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">Wholesale total value (€)</th>');
                a.document.write('</tr>');
                stock.product.map((stock) => {
                    a.document.write('<tr>');
                    a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:20%">' + stock.item_description + '</td>');
                    a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:20%">' + stock.weight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</td>');
                    a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:10%">' + stock.c88_code + '</td>');
                    a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:10%">' + stock.total_quantity + '</td>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.total_value_in_pound) + '</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.wholesale_value_in_pound) + '</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.total_value_in_euro) + '</th>');
                    a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.wholesale_value_in_euro) + '</th>');
                    a.document.write('</tr>');
                });
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:20%">Total</td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:20%">' + stock.weight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:10%"></td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse; width:10%">' + stock.total_quantity + '</td>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.total_value_in_pound) + '</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.wholesale_value_in_pound) + '</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.total_value_in_euro) + '</th>');
                a.document.write('<th style="border:1px solid black; border-collapse: collapse; width:10%">' + this.getFormatedPrice(stock.wholesale_value_in_euro) + '</th>');
                a.document.write('</tr>');
                a.document.write('</table>');
            });
        }
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    exportTableToExcel = () => {
        const { selectedContainer, totalWeight, exportList } = this.state;
        let tabText = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>';
        tabText = tabText + '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">';
        tabText = tabText + '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Jacobs Well</Author><Created>1632198631866</Created></DocumentProperties>';
        tabText = tabText + '<Styles><Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style><Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style></Styles>';
        // Summary worksheet
        tabText = tabText + '<Worksheet ss:Name="Summary">';
        tabText = tabText + '<Table>';
        tabText = tabText + '<Row>';
        tabText = tabText + '<Cell><Data ss:Type="String">Container: ' + selectedContainer[0].description + '</Data></Cell>';
        tabText = tabText + '</Row>';
        tabText = tabText + '<Row>';
        tabText = tabText + '<Cell><Data ss:Type="String">Total weight: ' + totalWeight.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,') + 'kg</Data></Cell>';
        tabText = tabText + '</Row>';
        tabText = tabText + '<Row>';
        tabText = tabText + '<Cell><Data ss:Type="String"></Data></Cell>';
        tabText = tabText + '</Row>';
        tabText = tabText + '<Row>';
        tabText = tabText + '<Cell><Data ss:Type="String">Category</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Total weight (kg)</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Number boxes/ sacks/ pallets</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Full total value (£)</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Wholesale total value (£)</Data></Cell>';
        tabText = tabText + '</Row>';
        exportList[1].map((category) => {
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">' + category.product_category_description + '</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="Number">' + category.weight.toFixed(2) + '</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="Number">' + category.total_quantity + '</Data></Cell>';
            tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + category.total_value_in_pound.toFixed(2) + '</Data></Cell>';
            tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + category.wholesale_value_in_pound.toFixed(2) + '</Data></Cell>';
            tabText = tabText + '</Row>';
        });
        tabText = tabText + '<Row>';
        tabText = tabText + '<Cell><Data ss:Type="String">Total</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="Number">' + totalWeight.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="Number">' + exportList[0].total_quantity + '</Data></Cell>';
        tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + exportList[0].total_value_in_pound.toFixed(2) + '</Data></Cell>';
        tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + exportList[0].wholesale_value_in_pound.toFixed(2) + '</Data></Cell>';
        tabText = tabText + '</Row>';
        tabText = tabText + '</Table>';
        tabText = tabText + '</Worksheet>';
        // Detailed list worksheet
        tabText = tabText + '<Worksheet ss:Name="DetailedList">';
        tabText = tabText + '<Table>';
        tabText = tabText + '<Row>';
        tabText = tabText + '<Cell><Data ss:Type="String">Item</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Weight (kg)</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">C88 code</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Batch number</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Expiry date</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Number boxes/ sacks/ pallets</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Full total value (£)</Data></Cell>';
        tabText = tabText + '<Cell><Data ss:Type="String">Wholesale total value (£)</Data></Cell>';
        tabText = tabText + '</Row>';
        exportList[1].map((stock) => {
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">' + stock.product_category_description + '</Data></Cell>';
            tabText = tabText + '</Row>';
            if (stock.product_category_id == 10) {
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">Total ' + stock.product_category_description + ' weight ' + stock.weight.toFixed(2) + 'kg</Data></Cell>';
                tabText = tabText + '</Row>';
            }
            stock.product.map((stock) => {
                tabText = tabText + '<Row>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + stock.item_description + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="Number">' + stock.weight.toFixed(2) + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + stock.c88_code + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + stock.batch_number + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="String">' + this.formatDate(stock.expiry_date) + '</Data></Cell>';
                tabText = tabText + '<Cell><Data ss:Type="Number">' + stock.total_quantity + '</Data></Cell>';
                tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + stock.total_value_in_pound.toFixed(2) + '</Data></Cell>';
                tabText = tabText + '<Cell ss:StyleID="Currency"><Data ss:Type="Number">' + stock.wholesale_value_in_pound.toFixed(2) + '</Data></Cell>';
                tabText = tabText + '</Row>';
            });
            tabText = tabText + '<Row>';
            tabText = tabText + '<Cell><Data ss:Type="String">Total</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="Number">' + stock.weight.toFixed(2) + '</Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String"></Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String"></Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="String"></Data></Cell>';
            tabText = tabText + '<Cell><Data ss:Type="Number">' + stock.total_quantity + '</Data></Cell>';
            tabText = tabText + '<Cell  ss:StyleID="Currency"><Data ss:Type="Number">' + stock.total_value_in_pound.toFixed(2) + '</Data></Cell>';
            tabText = tabText + '<Cell  ss:StyleID="Currency"><Data ss:Type="Number">' + stock.wholesale_value_in_pound.toFixed(2) + '</Data></Cell>';
            tabText = tabText + '</Row>';
        });
        tabText = tabText + '</Table>';
        tabText = tabText + '</Worksheet>';
        tabText = tabText + '</Workbook>';
        let downloadLink;
        let dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        let tableHTML = tabText;
        let filename = "Export list " + selectedContainer[0].description;
        filename = filename ? filename + '.xls' : 'excel_data.xls';
        downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        if (navigator.msSaveOrOpenBlob) {
            var blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
            downloadLink.download = filename;
            downloadLink.click();
        }
    }

    formatDate(date) {
        let expirydate;
        let dateObject = new Date(date);
        if (date !== "0001-01-01T00:00:00" || date === "" || date === null) {
            let day = dateObject.getDate();
            let month = dateObject.getMonth() + 1;
            let year = dateObject.getFullYear();
            if (day < 10) day = '0' + day;
            if (month < 10) month = '0' + month;
            expirydate = `${day}/${month}/${year}`;
        } else {
            expirydate = "";
        }
        return expirydate;
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}