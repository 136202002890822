import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, FormInput,
    Alert
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class AddBoxesToPallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedBoxContent: [],
            showConfirmation: false,
            boxContents: [],
            isError: false,
            errorMessage: "",
            isYesBtnDisabled: false,
            yesBtnText: "Yes",
            query: "",
            isLoading: false,
            earliestExpiryDate: "",
            isErrorInBoxContent: false,
            successMessage: "",
            palletNumber: "",
            location: "",
            today: "",

            numberOfItem: "",
            responseNumberOfItem: "",
            validNumberOfItem: false,

            weight: "",
            responseWeight: "",
            validWeight: false,

            isJSpallet: false,

            description: "",
            responseDescription: "",
        };
        this.boxContents = React.createRef();
    }

    componentDidMount() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const palletNumber = urlParams.get('pallet')
        this.setState({ palletNumber: palletNumber });
        const location = urlParams.get('location')
        this.setState({ location: location });

        axios.get(`${ApiUrls.viewPalletContents}?palletNumber=${palletNumber}&location=${location}`, authorizationHeader).then((response) => {
            var palletContent = JSON.parse(response.data.data);
            var palletInfo = palletContent.PalletInfo;
            var palletCategoryInfo = palletContent.PalletCategoryInfo;
            (palletCategoryInfo.id == 'JS' && palletCategoryInfo.product_category == 'MDISP') ? this.setState({ isJSpallet: true }) : this.setState({ isJSpallet: false });
        });



        this.getTodayDate();
        if (this.state.showConfirmation) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }
    _cache = {};

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/Dashboard">Home</a>
                            </BreadcrumbItem>
                            {this.state.location == "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                            )}
                            {this.state.location == "medical" && (
                                <BreadcrumbItem>
                                    <a href="/medical-disposables">Medical Disposable Interface</a>
                                </BreadcrumbItem>
                            )}
                            {this.state.location == "food" && (
                                <BreadcrumbItem>
                                    <a href="/food">Food Interface</a>
                                </BreadcrumbItem>
                            )}
                            {this.state.location == "warehouse" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=warehouse">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            {this.state.location == "medical" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=medical">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            {this.state.location == "food" && (
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=food">Edit a Pallet</a>
                                </BreadcrumbItem>
                            )}
                            <BreadcrumbItem active>Add Boxes To Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>ADD BOXES TO PALLET</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isError && (
                                        <Alert theme="danger">
                                            {this.state.errorMessage}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.successMessage && (
                                        <Alert theme="success">
                                            {this.state.successMessage}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Pallet number : {this.state.palletNumber}</label>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#boxContents">Box Contents :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedBoxContent} options={this.state.boxContents} onChange={this.setBoxContent} isLoading={this.state.isLoading}
                                            id="boxcontent-typeahead"
                                            labelKey="description"
                                            minLength={3}
                                            onInputChange={this.handleInputChange}
                                            onPaginate={this.handlePagination}
                                            onSearch={this.handleSearch}
                                            placeholder="Search for box contents"
                                            ref={this.boxContents}
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.description}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        {(this.state.isErrorInBoxContent) && (
                                            <span className="error-txt">Please select a box content</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            {this.state.isJSpallet && (
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#Description">Description :<span className="error-txt"></span></label>
                                            <FormInput size="lg" type="text" id="#Description" value={this.state.description} onChange={this.SetDescription} />
                                            <span className="error-txt">{this.state.responseDescription}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Number of Boxes: <span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#NumberOfItem" value={this.state.numberOfItem} onChange={this.setNumberOfItem} min="1" />
                                        <span className="error-txt">{this.state.responseNumberOfItem}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#expiryDate">Earliest Expiry Date :<span className="error-txt"></span></label>
                                        <FormInput size="lg" type="date" id="#expiry-date-datepicker" value={this.state.earliestExpiryDate} onChange={this.onDateChange} />
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#weight">Weight per box(kg): <span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#weight" value={this.state.weight} onChange={this.setWeight} min="1" />
                                        <span className="error-txt">{this.state.responseWeight}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>


                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>EDIT A PALLET – ADD BOXES TO PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large" }}>{`Do you want to put ${this.state.numberOfItem} boxes of ${this.state.selectedBoxContent[0].description} weighing ${this.state.weight}kg onto pallet no. ${this.state.palletNumber}?`}</label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Button theme="success" size="lg" disabled={this.isYesBtnDisabled} onClick={this.onYesClick}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick}>No</Button>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    setBoxContent = (value) => {
        this.setState({ selectedBoxContent: value, isErrorInBoxContent: false, isError: false, errorMessage: "", successMessage: "" })
    }

    onDateChange = (value) => {
        this.setState({ earliestExpiryDate: value.target.value, isError: false, errorMessage: "", successMessage: "" });
    }

    handleInputChange = query => {
        this.setState({ query, isError: false, errorMessage: "", successMessage: "" });
    };

    handleSearch = query => {
        if (this._cache[query]) {
            this.setState({ boxContents: this._cache[query].options });
            return;
        }
        this.setState({ isLoading: true });
        this.makeAndHandleRequest(query).then(resp => {
            this._cache[query] = { ...resp, page: 1 };
            this.setState({
                isLoading: false,
                boxContents: resp.options,
            });
        });
    };

    makeAndHandleRequest(query, page = 1) {
        return axios.get(`${ApiUrls.getBoxContents}?query=${query}&perpage=${PER_PAGE}`, authorizationHeader)
            .then(response => {
                var boxContents = JSON.parse(response.data.data);
                const options = boxContents.map(i => ({
                    id: i.id,
                    description: i.description,
                }));
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    SetDescription = (value) => {
        this.setState({ description: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseDescription: "Please enter description" });
        } else {
            this.setState({ responseDescription: "", validNumberOfItem: true });
        }
    }

    setNumberOfItem = (value) => {
        this.setState({ numberOfItem: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    validateNumberOfItem = () => {
        if (!(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(this.state.numberOfItem)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    setWeight = (value) => {
        this.setState({ weight: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (value.target.value < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    validateWeight = () => {
        if (!(this.state.weight)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(this.state.weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (this.state.weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    onConfirmClick = () => {
        this.validateNumberOfItem();
        this.validateWeight();
      
        if (this.state.selectedBoxContent.length === 0) {
            document.body.style.overflow = 'unset';
            this.setState({
                showConfirmation: false,
                isErrorInBoxContent: true
            });
        }

        const { validNumberOfItem, validWeight } = this.state;
        if (this.state.selectedBoxContent.length > 0 && validNumberOfItem && validWeight) {
            this.setState({ showConfirmation: true });
        }
    };
    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        var boxInfo = {
            PalletNumber: this.state.palletNumber,
            Description: this.state.description,
            BoxContentId: this.state.selectedBoxContent[0].id,
            NumberOfBoxes: this.state.numberOfItem ? parseInt(this.state.numberOfItem) : null,
            EarliestExpiryDate: this.state.earliestExpiryDate ? this.state.earliestExpiryDate : null,
            Weight: this.state.weight ? parseFloat(this.state.weight) : null
        };
        axios.post(ApiUrls.putBoxOnPallet, boxInfo, authorizationHeader).then((response) => {
            console.log(response);
            if (response.data.isSuccess) {
                this.boxContents.current.clear();
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, successMessage: response.data.messageText,
                    selectedBoxContent: [], numberOfItem: "",
                    weight: "", description: "", responseDescription: "",
                    boxContents: [], isError: false,
                    errorMessage: "", query: "",
                    isLoading: false, earliestExpiryDate: "",
                    isErrorInBoxContent: false, isErrorInWeight: false,
                    isErrorInNumber: false,
                });
            }
            else {
                this.setState({ isError: true, errorMessage: response.data.messageText, isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
            }

        }).catch((error) => {
            this.setState({ isError: true, errorMessage: "Something went wrong", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
            this.showError(error);
        });
    }
    onNoClick = () => {
        this.setState({ showConfirmation: false, isPrintView: false })
    }

    getTodayDate = () => {
        var date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var today = [year, month, day].join('-');
        this.setState({ today: today });
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isError: true, errorMessage: "Something went wrong" });
        }
    }
}

