import axios from "axios";
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {
    Alert, Breadcrumb, Row, BreadcrumbItem,
    Button, Card, CardBody, CardTitle, Col, FormGroup,
    FormInput, FormSelect, InputGroup, InputGroupAddon, Modal, ModalBody
} from "shards-react";
import { ApiUrls } from "../../environments/apiUrls";
import { PrimaryStorageLocation } from "../common/PrimaryStorageLocation";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class AllocateStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
            isResponse: false,
            isValidPrimaryLocation: false,
            isValidSecondaryLocation: false,
            isSecondaryLocationLoading: false,
            isPalletConfirmShowing: false,
            isNonPalletConfirmShowing: false,
            isPalletProcessing: false,
            isSearchBtnPressed: false,
            isPrimaryLocationReloadValid: false,
            isNonPalletProcessing: false,
            isViewContentClicked: false,
            isPalletContentLoading:false,
            nonPalletContainerId: "",
            nonPalletContainerName: "",
            nonPalletDescription: "",
            nonPalletId: "",
            nonPalletNumberOfQtyToAllocated: "",
            theme: "",
            response: "",
            selectedPalletNumber: "",
            selectedContainerName: "",
            selectedContainerId: "",
            responseContainer: "",
            reloadSelectedSecondaryLocation: [],
            reloadSelectedPrimaryLocation: [],
            selectedPrimaryLocation: [],
            selectedSecondaryLocation: [],
            containers: [],
            selectedContainer: [],
            nonPalletItemsInStock: [],
            palletsInStock: [],
            palletNumber: "",
            isJSpallet: false,
            numberOfBoxes: "",
            totalWeight: "",
            totalPrice: "",
            palletInfo: [],
            isWeightHidden: false,
            isBatchnumberHidden: false,
            boxContents: [],
        };
        this.storageLocation = React.createRef();
    }

    componentDidMount() {
        this.getContainersList();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { SearchBar } = Search;
        const { palletsInStock, nonPalletItemsInStock, isResponse,
            theme, response, isDataLoading, isSearchBtnPressed,
            isPalletConfirmShowing, isPalletProcessing,
            selectedPalletNumber, selectedContainerName,
            isNonPalletConfirmShowing, nonPalletNumberOfQtyToAllocated,
            nonPalletContainerName, nonPalletDescription,
            isNonPalletProcessing, isViewContentClicked, palletNumber, isPalletContentLoading,
            boxContents,        } = this.state;
        const columnsPalletsInStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'pallet_number',
                    formatter: this.createLinkBtnForPalletNumber,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Allocate',
                    formatter: this.allocatePalletsToContainer,
                    style: {
                        fontWeight: 'normal',
                    }
                },
                {
                    text: 'View Content',
                    formatter: this.createViewContentBtn,
                    style: {
                        fontWeight: 'normal',
                    }
                },
            ];
        const columnsNonPalletItemsInStock =
            [
                {
                    text: 'Description',
                    dataField: 'description_with_qty',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Allocate',
                    formatter: this.allocateNonPalletsToContainer,
                    style: {
                        fontWeight: 'normal',
                    }
                },
            ];

        const columnsForPalletContent =
            [
                {
                    text: 'Content',
                    formatter: this.getDescription,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Description',
                    hidden: !this.state.isJSpallet,
                    dataField: 'description_content',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.getExpiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Weight',
                    hidden: this.state.isWeightHidden,
                    formatter: this.getWeight,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Batch number',
                    hidden: this.state.isBatchnumberHidden,
                    dataField: 'batchnumber',
                    style: {
                        fontWeight: 'normal'
                    }
                }
            ];
        const palletsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="PalletNumber"
                    columns={columnsPalletsInStock}
                    data={palletsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
        const nonPalletItemsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="id"
                    columns={columnsNonPalletItemsInStock}
                    data={nonPalletItemsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Allocate Stock</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>ALLOCATE TO FUTURE CONTAINER OR DESTINATION</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <PrimaryStorageLocation ref={this.storageLocation}
                                getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                            />
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!isDataLoading && (
                                        <div>
                                            {isSearchBtnPressed && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5>PALLETS</h5>
                                                        </Col>
                                                    </Row>
                                                    {palletsInStock.length != 0 && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {palletsInStockTable}
                                                            </PaginationProvider>
                                                        </>
                                                    )}
                                                    {palletsInStock.length == 0 && (
                                                        <><Alert theme="warning">No data found</Alert></>
                                                    )}
                                                    <hr />
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5>NON-PALLET ITEMS</h5>
                                                        </Col>
                                                    </Row>
                                                    {nonPalletItemsInStock.length != 0 && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {nonPalletItemsInStockTable}
                                                            </PaginationProvider>
                                                        </>
                                                    )}
                                                    {nonPalletItemsInStock.length == 0 && (
                                                        <><Alert theme="warning">No data found</Alert></>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isPalletConfirmShowing && (
                    <Modal open={isPalletConfirmShowing} toggle={() => this.setState({ isPalletConfirmShowing: !isPalletConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>ALLOCATE TO FUTURE CONTAINER OR DESTINATION - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>
                                        Do you want to allocate pallet {selectedPalletNumber} to container {selectedContainerName}?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleYesBtnClickForPallet} disabled={isPalletProcessing}>{isPalletProcessing ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ isPalletConfirmShowing: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
                {isNonPalletConfirmShowing && (
                    <Modal open={isNonPalletConfirmShowing} toggle={() => this.setState({ isNonPalletConfirmShowing: !isNonPalletConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>ALLOCATE TO FUTURE CONTAINER OR DESTINATION - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>
                                        Do you want to allocate {nonPalletNumberOfQtyToAllocated} of {nonPalletDescription} to container {nonPalletContainerName}?
                                    </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleYesBtnClickForNonPallet} disabled={isNonPalletProcessing}>{isNonPalletProcessing ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ isNonPalletConfirmShowing: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
               
                <Modal open={isViewContentClicked} toggle={() => this.setState({ isViewContentClicked: !isViewContentClicked })} centered={true} className={"custom-modal"}>
                    <ModalBody>
                        <Row>
                            <Col><h2>Pallet number : {palletNumber}<Button style={{ float: "right" }} theme="light" onClick={() => this.setState({ isViewContentClicked: false })} size="lg">Close</Button></h2></Col>
                        </Row>
                        <hr />
                        {isPalletContentLoading && (
                            <label style={{ fontWeight: "bold" }}>Loading...</label>
                        )}
                        {!isPalletContentLoading && (
                            <div style={{ width: "100%" }}>
                                {boxContents.length >= 1 && (
                                    <>
                                        <BootstrapTable
                                            keyField='id'
                                            data={boxContents}
                                            columns={columnsForPalletContent}
                                            wrapperClasses="table-responsive"
                                            pagination={paginationFactory()}
                                        />


                                    </>
                                )}
                                {boxContents.length == 0 && (
                                    <><Alert theme="warning">Pallet is empty</Alert></>
                                )}
                            </div>
                        )}

                        </ModalBody>
                    </Modal>
                
            </>
        );
    }

    getContainersList = () => {
        axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result}) => {
            if (result.isSuccess) {
                let containersList = JSON.parse(result.data);
                let sortedContainersList = sortByAlphanumerically(containersList, 'description');
                this.setState({ containersList: sortedContainersList });              
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ isValidPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ isValidSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    handleSearch = () => {
        this.storageLocation.current.validatePrimaryLocation();
        const { isValidPrimaryLocation, selectedPrimaryLocation, selectedSecondaryLocation } = this.state;
        if (isValidPrimaryLocation) {
            this.setState({ isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPressed: true })
            axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let containersList = JSON.parse(result.data);
                    let sortedContainersList = sortByAlphanumerically(containersList, 'description');
                    this.setState({ containersList: sortedContainersList });
                    if (isValidPrimaryLocation && selectedSecondaryLocation.length !== 0) {
                        this.getData(false, parseInt(selectedSecondaryLocation[0].Id));
                        this.setState({ isPrimaryLocationReloadValid: isValidPrimaryLocation, reloadSelectedSecondaryLocation: selectedSecondaryLocation });
                    } else {
                        this.getData(true, parseInt(selectedPrimaryLocation[0].id));
                        this.setState({ isPrimaryLocationReloadValid: isValidPrimaryLocation, reloadSelectedPrimaryLocation: selectedPrimaryLocation });
                    }                    
                }
            }).catch((error) => {
                this.showError(error);
            });            
        }
    }

    getData = (isPrimaryLocationId, locationId) => {
        this.getContainersList(); 
        axios.get(`${ApiUrls.viewNonPalletListAllocations}?isPriLocId=${isPrimaryLocationId}&locId=${locationId}`, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let nonPalletItemsInStock = JSON.parse(result.data);
                let sortedNonPalletItemsInStock = sortByAlphanumerically(nonPalletItemsInStock, 'description_with_qty');
                this.setState({ nonPalletItemsInStock: sortedNonPalletItemsInStock });               
            }
            axios.get(`${ApiUrls.viewPalletListAllocations}?isPriLocId=${isPrimaryLocationId}&locId=${locationId}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    let palletsInStock = JSON.parse(result.data);
                    let sortedPalletsInStock = sortByAlphanumerically(palletsInStock, 'pallet_number');
                    this.setState({ palletsInStock: sortedPalletsInStock, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });                    
        }).catch((error) => {
            this.showError(error);
        });          
    };

    allocatePalletsToContainer = (cell, row) => {
        const { containersList } = this.state;
        return (
            <Row>
                <Col sm="12" md="6" lg="6" >
                    <FormGroup>
                        <FormSelect onChange={e => this.setPalletsToContainer(e, row)} id={row.id} style={{ background: 'white', fontSize: 'inherit'}} >
                            <option value="0" style={{ padding: '2px' }}>Select a container</option>
                            {containersList.map(({ id, description }) => {
                                return (<option key={id} value={id} style={{ padding: '2px' }}>{description}</option>)
                            })}
                        </FormSelect>
                    </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="6" >
                    <Button theme="danger" block onClick={() => this.handlePalletAllocation(row)}>Allocate</Button>
                </Col>
            </Row>
        )
    }

    setPalletsToContainer = ({ target }, row) => {
        this.setState({ isResponse: false });
        const { palletsInStock, containersList } = this.state;
        if (target.value !== 0) {
            palletsInStock.forEach(boxContent => {
                if (boxContent.pallet_number == row.pallet_number) {                    
                    boxContent.container_id = parseInt(target.value);
                    containersList.forEach(container => {
                        if (container.id == parseInt(target.value)) {
                            boxContent.container_name = container.description;
                        }
                    });                 
                }
            })
            this.setState({ palletsInStock });
        } else {
            palletsInStock.forEach(boxContent => {
                if (boxContent.pallet_number == row.pallet_number) {
                    boxContent.container_name = "";
                    boxContent.container_id = "";
                }
            })
            this.setState({ palletsInStock });
        }        
    }

    handlePalletAllocation = (row) => {
        row.container_id != 0
            ? this.setState({ isPalletConfirmShowing: true, selectedPalletNumber: row.pallet_number, selectedContainerName: row.container_name, selectedContainerId: row.container_id, isResponse: false, })
            : this.setState({ isResponse: true, theme: "danger", response: "Please select a container", isPalletProcessing: false, isPalletConfirmShowing: false });
    }

    handleYesBtnClickForPallet = () => {
        this.setState({ isPalletProcessing: true });
        const { selectedPalletNumber, selectedContainerId } = this.state;
        axios.post((`${ApiUrls.addPalletListAllocations}?palletNumber=${selectedPalletNumber}&containerId=${parseInt(selectedContainerId)}`), {}, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success", response: result.messageText, isPalletProcessing: false, isPalletConfirmShowing: false,
                    isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPressed: true 
                });
                const { isPrimaryLocationReloadValid, reloadSelectedSecondaryLocation, reloadSelectedPrimaryLocation } = this.state;  
                (isPrimaryLocationReloadValid && reloadSelectedSecondaryLocation.length !== 0)
                    ? this.getData(false, parseInt(reloadSelectedSecondaryLocation[0].Id))
                    : this.getData(true, parseInt(reloadSelectedPrimaryLocation[0].id));
            }
            else {
                this.setState({ isResponse: true, theme: "danger", response: result.messageText, isPalletProcessing: false, isPalletConfirmShowing: false });
            }
        }).catch((error) => {
            this.showError(error); 
        });
    }

    allocateNonPalletsToContainer = (cell, row) => {
        const { containersList } = this.state;
        return (
            <Row>
                <Col >
                    <FormGroup>
                        <FormSelect onChange={e => this.setNonPalletsToContainer(e, row)} id={row.id} style={{ background: 'white', fontSize: 'inherit' }} >
                            <option value="0" style={{ padding: '2px' }}>Select a container</option>
                            {containersList.map(({id, description }) => {
                                return (<option key={id} value={id} style={{ padding: '2px' }}> {description}</option>)
                            })}
                        </FormSelect>
                    </FormGroup>
                </Col>
                <Col >
                    <FormGroup>
                    <InputGroup>
                        <InputGroupAddon type="prepend">
                            <Button theme="primary" onClick={() => this.removeBox(row)}>-</Button>
                        </InputGroupAddon>
                            <FormInput style={{ textAlign: "center", maxWidth: "100px" }} size="5" id={row.number_of_qty_to_allocated_id} maxlength="5" onChange={(e) => this.updateStock(e, row)}/>
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={() => this.addBox(row)}>+</Button>
                        </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col >
                    <Button theme="danger" block onClick={() => this.handleNonPalletAllocation(row)}>Allocate</Button>
                </Col>
            </Row>
        )
    }

    setNonPalletsToContainer = ({ target }, row) => {
        this.setState({ isResponse: false });
        const { nonPalletItemsInStock, containersList } = this.state;
        if (target.value !== 0) {
            nonPalletItemsInStock.forEach(boxContent => {
                if (boxContent.id == row.id) {
                    boxContent.container_id = parseInt(target.value);
                    containersList.forEach(container => {
                        if (container.id == parseInt(target.value)) {
                            boxContent.container_name = container.description;
                        }
                    });
                }
            });
            this.setState({ nonPalletItemsInStock });
        } else {
            nonPalletItemsInStock.forEach(boxContent => {
                if (boxContent.id == row.id) {
                    boxContent.container_name = "";
                    boxContent.container_id = "";
                }
            });
            this.setState({ nonPalletItemsInStock });
        }
    }

    updateStock = ({ target }, row) => {
        const { nonPalletItemsInStock} = this.state;
        if (target.value >= 1 && target.value <= row.number_of_item) {
            nonPalletItemsInStock.forEach(item => {
                if (item.number_of_qty_to_allocated_id == row.number_of_qty_to_allocated_id) {
                    item.number_of_qty_to_allocated = parseInt(target.value);
                    document.getElementById(row.number_of_qty_to_allocated_id).value = item.number_of_qty_to_allocated;
                }
            });
        }
        else {
            nonPalletItemsInStock.forEach(item => {
                if (item.number_of_qty_to_allocated_id == row.number_of_qty_to_allocated_id) {
                    document.getElementById(row.number_of_qty_to_allocated_id).value = item.number_of_qty_to_allocated;
                }
            });
        }
        this.setState({ nonPalletItemsInStock: nonPalletItemsInStock });
    }

    removeBox = (row) => {
        this.setState({ isResponse: false });
        const { nonPalletItemsInStock } = this.state;
        if (!(row.number_of_qty_to_allocated == 1)) {
            nonPalletItemsInStock.forEach(boxContent => {
                if (boxContent.number_of_qty_to_allocated_id == row.number_of_qty_to_allocated_id) {
                    boxContent.number_of_qty_to_allocated -= 1;
                    document.getElementById(row.number_of_qty_to_allocated_id).value = boxContent.number_of_qty_to_allocated;
                }
            })
        }
        this.setState({ nonPalletItemsInStock: nonPalletItemsInStock });
    }

    addBox = (row) => {
        this.setState({ isResponse: false });
        const { nonPalletItemsInStock } = this.state;
        if (!(row.number_of_qty_to_allocated == row.number_of_item)) {
            nonPalletItemsInStock.forEach(boxContent => {
                if (boxContent.number_of_qty_to_allocated_id == row.number_of_qty_to_allocated_id) {
                    boxContent.number_of_qty_to_allocated += 1;
                    document.getElementById(row.number_of_qty_to_allocated_id).value = boxContent.number_of_qty_to_allocated;
                }
            });
        }
        this.setState({ nonPalletItemsInStock: nonPalletItemsInStock });
    }

    handleNonPalletAllocation = (row) => {
        row.container_id != 0
            ? this.setState({
                isNonPalletConfirmShowing: true, nonPalletContainerId: row.container_id, nonPalletContainerName: row.container_name,
                nonPalletDescription: row.description, nonPalletId: row.id, nonPalletNumberOfQtyToAllocated: row.number_of_qty_to_allocated,
                isResponse: false,
            })
            : this.setState({ isResponse: true, theme: "danger", response: "Please select a container", isNonPalletProcessing: false, isNonPalletConfirmShowing: false });
    }

    handleYesBtnClickForNonPallet = () => {
        this.setState({ isNonPalletProcessing: true });
        const { nonPalletId, nonPalletNumberOfQtyToAllocated, nonPalletContainerId, isPrimaryLocationReloadValid, reloadSelectedSecondaryLocation, reloadSelectedPrimaryLocation } = this.state;
        axios.post((`${ApiUrls.addNonPalletListAllocations}?StockId=${parseInt(nonPalletId)}&numberOfItem=${parseInt(nonPalletNumberOfQtyToAllocated)}&containerId=${parseInt(nonPalletContainerId)}`), {}, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success", response: result.messageText, isNonPalletProcessing: false, isNonPalletConfirmShowing: false,
                    isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPressed: true
                });
                (isPrimaryLocationReloadValid && reloadSelectedSecondaryLocation.length !== 0)
                    ? this.getData(false, parseInt(reloadSelectedSecondaryLocation[0].Id))
                    : this.getData(true, parseInt(reloadSelectedPrimaryLocation[0].id));
            }
            else {
                this.setState({ isResponse: true, theme: "danger", response: result.messageText, isNonPalletProcessing: false, isNonPalletConfirmShowing: false });
            }
        }).catch((error) => {
            this.showError(error);        
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isPalletProcessing: false, isPalletConfirmShowing: false });
        }
    }

    createViewContentBtn = (cell, row) => {
        return (
            <Row>
                <Col sm="12" md="12" lg="12" >
                    <Button theme="success" block onClick={() => this.onViewContentClick(row)}>View Content</Button>
                </Col>
            </Row>
        )
    }

    createLinkBtnForPalletNumber = (cell, row) => {
        return (<a key={row.pallet_number } href={"#"} onClick={(event)=>this.showPalletContent(event, row)}>{row.pallet_number}</a>)
    }

    showPalletContent = (event, row) => {
        event.preventDefault();
        this.setState({ isViewContentClicked: true, palletNumber: row.pallet_number, isPalletContentLoading: true });
        this.getPalletContent(row.pallet_number);
        //return false;
        //console.log(e);
    }

    onViewContentClick = (row) => {
        this.setState({ isViewContentClicked: true, palletNumber: row.pallet_number, isPalletContentLoading: true });
        this.getPalletContent(row.pallet_number);
    }

    getPalletContent = (palletNumber) => {
        axios.get(`${ApiUrls.viewPalletContents}?PalletNumber=${palletNumber}`, authorizationHeader).then((response) => {
            var palletContent = JSON.parse(response.data.data);
            console.log(palletContent);
            var boxContents = palletContent.PalletBoxContents;
            var sortBoxContents = sortByAlphanumerically(boxContents, 'description');
            var palletInfo = palletContent.PalletInfo;
            (palletInfo.PalletCategory == 10) ? this.setState({ isJSpallet: true }) : this.setState({ isJSpallet: false });
            var numberOfBoxes = 0;
            var totalWeight = 0;
            var totalPrice = 0;
            boxContents.map((box) => {
                var price = (box.total_value).toFixed(2);
                totalPrice += parseFloat(price);
                numberOfBoxes += box.numberofboxes;
                totalWeight += box.totalweight;
            })
            
            if (palletInfo.PalletCategory == 1) {
                this.setState({ isWeightHidden: true, isBatchnumberHidden: false })
            } else {
                this.setState({ isWeightHidden: false, isBatchnumberHidden: true })
            }

            this.setState({ boxContents: sortBoxContents, isPalletContentLoading: false, numberOfBoxes: numberOfBoxes, totalWeight: totalWeight, totalPrice: totalPrice, palletInfo: palletInfo });
        }).catch((error) => {
            this.showError(error);
        });
    }

    getDescription = (cell, row) => {
        return (<div>
            {row.description} ({row.numberofboxes})
        </div>)
    }

    getExpiryDate = (cell, row) => {
        var expiryDate = row.expirydate;
        var date;
        expiryDate ? date = getDateInGivenFormat(expiryDate.slice(0, 10)) : date = '';
        return (<div>{date}</div>)
    }

    getWeight = (cell, row) => {
        return (<div>{row.totalweight}kg</div>)
    }
}