import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem
} from "shards-react";

export class MedicalDisposable extends Component {

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const BlueButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#0000FF",
            borderColor: "#0000FF",
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Medical Disposable Interface</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/start-new-pallet/?location=medical"}>Start New Pallet</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/edit-pallet/?location=medical"}>Edit Pallet​</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/finish-pallet/?location=medical"}>Finish Pallet</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/put-box-on-pallet"}>Put Box on Pallet</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4"></Col>
                                <Col sm="12" lg="4" lg="4"></Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
            </>
        );
    }
}