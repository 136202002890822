import axios from "axios";
import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
    Alert, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle,
    Col, FormGroup, FormInput, Modal, ModalBody, Row
} from "shards-react";
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { StorageLocation } from "../common/StorageLocation";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class RelocateNonPalletItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            selectedPrimaryLocation: [],
            validPrimaryLocation: false,

            selectedSecondaryLocation: [],
            validSecondaryLocation: false,
            isSecondaryLocationLoading: false,

            isSearchBtnPress: false,

            nonPalletItemsInStock: [],
            selectedNonPalletItemsInStock: [],
            responseNonPalletItemsInStock: "",
            validNonPalletItemsInStock: false,
            isNonPalletItemsInStockLoading: false,

            numberOfItem: "",
            responseNumberOfItem: "",
            validNumberOfItem: false,

            selectedRelocatePrimaryLocation: [],
            validRelocatePrimaryLocation: false,

            selectedRelocateSecondaryLocation: [],
            validRelocateSecondaryLocation: false,
            isRelocateSecondaryLocationLoading: false,

            showConfirmation: false,
            yesBtnText: "Yes",
            isYesBtnDisabled: false,
        };
        this.StorageLocation = React.createRef();
        this.RelocateStorageLocation = React.createRef();
        this.nonPalletItemsInStock = React.createRef();
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Move or load Non pallet item​</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>MOVE NON PALLET ITEM</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <form id="myForm">

                                <StorageLocation ref={this.StorageLocation}
                                    getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                    getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                    getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                    getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                    getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                                />

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <Button theme="primary" size="lg" onClick={this.onSearchClick} type="button">Search</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {this.state.isSearchBtnPress && (
                                    <>

                                        {this.state.isNonPalletItemsInStockLoading && (
                                            <Row>
                                                <Col sm="12" md="10" lg="8">
                                                    <FormGroup>
                                                        <label><i class="fa fa-spinner fa-spin"></i>Loading</label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}

                                        {!this.state.isNonPalletItemsInStockLoading && (
                                            <>
                                                {this.state.nonPalletItemsInStock.length == 0 && (
                                                    <Alert theme="warning">The relevant location does not have any non-pallet item​(s) in stock</Alert>
                                                )}

                                                {this.state.nonPalletItemsInStock.length !== 0 && (
                                                    <>
                                                        <Row>
                                                            <Col sm="12" md="10" lg="8">
                                                                <FormGroup>
                                                                    <label style={{ fontSize: "large" }} htmlFor="#NonPalletItemsInStock">Non pallet items in stock :<span className="error-txt">*</span></label>
                                                                    <Typeahead size="lg" value={this.state.selectedNonPalletItemsInStock}
                                                                        id="Id"
                                                                        labelKey="displaydescription"
                                                                        onChange={this.setNonPalletItemsInStock}
                                                                        options={this.state.nonPalletItemsInStock}
                                                                        placeholder=""
                                                                        ref={this.nonPalletItemsInStock}
                                                                    />
                                                                    <span className="error-txt">{this.state.responseNonPalletItemsInStock}</span>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        {this.state.selectedNonPalletItemsInStock.length != 0 && (<>

                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                    <FormGroup>
                                                                        <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Quantity :<span className="error-txt">*</span></label>
                                                                        <FormInput size="lg" type="number" id="#NumberOfItem" value={this.state.numberOfItem} onChange={this.setNumberOfItem} min="1" />
                                                                        <span className="error-txt">{this.state.responseNumberOfItem}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <StorageLocation ref={this.RelocateStorageLocation}
                                                                getSelectedPrimaryLocation={this.getUpdateOnSelectedRelocatePrimaryLocation}
                                                                getPrimaryLocationValidStatus={this.getUpdateOnValidRelocatePrimaryLocation}
                                                                getSecondaryLocationValidStatus={this.getUpdateOnValidRelocateSecondaryLocation}
                                                                getSelectedSecondaryLocation={this.getUpdateOnSelectedRelocateSecondaryLocation}
                                                                getSecondaryLocationLoadingStatus={this.getUpdateOnIsRelocateSecondaryLocationLoading}
                                                            />

                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                    <FormGroup>
                                                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick} type="submit">Confirm</Button>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                    </>
                                )}
                            </form>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>CHOOSE LOCATION FOR NON PALLET ITEMS - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to locate {this.state.numberOfItem} of {this.state.selectedNonPalletItemsInStock[0].description}
                                          &nbsp;in location {this.state.selectedPrimaryLocation[0].description} {this.state.selectedSecondaryLocation.length !== 0 && (<>({this.state.selectedSecondaryLocation[0].Name})</>)}
                                          &nbsp;to location {this.state.selectedRelocatePrimaryLocation[0].description} {this.state.selectedRelocateSecondaryLocation.length !== 0 && (<>({this.state.selectedRelocateSecondaryLocation[0].Name})</>)}
                                      </label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>

                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}

            </>
        );
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({isResponse:false});
        this.setState({ selectedPrimaryLocation: value });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ isResponse: false });
        this.setState({ validPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ validSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    resetStorageLocation() {
        this.refs.StorageLocation.resetStorageLocation();
    }

    validatePrimaryLocation() {
        this.refs.StorageLocation.validatePrimaryLocation();
    }

    validateSecondaryLocation() {
        this.refs.StorageLocation.validateSecondaryLocation();
    }

    onSearchClick = () => {
        this.StorageLocation.current.validatePrimaryLocation();
        if (!this.state.isSecondaryLocationLoading && !this.state.validSecondaryLocation) {
            this.StorageLocation.current.validateSecondaryLocation();
        }
        const { validPrimaryLocation, validSecondaryLocation, isSecondaryLocationLoading } = this.state;
        if (validPrimaryLocation && validSecondaryLocation && !isSecondaryLocationLoading) {
            this.setState({ isNonPalletItemsInStockLoading: true, isSearchBtnPress: true });
            const { selectedPrimaryLocation, selectedSecondaryLocation } = this.state;
            var primaryLocationId, secondaryLocationId
            if (selectedSecondaryLocation.length !== 0) {
                primaryLocationId = selectedPrimaryLocation[0].id;
                secondaryLocationId = selectedSecondaryLocation[0].Id
            } else {
                primaryLocationId = selectedPrimaryLocation[0].id;
                secondaryLocationId = 0
            }
            axios.get(`${ApiUrls.getNonPalletItemsInStockByLocId}?primaryLocId=${primaryLocationId}&secondaryLocId=${secondaryLocationId}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var nonPalletItemsInStock = JSON.parse(response.data.data);
                    var sortNonPalletItemsInStock = sortByAlphanumerically(nonPalletItemsInStock, 'displaydescription');
                    this.setState({
                        nonPalletItemsInStock: sortNonPalletItemsInStock, isNonPalletItemsInStockLoading: false,
                        numberOfItem: "", responseNumberOfItem: "", validNumberOfItem: false,
                    });
                }
            }).catch((error) => {
                this.showError(error);
            })
        }
    };

    setNonPalletItemsInStock = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({
                selectedNonPalletItemsInStock: value, validNonPalletItemsInStock: true,
                responseNonPalletItemsInStock: ""
            });
        } else {
            this.setState({
                selectedNonPalletItemsInStock: [], validNonPalletItemsInStock: false,
                responseNonPalletItemsInStock: "Please select a item"
            });
        }
    };

    validateNonPalletItemsInStock = () => {
        if (this.state.selectedNonPalletItemsInStock.length === 0) {
            this.setState({ responseNonPalletItemsInStock: "Please select a item", validNonPalletItemsInStock: false });
        } else {
            this.setState({ responseNonPalletItemsInStock: "", validNonPalletItemsInStock: true });
        }
    };

    setNumberOfItem = (value) => {
        this.setState({ numberOfItem: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) > this.state.selectedNonPalletItemsInStock[0].numberofitem) {
            this.setState({ responseNumberOfItem: "Stock available less than requested amount", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    validateNumberOfItem = () => {
        if (!(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) > this.state.selectedNonPalletItemsInStock[0].numberofitem) {
            this.setState({ responseNumberOfItem: "Stock available less than requested amount", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(this.state.numberOfItem)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    getUpdateOnSelectedRelocatePrimaryLocation = (value) => {
        this.setState({ selectedRelocatePrimaryLocation: value });
    };

    getUpdateOnValidRelocatePrimaryLocation = (value) => {
        this.setState({ validRelocatePrimaryLocation: value });
    };

    getUpdateOnValidRelocateSecondaryLocation = (value) => {
        this.setState({ validRelocateSecondaryLocation: value });
    };

    getUpdateOnSelectedRelocateSecondaryLocation = (value) => {
        this.setState({ selectedRelocateSecondaryLocation: value });
    };

    getUpdateOnIsRelocateSecondaryLocationLoading = (value) => {
        this.setState({ isRelocateSecondaryLocationLoading: value });
    };

    onConfirmClick = (event) => {
        event.preventDefault();
        this.RelocateStorageLocation.current.validatePrimaryLocation();
        this.validateNumberOfItem();
        if (!this.state.isRelocateSecondaryLocationLoading && !this.state.validRelocateSecondaryLocation) {
            this.RelocateStorageLocation.current.validateSecondaryLocation();
        }
        const { validNumberOfItem, validRelocatePrimaryLocation, validRelocateSecondaryLocation, isRelocateSecondaryLocationLoading } = this.state;
        if (validNumberOfItem && validRelocatePrimaryLocation && validRelocateSecondaryLocation && !isRelocateSecondaryLocationLoading) {
            this.setState({ showConfirmation: true });
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false });
    }

    onYesClick = () => {       
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });
        if (this.state.selectedSecondaryLocation.length != 0 && this.state.selectedRelocateSecondaryLocation.length != 0) {
            var UpdateNonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.selectedNonPalletItemsInStock[0].nonpalletitemid),
                NumberOfItem: parseInt(this.state.numberOfItem),
                PrimaryLocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryLocationId: parseInt(this.state.selectedSecondaryLocation[0].Id),
                RelocatePrimaryLocationId: parseInt(this.state.selectedRelocatePrimaryLocation[0].id),
                RelocateSecondaryLocationId: parseInt(this.state.selectedRelocateSecondaryLocation[0].Id),
            };
        }
        else if (this.state.selectedSecondaryLocation.length != 0 && this.state.selectedRelocateSecondaryLocation.length == 0) {
            var UpdateNonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.selectedNonPalletItemsInStock[0].nonpalletitemid),
                NumberOfItem: parseInt(this.state.numberOfItem),
                PrimaryLocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryLocationId: parseInt(this.state.selectedSecondaryLocation[0].Id),
                RelocatePrimaryLocationId: parseInt(this.state.selectedRelocatePrimaryLocation[0].id),
                RelocateSecondaryLocationId: 0,
            };
        }
        else if (this.state.selectedSecondaryLocation.length == 0 && this.state.selectedRelocateSecondaryLocation.length != 0) {
            var UpdateNonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.selectedNonPalletItemsInStock[0].nonpalletitemid),
                NumberOfItem: parseInt(this.state.numberOfItem),
                PrimaryLocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryLocationId: 0,
                RelocatePrimaryLocationId: parseInt(this.state.selectedRelocatePrimaryLocation[0].id),
                RelocateSecondaryLocationId: parseInt(this.state.selectedRelocateSecondaryLocation[0].Id),
            };
        }
        else if (this.state.selectedSecondaryLocation.length == 0 && this.state.selectedRelocateSecondaryLocation.length == 0) {
            var UpdateNonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.selectedNonPalletItemsInStock[0].nonpalletitemid),
                NumberOfItem: parseInt(this.state.numberOfItem),
                PrimaryLocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryLocationId: 0,
                RelocatePrimaryLocationId: parseInt(this.state.selectedRelocatePrimaryLocation[0].id),
                RelocateSecondaryLocationId: 0,
            };
        }	
     
        console.log(UpdateNonPalletItemsInStockInfo)

        axios.post(ApiUrls.updateNonPalletItemToStock, UpdateNonPalletItemsInStockInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.nonPalletItemsInStock.current.clear();
                this.StorageLocation.current.resetStorageLocation();
                this.RelocateStorageLocation.current.resetStorageLocation();
                this.setState({
                    isResponse: true, response: response.data.messageText, theme: "success",
                    isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false,
                    selectedPrimaryLocation: [], validPrimaryLocation: false,
                    selectedSecondaryLocation: [], validSecondaryLocation: false,
                    isSecondaryLocationLoading: false, isSearchBtnPress: false,
                    nonPalletItemsInStock: [], selectedNonPalletItemsInStock: [],
                    responseNonPalletItemsInStock: "", validNonPalletItemsInStock: false,
                    isNonPalletItemsInStockLoading: false, numberOfItem: "",
                    responseNumberOfItem: "", validNumberOfItem: false,
                    selectedRelocatePrimaryLocation: [], validRelocatePrimaryLocation: false,
                    selectedRelocateSecondaryLocation: [], validRelocateSecondaryLocation: false,
                    isRelocateSecondaryLocationLoading: false,
                });	
            }
            else {
                this.setState({ isResponse: true, response: response.data.messageText, theme: "danger", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
            }
        }).catch((error) => {
            this.showError(error);          
            
        });
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
        }
    }
}