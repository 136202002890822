import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, Alert,
    Modal, ModalBody, FormInput
} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class AdjustPrices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isDataLoading: false,
            isSearchBtnPress: false,
            isValidContainer: false,
            isValidPercentage: false,
            isConfirmShown: false,
            isYesBtnDisabled: false,
            errorInContainer: "",
            theme: "",
            response: "",
            containerName: "",
            errorInPercentage: "",
            containerId: 0,
            totalValue: 0,
            wholesaleValue: 0,
            newWholesaleValue: 0,
            percentage: 0,
            containers: [],
            selectedContainer: [],
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],
        };
        this.containers = React.createRef();
    }

    componentDidMount() {
        this.getContainers();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response,
            selectedContainer, containers, emptyLabel,
            errorInContainer, isDataLoading, isSearchBtnPress,
            totalValue, wholesaleValue, percentage,
            errorInPercentage, newWholesaleValue, isConfirmShown,
            containerName, isYesBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Adjust Prices</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>ADJUST PRICES</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#Container">Select a container:<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" selected={selectedContainer}
                                            id="id"
                                            labelKey="description"
                                            onChange={this.setContainer}
                                            options={containers}
                                            placeholder=""
                                            ref={this.containers}
                                            emptyLabel={emptyLabel}
                                        />
                                        <span className="error-txt">{errorInContainer}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (<>
                                {isSearchBtnPress && (<>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} ><b>Total value</b> : &#163; {totalValue.toFixed(2)} </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} ><b>Wholesale value</b> : &#163; {wholesaleValue.toFixed(2)} </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup >
                                                <label style={{ fontSize: "large" }} htmlFor="#percentage">Percentage ( eg: -2.5% or +2.75% ) :<span className="error-txt">*</span></label>
                                                <FormInput size="lg" type="number" id="#percentage" value={percentage} onChange={this.setPercentage} />
                                                <span className="error-txt">{errorInPercentage}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup >
                                                <label style={{ fontSize: "large" }} ><b>New wholesale value</b> : &#163; {newWholesaleValue.toFixed(2)} </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup >
                                                <Button theme="primary" size="lg" type="submit" onClick={this.handleUpdate}>Update</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </>)}
                            </>)}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmShown && (
                    <Modal open={isConfirmShown} toggle={() => this.setState({ isConfirmShown: !isConfirmShown })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>ADJUST PRICES - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to adjust the retail value of each item listed in the container <b> {containerName} </b> by {percentage} % ?</label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.handleYesBtnClick} disabled={isYesBtnDisabled}>{isYesBtnDisabled ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ isConfirmShown: false })} disabled={isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                        </ModalBody>
                    </Modal>
                )}
            </>);
    }

    getContainers = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const container = urlParams.get('Container');
        axios.get(ApiUrls.getContainersNotShipped, authorizationHeader).then(({ data: result}) => {
            if (result.isSuccess) {
                var containers = JSON.parse(result.data);
                var sortedContainers = sortByAlphanumerically(containers, 'description');
                this.setState({ containers: sortedContainers, selectedContainer: [], emptyLabel: "No matches found" });
                if (container != null) {
                    sortedContainers.map((result) => {
                        if (result.id == parseInt(container)) {
                            var select = [];
                            select.push(result);
                            this.setContainer(select);
                        }
                    })
                }
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setContainer = (value) => {
        this.setState({ isResponse: false, isConfirmShown: false, });
        if (value != null && value.length > 0) {
            this.setState({
                selectedContainer: value, errorInContainer: "", isValidContainer: true, containerId: value[0].id,
                containerName: value[0].description, isDataLoading: true, isSearchBtnPress: true,
                totalValue: 0, wholesaleValue: 0, newWholesaleValue: 0
            });
            axios.get(`${ApiUrls.getTotalAndWholesaleValueByContainer}?containerId=${parseInt(value[0].id)}`, authorizationHeader).then(({ data: result }) => {
                if (result.isSuccess) {
                    var value = JSON.parse(result.data);
                    this.setState({ isSearchBtnPress: true, totalValue: value.total_value, wholesaleValue: value.wholesale_value, newWholesaleValue: value.wholesale_value, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });
        } else {
            this.setState({
                selectedContainer: [], errorInContainer: "Please select a container", isValidContainer: false, containerId: 0,
                containerName: "", isDataLoading: false, isSearchBtnPress: false,
                totalValue: 0, wholesaleValue: 0, newWholesaleValue: 0
            });
        }
    };

    validateContainer = () => {
        const { selectedContainer } = this.state;
        this.setState({ isResponse: false, isConfirmShown: false, });
        (selectedContainer != null && selectedContainer.length > 0)
            ? this.setState({ errorInContainer: "" })
            : this.setState({ errorInContainer: "Please select a container" });
    };

    setPercentage = ({ target }) => {
        const { totalValue, wholesaleValue } = this.state;
        this.setState({ isResponse: false, isConfirmShown: false, percentage: target.value });
        if (!(target.value)) {
            this.setState({ errorInPercentage: "Please enter a percentage", isValidPercentage: false, newWholesaleValue: totalValue });
        } else if (isNaN(target.value)) {
            this.setState({ errorInPercentage: "Please enter number", isValidPercentage: false, newWholesaleValue: totalValue });
        } else {
            var newWholesaleValue = parseFloat(wholesaleValue) * ((100 + parseFloat(target.value)) / 100);
            this.setState({ errorInPercentage: "", isValidPercentage: true, newWholesaleValue: parseFloat(newWholesaleValue) });
        }
    }

    validatePercentage = () => {
        const { percentage } = this.state;
        if (!(percentage)) {
            this.setState({ errorInPercentage: "Please enter a percentage", isValidPercentage: false });
        } else if (isNaN(percentage)) {
            this.setState({ errorInPercentage: "Please enter number", isValidPercentage: false });
        } else {
            this.setState({ errorInPercentage: "", isValidPercentage: true });
        }
    }

    handleUpdate = (value) => {
        value.preventDefault();
        this.validateContainer();
        this.validatePercentage();
        const { isValidContainer, isValidPercentage } = this.state;
        (isValidContainer && isValidPercentage)
            ? this.setState({ isConfirmShown: true })
            : this.setState({ isConfirmShown: false });
    }

    handleYesBtnClick = () => {
        const { containerId, percentage } = this.state;
        this.setState({ isYesBtnDisabled: true, isResponse: false });
        axios.post(`${ApiUrls.updateWholesaleValue}?containerId=${parseInt(containerId)}&percentage=${parseFloat(percentage)}`, {}, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: false, response: result.messageText, theme: "success",
                    isYesBtnDisabled: false, isConfirmShown: false
                });
                window.location.href = "/export-list/?Container=" + containerId;
            }
            else {
                this.setState({
                    isResponse: true, response: result.messageText, theme: "danger",
                    isYesBtnDisabled: false, isConfirmShown: false
                });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}