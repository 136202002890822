import React, { Component } from "react";
import {
  Card,
  CardBody,
  Button,
  FormGroup,
  Container,
  Row,
  Col,
} from "shards-react";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assignedModules: [],
      isAdminUser: false,
      isAccessGiven: false,
    };
  }

  componentDidMount() {
    var token = localStorage.getItem("Token");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    var decryptToken = JSON.parse(jsonPayload);
    var userDetails = JSON.parse(decryptToken.UserDetail);
    var assignedModules = [];
    if (!userDetails.IsAdmin) {
      var moduleString = userDetails.Modules;
      assignedModules = moduleString.split(",");
    }
    this.setState({
      isAdminUser: userDetails.IsAdmin,
      assignedModules: assignedModules,
    });
  }

  render() {
    return (
      <div className="container">
        <Container fluid className="main-content-container px-4">
          <Col xs="12" sm="12" md="12" lg="12">
            <h3 className="page-title">&nbsp;</h3>
          </Col>
          <Card>
            <CardBody>
              <div style={{ marginTop: "1.875rem" }}>
                <Row>
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#7abc32",
                            borderColor: "#7abc32",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() => (window.location.href = "/list-stock")}
                        >
                          List Stock
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#7abc32",
                            borderColor: "#7abc32",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href =
                              "/list-shipped-containers-and-small-donations")
                          }
                        >
                          List Shipped Containers & Small Donations
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#7abc32",
                            borderColor: "#7abc32",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href =
                              "/view-deleted-items-and-pallets")
                          }
                        >
                          View Deleted Pallets and Items
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#7abc32",
                            borderColor: "#7abc32",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/search-for-any-item")
                          }
                        >
                          Search for Any Item
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#556b2f",
                            borderColor: "#556b2f",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/list-allocations")
                          }
                        >
                          List Allocations
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#556b2f",
                            borderColor: "#556b2f",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href =
                              "/list-allocations?location=prioritize")
                          }
                        >
                          Prioritize Allocations
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#556b2f",
                            borderColor: "#556b2f",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/allocate-stock")
                          }
                        >
                          Allocate Stock
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff0000",
                            borderColor: "#ff0000",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/send-small-donation")
                          }
                        >
                          Small Donations
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff0000",
                            borderColor: "#ff0000",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/drug-recall")
                          }
                        >
                          Drug Recall
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "4").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff8b3d",
                            borderColor: "#ff8b3d",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/bottle-room")
                          }
                        >
                          Launch Bottle Room Interface
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "2").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff8b3d",
                            borderColor: "#ff8b3d",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/medical-disposables")
                          }
                        >
                          Launch Disposables Interface
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "3").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff8b3d",
                            borderColor: "#ff8b3d",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() => (window.location.href = "/warehouse")}
                        >
                          Launch Warehouse Interface
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "5").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff8b3d",
                            borderColor: "#ff8b3d",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() => (window.location.href = "/food")}
                        >
                          Launch Food Interface
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "1").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#aaaaaa",
                            borderColor: "#aaaaaa",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/invite-user")
                          }
                        >
                          Invite User
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "1").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#aaaaaa",
                            borderColor: "#aaaaaa",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() => (window.location.href = "/view-users")}
                        >
                          View Users
                        </Button>
                      </FormGroup>
                    </Col>
                  )}

                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#5b0a91",
                            borderColor: "#5b0a91",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/rename-a-container")
                          }
                        >
                          Rename a Container
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#5b0a91",
                            borderColor: "#5b0a91",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/create-new-container")
                          }
                        >
                          Create New Container
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff77bc",
                            borderColor: "#ff77bc",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/ship-a-container")
                          }
                        >
                          Ship a Container
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff77bc",
                            borderColor: "#ff77bc",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/unship-a-container")
                          }
                        >
                          Unship a Container
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff77bc",
                            borderColor: "#ff77bc",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() => (window.location.href = "/food-list")}
                        >
                          Create Food List
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff77bc",
                            borderColor: "#ff77bc",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/export-list")
                          }
                        >
                          Create Export List
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "6").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#ff77bc",
                            borderColor: "#ff77bc",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/adjust-prices")
                          }
                        >
                          Adjust Prices
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                  {(this.state.isAdminUser ||
                    this.state.assignedModules.filter((x) => x === "1").length >
                      0) && (
                    <Col sm="3" lg="3">
                      <FormGroup>
                        <Button
                          disabled={false}
                          style={{
                            width: "100%",
                            height: "100px",
                            backgroundColor: "#6495ed",
                            borderColor: "#6495ed",
                            boxShadow: "none",
                          }}
                          size="lg"
                          onClick={() =>
                            (window.location.href = "/maintenance")
                          }
                        >
                          Maintenance
                        </Button>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <Row></Row>
              </div>
            </CardBody>
          </Card>
          <br></br>
        </Container>
      </div>
    );
  }
}
