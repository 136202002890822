export const sortByAlphanumerically = (givenArray, property) => {
    let alphanumericallySortedArray = [];
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    switch (property) {
        case 'id':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.id, b.id));
            break;
        case 'description':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.description, b.description));
            break;
        case 'Description':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.Description, b.Description));
            break;
        case 'Name':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.Name, b.Name));
            break;
        case 'displaydescription':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.displaydescription, b.displaydescription));
            break;
        case 'nonpalletitemdescription':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.nonpalletitemdescription, b.nonpalletitemdescription));
            break;
        case 'PalletNumber':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.PalletNumber, b.PalletNumber));
            break;
        case 'pallet_number':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.pallet_number, b.pallet_number));
            break;
        case 'number_of_qty_to_allocated_id':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.number_of_qty_to_allocated_id, b.number_of_qty_to_allocated_id));
            break;
        case 'expiry_date':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.expiry_date, b.expiry_date));
            break;
        case 'category':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.category, b.category));
            break;
        case 'location':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.location, b.location));
            break;
        case 'date_deleted':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.date_deleted, b.date_deleted));
            break;
        case 'username':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.username, b.username));
            break;
        case 'modulename':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.modulename, b.modulename));
            break;
        case 'product_category_description':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.product_category_description, b.product_category_description));
            break;
        case 'sort_item_description':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.sort_item_description, b.sort_item_description));
            break;
        case 'sort_country':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.sort_country, b.sort_country));
            break;
        case 'expirydate':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.expirydate, b.expirydate));
            break;
        case 'description_with_qty':
            alphanumericallySortedArray = givenArray.sort((a, b) => collator.compare(a.description_with_qty, b.description_with_qty));
            break;
    }
    console.log(alphanumericallySortedArray);
    return alphanumericallySortedArray;
}