import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem
} from "shards-react";

export class Warehouse extends Component {

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const DarkBuleButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#0000A0",
            borderColor: "#0000A0",
        };

        const BlueButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#0000FF",
            borderColor: "#0000FF",
        };

        const RedButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#FF0000",
            borderColor: "#FF0000"
        };

        const PurpleButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#800080",
            borderColor: "#800080",
        };

        const GreenButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#708B39",
            borderColor: "#708B39",
        };

        const lightGreenButton = {
            width: "100%",
            height: "100px",
            boxShadow: "none",
            backgroundColor: "#7abc32",
            borderColor: "#7abc32",
        };


        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Warehouse Interface</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop:"2%"}}>
                        <CardBody>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/new-pallet-warehouse"}>Make New Pallet​</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/move-pending-pallet"}>Choose storage location for "Pending" Pallet​</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/move-pallet"}>Move or load Pallet</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/edit-pallet/?location=warehouse"}>Edit a Pallet</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={BlueButton} size="lg" onClick={() => window.location.href = "/finish-pallet/?location=warehouse"}>Finish Pallet</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={PurpleButton} size="lg" onClick={() => window.location.href = "/store-non-pallet-item"}>Store Non Pallet Item</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={PurpleButton} size="lg" onClick={() => window.location.href = "/relocate-non-pallet-item"}>Move or load ​Non-pallet item</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={DarkBuleButton} size="lg" onClick={() => window.location.href = "/delete-pallet-or-item"}>Delete Pallet or item</Button> 
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={GreenButton} size="lg" onClick={() => window.location.href = "/report-on-storage-locations"}>Report on Storage locations</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={GreenButton} size="lg" onClick={() => window.location.href = "/search-for-pallet"}>Search for Pallet</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={GreenButton} size="lg" onClick={() => window.location.href = "/list-allocations?location=viewcontainer"}>View Container​ Allocations</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={GreenButton} size="lg" onClick={() => window.location.href = "/pallet-contents"}>Report on  Individual ​Pallet Contents</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>

                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={lightGreenButton} size="lg" onClick={() => window.location.href = "/search-for-any-item?location=warehouse"}>Search for Any Item</Button>
                                    </FormGroup>
                                </Col>


                                <Col sm="12" lg="4" lg="4">
                                    <FormGroup>
                                        <Button style={RedButton} size="lg" onClick={() => window.location.href = "/send-small-donation?location=warehouse"}>Make a Small Donation</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                </Col>
                                <Col sm="12" lg="4" lg="4">
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
            </>
            );
    }
}