import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, Alert,
    FormInput, Modal, ModalBody
} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { StorageLocation } from "../common/StorageLocation";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class StoreNonPalletItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            selectedPrimaryLocation: [],
            validPrimaryLocation: false,

            selectedSecondaryLocation: [],
            validSecondaryLocation: false,
            isSecondaryLocationLoading: false,

            numberOfItem: "",
            responseNumberOfItem: "",
            validNumberOfItem: false,

            weight: "",
            responseWeight: "",
            validWeight: false,

            nonPalletItems: [],
            selectedNonPalletItem: [],
            responseNonPalletItem: "",
            nonPalletItemDescription: "",
            nonPalletItemID: "",
            validNonPalletItem: false,
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],

            showConfirmation: false,
            yesBtnText: "Yes",
            isYesBtnDisabled: false,
        };
        this.nonPalletItem = React.createRef();
        this.StorageLocation = React.createRef();
    }

    componentDidMount() {
        this.getNonPalletItems();
    }
    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Store Non Pallet item​</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>CHOOSE LOCATION FOR NON PALLET ITEMS</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <form id="myForm">

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#selectItem">Select item :<span className="error-txt">*</span></label>
                                            <Typeahead size="lg" selected={this.state.selectedNonPalletItem}
                                                id="Id"
                                                labelKey="Description"
                                                onChange={this.setNonPalletItem}
                                                options={this.state.nonPalletItems}
                                                placeholder=""
                                                ref={this.nonPalletItem}
                                                emptyLabel={this.state.emptyLabel}
                                            />
                                            <span className="error-txt">{this.state.responseNonPalletItem}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Number of item(s) :<span className="error-txt">*</span></label>
                                            <FormInput size="lg" type="number" id="#NumberOfItem" value={this.state.numberOfItem} onChange={this.setNumberOfItem} min="1" />
                                            <span className="error-txt">{this.state.responseNumberOfItem}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#weight">Individual item’s weight(kg) :<span className="error-txt">*</span></label>
                                            <FormInput size="lg" type="number" id="#weight" value={this.state.weight} onChange={this.setWeight} min="1" />
                                            <span className="error-txt">{this.state.responseWeight}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <StorageLocation ref={this.StorageLocation}
                                    getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                    getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                    getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                    getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                    getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                                />

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <Button theme="primary" size="lg" onClick={this.onConfirmClick} type="submit">Confirm</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </form>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>CHOOSE LOCATION FOR NON PALLET ITEMS - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to locate {this.state.numberOfItem} of {this.state.nonPalletItemDescription} in location {this.state.selectedPrimaryLocation[0].description}
                                      {this.state.selectedSecondaryLocation.length !== 0 && (<>({this.state.selectedSecondaryLocation[0].Name})</>)}?</label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>

                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ validPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ validSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    resetStorageLocation() {
        this.refs.StorageLocation.resetStorageLocation();
    }

    validatePrimaryLocation() {
        this.refs.StorageLocation.validatePrimaryLocation();
    }

    validateSecondaryLocation() {
        this.refs.StorageLocation.validateSecondaryLocation();
    }

    getNonPalletItems = () => {
        axios.get(ApiUrls.getNonPalletItems, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var nonPalletItems = JSON.parse(response.data.data);
                var sortNonPalletItems = sortByAlphanumerically(nonPalletItems, 'Description');
                this.setState({ nonPalletItems: sortNonPalletItems, selectedNonPalletItem: [], emptyLabel: "No matches found"});
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    setNonPalletItem = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({
                selectedNonPalletItem: value, nonPalletItemDescription: value[0].Description,
                nonPalletItemID: value[0].Id, responseNonPalletItem: "", validNonPalletItem: true
            });
        } else {
            this.setState({
                selectedNonPalletItem: [], nonPalletItemDescription: "", nonPalletItemID: "",
                responseNonPalletItem: "Please select a item", validNonPalletItem: false
            });
        }
    };

    validateNonPalletItem = () => {
        if (this.state.selectedNonPalletItem.length === 0) {
            this.setState({ responseNonPalletItem: "Please select a item", validNonPalletItem: false });
        } else {
            this.setState({ responseNonPalletItem: "", validNonPalletItem: true });
        }
    };

    setNumberOfItem = (value) => {
        this.setState({ numberOfItem: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    validateNumberOfItem = () => {
        if (!(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(this.state.numberOfItem)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    setWeight = (value) => {
        this.setState({ weight: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (value.target.value < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    validateWeight = () => {
        if (!(this.state.weight)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(this.state.weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (this.state.weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false, isResponse: false });
    }

    onConfirmClick = (event) => {
        event.preventDefault();
        this.validateNonPalletItem();
        this.validateNumberOfItem();
        this.validateWeight();
        this.StorageLocation.current.validatePrimaryLocation();
        if (!this.state.isSecondaryLocationLoading && !this.state.validSecondaryLocation) {
            this.StorageLocation.current.validateSecondaryLocation();
        }
        const { validNonPalletItem, validNumberOfItem, validWeight, validPrimaryLocation, validSecondaryLocation, isSecondaryLocationLoading } = this.state;
        if (validNonPalletItem && validNumberOfItem && validWeight && validPrimaryLocation && validSecondaryLocation && !isSecondaryLocationLoading) {
            this.setState({ showConfirmation: true });
        }
    }

    onYesClick = () => {
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });

        if (this.state.selectedSecondaryLocation.length !== 0) {
            var NonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.nonPalletItemID),
                NumberOfItem: parseInt(this.state.numberOfItem),
                IndividualItemWeight: parseFloat(this.state.weight),
                LocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryStorageLocationId: parseInt(this.state.selectedSecondaryLocation[0].Id),
            };
        } else {
            var NonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.nonPalletItemID),
                NumberOfItem: parseInt(this.state.numberOfItem),
                IndividualItemWeight: parseFloat(this.state.weight),
                LocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryStorageLocationId: 0,
            };
        }
        axios.post(ApiUrls.addNonPalletItemToStock, NonPalletItemsInStockInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.nonPalletItem.current.clear();
                this.StorageLocation.current.resetStorageLocation();
                this.setState({
                    isResponse: true, theme: "success", response: response.data.messageText,
                    numberOfItem: "", responseNumberOfItem: "", validNumberOfItem: false,
                    weight: "", responseWeight: "", validWeight: false,
                    selectedNonPalletItem: [], responseNonPalletItem: "",
                    nonPalletItemDescription: "", nonPalletItemID: "", validNonPalletItem: false,
                    showConfirmation: false, yesBtnText: "Yes", isYesBtnDisabled: false,
                });
            }
            else {
                this.setState({
                    isResponse: true, response: response.data.messageText, theme: "danger",
                    isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
                });
            }
        }).catch((error) => {
            this.showError(error);           
        });
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger",
                isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
            });
        }
    }
}