import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Modal,
  ModalBody,
  FormGroup,
  Alert,
} from "shards-react";
import axios from "axios";
import { DateInput } from "@opuscapita/react-dates";
import { ApiUrls } from "../../environments/apiUrls";
import { getSelectedDateFromDatePicker } from "../../utils/formatDateFromDatePicker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { sortDescendingByAlphanumerically } from "../../utils/sortDescending";

const authorizationHeader = {
  headers: { Authorization: "Bearer " + localStorage.getItem("Token") },
};

export class UnshipContainer extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      isResponse: false,
      isValidStartDate: false,
      isValidEndDate: false,
      isEndDateDisabled: true,
      isConfirmShowing: false,
      isDataLoading: false,
      isSearchBtnPressed: false,
      isProcessing: false,
      theme: "",
      response: "",
      startDate: "",
      endDate: "",
      errorResponseForStartDate: "",
      errorResponseForEndDate: "",
      today: "",
      shippedContainersList: [],
      containerName: "",
      selectedContainer: {},
      visible: false,
      countdown: 0,
      timeUntilDismissed: 5,
    };

    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  componentDidMount() {
    this.getTodayDate();
  }

  render() {
    const customStyle = {
      paddingRight: "0px",
      paddingLeft: "0px",
    };
    const columnsShippedContainer = [
      {
        text: "Container name",
        dataField: "container_name",
        style: {
          fontWeight: "normal",
        },
      },
      {
        text: "Shipped country",
        dataField: "country",
        style: {
          fontWeight: "normal",
        },
      },
      {
        text: "Date shipped",
        formatter: this.getDateShipped,
        style: {
          fontWeight: "normal",
        },
      },
      {
        text: "Unship",
        formatter: this.doUnship,
        style: {
          fontWeight: "normal",
        },
      },
    ];
    const {
      isResponse,
      theme,
      response,
      startDate,
      today,
      errorResponseForStartDate,
      endDate,
      isEndDateDisabled,
      errorResponseForEndDate,
      isDataLoading,
      isSearchBtnPressed,
      shippedContainersList,
      containerName,
      isConfirmShowing,
      isProcessing,
    } = this.state;
    return (
      <>
        <Col sm="12" md="1" lg="2" style={customStyle}></Col>
        <Col sm="12" md="10" lg="8" style={customStyle}>
          <CardTitle style={{ marginTop: "2%" }}>
            <Breadcrumb>
              <BreadcrumbItem>
                <a href="/dashboard">Home</a>
              </BreadcrumbItem>
              <BreadcrumbItem active>Unship a Container</BreadcrumbItem>
            </Breadcrumb>
          </CardTitle>
          <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
            <CardBody>
              <Row>
                <Col sm="12" md="12" lg="12">
                  <h4>UNSHIP A CONTAINER</h4>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="12" lg="12">
                  {isResponse && (
                    <Alert theme={theme} open={this.state.visible}>
                      {response}
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <FormGroup>
                    <label style={{ fontSize: "large" }} htmlFor="#startDate">
                      Date from :<span className="error-txt">*</span>
                    </label>
                    <DateInput
                      id="#startDate"
                      value={startDate}
                      dateFormat="dd/MM/yyyy"
                      locale="en"
                      onChange={this.setStartDate}
                      max={today}
                      modifiers={{
                        disabled: { after: new Date() },
                      }}
                    />
                    <span className="error-txt">
                      {errorResponseForStartDate}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <FormGroup>
                    <label style={{ fontSize: "large" }} htmlFor="#endDate">
                      Date to :<span className="error-txt">*</span>
                    </label>
                    <DateInput
                      id="#endDate"
                      value={endDate}
                      dateFormat="dd/MM/yyyy"
                      locale="en"
                      onChange={this.setEndDate}
                      min={startDate}
                      max={today}
                      disabled={isEndDateDisabled}
                      modifiers={{
                        disabled: {
                          before: startDate,
                          after: new Date(),
                        },
                      }}
                    />
                    <span className="error-txt">{errorResponseForEndDate}</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="10" lg="8">
                  <FormGroup>
                    <Button
                      theme="primary"
                      size="lg"
                      onClick={this.handleSearch}
                    >
                      Search
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              {isDataLoading && (
                <label style={{ fontWeight: "bold" }}>Loading...</label>
              )}
              {!isDataLoading && (
                <>
                  {isSearchBtnPressed && (
                    <>
                      {shippedContainersList.length ? (
                        <>
                          <BootstrapTable
                            keyField="id"
                            data={shippedContainersList}
                            columns={columnsShippedContainer}
                            wrapperClasses="table-responsive"
                            pagination={paginationFactory()}
                          />
                          <br />
                        </>
                      ) : (
                        <Alert theme="warning">No Data Found</Alert>
                      )}
                    </>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="1" lg="2" style={customStyle}></Col>
        {isConfirmShowing && (
          <Modal
            open={isConfirmShowing}
            toggle={() =>
              this.setState({ isConfirmShowing: !isConfirmShowing })
            }
            centered={true}
            className={"custom-modal"}
          >
            <ModalBody>
              <Row>
                <Col>
                  <h2>UNSHIP CONTAINER - CONFIRMATION</h2>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm="12" md="12" lg="12">
                  <label
                    style={{
                      fontSize: "x-large",
                      textAlign: "justify",
                      textJustify: "inter-word",
                    }}
                  >
                    You are about to unship <b>{containerName}</b> container. Do
                    you want to proceed?
                  </label>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col>
                  <Button
                    theme="success"
                    size="lg"
                    onClick={this.handleYesBtn}
                    disabled={isProcessing}
                  >
                    {isProcessing ? "Loading..." : "Yes"}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    theme="secondary"
                    size="lg"
                    onClick={() =>
                      this.setState({
                        isConfirmShowing: false,
                        selectedOption: {},
                      })
                    }
                    disabled={isProcessing}
                  >
                    No
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }

  getTodayDate = () => {
    let date = new Date(),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let today = [year, month, day].join("-");
    this.setState({ today: new Date(today) });
  };

  setStartDate = (value) => {
    this.setState({ startDate: value });
    const { today } = this.state;
    if (!value)
      this.setState({
        errorResponseForStartDate: "Please enter date from",
        isValidStartDate: false,
        isEndDateDisabled: true,
        endDate: "",
        isValidEndDate: false,
      });
    else if (value > today)
      this.setState({
        errorResponseForStartDate: "Please enter past date",
        isValidStartDate: false,
        isEndDateDisabled: true,
        endDate: "",
        isValidEndDate: false,
      });
    else
      this.setState({
        errorResponseForStartDate: "",
        isValidStartDate: true,
        isEndDateDisabled: false,
        endDate: today,
        isValidEndDate: true,
      });
  };

  validateStartDate = () => {
    const { startDate, today } = this.state;
    if (!startDate)
      this.setState({
        errorResponseForStartDate: "Please enter date from",
        isValidStartDate: false,
      });
    else if (startDate > today)
      this.setState({
        errorResponseForStartDate: "Please enter past date",
        isValidStartDate: false,
      });
    else
      this.setState({ errorResponseForStartDate: "", isValidStartDate: true });
  };

  setEndDate = (value) => {
    this.setState({ endDate: value });
    const { startDate, today } = this.state;
    if (!value)
      this.setState({
        errorResponseForEndDate: "Please enter date to",
        isValidEndDate: false,
      });
    else if (value < startDate)
      this.setState({
        errorResponseForEndDate: "Please enter future date",
        isValidEndDate: false,
      });
    else if (value > today)
      this.setState({
        errorResponseForEndDate: "Please enter past date",
        isValidEndDate: false,
      });
    else this.setState({ errorResponseForEndDate: "", isValidEndDate: true });
  };

  validateEndDate = () => {
    const { endDate, startDate, today } = this.state;
    if (!endDate)
      this.setState({
        responseExpiryDate: "Please enter date to ",
        isValidEndDate: false,
      });
    else if (endDate < startDate)
      this.setState({
        responseExpiryDate: "Please enter future date",
        isValidEndDate: false,
      });
    else if (endDate > today)
      this.setState({
        errorResponseForEndDate: "Please enter past date",
        isValidEndDate: false,
      });
    else this.setState({ errorResponseForEndDate: "", isValidEndDate: true });
  };

  handleSearch = () => {
    this.validateStartDate();
    this.validateEndDate();
    const { startDate, endDate, isValidStartDate, isValidEndDate } = this.state;
    let startDateInIsoFormat = getSelectedDateFromDatePicker(startDate);
    let endDateInIsoFormat = getSelectedDateFromDatePicker(endDate);
    if (isValidStartDate && isValidEndDate) {
      this.setState({
        isDataLoading: true,
        shippedContainersList: [],
        isSearchBtnPressed: true,
      });
      axios
        .get(
          `${ApiUrls.getShippedContainers}?startDate=${startDateInIsoFormat}&endDate=${endDateInIsoFormat}`,
          authorizationHeader
        )
        .then(({ data: result }) => {
          if (result.isSuccess) {
            let shippedContainersList = JSON.parse(result.data);
            shippedContainersList = sortDescendingByAlphanumerically(
              shippedContainersList,
              "shipped_date"
            );
            this.setState({
              shippedContainersList: shippedContainersList,
              isDataLoading: false,
            });
          }
        })
        .catch((error) => {
          this.showError(error);
        });
    }
  };

  getDateShipped = (cell, row) => {
    let dateShipped = row.shipped_date;
    let date = dateShipped
      ? getDateInGivenFormat(dateShipped.slice(0, 10))
      : "";
    return <div>{date}</div>;
  };

  doUnship = (cell, row) => {
    return (
      <Button theme="danger" onClick={(e) => this.handleUnship(e, row)} block>
        Unship
      </Button>
    );
  };

  handleUnship = (e, row) => {
    this.setState({
      selectedContainer: {
        id: row.container_id,
      },
      containerName: row.container_name,
      isConfirmShowing: true,
      isResponse: false,
      response: "",
      isProcessing: false,
    });
  };

  handleYesBtn = () => {
    this.setState({
      isResponse: false,
      theme: "",
      response: "",
      isProcessing: true,
    });
    const { selectedContainer, startDate, endDate } = this.state;
    let startDateInIsoFormat = getSelectedDateFromDatePicker(startDate);
    let endDateInIsoFormat = getSelectedDateFromDatePicker(endDate);
    axios
      .post(ApiUrls.unshipContainer, selectedContainer, authorizationHeader)
      .then(({ data: result }) => {
        if (result.isSuccess) {
          this.clearInterval();
          this.setState({
            isResponse: true,
            theme: "success",
            response: result.messageText,
            isDataLoading: true,
            isConfirmShowing: false,
            shippedContainersList: {},
            isSearchBtnPressed: true,
            visible: true,
            countdown: 0,
            timeUntilDismissed: 5,
          });
          this.interval = setInterval(this.handleTimeChange, 1000);
          axios
            .get(
              `${ApiUrls.getShippedContainers}?startDate=${startDateInIsoFormat}&endDate=${endDateInIsoFormat}`,
              authorizationHeader
            )
            .then(({ data: result }) => {
              if (result.isSuccess) {
                let shippedContainersList = JSON.parse(result.data);
                shippedContainersList = sortDescendingByAlphanumerically(
                  shippedContainersList,
                  "shipped_date"
                );
                this.setState({
                  shippedContainersList: shippedContainersList,
                  isDataLoading: false,
                });
              }
            })
            .catch((error) => {
              this.showError(error);
              console.log(error);
            });
        } else {
          console.log("error");
          this.clearInterval();
          this.setState({
            isResponse: true,
            theme: "danger",
            response: result.Message,
            isConfirmShowing: false,
            isProcessing: false,
            visible: true,
            countdown: 0,
            timeUntilDismissed: 5,
          });
          this.interval = setInterval(this.handleTimeChange, 1000);
        }
      })
      .catch((error) => {
        this.showError(error);
        console.log(error);
      });
  };

  showError = (error) => {
    if (error?.response?.status === 401) {
      window.localStorage.removeItem("Token");
      window.location.href = "/login";
    } else {
      this.clearInterval();
      this.setState({
        isResponse: true,
        response: "Something went wrong",
        theme: "danger",
        isConfirmShowing: false,
        visible: true,
        countdown: 0,
        timeUntilDismissed: 5,
      });
      this.interval = setInterval(this.handleTimeChange, 1000);
    }
  };

  // Alert timer handle
  showAlert() {
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }
  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 },
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }
  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }
}
