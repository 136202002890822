import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, Modal, ModalBody,
    Alert
} from "shards-react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from "axios";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { parseJSON } from 'jquery';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'; 
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ViewUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: true,
            isConfirmShowing: false,
            isResponse: false,
            isProcessing: false,
            selectedUserEmail: "",
            theme: "",
            response: "",
            selectedOption: "",
            users: []
        };
    }

    componentDidMount() {
        axios.get(ApiUrls.getAllActiveUsers, authorizationHeader).then(({ data: result}) => {
            let users = parseJSON(result.data);
            let sortedUsers = sortByAlphanumerically(users, 'username');
            this.setState({ users: sortedUsers, isDataLoading: false })
        }).catch((error) => {
            this.showError(error);
        });
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const columns =
            [
                {
                    text: 'Username',
                    dataField: 'username',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Role',
                    dataField: 'role',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Edit/View',
                    formatter: this.addEditButton
                },
                {
                    text: 'Reset password',
                    formatter: this.addResetButton
                },
                {
                    text: 'Remove',
                    formatter: this.addRemoveButton
                },
            ];
        const { SearchBar } = Search;
        const { users, isResponse, theme, response,
            isDataLoading, isConfirmShowing, selectedOption, isProcessing } = this.state;
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            totalSize: users.length
        };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div style={{ overflowX: "auto" }}>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="userid"
                    columns={columns}
                    data={users}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
            );
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>View Users</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>ACTIVE USERS</h4>
                                </Col>                              
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!isDataLoading && (
                                        <PaginationProvider
                                            pagination={
                                                paginationFactory(options)
                                            }
                                        >
                                            { contentTable}
                                        </PaginationProvider>
                                    )}                                   
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                {isConfirmShowing && (
                    <Modal open={isConfirmShowing} toggle={() => this.setState({ isConfirmShowing: !isConfirmShowing })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>REMOVE USER - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                {selectedOption === "remove" && (
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>You are about to remove this user from the system. Do you want to proceed? </label>
                                    </Col>
                                )}
                                {selectedOption === "reset" && (
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to reset the password? </label>
                                    </Col>
                                )}
                            </Row>
                            <br></br>
                            <Row>
                                <Col>
                                    <Button theme="success" size="lg" onClick={this.handleYesBtn} disabled={isProcessing}>{isProcessing ? "Loading..." : "Yes"}</Button>
                                </Col>
                                <Col>
                                    <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ isConfirmShowing: false, selectedOption: "", selectedUserEmail: "" })}>No</Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>         
            );
    }

    addEditButton = (cell, row) => {
        return (
            <Button theme="primary" onClick={e => this.handleEditUser(e, row)} style={{ width: "100%", height: "100%" }}>
                {row.isadmin ? "View User" : "Edit User"}
            </Button>);
    };

    addResetButton = (cell, row) => {
        return (
            <Button theme="success" onClick={e => this.handleResetPassword(e, row)} style={{ width: "100%", height: "100%" }}>
                Reset Password
            </Button>);
    };

    addRemoveButton = (cell, row) => {
        return (
            <Button theme="danger" onClick={e => this.handleRemoveUser(e, row)} style={{ width: "100%", height: "100%" }}>
                Remove User
            </Button>);
    };

    handleEditUser = (e, row) => {
        window.location.href = `/edit-user/?userid=${row.userid}`;
    };

    handleRemoveUser = (e, row) => {
        this.setState({
            selectedUserEmail: row.username, isConfirmShowing: true,
            isResponse: false, response: "", isProcessing: false,
            selectedOption: "remove"
        });
    };

    handleResetPassword = (e, row) => {
        this.setState({
            selectedUserEmail: row.username, isConfirmShowing: true,
            isResponse: false, response: "", isProcessing: false,
            selectedOption: "reset"
        });
    };

    handleYesBtn = () => {
        this.setState({ isResponse: false, theme: "", response: "", isProcessing: true });
        const { selectedOption } = this.state;
        if (selectedOption === "remove")
            this.removeUser();
        else if (selectedOption === "reset")
            this.resetPassword();
    };

    removeUser = () => {
        const { selectedUserEmail } = this.state;
        let removedUserInfo = {
            EmailAddress: selectedUserEmail
        }
        axios.post(ApiUrls.removeUser, removedUserInfo, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success",
                    response: result.messageText, isDataLoading: true,
                    isConfirmShowing: false
                });
                axios.get(ApiUrls.getAllActiveUsers, authorizationHeader).then(({ data: result }) => {
                    let users = parseJSON(result.data);
                    this.setState({ users, isDataLoading: false, isProcessing: false });
                }).catch((error) => {
                    this.showError(error);
                });
                setTimeout(() => this.setState({ isResponse: false }), 10000);
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: result.Message,
                    isConfirmShowing: false, isProcessing: false
                });
            }
        }).catch((error) => {
            this.showError(error);         
        });
    };

    resetPassword = () => {
        const { selectedUserEmail } = this.state;
        let emailInfo = {
            EmailAddress: selectedUserEmail
        }
        axios.post(ApiUrls.passwordReset, emailInfo, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success", response: "Password reset link has been sent to user email",
                    isDataLoading: true, isConfirmShowing: false
                });
                axios.get(ApiUrls.getAllActiveUsers, authorizationHeader).then(({ data: result }) => {
                    let users = parseJSON(result.data);
                    this.setState({ users, isDataLoading: false, isProcessing: false });
                }).catch((error) => {
                    this.showError(error);
                });
                setTimeout(() => this.setState({ isResponse: false }), 10000);
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger",
                    response: result.Message, isConfirmShowing: false,
                    isProcessing: false
                });
            }
        }).catch((error) => {
            this.showError(error);  
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, theme: "danger",
                response: (error.Message || "Something went wrong"), isConfirmShowing: false
            });
        }
    }
}