import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Form, FormInput, FormGroup,
    Alert
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

export class UpdatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: "",
            newPassword: "",
            reEnterPassword: "",

            isErrorInResetPassword: false,
            errorMessage: "",

            buttonText: "Update Password",

            isUpdatePasswordBtnDisabled:true
        };
    }

    componentDidMount() {
        this.setState({ token: this.props.location.search.split("=").pop() });
    }


    render() {
        return (
            <Card style={{ maxWidth: "25rem", marginTop: "8%", marginLeft: "auto", marginRight: "auto" }}>
                <CardHeader style={{
                    fontWeight: "bold", fontSize: "20px", textAlign: "center"
                }} class="card text-center">Update Password</CardHeader>
                <CardBody>
                    <Form>
                        {this.state.isErrorInResetPassword && (
                            <FormGroup>
                                <Alert theme="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            </FormGroup>
                        )}
                       
                        <FormGroup>
                            <label htmlFor="#newPassword">New Password</label>
                            <FormInput type="password" id="#newPassword" value={this.state.newPassword} placeholder="New Password" required onInput={this.onNewPasswordchanged} onChange={this.onNewPasswordchanged} />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="#reEnterPassword">Confirm Password</label>
                            <FormInput type="password" id="#reEnterPassword" value={this.state.reEnterPassword} placeholder="Confirm Password" required onInput={this.onReEnterPasswordchanged} onChange={this.onReEnterPasswordchanged} />
                        </FormGroup>

                        <FormGroup>
                            <Button style={{ width: "100%" }} onClick={this.onResetPasswordBtnClick} disabled={this.state.isUpdatePasswordBtnDisabled} type="submit">{this.state.buttonText}</Button>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        );
    }

    onNewPasswordchanged = (event) => {
        let newPassword = event.target.value;
        if (this.state.reEnterPassword && newPassword) {
            this.setState({ newPassword: newPassword, isErrorInResetPassword: false, isUpdatePasswordBtnDisabled: false, errorMessage: "" });
        }
        else {
            this.setState({ newPassword: newPassword, isErrorInResetPassword: false, isUpdatePasswordBtnDisabled: true, errorMessage: "" });
        }
    }

    onReEnterPasswordchanged = (event) => {
        let reEnterPassword = event.target.value;
        if (this.state.newPassword && reEnterPassword) {
            this.setState({ reEnterPassword: reEnterPassword, isErrorInResetPassword: false, isUpdatePasswordBtnDisabled: false, errorMessage: "" });
        }
        else {
            this.setState({ reEnterPassword: reEnterPassword, isErrorInResetPassword: false, isUpdatePasswordBtnDisabled: true, errorMessage: "" });
        }
    }

    onResetPasswordBtnClick = (event) => {
        event.preventDefault();
        this.setState({ buttonText: "Loading...", isUpdatePasswordBtnDisabled: true, isErrorInResetPassword: false, errorMessage: "" });
        const { newPassword, reEnterPassword, token } = this.state;
        if (newPassword !== reEnterPassword) {
            this.setState({ isErrorInResetPassword: true, errorMessage: "Passwords don't match", newPassword: "", reEnterPassword: "", buttonText: "Update Password", isUpdatePasswordBtnDisabled: true });
        } else {

            if (!token) {
                this.setState({ isErrorInResetPassword: true, errorMessage: "Use the correct link", newPassword: "", reEnterPassword: "", buttonText: "Update Password", isUpdatePasswordBtnDisabled: true });
            } else {
                var newPasswordInfo = {
                    NewPassword: this.state.newPassword.trim(),
                    Token: this.state.token
                }
                axios.post(ApiUrls.updatePassword, newPasswordInfo).then((response) => {
                    console.log(response);
                    if (response.data.isSuccess) {
                        
                        window.location.href = "/login";
                    }
                    else {
                        this.setState({ isErrorInResetPassword: true, errorMessage: response.data.messageText, newPassword: "", reEnterPassword: "", buttonText: "Update Password", isUpdatePasswordBtnDisabled: true});
                    }

                }).catch((error) => {
                    this.setState({ isErrorInResetPassword: true, errorMessage: "Something went wrong", buttonText: "Update Password", isUpdatePasswordBtnDisabled: true });
                });
            }
        }
    }
}