import React, { Component } from 'react';
import {
    Card, CardHeader, CardBody, Button, FormInput,
    FormGroup, Alert, Container
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            currentPassword: "",

            newPassword: "",
            isErrorInNewPassword: false,
            errorMessageForNewPassword: "",
            isSuccessInNewPassword: false,
            successMessageForNewPassword: "",

            confirmPassword: "",
            isErrorInConfirmPassword: false,
            errorMessageForConfirmPassword: "",
            isSuccessInConfirmPassword: false,
            successMessageForConfirmPassword: "",

            errorMessage: "",
            isErrorInUpdatePassword: false,

            successMessage: "",
            isSuccess: false,

            isUpdateBtnDisabled: true,
            buttonText: "Update Password",
        };
    }

    componentDidMount() {
        this.setState({ token: this.props.location.search.split("=").pop() });
    }


    render() {
        return (
            <Container fluid className="dr-example-container">  
            <Card style={{ maxWidth: "25rem", marginTop: "8%", marginLeft: "auto", marginRight: "auto" }}>
                <CardHeader style={{
                    fontWeight: "bold", fontSize: "20px", textAlign: "center"
                }} class="card text-center">Update Password</CardHeader>
                <CardBody>
                    <form>

                        {this.state.isErrorInUpdatePassword && (
                            <FormGroup>
                                <Alert theme="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            </FormGroup>
                        )}

                        {this.state.isSuccess && (
                            <FormGroup>
                                <Alert theme="success">
                                    {this.state.successMessage}
                                </Alert>
                            </FormGroup>
                        )}

                        <FormGroup>
                            <label htmlFor="#CurrentPassword">Current Password</label>
                            <FormInput type="password" id="#Password" value={this.state.currentPassword} placeholder="Current Password" required onChange={this.onCurrentPasswordchanged} />
                            
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="#NewPassword">New Password</label>
                            <FormInput type="password" id="#NewPassword" value={this.state.newPassword} placeholder="New Password" required onChange={this.onNewPasswordchanged} />
                            {this.state.isErrorInNewPassword && (<div className="invalid-feedback d-block">{this.state.errorMessageForNewPassword}</div>)}
                            {this.state.isSuccessInNewPassword && (<div className="valid-feedback d-block">{this.state.successMessageForNewPassword}</div>)}
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="#ConfirmPassword">Confirm Password</label>
                            <FormInput type="password" id="#ConfirmPassword" value={this.state.confirmPassword} placeholder="Confirm Password" required onChange={this.onConfirmPasswordchanged} />
                            {this.state.isErrorInConfirmPassword && (<div className="invalid-feedback d-block">{this.state.errorMessageForConfirmPassword}</div>)}
                            {this.state.isSuccessInConfirmPassword && (<div className="valid-feedback d-block">{this.state.successMessageForConfirmPassword}</div>)}
                        </FormGroup>

                        <FormGroup>
                            <Button style={{ width: "100%" }} onClick={this.onUpdateBtnClick} disabled={this.state.isUpdateBtnDisabled} type="submit">{this.state.buttonText}</Button>
                        </FormGroup>

                    </form>
                </CardBody>
                </Card>
            </Container>
        );
    }


    onCurrentPasswordchanged = (event) => {
        let password = event.target.value;
        var isBtnDisabled = false;
        if (this.state.newPassword && this.state.confirmPassword && password) {
            isBtnDisabled = false;
        }
        else {
            isBtnDisabled = true;
        }
        this.setState({ currentPassword: password, isUpdateBtnDisabled: isBtnDisabled, isErrorInUpdatePassword: false, errorMessage: "" });
    }

    onNewPasswordchanged = (event) => {
        let newPassword = event.target.value;
        var isBtnDisabled = false;
        if (this.state.currentPassword && this.state.confirmPassword && newPassword) {
            isBtnDisabled = false;
        }
        else {
            isBtnDisabled = true;
        }
        this.setState({ newPassword: newPassword, isUpdateBtnDisabled: isBtnDisabled, isErrorInUpdatePassword: false, errorMessage: "" });

    }

    onConfirmPasswordchanged = (event) => {
        let confirmPassword = event.target.value;
        var isBtnDisabled = false;
        if (this.state.currentPassword && this.state.newPassword && confirmPassword) {
            isBtnDisabled = false;
        }
        else {
            isBtnDisabled = true;
        }
        this.setState({ confirmPassword: confirmPassword, isUpdateBtnDisabled: isBtnDisabled, isErrorInUpdatePassword: false, errorMessage: "" });

    }

    onUpdateBtnClick = (event) => {
        event.preventDefault();
        //var strongRegex = new RegExp("(?=.{8,})");
        //const  strongPasswordErrorMessage = "New password must contain at least one lowercase alphabetical character, one uppercase alphabetical character, one numeric character, one special character avoid this character [!,@,#,$,%,^,&,*], and must be eight characters or longer"
        this.setState({ buttonText: "Loading...", isUpdateBtnDisabled: true });
        const { currentPassword, newPassword, confirmPassword} = this.state;

        if (currentPassword && newPassword && confirmPassword) {
            if (confirmPassword.trim() === newPassword.trim()) {
                var updatePasswordInfo = {
                    CurrentPassword: currentPassword.trim(),
                    NewPassword: newPassword.trim(),
                }
                axios.post(ApiUrls.changePassword, updatePasswordInfo, authorizationHeader).then((response) => {
                    if (response.data.isSuccess) {
                        
                        window.location.href = "/";
                    }
                    else {
                        this.setState({ isErrorInUpdatePassword: true, errorMessage: response.data.messageText, isSuccess: false, currentPassword: "", newPassword: "", confirmPassword: "", buttonText: "Update Password", isUpdateBtnDisabled: false });
                    }

                }).catch((error) => {
                    console.log(error);
                    if (error.response.status == 401) {
                        window.localStorage.removeItem("Token");
                        window.location.href = "/login";
                    }
                    this.setState({ isErrorInUpdatePassword: true, errorMessage: "Something went wrong", isSuccess: false, currentPassword: "", newPassword: "", confirmPassword: "", buttonText: "Update Password", isUpdateBtnDisabled: false });
                });
            }
            else {
                this.setState({ isErrorInUpdatePassword: true, errorMessage: "New password and confirm password do not match", isSuccess: false, currentPassword: "", newPassword: "", confirmPassword: "", buttonText: "Update Password", isUpdateBtnDisabled: false });
            }
        }
        else {
            this.setState({ isErrorInUpdatePassword: true, errorMessage: "Please fill all required fields", isSuccess: false, currentPassword: "", newPassword: "", confirmPassword: "", buttonText: "Update Password", isUpdateBtnDisabled: false });
        }
    }

}