import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, Modal, ModalBody, FormGroup,
    Alert
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class CurrentItemsDonation extends Component {

    constructor(props) {
        super(props);
        this.state = {

            isPalletLoading: false,
            isNonPalletLoading: false,
            isItemNotInStockLoading: false,

            pallet: [],
            nonPallet: [],
            itemNotInStock: [],
            pallestWithContent: [],

            donationInfo: "",

            showConfirm: false,
            FinishConfirm: false,
            isFinishPalletBtnDisabled: true,
            buttonText: "Confirm",
            isProcessing: false,
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const donationNumber = urlParams.get('donationNumber')
        this.setState({
            donationNumber: donationNumber, isPalletLoading: true,
            isNonPalletLoading: true, isItemNotInStockLoading: true
        });

        axios.get(`${ApiUrls.getpalletsDonation}?donationId=${donationNumber}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                let palletsWithContent = [];
                let pallets = JSON.parse(response.data.data);
                pallets.forEach((pallet) => {
                    let palletNumber = pallet.PalletNumber;
                    axios.get(`${ApiUrls.viewPalletContents}?PalletNumber=${palletNumber}`, authorizationHeader).then((response) => {
                        let palletContent = JSON.parse(response.data.data);
                        let boxContents = palletContent.PalletBoxContents;
                        boxContents.forEach((box) => {
                            box['PalletNumber'] = palletNumber;
                            palletsWithContent.push(box);
                        });
                        console.log(palletsWithContent);
                    }).catch((error) => {
                        this.showError(error);
                    });
                });
                let sortedPallets = sortByAlphanumerically(pallets, 'PalletNumber');
                let sortedPallestWithContent = sortByAlphanumerically(palletsWithContent, 'PalletNumber');
                this.setState({ pallet: sortedPallets, palletsWithContent: sortedPallestWithContent, isPalletLoading: false });
                console.log(sortedPallets);
                console.log(sortedPallestWithContent);
            }
        }).catch((error) => {
            this.showError(error);
        });

        axios.get(`${ApiUrls.getNonPalletsDonation}?donationId=${donationNumber}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var nonPallet = JSON.parse(response.data.data);
                var sortNonPallets = sortByAlphanumerically(nonPallet, 'description');
                this.setState({ nonPallet: sortNonPallets, isNonPalletLoading: false });
                console.log(nonPallet);
            }
        }).catch((error) => {
            this.showError(error);
        });

        axios.get(`${ApiUrls.getItemNotInStocksDonation}?donationId=${donationNumber}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var itemNotInStock = JSON.parse(response.data.data);
                var sortItemNotInStock = sortByAlphanumerically(itemNotInStock, 'description');
                this.setState({ itemNotInStock: sortItemNotInStock, isItemNotInStockLoading: false });
                console.log(itemNotInStock);
            }
        }).catch((error) => {
            this.showError(error);
        });

        axios.get(`${ApiUrls.DonationInfo}?donationId=${donationNumber}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var donationInfo = JSON.parse(response.data.data)
                this.setState({ donationInfo: donationInfo });
                console.log(donationInfo);
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const columnsPallet =
            [
                {
                    text: 'Pallet number',
                    dataField: 'PalletNumber',
                    style: {
                        fontWeight: 'normal'
                    }
                }
            ];

        const columnsNonPallet =
            [
                {
                    text: 'Description',
                    dataField: 'description',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Quantity',
                    dataField: 'quantity',
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];

        const columnsItemNotInStock =
            [
                {
                    text: 'Description',
                    dataField: 'description',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Quantity',
                    dataField: 'quantity',
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];

        const { SearchBar } = Search;

        const palletsTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="PalletNumber"
                    columns={columnsPallet}
                    data={this.state.pallet}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );

        const nonPalletsTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="Description"
                    columns={columnsNonPallet}
                    data={this.state.nonPallet}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );

        const itemNotInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="Description"
                    columns={columnsItemNotInStock}
                    data={this.state.itemNotInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                <Col sm="12" md="10" lg="8" style={mystyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/send-small-donation">Small Donations​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href={"/make-small-donation/?donationNumber=" + this.state.donationNumber}>Make a Small Donation​</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Donation List​</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>SEND A SMALL DONATION - DONATION LIST</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h5>PALLETS</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isPalletLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!this.state.isPalletLoading && (
                                        <>
                                            {this.state.pallet.length != 0 && (<>
                                                <PaginationProvider
                                                    pagination={
                                                        paginationFactory()
                                                    }
                                                >
                                                    {palletsTable}
                                                </PaginationProvider>
                                            </>)}
                                            {this.state.pallet.length == 0 && (<>
                                                <Alert theme="warning">No Data Found</Alert>
                                            </>)}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h5>NON-PALLETS</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isNonPalletLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!this.state.isNonPalletLoading && (
                                        <>
                                            {this.state.nonPallet.length != 0 && (<>
                                                <PaginationProvider
                                                    pagination={
                                                        paginationFactory()
                                                    }
                                                >
                                                    {nonPalletsTable}
                                                </PaginationProvider>
                                            </>)}
                                            {this.state.nonPallet.length == 0 && (<>
                                                <Alert theme="warning">No Data Found</Alert>
                                            </>)}

                                        </>
                                    )}
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h5>ITEM NOT IN STOCK</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isItemNotInStockLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!this.state.isItemNotInStockLoading && (
                                        <>
                                            {this.state.itemNotInStock.length != 0 && (<>
                                                <PaginationProvider
                                                    pagination={
                                                        paginationFactory()
                                                    }
                                                >
                                                    {itemNotInStockTable}
                                                </PaginationProvider>
                                            </>)}
                                            {this.state.itemNotInStock.length == 0 && (<>
                                                <Alert theme="warning">No Data Found</Alert>
                                            </>)}
                                        </>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="4" lg="4">
                                    <Button style={{ margin: "1%" }} size="lg" block onClick={e => this.onFinishDonationBtnClick(e)}>Finish Small Donation</Button>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {this.state.showConfirm && (
                    <Modal open={this.state.showConfirm} toggle={() => this.setState({ showConfirm: !this.state.showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>FINISH SMALL DONATION - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>

                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to add another item to this small donation? </label>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm="6" lg="6">
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.finishDonationClick} >Finish Small Donation</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="6" lg="6">
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={this.yesClick}>Yes</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
                {this.state.FinishConfirm && (
                    <Modal open={this.state.FinishConfirm} toggle={() => this.setState({ FinishConfirm: !this.state.FinishConfirm })} centered={true} className={"custom-modal"}>                       
                        <ModalBody>
                            <Row>
                                <Col><h2>FINISH SMALL DONATION - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to send the following items in small donation number {this.state.donationInfo.Id}  To {this.state.donationInfo.Name} ?</label>
                                </Col>
                                <Col sm="12" md="12" lg="12">
                                    <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                        {this.state.palletsWithContent.length != 0 && (<>
                                            <label style={{ fontSize: "25px" }}><b>Pallets</b></label>
                                            <table style={{ width: "100%", border: "1px solid black" }}>
                                                <tr style={{ border: "1px solid black" }}>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Pallet number</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "40%" }}>Description</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Total weight</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Price</th>
                                                </tr>
                                                {this.state.palletsWithContent.map((box) => {
                                                    return (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{box.PalletNumber}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "40%" }}>{box.description}({box.numberofboxes})</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{box.totalweight || ''}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{box.total_value}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </>)}
                                        <br />
                                        {this.state.nonPallet.length != 0 && (<>
                                            <label style={{ fontSize: "25px" }}><b>Non-pallets</b></label>
                                            <table style={{ width: "100%", border: "1px solid black" }}>
                                                <tr style={{ border: "1px solid black" }}>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "60%" }}>Description</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Quantity</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Price</th>
                                                </tr>
                                                {this.state.nonPallet.map((box) => {
                                                    return (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "60%" }}>{box.description}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{box.quantity}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{parseFloat(box.total_price).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </>)}
                                        <br />
                                        {this.state.itemNotInStock.length != 0 && (<>
                                            <label style={{ fontSize: "25px" }}><b>Item not in stock</b></label>
                                            <table style={{ width: "100%", border: "1px solid black" }}>
                                                <tr style={{ border: "1px solid black" }}>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "60%" }}>Description</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Quantity</th>
                                                    <th style={{ border: "1px solid black", fontSize: "18px", width: "20%" }}>Price</th>
                                                </tr>
                                                {this.state.itemNotInStock.map((box) => {
                                                    return (
                                                        <tr style={{ border: "1px solid black" }}>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "60%" }}>{box.description}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{box.quantity}</td>
                                                            <td style={{ border: "1px solid black", fontSize: "15px", width: "20%" }}>{parseFloat(box.total_price).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                        </>)}
                                    </div>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Button style={{ margin: "1%" }} theme="success" size="lg" block onClick={this.confirmClick} disabled={this.state.isProcessing ? true : false}>{this.state.isProcessing ? "Loading..." : "Yes"}</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Button style={{ margin: "1%" }} theme="primary" size="lg" block onClick={this.handlePrint}>Print</Button>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="4" lg="4">
                                    <FormGroup>
                                        <Button style={{ margin: "1%" }} theme="secondary" size="lg" block onClick={() => this.setState({ FinishConfirm: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    onFinishDonationBtnClick = (value) => {
        this.setState({ showConfirm: true })
    }

    yesClick = (value) => {
        window.location.href = "/make-small-donation/?donationNumber=" + this.state.donationNumber
    }

    finishDonationClick = (value) => {
        this.setState({ showConfirm: false, FinishConfirm: true })
    }

    confirmClick = (value) => {
        this.setState({ buttonText: "Loading...", isFinishPalletBtnDisabled: true, isProcessing: true })
        var Donation = {
            Id: parseInt(this.state.donationNumber),
        };

        axios.post(ApiUrls.FinishDonation, Donation, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isResponse: true, theme: "success", response: response.data.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                    isProcessing: false, FinishConfirm: false,
                })
                window.location.href = "/send-small-donation"
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: response.data.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                    isProcessing: false, FinishConfirm: false
                })
            }
        }).catch((error) => {
            this.showError(error);

        });
    }

    handlePrint = () => {
        const { palletsWithContent, nonPallet, itemNotInStock, donationInfo } = this.state;
        let customWindow = window.open('', '', 'height=500, width=500');
        customWindow.document.write('<html>');
        customWindow.document.write('<body>');
        customWindow.document.write(`<h1>SMALL DONATION: ${donationInfo.Id}</h1>`);
        customWindow.document.write(`<h2>Sending items to: ${donationInfo.Name} </h3>`);
        customWindow.document.write(`<h2>Country: ${donationInfo.Country} </h3>`);
        customWindow.document.write('<br>');
        if (palletsWithContent && palletsWithContent.length > 0) {
            customWindow.document.write('<h3>Pallets</h4>');
            customWindow.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            customWindow.document.write('<tr>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Pallet number</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:40%">Description</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Total weight</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Price</th>');
            customWindow.document.write('<tr>');
            palletsWithContent.map((item) => {
                customWindow.document.write('<tr>');
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${item.PalletNumber}</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:40%">${item.description}(${item.numberofboxes})</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${item.totalweight || ''}</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${item.total_value}</td>`);
                customWindow.document.write('<tr>');
            });
            customWindow.document.write('</table>');
        }
        if (nonPallet && nonPallet.length > 0) {
            customWindow.document.write('<h3>Non-pallets</h4>');
            customWindow.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            customWindow.document.write('<tr>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%">Description</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Quantity</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Price</th>');
            customWindow.document.write('<tr>');
            nonPallet.map((item) => {
                customWindow.document.write('<tr>');
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:60%">${item.description}</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${item.quantity}</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${parseFloat(item.total_price).toFixed(2)}</td>`);
                customWindow.document.write('<tr>');
            });
            customWindow.document.write('</table>');
        }
        if (itemNotInStock && itemNotInStock.length > 0) {
            customWindow.document.write('<h3>Item not in stock</h4>');
            customWindow.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');
            customWindow.document.write('<tr>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:60%">Description</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Quantity</th>');
            customWindow.document.write('<th style="border:1px solid black; border-collapse: collapse; width:20%">Price</th>');
            customWindow.document.write('<tr>');
            itemNotInStock.map((item) => {
                customWindow.document.write('<tr>');
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:60%">${item.description}</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${item.quantity}</td>`);
                customWindow.document.write(`<td style="border:1px solid black; border-collapse: collapse; width:20%">${parseFloat(item.total_price).toFixed(2)}</td>`);
                customWindow.document.write('<tr>');
            });
            customWindow.document.write('</table>');
        }
        customWindow.document.write('</body></html>');
        customWindow.document.close();
        customWindow.print();
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, theme: "danger", response: "Something went wrong",
                buttonText: "Confirm", isFinishPalletBtnDisabled: true, showConfirm: false,
                isProcessing: false, FinishConfirm: false
            })
        }
    }
}