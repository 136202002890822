import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button,
    Row, Col, Breadcrumb, BreadcrumbItem, Alert,
    FormGroup, FormSelect
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { sortDescendingByAlphanumerically } from "../../utils/sortDescending";
import { getDateInGivenFormat } from "../../utils/formatDate";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ReportOnCurrentStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
            itemsInStock: [],
            isDataLoading: false,

        };
    }

    componentDidMount() {
        this.setState({ isDataLoading: true })
        axios.get(ApiUrls.viewBottleRoomItemsInStock, authorizationHeader).then((response) => {
            var itemsInStock = JSON.parse(response.data.data);
            var sortItemsInStock = sortByAlphanumerically(itemsInStock, 'description');
            this.setState({ itemsInStock: sortItemsInStock, isDataLoading: false })
        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });
    }

    render() {

        const columns =
            [
                {
                    text: 'Description',
                    formatter: this.description,
                    dataField: 'description',
                    sort: true,
                    style: {
                        fontWeight: 'normal',
                        width: '50%'
                    }
                },
                {
                    text: 'Batch number',
                    dataField: 'batchnumber',
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.expiryDate,
                    dataField: 'expirydate',
                    sort: true,
                    style: {
                        fontWeight: 'normal',
                        width: '25%'
                    }
                },               
            ];

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/bottle-room">Bottle Room Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Report on Current Stock</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>REPORT ON STOCK</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#type">Sorted by :<span className="error-txt"></span></label>
                                        <FormSelect size="lg" onChange={this.sortItemsInStock}>
                                            <option value="AscDescription">Description(Ascending)</option>
                                            <option value="DesDescription">Description(Descending)</option>
                                            <option value="AscExpirydate">Expiry Date(Ascending)</option>
                                            <option value="DesExpirydate">Expiry Date(Descending)</option>                                       
                                        </FormSelect>
                                    </FormGroup>
                                </Col>
                            </Row>
                           
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!this.state.isDataLoading && (
                                        <div>
                                            {this.state.itemsInStock.length >= 1 && (
                                                <div>
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={this.state.itemsInStock}
                                                        columns={columns}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                    <Button size="lg" onClick={this.printBtnClick}>Print</Button>
                                                </div>
                                            )}
                                            {this.state.itemsInStock == 0 && (
                                                <Alert theme="warning">Pallet is Empty</Alert>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
              
            </>
        );
    }

    description = (cell, row) => {
        return (<div>
            {row.description} ({row.quantity})
        </div>)
    }

    expiryDate = (cell, row) => {
        var expiryDate = row.expirydate;
        var date;
        expiryDate ? date = getDateInGivenFormat(expiryDate.slice(0, 10)) : date = '';
        return (<div>{date}</div>)
    }

    printBtnClick = (value) => {
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write('<h1>REPORT ON STOCK</h1>');
        a.document.write('<br>');
        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');

        a.document.write('<tr>');
        a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Description</th>');
        a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Batch number</th>');
        a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Expiry date</th>');
        a.document.write('</tr>');
        {
            this.state.itemsInStock.map((box) => {
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + box.description + ' (' + box.quantity + ')</td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + box.batchnumber + '</td>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + getDateInGivenFormat(box.expirydate?.slice(0, 10)) + '</td>');
                a.document.write('</tr>');
            })
        }
        a.document.write('</table>');
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    sortItemsInStock = (value) => {
        this.setState({ isDataLoading: true })
        if (value.target.value == "DesDescription") {
            var sortItemsInStock = sortDescendingByAlphanumerically(this.state.itemsInStock, 'description');
            this.setState({ isDataLoading: false, itemsInStock: sortItemsInStock })
        }
        else if (value.target.value == "AscDescription") {
            var sortItemsInStock = sortByAlphanumerically(this.state.itemsInStock, 'description');
            this.setState({ isDataLoading: false, itemsInStock: sortItemsInStock  })

        }
        else if (value.target.value == "DesExpirydate") {
            var sortItemsInStock = sortDescendingByAlphanumerically(this.state.itemsInStock, 'expirydate');
            this.setState({ isDataLoading: false, itemsInStock: sortItemsInStock  })
        }
        else if (value.target.value == "AscExpirydate") {
            var sortItemsInStock = sortByAlphanumerically(this.state.itemsInStock, 'expirydate');
            this.setState({ isDataLoading: false, itemsInStock: sortItemsInStock  })
        }
    }

    showError = (error) => {
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
} 