import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, FormGroup, Alert
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";
import { getDateInGivenFormat } from "../../utils/formatDate";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { PrimaryStorageLocation } from "../common/PrimaryStorageLocation";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class ReportOnStorageLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDataLoading: false,
                     
            isResponse: false,
            theme: "",
            response: "",
            
            selectedPrimaryLocation: [],
            validPrimaryLocation: false,

            selectedSecondaryLocation: [],
            validSecondaryLocation: false,
            isSecondaryLocationLoading: false,

            nonPalletItemsInStock: [],
            palletsInStock: [],

            isSearchBtnPress: false,
        };
        this.StorageLocation = React.createRef();
    }

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const columnsNonPalletItemsInStock =
            [
                {
                    text: 'Description',
                    dataField: 'nonpalletitemdescription',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Quantity',
                    dataField: 'numberofitem',
                    style: {
                        fontWeight: 'normal'
                    }
                }               
            ];

        const columnsPalletsInStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'PalletNumber', 
                    hidden:true,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Pallet number',
                    formatter: this.palletNumberLink, 
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Earliest expiry date',
                    formatter: this.earliestExpiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'View content',
                    formatter: this.viewContent,
                    style: {
                        fontWeight: 'normal'
                    }
                }
            ];
       
        const { SearchBar } = Search;

        const palletsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="PalletNumber"
                    columns={columnsPalletsInStock}
                    data={this.state.palletsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );

        const nonPalletItemsInStockTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <PaginationListStandalone {...paginationProps} />
                <ToolkitProvider
                    keyField="id"
                    columns={columnsNonPalletItemsInStock}
                    data={this.state.nonPalletItemsInStock}
                    search>
                    {
                        toolkitprops => (
                            <div >
                                <SearchBar {...toolkitprops.searchProps} />
                                <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Report on Storage locations</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>REPORT ON STORAGE LOCATIONS</h4>
                                </Col>
                            </Row>

                            <PrimaryStorageLocation ref={this.StorageLocation}
                                getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                            />

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">

                                    {this.state.isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                 
                                    {!this.state.isDataLoading && (                                      
                                        <div>
                                            {this.state.isSearchBtnPress && ( 
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5>PALLETS IN STOCK</h5>
                                                        </Col>
                                                    </Row>

                                                    {this.state.palletsInStock.length != 0 && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {palletsInStockTable}
                                                            </PaginationProvider>
                                                        </>
                                                    )}

                                                    {this.state.palletsInStock.length == 0 && (
                                                        <><Alert theme="warning">No data found</Alert></>
                                                    )}
                                                    <hr/>
                                                    <Row>
                                                        <Col sm="12" md="12" lg="12">
                                                            <h5>NON PALLET ITEM IN STOCK</h5>
                                                        </Col>
                                                    </Row>

                                                    {this.state.nonPalletItemsInStock.length != 0 && (
                                                        <>
                                                            <PaginationProvider
                                                                pagination={
                                                                    paginationFactory()
                                                                }
                                                            >
                                                                {nonPalletItemsInStockTable}
                                                            </PaginationProvider>
                                                        </>
                                                    )}

                                                    {this.state.nonPalletItemsInStock.length == 0 && (
                                                        <><Alert theme="warning">No data found</Alert></>
                                                    )}         
                                                </>
                                                )}                                   
                                        </div>   
                                    )}
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
            </>
        );
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ validPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ validSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    onConfirmClick = () => {
        this.StorageLocation.current.validatePrimaryLocation();
        this.setState({ isDataLoading: true, nonPalletItemsInStock: [], palletsInStock: [], isSearchBtnPress:true })
        const { validPrimaryLocation, validSecondaryLocation, selectedPrimaryLocation, selectedSecondaryLocation } = this.state;
        if (validPrimaryLocation && selectedSecondaryLocation.length !== 0) {
            this.getData(false, parseInt(selectedSecondaryLocation[0].Id));           
        } else {
            this.getData(true, parseInt(selectedPrimaryLocation[0].id));
        }   
    }

    getData = (isPriLocId, locId) => {
        axios.get(`${ApiUrls.getNonPalletItemsInStockGroupByLocId}?isPriLocId=${isPriLocId}&locId=${locId}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var nonPalletItemsInStock = JSON.parse(response.data.data);
                var sortNonPalletItemsInStock = sortByAlphanumerically(nonPalletItemsInStock, 'nonpalletitemdescription');
                this.setState({ nonPalletItemsInStock: sortNonPalletItemsInStock });
            }
        }).catch((error) => {
            this.showError(error);
        });
        axios.get(`${ApiUrls.getPalletsInStockGroupByLocId}?isPriLocId=${isPriLocId}&locId=${locId}`, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var palletsInStock = JSON.parse(response.data.data);
                var sortPalletsInStock = sortByAlphanumerically(palletsInStock, 'PalletNumber');
                this.setState({ palletsInStock: sortPalletsInStock, isDataLoading: false });
            }
        }).catch((error) => {
            this.showError(error);
        }); 
    };

    earliestExpiryDate = (cell, row) => {
        var expiryDate = row.EarliestExpiryDate;
        var date;
        expiryDate ? date = getDateInGivenFormat(expiryDate.slice(0, 10)) : date = '';
        return (<div>{date}</div>)
    }

    viewContent = (cell, row) => {
        return (<div><Button theme="danger" block onClick={() => window.location.href = "/pallet-contents/?palletNumber=" + row.PalletNumber }>View Content</Button></div>)
    }

    palletNumberLink = (cell, row) => {
        console.log(row)
        return (<a href={"/pallet-contents/?palletNumber=" + row.PalletNumber}>{row.PalletNumber}</a>)
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}