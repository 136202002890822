import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput,
    InputGroup, InputGroupAddon,
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class PutStockOnBottleRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            theme: "",
            response: "",

            selectedPallet: [],
            pallets: [],
            isPalletsLoading: false,
            palletquery: "",
            responseSelectedPallet: "",
            validSelectedPallet: false,

            itemsInStock: [],
            selectedItemsInStock: [],
            isDataLoading: false,

            showConfirmation: false,
            yesBtnText: "Yes",
            isYesBtnDisabled: false,

            palletNumber: "",
            location: "",
        };
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const palletNumber = urlParams.get('pallet')
        if (palletNumber) {
            this.setState({ palletNumber: palletNumber, validSelectedPallet: true });
            const location = urlParams.get('location',)
            this.setState({ location: location });
        }

        this.setState({ isDataLoading: true })
        axios.get(ApiUrls.viewBottleRoomItemsInStock, authorizationHeader).then((response) => {
            var itemsInStock = JSON.parse(response.data.data);
            var sortItemsInStock = sortByAlphanumerically(itemsInStock, 'description');
            this.setState({ itemsInStock: sortItemsInStock, isDataLoading: false });
        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });

    }

    render() {

        const columns =
            [
                {
                    text: 'Description',
                    formatter: this.Description,
                    dataField: 'description',
                    style: {
                        fontWeight: 'normal',
                        width: '30%'
                    }
                },
                {
                    text: 'Batch number',
                    dataField: 'batchnumber',
                    style: {
                        fontWeight: 'normal',
                        width: '15%'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.expiryDate,
                    style: {
                        fontWeight: 'normal',
                        width: '15%'
                    }
                },
                {
                    text: 'Quantity to be put on the pallet',
                    formatter: this.quantityToRemove,
                    style: {
                        fontWeight: 'normal',
                        width: '40%'
                    }
                },
            ];

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>

                        {(this.state.location != "bottle" && this.state.location != "warehouse") && ( 
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <a href="/dashboard">Home</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/bottle-room">Bottle Room Interface</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>Put Stock on Pallet</BreadcrumbItem>
                            </Breadcrumb>
                            )}

                        {this.state.location == "bottle" && (
                            
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <a href="/dashboard">Home</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/bottle-room">Bottle Room Interface</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=bottle">Edit a Pallet</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>Put Bottle Medicine</BreadcrumbItem>
                            </Breadcrumb>
                            
                        )}

                        {this.state.location == "warehouse" && (

                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <a href="/dashboard">Home</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/warehouse">Warehouse Interface</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <a href="/edit-Pallet?location=warehouse">Edit a Pallet</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>Put Bottle Medicine</BreadcrumbItem>
                            </Breadcrumb>

                        )}

                        
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            {!this.state.location && (
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>PUT STOCK ON PALLET</h4>
                                </Col>
                                </Row>
                            )}
                            {this.state.location && (
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>PUT BOTTLE MEDICINE</h4>
                                </Col>
                            </Row>
                            )}

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            {!this.state.location && (
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#pallet">Select a pallet :<span className="error-txt">*</span></label>
                                            <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                                id="pallet-typeahead"
                                                labelKey="id"
                                                minLength={1}
                                                onInputChange={this.handlePalletInputChange}
                                                onSearch={this.handlePalletSearch}
                                                placeholder="Search for pallets"
                                                renderMenuItemChildren={option => (
                                                    <div key={option.id}>
                                                        <span>{option.id}</span>
                                                    </div>
                                                )}
                                                useCache={false}
                                            />
                                            <span className="error-txt">{this.state.responseSelectedPallet}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isDataLoading && (
                                        <label style={{ fontWeight: "bold" }}>Loading...</label>
                                    )}
                                    {!this.state.isDataLoading && (
                                        <div>
                                            {this.state.itemsInStock.length >= 1 && (
                                                <div>
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={this.state.itemsInStock}
                                                        columns={columns}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                </div>
                                            )}
                                            {this.state.itemsInStock == 0 && (
                                                <Alert theme="warning">Pallet is Empty</Alert>
                                            )}
                                        </div>
                                    )}
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>PUT STOCK ON PALLET - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to put {this.state.selectedItemsInStock.quantitytoremove.toString()}
                                        &nbsp;of {this.state.selectedItemsInStock.description.toString()} (batch number : {this.state.selectedItemsInStock.batchnumber.toString()})
                                        &nbsp;onto pallet no. {this.state.palletNumber} ?
                                    </label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>

                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}

            </>
        );
    }

    Description = (cell, row) => {
        return (<div>
            {row.description} ({row.quantity})
        </div>)
    }

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isResponse: false });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getEditablePallets}?query=${palletquery}&locId=5`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));       
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });

    }

    setPallet = (value) => {
        this.setState({ isResponse: false })
        if (value !== null && value.length > 0) {
            this.setState({
                selectedPallet: value, responseSelectedPallet: "",
                validSelectedPallet: true, palletNumber: value[0].id,
            })
        } else {
            this.setState({
                selectedPallet: [], responseSelectedPallet: "Please select a pallet number",
                validSelectedPallet: true, palletNumber: ""
            })
        }
    };

    validatePallet = () => {
        if (this.state.selectedPallet.length === 0) {
            this.setState({ responseSelectedPallet: "Please select a pallet number", validSelectedPallet: false });
        } else {
            this.setState({ responseSelectedPallet: "", validSelectedPallet: true });
        }
    };

    expiryDate = (cell, row) => {
        var expiryDate = row.expirydate;
        var date;
        expiryDate ? date = getDateInGivenFormat(expiryDate.slice(0, 10)) : date = '';
        return (<div>{date}</div>)
    }

    quantityToRemove = (cell, row) => {
        return (
            <Row>
                <Col >
                    <InputGroup>
                        <InputGroupAddon type="prepend">
                            <Button theme="primary" onClick={e => this.removeBox(e, row)}>-</Button>
                        </InputGroupAddon>
                        <FormInput  style={{ textAlign: "center", maxWidth: "100px" }} size="5" id={row.id} maxlength="5" onChange={e => this.updateStock(e, row)} />
                        <InputGroupAddon type="append">
                            <Button theme="primary" onClick={e => this.addBox(e, row)}>+</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col >
                    <Button theme="success" block onClick={e => this.onRemoveBoxesBtnClick(e, row)}>Confirm</Button>
                </Col>
            </Row>
        )
    }

    updateStock = (e, row) => {
        let itemsInStock = this.state.itemsInStock
        if (e.target.value >= 1 && e.target.value <= row.quantity) {
            itemsInStock.forEach(item => {
                if (item.id == row.id) {
                    item.quantitytoremove = parseInt(e.target.value);
                    document.getElementById(row.id).value = item.quantitytoremove;
                    console.log(item.quantitytoremove)
                }
            })
        } else {
            itemsInStock.forEach(item => {
                if (item.id == row.id) {
                    document.getElementById(row.id).value = item.quantitytoremove;
                }
            })
        }
        this.setState({ itemsInStock: itemsInStock })
    }

    removeBox = (e, row) => {
        let itemsInStock = this.state.itemsInStock
        if (!(row.quantitytoremove == 1)) {
            itemsInStock.forEach(item => {
                if (item.id == row.id) {
                    item.quantitytoremove -= 1;
                    document.getElementById(row.id).value = item.quantitytoremove;
                }
            })
        }
        this.setState({ itemsInStock: itemsInStock })
    }

    addBox = (e, row) => {
        let itemsInStock = this.state.itemsInStock
        if (!(row.quantitytoremove == row.quantity)) {
            itemsInStock.forEach(item => {
                if (item.id == row.id) {
                    item.quantitytoremove += 1;
                    document.getElementById(row.id).value = item.quantitytoremove;
                }
            })
        }
        this.setState({ itemsInStock: itemsInStock })
    }

    onRemoveBoxesBtnClick = (e, row) => {
        if (!this.state.palletNumber) {
            this.validatePallet();
        }      
        if (this.state.validSelectedPallet) {
            this.setState({
                isResponse: false, selectedItemsInStock: row,
                showConfirmation: true,
            });
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false })
    }

    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading...", isDataLoading:true });
        var BottleRoomPalletContentList = {
            PalletNumber: this.state.palletNumber,
            StockId: parseInt(this.state.selectedItemsInStock.id),
            Quantity: parseInt(this.state.selectedItemsInStock.quantitytoremove),
        };
        axios.post(ApiUrls.addBottleRoomStockOnPallet, BottleRoomPalletContentList, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isYesBtnDisabled: false, yesBtnText: "Yes",
                    showConfirmation: false, isResponse: true,
                    theme: "success", response: response.data.messageText,
                });

                axios.get(ApiUrls.viewBottleRoomItemsInStock, authorizationHeader).then((response) => {
                    var itemsInStock = JSON.parse(response.data.data);
                    var sortItemsInStock = sortByAlphanumerically(itemsInStock, 'description');
                    this.setState({ itemsInStock: sortItemsInStock, isDataLoading: false });
                })
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: response.data.messageText,
                    isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
                });
            }
        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger",
                isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false
            });
        }
    }
} 