export const getPalletCategoryNameWithId = (palletCategories) => {
    const palletCategoryIdWithFeatureTypeId = new Map();
    for (let palletCategory of palletCategories) {
        palletCategoryIdWithFeatureTypeId.set(palletCategory.category_id, palletCategory.feature_type_id);
    }
    return palletCategoryIdWithFeatureTypeId;
};

export const FeaturePalletTypes = Object.freeze({
    MedicinesAndCreams: 1,
    MedicalDisposables: 2,
    Food: 3,
    Other: 4
});