import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {
    Card, CardTitle, CardBody, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, FormInput
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class SearchForPallet extends Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedPallet: [],
            pallets: [],
            isPalletsLoading: false,
            palletquery: "",
            locationId: "",
            currentLocation: [],
            responseSelectedPallet: "",
            validSelectedPallet: false,

        };
    }

    _cache = {};

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Search For Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>SEARCH FOR PALLET</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#pallet">Select a pallet :<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        <span className="error-txt">{this.state.responseSelectedPallet}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {!(this.state.currentLocation.length === 0) && (<>

                                {(this.state.currentLocation.IsDeleted && !this.state.currentLocation.IsDonation && !this.state.currentLocation.IsShipped) && (<>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#status">Pallet status :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#status" value="Deleted" disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </>)}

                                {(!this.state.currentLocation.IsDeleted && this.state.currentLocation.IsDonation && !this.state.currentLocation.IsShipped) && (<>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#status">Pallet status :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#status" value="Donated" disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#Id">Donation Id :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#Id" value={this.state.currentLocation.DonationId} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#CurrentLocation">Current Location :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#CurrentLocation" value={this.state.currentLocation.DonateLocation} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </>)}

                                {(!this.state.currentLocation.IsDeleted && !this.state.currentLocation.IsDonation && this.state.currentLocation.IsShipped) && (<>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#status">Pallet status :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#status" value="Shipped" disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#CurrentLocation">Current Location :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#CurrentLocation" value={this.state.currentLocation.PrimaryLocation +" - "+ this.state.currentLocation.SecondaryStorageLocation} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                </>)}

                                {(!this.state.currentLocation.IsDeleted && !this.state.currentLocation.IsDonation && !this.state.currentLocation.IsShipped) && (<>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#status">Pallet status :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#status" value="In stock" disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <label style={{ fontSize: "large" }} htmlFor="#CurrentLocation">Current Location :<span className="error-txt"></span></label>
                                                <FormInput size="lg" type="text" id="#CurrentLocation" value={this.state.currentLocation.PrimaryLocation} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {(this.state.currentLocation.SecondaryStorageLocation) && (
                                        <Row>
                                            <Col sm="12" md="10" lg="8">
                                                <FormGroup>
                                                    <label style={{ fontSize: "large" }} htmlFor="#SecondaryCurrentLocation">Secondary Location :<span className="error-txt"></span></label>
                                                    <FormInput size="lg" type="text" id="#SecondaryCurrentLocation" value={this.state.currentLocation.SecondaryStorageLocation} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}

                                </>)}

                            </>)}


                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

            </>
        );
    }

    setPallet = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({ selectedPallet: value, responseSelectedPallet: "", validSelectedPallet: true })
            axios.get(`${ApiUrls.getCurrentPalletLocation}?palletNumber=${value[0].id}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var currentLocation = JSON.parse(response.data.data);
                    console.log(currentLocation)
                    this.setState({ currentLocation: currentLocation });
                }
            }).catch((error) => {
                this.showError(error);
            });
        } else {
            this.setState({ selectedPallet: [], responseSelectedPallet: "Please select a pallet", validSelectedPallet: false, currentLocation: [] })
        }
    };

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isResponse: false });
    };

    handlePalletSearch = palletquery => {
        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getPallets}?query=${palletquery}&locId=${this.state.locationId}&includeDeleted=true`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}