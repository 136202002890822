import React, { Component } from 'react';
import {
    Card,
    CardTitle,
    CardBody,
    Button,
    FormGroup, Container, Row, Col, Breadcrumb, BreadcrumbItem, Form, Modal, ModalBody, FormInput, Alert
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { DateInput } from '@opuscapita/react-dates';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getSelectedDateFromDatePicker } from '../../utils/formatDateFromDatePicker';

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;
const locationId = 8;

export class PutBoxOnPallet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedBoxContent: [],
            selectedPallet: [],
            showConfirmation: false,
            boxContents: [],
            isError: false,
            errorMessage: "",
            isYesBtnDisabled: false,
            yesBtnText: "Yes",
            query: "",
            palletquery: "",
            isLoading: false,
            isPalletsLoading:false,
            pallets:[],

            isErrorInBoxContent : false,
            isErrorInWeight:false,
            isErrorInPallet: false,
            isErrorInNumber: false,

            successMessage: "",

            numberOfItem: "",
            responseNumberOfItem: "",
            validNumberOfItem: false,

            expiryDate: "",
            responseExpiryDate: "",
            validExpiryDate: false,

            weight: "",
            responseWeight: "",
            validWeight: false,

            today: "",

            isJSpallet: false,

            description: "",
            responseDescription: "",
        };
    }

    componentDidMount() {
        this.getTodayDate();
        if (this.state.showConfirmation) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    _cache = {};
    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (

            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/medical-disposables">Medical Disposable Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Put Box On Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>PUT BOX ON PALLET</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isError && (
                                        <Alert theme="danger">
                                            {this.state.errorMessage}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.successMessage && (
                                        <Alert theme="success">
                                            {this.state.successMessage}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#pallet">Which pallet?<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>

                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />

                                        {(this.state.isErrorInPallet) && (
                                            <span className="error-txt">Please select a pallet</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#boxContents">Box contents:<span className="error-txt">*</span></label>
                                        <AsyncTypeahead size="lg" value={this.state.selectedBoxContent} options={this.state.boxContents} onChange={this.setBoxContent} isLoading={this.state.isLoading}
                                            id="boxcontent-typeahead"
                                            labelKey="description"
                                            minLength={3}
                                            onInputChange={this.handleInputChange}
                                            onPaginate={this.handlePagination}
                                            onSearch={this.handleSearch}
                                            placeholder="Search for box contents"
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>

                                                    <span>{option.description}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />

                                        {(this.state.isErrorInBoxContent) && (
                                            <span className="error-txt">Please select a box content</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>

                            {this.state.isJSpallet && (
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#Description">Description :<span className="error-txt"></span></label>
                                            <FormInput size="lg" type="text" id="#Description" value={this.state.description} onChange={this.SetDescription} />
                                            <span className="error-txt">{this.state.responseDescription}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Number of boxes :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#NumberOfItem" value={this.state.numberOfItem} onChange={this.setNumberOfItem} min="1" />
                                        <span className="error-txt">{this.state.responseNumberOfItem}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup >
                                        <label style={{ fontSize: "large" }} htmlFor="#ExpiryDate">Earliest expiry date:</label>
                                        <DateInput
                                            id="#ExpiryDate"
                                            value={this.state.expiryDate}
                                            dateFormat="dd/MM/yyyy"
                                            locale="en"
                                            onChange={this.setExpiryDate}
                                        />
                                        <span className="error-txt">{this.state.responseExpiryDate}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#weight">Weight per box(kg) :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" type="number" id="#weight" value={this.state.weight} onChange={this.setWeight} min="1" />
                                        <span className="error-txt">{this.state.responseWeight}</span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick}>Confirm</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                           
                        
                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>PUT BOX ON PALLET - CONFIRMATION</h2></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <label style={{ fontSize: "x-large" }}>{`Do you want to put ${this.state.numberOfItem} of ${this.state.selectedBoxContent[0].description} weighing ${this.state.weight}kg onto pallet no. ${this.state.selectedPallet[0].id}?`}</label>
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6" xs="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" disabled={this.isYesBtnDisabled} onClick={this.onYesClick}>{this.state.yesBtnText}</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6" xs="6">
                                        <FormGroup>
                                            <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick}>No</Button>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )}

                {this.state.isPrintView && (
                    <Modal open={this.state.isPrintView} toggle={() => this.setState({ isPrintView: !this.state.isPrintView })} centered={true} className={"custom-modal"}>

                        <ModalBody>
                            <Form>
                                <Container className="dr-example-container" fluid={true}>
                                    <Row>
                                        <Col sm="12" lg="12">
                                            <FormGroup>
                                                <label style={{ fontSize: "100px" }}>{this.state.palletNumber}</label>
                                            </FormGroup>
                                        </Col>

                                    </Row>


                                    <Row>
                                        <Col sm="12" lg="12">
                                            <Button theme="primary" size="lg" onClick={this.printBtnClick}>Print</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form>
                        </ModalBody>

                    </Modal>
                )}
            </>

        );
    }
    //----------------------------------------------------------------------------------
    setNumberOfItem = (value) => {
        this.setState({ numberOfItem: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number of boxes", validNumberOfItem: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) >= 100000000) {
            this.setState({ responseQuantity: "Quantity is limited to be between 1-10000000", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    validateNumberOfItem = () => {
        if (!(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number of boxes", validNumberOfItem: false });
        } else if (isNaN(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) >= 100000000) {
            this.setState({ responseQuantity: "Quantity is limited to be between 1-10000000", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(this.state.numberOfItem)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    //----------------------------------------------------------------------------------

    setWeight = (value) => {
        this.setState({ weight: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (value.target.value < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    validateWeight = () => {
        if (!(this.state.weight)) {
            this.setState({ responseWeight: "Please enter correct weight", validWeight: false });
        } else if (isNaN(this.state.weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (this.state.weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    //----------------------------------------------------------------------------------

    setExpiryDate = (value) => {
        this.setState({ expiryDate: value, isResponse: false });
        if (!value) {
            this.setState({ responseExpiryDate: "Please enter earliest expiry date", validExpiryDate: false });
        } 
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    validateExpiryDate = () => {
        this.setState({ isResponse: false });
        if (!(this.state.expiryDate)) {
            this.setState({ responseExpiryDate: "Please enter earliest expiry Date", validExpiryDate: false });
        } 
        else {
            this.setState({ responseExpiryDate: "", validExpiryDate: true });
        }
    }

    //----------------------------------------------------------------------------------

    setBoxContent = (value) => {
        this.setState({ selectedBoxContent: value, isErrorInBoxContent: false, isError: false, errorMessage: "", successMessage:"" })
    }

    setPallet = (value) => {
        this.setState({ selectedPallet: value, isErrorInPallet: false, isError: false, errorMessage: "", successMessage: "" })
        if (value.length> 0) {
            axios.get(`${ApiUrls.viewPalletContents}?palletNumber=${value[0].id}&location=`, authorizationHeader).then((response) => {
                var palletContent = JSON.parse(response.data.data);
                var palletInfo = palletContent.PalletInfo;
                (palletInfo.PalletCategory == 10) ? this.setState({ isJSpallet: true }) : this.setState({ isJSpallet: false });
            });
        } else {
            this.setState({ isJSpallet: false })
        }       
    }
     
    handleInputChange = query => {
        this.setState({ query, isError: false, errorMessage: "", successMessage: "" });
    };

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isError: false, errorMessage: "", successMessage: "" });
    };
    
    handleSearch = query => {
        if (this._cache[query]) {
            this.setState({ boxContents: this._cache[query].options });
            return;
        }

        this.setState({ isLoading: true });
        this.makeAndHandleRequest(query).then(resp => {
            this._cache[query] = { ...resp, page: 1 };
            this.setState({
                isLoading: false,
                boxContents: resp.options,
            });
        });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequest(query, page = 1) {
        return axios.get(`${ApiUrls.getBoxContents}?query=${query}&perpage=${PER_PAGE}`, authorizationHeader)
            .then(response => {
            /* eslint-disable-line camelcase */
                var boxContents = JSON.parse(response.data.data);
                var options = boxContents.map(i => ({
                    id: i.id,
                    description: i.description,
                }));
                options = sortByAlphanumerically(options, 'description');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });
    }

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getEditablePallets}?query=${palletquery}&locId=${locationId}`, authorizationHeader)
            .then(response => {
                /* eslint-disable-line camelcase */
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });
    }

    onConfirmClick = () => {
        if (this.state.selectedBoxContent.length === 0) {
            document.body.style.overflow = 'unset';
            this.setState({
                showConfirmation: false,
                isErrorInBoxContent: true
            });
        }

       if (this.state.selectedPallet.length === 0) {
            document.body.style.overflow = 'unset';
            this.setState({
                showConfirmation: false,
                isErrorInPallet: true
            });
        }

        this.validateNumberOfItem();

        this.validateWeight();
        this.getTodayDate();
        const { validNumberOfItem, validExpiryDate, validWeight} = this.state;

        if (this.state.selectedBoxContent.length > 0 && this.state.selectedPallet.length > 0 && validNumberOfItem && validWeight) {
            this.setState({
                showConfirmation: true,
                isErrorInNumber: false,
                isErrorInWeight: false,
                isErrorInBoxContent: false,
                isErrorInPallet: false
            });
        }
    };

    onYesClick = () => {
        this.setState({ isYesBtnDisabled: true, yesBtnText: "Loading..." });
        //submit details
        var boxInfo = {
            PalletNumber: this.state.selectedPallet[0].id,
            Description: this.state.description,
            BoxContentId: this.state.selectedBoxContent[0].id,
            NumberOfBoxes: this.state.numberOfItem ? parseInt(this.state.numberOfItem) : null,
            EarliestExpiryDate: this.state.expiryDate ? getSelectedDateFromDatePicker(this.state.expiryDate) : null,
            Weight: this.state.weight?parseFloat(this.state.weight):null
        };
        axios.post(ApiUrls.putBoxOnPallet, boxInfo, authorizationHeader).then((response) => {
            console.log(response);
            if (response.data.isSuccess) {
                this.setState({ isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false, successMessage: response.data.messageText, selectedPallet: [], selectedBoxContent: [], numberofBoxes: null, weight: "" });
                window.location.href = "/medical-disposables"
            }
            else {
                this.setState({isError: true, errorMessage: response.data.messageText, isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
            }

        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false, isPrintView: false })
    }

    getTodayDate = () => {
        var date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        var today = [year, month, day].join('-');
        this.setState({ today: new Date(today) });
    }

    showError = (error) => {
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isError: true, errorMessage: "Something went wrong", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
        }
    }

    SetDescription = (value) => {
        this.setState({ description: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseDescription: "Please enter description"});
        } else {
            this.setState({ responseDescription: "", validNumberOfItem: true });
        }
    }

}