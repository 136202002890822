export const sortDescendingByAlphanumerically = (givenArray, property) => {
  let alphanumericallySortedArray = [];
  const collator = new Intl.Collator("en", {
    numeric: true,
    sensitivity: "base",
  });
  switch (property) {
    case "expiry_date":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.expiry_date, b.expiry_date)
      );
      break;
    case "expirydate":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.expirydate, b.expirydate)
      );
      break;
    case "description":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.description, b.description)
      );
      break;
    case "category":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.category, b.category)
      );
      break;
    case "location":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.location, b.location)
      );
      break;
    case "date_deleted":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.date_deleted, b.date_deleted)
      );
      break;
    case "pallet_number":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.pallet_number, b.pallet_number)
      );
      break;
    case "shipped_date":
      alphanumericallySortedArray = givenArray.sort((b, a) =>
        collator.compare(a.shipped_date, b.shipped_date)
      );
      break;
  }
  console.log(alphanumericallySortedArray);
  return alphanumericallySortedArray;
};
