import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, Breadcrumb,
    BreadcrumbItem, Row, Col, FormGroup,
    Alert, FormSelect, Badge
} from "shards-react";
import ReactExport from "react-export-excel";
import axios from "axios";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { PrimaryStorageLocation } from "../common/PrimaryStorageLocation";
import { ApiUrls } from "../../environments/apiUrls";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { sortDescendingByAlphanumerically } from "../../utils/sortDescending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export class ListStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            isDataLoading: false,
            isSearchBtnPressed: false,
            isSecondaryLocationLoading: false,
            isValidPrimaryLocation: false,
            isValidSecondaryLocation: false,
            errorResponseForSearch: "",
            errorResponseForLocationSelection: "",
            theme: "",
            response: "",
            selectedLocation: [],
            selectedPrimaryLocation: [],
            selectedSecondaryLocation: [],
            listStock: [],
        };
        this.storageLocation = React.createRef();
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const columnsListStock =
            [
                {
                    text: 'Pallet number',
                    dataField: 'pallet_number',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Description',
                    formatter: this.getDescription,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.getExpiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Category',
                    dataField: 'category',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Location',
                    dataField: 'location',
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];
        const { isResponse, theme, response, selectedLocation, errorResponseForLocationSelection,
            errorResponseForSearch, isDataLoading, isSearchBtnPressed, listStock } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>List Stock</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>LIST STOCK</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <PrimaryStorageLocation ref={this.storageLocation}
                                getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                            />
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleAdd}>Add</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <Card>
                                        <CardBody style={{ border: "2px solid", borderRadius: "5px", paddingLeft: "0px", paddingRight: "0px" }}>
                                            <FormGroup>
                                                {selectedLocation.map((location) => {
                                                    return (<><Badge outline size="lg" style={{ fontSize: "20px", margin: "1%" }}>{location.location}<i class='fa fa-close' style={{ fontSize: "20px", color: "red" }} onClick={e => this.removeLocation(e, location)}>&nbsp; X</i></Badge> &nbsp;</>)
                                                })}
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <span className="error-txt">{errorResponseForLocationSelection}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.handleSearch}>Search</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <span className="error-txt">{errorResponseForSearch}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isDataLoading && (
                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                            )}
                            {!isDataLoading && (  
                                <>
                                    {isSearchBtnPressed && ( 
                                        <>
                                            {listStock.length != 0 && (
                                                <>
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">                                                         
                                                        </Col>
                                                        <Col sm="12" md="6" lg="6">
                                                            <FormGroup>
                                                                <label style={{ fontSize: "large" }} htmlFor="#type">Sorted by :<span className="error-txt"></span></label>
                                                                <FormSelect size="lg" onChange={this.sortListStock}>
                                                                    <option value=""></option>
                                                                    <option value="ascCategory">Category(ascending)</option>
                                                                    <option value="desCategory">Category(descending)</option>
                                                                    <option value="ascDescription">Description(ascending)</option>
                                                                    <option value="desDescription">Description(descending)</option>
                                                                    <option value="ascExpirydate">Expiry date(ascending)</option>
                                                                    <option value="desExpirydate">Expiry date(descending)</option>
                                                                    <option value="ascLocation">Location(ascending)</option>
                                                                    <option value="desLocation">Location(descending)</option>
                                                                </FormSelect>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <BootstrapTable
                                                        keyField='id'
                                                        data={listStock}
                                                        columns={columnsListStock}
                                                        wrapperClasses="table-responsive"
                                                        pagination={paginationFactory()}
                                                    />
                                                    <br />
                                                    <Row>
                                                        <Col sm="12" md="6" lg="6">
                                                            <ExcelFile type="button" element={<Button style={{ margin: "1%" }} size="lg" block>Save/Export</Button>} >
                                                                <ExcelSheet data={listStock} name="listStock">
                                                                    <ExcelColumn label="Pallet number" value="pallet_number" />
                                                                    <ExcelColumn label="Description" value="description" />
                                                                    <ExcelColumn label="Quantity" value="quantity" />
                                                                    <ExcelColumn label="Total price" value="total_price" />
                                                                    <ExcelColumn label="Expiry date" value={(col) => getDateInGivenFormat(col.expiry_date?.slice(0, 10))} />
                                                                    <ExcelColumn label="Category" value="category" />
                                                                    <ExcelColumn label="Location" value="location" />
                                                                </ExcelSheet>
                                                            </ExcelFile>
                                                        </Col>
                                                        <Col sm="12" md="6" lg="6">
                                                            <Button style={{ margin: "1%" }} size="lg" block onClick={this.handlePrint}>Print</Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {listStock.length == 0 && (
                                                <><Alert theme="warning">No Data Found</Alert></>
                                            )}
                                        </>                                       
                                        )}
                                </>                                
                                )}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
            </>
        );
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({ selectedPrimaryLocation: value, errorResponseForSearch: "", errorResponseForLocationSelection:"" });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ isValidPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ isValidSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    handleAdd = () => {
        this.storageLocation.current.validatePrimaryLocation();
        const { isValidPrimaryLocation, isSecondaryLocationLoading, selectedPrimaryLocation, selectedSecondaryLocation, selectedLocation } = this.state;
        if (isValidPrimaryLocation  && !isSecondaryLocationLoading) {
            let location = (selectedSecondaryLocation.length !== 0)
                ?
                {
                    primary_id: parseInt(selectedPrimaryLocation[0].id),
                    secondary_id: parseInt(selectedSecondaryLocation[0].Id),
                    primary_location: selectedPrimaryLocation[0].description,
                    secondary_location: selectedSecondaryLocation[0].Name,
                    location: `${selectedPrimaryLocation[0].description}(${selectedSecondaryLocation[0].Name})`
                }
                :
                {
                    primary_id: parseInt(selectedPrimaryLocation[0].id),
                    secondary_id: 0,
                    primary_location: selectedPrimaryLocation[0].description,
                    secondary_location: null,
                    location: selectedPrimaryLocation[0].description
                }
            let index = selectedLocation.findIndex(x => x.primary_id === location.primary_id && x.secondary_id === location.secondary_id);
            if (index === -1) {
                selectedLocation.push(location);
                this.setState({ selectedLocation, errorResponseForLocationSelection: "" });
            } else {
                this.setState({ errorResponseForLocationSelection: "Location have been already selected" });
            }
            this.storageLocation.current.resetStorageLocation();
        }
    }

    handleSearch = () => {
        const { selectedLocation} = this.state;
        if (selectedLocation.length !== 0) {
            this.setState({ isDataLoading: true, listStock: [], isSearchBtnPressed: true, errorResponseForSearch: "" })
            let selectedLocations = [];
            selectedLocation.forEach(location => {
                var selectedLocation = {
                    PrimaryId: location.primary_id,
                    SecondaryId: location.secondary_id,
                    PrimaryLocation: location.primary_location,
                    SecondaryLocation: location.secondary_location,
                    location: location.location,
                }
                selectedLocations.push(selectedLocation);
            });
            axios.post(`${ApiUrls.listStock}`, selectedLocations, authorizationHeader).then(({data: result }) => {
                if (result.isSuccess) {
                    let listStock = JSON.parse(result.data);
                    let sortedListStock = sortByAlphanumerically(listStock, 'pallet_number');
                    this.setState({ listStock: sortedListStock, isDataLoading: false });
                }
            }).catch((error) => {
                this.showError(error);
            });
        }
        else {
            this.setState({ errorResponseForSearch: "Please select location to search" });
        } 
    }

    removeLocation = (e, location) => {
        const { selectedLocation } = this.state;
        let index = selectedLocation.findIndex(x => x.primary_id === location.primary_id && x.secondary_id === location.secondary_id);
        selectedLocation.splice(index,1);
        this.setState({ selectedLocation });
    }

    getExpiryDate = (cell, row) => {
        let expiryDate = row.expiry_date;
        let date = expiryDate ? getDateInGivenFormat(expiryDate.slice(0, 10)) : '';
        return (<div>{date}</div>);
    }

    getDescription = (cell, row) => {
        return (
            <div>
                {row.description} ({row.quantity})
            </div>
        );
    }

    handlePrint = () => {
        let customWindow = window.open('', '', 'height=500, width=500');
        const { listStock } = this.state;
        customWindow.document.write(`
            <html>
            <body>
                <h1>LIST STOCK</h1>
                <br>
                <table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">
                    <tr>  
                        <th style="border:1px solid black; border-collapse: collapse">Pallet number</th>
                        <th style="border:1px solid black; border-collapse: collapse">Description</th>
                        <th style="border:1px solid black; border-collapse: collapse">Expiry date</th>
                        <th style="border:1px solid black; border-collapse: collapse">Category</th>
                        <th style="border:1px solid black; border-collapse: collapse">Location</th>
                    </tr>
        `);
        listStock.map((box) => {
            customWindow.document.write(`
                <tr>
                    <td style="border:1px solid black; border-collapse: collapse">${box.pallet_number}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.description} (${box.quantity})</td>
                    <td style="border:1px solid black; border-collapse: collapse">${getDateInGivenFormat(box.expiry_date?.slice(0, 10))}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.category}</td>
                    <td style="border:1px solid black; border-collapse: collapse">${box.location}</td>
                </tr>
            `);
        });
        customWindow.document.write(`
            </table>
            </body>
            </html>
        `);
        customWindow.document.close();
        customWindow.print();
    }

    sortListStock = ({ target }) => {
        this.setState({ isDataLoading: true })
        const { listStock } = this.state;
        let sortedListStock = [];
        switch (target.value) {
            case "ascDescription":
                sortedListStock = sortByAlphanumerically(listStock, 'description');
                break;
            case "desDescription":
                sortedListStock = sortDescendingByAlphanumerically(listStock, 'description');
                break;
            case "ascExpirydate":
                sortedListStock = sortByAlphanumerically(listStock, 'expiry_date');
                break;
            case "desExpirydate":
                sortedListStock = sortDescendingByAlphanumerically(listStock, 'expiry_date');
                break;
            case "ascCategory":
                sortedListStock = sortByAlphanumerically(listStock, 'category');
                break;
            case "desCategory":
                sortedListStock = sortDescendingByAlphanumerically(listStock, 'category');
                break;
            case "ascLocation":
                sortedListStock = sortByAlphanumerically(listStock, 'location');
                break;
            case "desLocation":
                sortedListStock = sortDescendingByAlphanumerically(listStock, 'location');
                break;
        }
        this.setState({ isDataLoading: false, listStock: sortedListStock });
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger" });
        }
    }
}