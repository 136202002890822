import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, Alert, FormInput
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ApiUrls } from "../../environments/apiUrls";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationToken = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class PalletContents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPallet: [],
            pallets: [],
            isPalletsLoading: false,
            isErrorInPallet: false,
            palletquery: "",
            boxContents: [],
            isDataLoading: false,
            viewPalletContents: false,
            locationId: "",
            numberOfBoxes: "",
            totalWeight: "",
            totalPrice: "",
            isWeightHidden: false,
            isBatchnumberHidden: false,
            palletInfo: [],
            isViewContent: false,
            palletNumber: "",

            isResponse: false,
            theme: "",
            response: "",

            isJSpallet: false,
            newWeight: 0,
            showUpdateWeightConfirmation: false,
            errorInWeight: "",
            isYesBtnDisabled: false
        };
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        var palletnumber = [];
        var Pallet = { id: urlParams.get('palletNumber') }
        if (urlParams.get('palletNumber') != null) {
            palletnumber.push(Pallet);
            this.setPallet(palletnumber);
            this.setState({ isViewContent: true, palletNumber: urlParams.get('palletNumber') });
        } else {
            this.setState({ isViewContent: false });
        }
    }

    _cache = {};

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        const columns =
            [
                {
                    text: 'Content',
                    formatter: this.description,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Description',
                    hidden: !this.state.isJSpallet,
                    dataField: 'description_content',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Expiry date',
                    formatter: this.expiryDate,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Weight',
                    hidden: this.state.isWeightHidden,
                    formatter: this.weight,
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Batch number',
                    hidden: this.state.isBatchnumberHidden,
                    dataField: 'batchnumber',
                    style: {
                        fontWeight: 'normal'
                    }
                },
                {
                    text: 'Price(£)',
                    hidden: this.state.isViewContent,
                    formatter: this.price,
                    style: {
                        fontWeight: 'normal'
                    }
                },
            ];
        const { palletInfo, isViewContent, isResponse,
            selectedPallet, palletNumber, isPalletsLoading,
            isDataLoading, boxContents, newWeight,
            response, pallets, viewPalletContents,
            showUpdateWeightConfirmation, errorInWeight, isYesBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>

                            {isViewContent && (<>
                                <BreadcrumbItem>
                                    <a href="/report-on-storage-locations">Report on Storage locations</a>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>{palletNumber}</BreadcrumbItem>
                            </>)}

                            {!isViewContent && (<>
                                <BreadcrumbItem active>Report on  Individual Pallet Contents</BreadcrumbItem>
                            </>)}

                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            {isViewContent && (<>
                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <h4>Pallet number : {palletNumber.toUpperCase()}</h4>
                                    </Col>
                                </Row>
                            </>)}

                            {!isViewContent && (<>

                                <Row>
                                    <Col sm="12" md="12" lg="12">
                                        <h4>REPORT ON PALLET CONTENTS</h4>
                                    </Col>
                                </Row>

                            </>)}
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            {!isViewContent && (<>
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#pallet">Select a pallet</label>
                                            <AsyncTypeahead size="lg" value={selectedPallet} onChange={this.setPallet} options={pallets} isLoading={isPalletsLoading}
                                                id="pallet-typeahead"
                                                labelKey="id"
                                                minLength={1}
                                                onInputChange={this.handlePalletInputChange}
                                                onSearch={this.handlePalletSearch}
                                                placeholder="Search for pallets"
                                                renderMenuItemChildren={option => (
                                                    <div key={option.id}>
                                                        <span>{option.id}</span>
                                                    </div>
                                                )}
                                                useCache={false}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </>)}

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {viewPalletContents && (
                                        <div>
                                            {isDataLoading && (
                                                <label style={{ fontWeight: "bold" }}>Loading...</label>
                                            )}
                                            {!isDataLoading && (
                                                <div style={{ width: "100%" }}>
                                                    {boxContents.length >= 1 && (
                                                        <>
                                                            <BootstrapTable
                                                                keyField='id'
                                                                data={boxContents}
                                                                columns={columns}
                                                                wrapperClasses="table-responsive"
                                                                pagination={paginationFactory()}
                                                            />

                                                            {!isViewContent && (<>
                                                                <Button size="lg" onClick={this.printBtnClick}>Print</Button>
                                                            </>)}

                                                        </>
                                                    )}
                                                    {palletInfo !== null && this.shouldShowUpdateWeightUI() && (
                                                        <>
                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                    <FormGroup>
                                                                        <label style={{ fontSize: "large" }} htmlFor="#currentWeight">Current Weight(kg) :</label>
                                                                        <FormInput size="lg" type="text" id="#currentWeight" value={palletInfo.TotalWeight} disabled/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                    <FormGroup>
                                                                        <label style={{ fontSize: "large" }} htmlFor="#newWeight">New Weight(kg) :<span className="error-txt">*</span></label>
                                                                        <FormInput size="lg" type="number" id="#newWeight" value={newWeight} onChange={this.setNewWeight} />
                                                                        <span className="error-txt">{errorInWeight}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                    <FormGroup >
                                                                        <Button theme="primary" size="lg" type="submit" onClick={this.onUpdateClick}> Update </Button>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    )}

                                                    {!this.shouldShowUpdateWeightUI() && boxContents == 0 && (
                                                        <Alert theme="warning">Pallet is empty</Alert>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                    )}
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {showUpdateWeightConfirmation && (
                    <Modal open={showUpdateWeightConfirmation} toggle={() => this.setState({ showUpdateWeightConfirmation: !this.state.showUpdateWeightConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>REPORT ON PALLET CONTENTS - UPDATE WEIGHT - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Do you want to update the weight of Pallet Number <b> {palletInfo.PalletNumber} </b> as {newWeight}kg ?</label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onConfirmClick} disabled={isYesBtnDisabled}>{isYesBtnDisabled?'Loading...':'Yes'}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ showUpdateWeightConfirmation: false })} disabled={this.state.isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    description = (cell, row) => {
        return (<div>
            {row.description} ({row.numberofboxes})
        </div>)
    }

    expiryDate = (cell, row) => {
        var expiryDate = row.expirydate;
        var date;
        expiryDate ? date = getDateInGivenFormat(expiryDate.slice(0, 10)) : date = '';
        return (<div>{date}</div>)
    }

    weight = (cell, row) => {
        return (<div>{row.totalweight}kg</div>)
    }

    price = (cell, row) => {
        return (<div>{row.total_value.toFixed(2)}</div>)
    }

    printPrice = (row) => {
        return row.total_value.toFixed(2)
    }

    setPallet = (value) => {
        console.log(value)
        this.setState({ selectedPallet: value, isErrorInPallet: false })

        if (this.state.selectedPallet.length === 0) {
            this.setState({ isErrorInPallet: false, viewPalletContents: true, isDataLoading: true })
            axios.get(`${ApiUrls.viewPalletContents}?PalletNumber=${value[0].id}`, authorizationToken).then((response) => {
                var palletContent = JSON.parse(response.data.data);
                var boxContents = palletContent.PalletBoxContents;
                var sortBoxContents = sortByAlphanumerically(boxContents, 'description');
                var palletInfo = palletContent.PalletInfo;
                (palletInfo.PalletCategory == 10) ? this.setState({ isJSpallet: true }) : this.setState({ isJSpallet: false });
                var numberOfBoxes = 0;
                var totalWeight = 0;
                var totalPrice = 0;
                boxContents.map((box) => {
                    var price = (box.total_value).toFixed(2);                      
                    totalPrice += parseFloat(price);
                    numberOfBoxes += box.numberofboxes;
                    totalWeight += box.totalweight;
                })
                console.log(palletInfo);
                if (palletInfo.PalletCategory == 1) {
                    this.setState({ isWeightHidden: true, isBatchnumberHidden: false })
                } else {
                    this.setState({ isWeightHidden: false, isBatchnumberHidden: true })
                }

                this.setState({ boxContents: sortBoxContents, isDataLoading: false, numberOfBoxes: numberOfBoxes, totalWeight: totalWeight, totalPrice: totalPrice, palletInfo: palletInfo })
                if (boxContents == "") { this.setState({ isRemoveBoxesBtnDisabled: true }) } else { this.setState({ isRemoveBoxesBtnDisabled: false }) }
            }).catch((error) => {
                this.showError(error);
            });

        } else {
            this.setState({ isErrorInPallet: true, viewPalletContents: false })
        }
    };

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isResponse: false, theme: "", response: "" });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getPallets}?query=${palletquery}&locId=${this.state.locationId}&includeDeleted=false`, authorizationToken)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    printBtnClick = (value) => {
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body>');
        a.document.write('<h1>Pallet Number :' + this.state.selectedPallet[0].id + '</h1>');
        a.document.write('<br>');
        a.document.write('<table style="width:100%; border:1px solid black; border-collapse: collapse; font-size:20px">');

        a.document.write('<tr>');
        a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Content</th>');
        if (this.state.isJSpallet) { a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Description</th>'); }
        a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Expiry date</th>');
        if (this.state.palletInfo.PalletCategory == 1) {
            a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Batch number</th>');
        } else {
            a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Weight</th>');
        }
        a.document.write('<th style="border:1px solid black; border-collapse: collapse" >Price(£)</th>');
        a.document.write('</tr>');
        {
            this.state.boxContents.map((box) => {
                a.document.write('<tr>');
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + box.description + ' (' + box.numberofboxes + ')</td>');
                if (this.state.isJSpallet) { a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + box.description_content + '</td>'); }
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + getDateInGivenFormat(box.expirydate?.slice(0, 10)) + '</td>');
                if (this.state.palletInfo.PalletCategory == 1) {
                    a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + box.batchnumber + '</td>');
                } else {
                    a.document.write('<td style="border:1px solid black; border-collapse: collapse" >' + box.totalweight + 'kg</td>');
                }
                a.document.write('<td style="border:1px solid black; border-collapse: collapse" >£' + this.printPrice(box) + '</td>');
                a.document.write('</tr>');
            })
        }

        a.document.write('</table>');

        a.document.write('<h3>Number of boxes :' + this.state.numberOfBoxes + '</h3>');
        if (this.state.palletInfo.PalletCategory == 1) {

        } else {
            a.document.write('<h3>Total weight :' + this.state.totalWeight + 'kg</h3>');
        }
        a.document.write('<h3>Total price : £' + this.state.totalPrice.toFixed(2) + '</h3>');

        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    showError = (error) => {
        console.log(error);
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isYesBtnDisabled: false, showUpdateWeightConfirmation: false });
        }
    }

    shouldShowUpdateWeightUI = () => {
        const { palletInfo } = this.state;
        if (palletInfo.PalletCategory === 1 || palletInfo.PalletCategory === 4 || palletInfo.PalletCategory === 7 || (palletInfo.PalletCategory >= 9 && palletInfo.PalletCategory <= 11)) {
            return false;
        }
        else {
            return true;
        }
    }
    setNewWeight = (value) => {
        this.setState({ newWeight: value.target.value, errorInWeight:"" });
    }

    onUpdateClick = () => {
        const { newWeight } = this.state;
        if (!newWeight) {
            this.setState({ errorInWeight: "Please enter a new weight", newWeight: newWeight });
        } else if (isNaN(newWeight)) {
            this.setState({ errorInWeight: "Please enter number", newWeight: newWeight });
        } else {
            this.setState({ showUpdateWeightConfirmation: true, newWeight: newWeight });
        }
        
    }

    onConfirmClick = () => {
        const { newWeight, palletInfo, isViewContent } = this.state;

        this.setState({ isYesBtnDisabled: true, isResponse: false });

        var weightUpdateInfo = {
            "PalletNumber": palletInfo.PalletNumber,
            "Weight": newWeight
        }
        axios.post(ApiUrls.updatePalletWeight, weightUpdateInfo, authorizationToken).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isResponse: true, response: response.data.messageText, theme: "success",
                    isYesBtnDisabled: false, showUpdateWeightConfirmation: false
                });
                if (isViewContent) {
                    window.location.href = "/report-on-storage-locations/";
                }
                else {
                    window.location.href = "/pallet-contents/";
                }
               
            }
            else {
                this.setState({
                    isResponse: true, response: response.data.messageText, theme: "danger",
                    isYesBtnDisabled: false, showUpdateWeightConfirmation: false
                });
            }
        }).then((error) => {
            this.showError(error);
        });
    }
}