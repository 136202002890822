import React, { Component } from 'react';
import {FormGroup, Row, Col} from "shards-react";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class StorageLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryLocations: [],
            selectedPrimaryLocation: [],
            responsePrimaryLocation: "",
            emptyLabel: [<>< i class="fa fa-spinner fa-spin" > </i> Loading</>],

            secondaryLocations: [],
            selectedSecondaryLocation: [],
            responseSecondaryLocation: "",
            isSecondaryLocationLoading: false,
        };
        this.primaryLocation = React.createRef();
        this.secondaryLocation = React.createRef();
    }

    componentDidMount() {
        this.GetPrimaryLocations();
    }

    render() {
        return (  
            <>
                <Row>
                    <Col sm="12" md="10" lg="8">
                        <FormGroup style={{ fontSize: "large" }}>
                            <label htmlFor="#PrimaryLocation">Location stored :<span className="error-txt">*</span></label>
                            <Typeahead size="lg" selected={this.state.selectedPrimaryLocation}
                                id="id"
                                labelKey="description"
                                onChange={this.SetPrimaryLocation}
                                options={this.state.primaryLocations}
                                placeholder=""
                                ref={this.primaryLocation}
                                emptyLabel={this.state.emptyLabel}
                            />
                            <span className="error-txt">{this.state.responsePrimaryLocation}</span>
                        </FormGroup>
                    </Col>
                </Row>

                {this.state.isSecondaryLocationLoading && (
                    <Row>
                        <Col sm="12" md="10" lg="8">
                            <FormGroup>
                                <label><i class="fa fa-spinner fa-spin"></i>Loading</label>
                            </FormGroup>
                        </Col>
                    </Row>
                )}


                {!this.state.isSecondaryLocationLoading && (
                    <>
                        {this.state.secondaryLocations.length !== 0 && (
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup style={{ fontSize: "large" }}>
                                        <label htmlFor="#SecondaryLocations">Secondary location stored :<span className="error-txt">*</span></label>
                                        <Typeahead size="lg" value={this.state.selectedSecondaryLocation}
                                            id="locId"
                                            labelKey="Name"
                                            onChange={this.SetSecondaryLocation}
                                            options={this.state.secondaryLocations}
                                            placeholder=""
                                            ref={this.secondaryLocation}
                                        />
                                        <span className="error-txt">{this.state.responseSecondaryLocation}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                    </>
                )}

                
            </>           
            );
    }

    GetPrimaryLocations = () => {
        axios.get(ApiUrls.getStorageLocations, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                var primaryLocations = JSON.parse(response.data.data);
                var sortPrimaryLocations = sortByAlphanumerically(primaryLocations, 'description');
                this.setState({ primaryLocations: sortPrimaryLocations, selectedPrimaryLocation: [], emptyLabel: "No matches found" });
            }
        })
    };

    SetPrimaryLocation = (value) => {
        if (value !== null && value.length > 0) {
            this.setState({ responsePrimaryLocation: "", selectedPrimaryLocation: value, isSecondaryLocationLoading:true});
            this.props.getSelectedPrimaryLocation(value);
            this.props.getPrimaryLocationValidStatus(true);
            this.props.getSecondaryLocationLoadingStatus(true);
            
            axios.get(`${ApiUrls.getSecondaryLocListByPrimaryLocId}?locationId=${value[0].id}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var secondaryLocations = JSON.parse(response.data.data);
                    var sortSecondaryLocations = sortByAlphanumerically(secondaryLocations, 'Name');
                    if (secondaryLocations.length !== 0) {
                        this.setState({ secondaryLocations: sortSecondaryLocations, isSecondaryLocationLoading: false });
                        this.props.getSecondaryLocationValidStatus(false);
                        this.props.getSecondaryLocationLoadingStatus(false);
                        this.props.getSelectedSecondaryLocation([]);
                    } else {
                        this.setState({ secondaryLocations: [], isSecondaryLocationLoading: false });
                        this.props.getSecondaryLocationValidStatus(true);
                        this.props.getSecondaryLocationLoadingStatus(false);
                        this.props.getSelectedSecondaryLocation([]);
                    }
                }
            })
        } else {
            this.setState({
                responsePrimaryLocation: "Please select a location", selectedPrimaryLocation: [],
                secondaryLocations: [], isSecondaryLocationLoading: false
            });
            this.props.getSelectedPrimaryLocation([]);
            this.props.getPrimaryLocationValidStatus(false);
            this.props.getSecondaryLocationLoadingStatus(false);
        }
    };

    SetSecondaryLocation = (value) => {
        if (value !== null && value.length > 0) {
            this.setState({ responseSecondaryLocation: "", selectedSecondaryLocation: value});
            this.props.getSelectedSecondaryLocation(value);
            this.props.getSecondaryLocationValidStatus(true);           
        } else {
            this.setState({ responseSecondaryLocation: "Please select a secondary location", selectedSecondaryLocation: [] });
            this.props.getSelectedSecondaryLocation([]);
            this.props.getSecondaryLocationValidStatus(false);            
        }
    };

    resetStorageLocation = () => {
        this.primaryLocation.current.clear();
        if (this.state.secondaryLocations.length !== 0) {
            this.secondaryLocation.current.clear();
        }
        this.setState({
            selectedPrimaryLocation: [], responsePrimaryLocation: "",
            secondaryLocations: [], selectedSecondaryLocation: [],
            responseSecondaryLocation: ""
        });   
    };

    validatePrimaryLocation = () => {
        if (this.state.selectedPrimaryLocation !== null && this.state.selectedPrimaryLocation.length > 0) {
            this.setState({ responsePrimaryLocation: ""});
            this.props.getPrimaryLocationValidStatus(true);
        } else {
            this.setState({ responsePrimaryLocation: "Please select a Primary location" });
            this.props.getPrimaryLocationValidStatus(false);
        }
    };

    validateSecondaryLocation = () => {
        if (this.state.selectedSecondaryLocation !== null && this.state.selectedSecondaryLocation.length > 0 && this.state.secondaryLocations.length !== 0) {
            this.setState({ responseSecondaryLocation: "" });
            this.props.getSecondaryLocationValidStatus(true);
        } else {
            this.setState({ responseSecondaryLocation: "Please select a secondary location" });
            this.props.getSecondaryLocationValidStatus(false);
        }
    };

}
export default StorageLocation;