import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Row, Col, Breadcrumb, BreadcrumbItem, Alert,
    FormInput, Form, FormSelect, Modal, ModalBody} from "shards-react";
import axios from "axios";
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { StorageLocation } from "../common/StorageLocation";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };
const PER_PAGE = 20;

export class DeletePalletOrItem extends Component {

    constructor(props) {
        super(props);
        this.state = {

            isPallet: false,
            isNonPalletItem: false,

            isResponse: false,
            theme: "",
            response: "",

            selectedPrimaryLocation: [],
            validPrimaryLocation: false,

            selectedSecondaryLocation: [],
            validSecondaryLocation: false,
            isSecondaryLocationLoading: false,

            isSearchBtnPress: false,

            nonPalletItemsInStock: [],
            selectedNonPalletItemsInStock: [],
            responseNonPalletItemsInStock: "",
            validNonPalletItemsInStock: false,
            isNonPalletItemsInStockLoading: false,

            numberOfItem: "",
            responseNumberOfItem: "",
            validNumberOfItem: false,

            showConfirmation: false,
            yesBtnText: "Yes",
            isYesBtnDisabled: false,

            selectedPallet: [],
            pallets: [],
            isPalletsLoading: false,
            isErrorInPallet: false,
            palletquery: "",
            showConfirm: false,
            isFinishPalletBtnDisabled: true,
            buttonText: "Confirm"
        };
        this.StorageLocation = React.createRef();
        this.nonPalletItemsInStock = React.createRef();
        this.palletNumber = React.createRef();
    }

    _cache = {};

    render() {

        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                <Col sm="12" md="10" lg="8" style={mystyle}>

                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href="/warehouse">Warehouse Interface</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Delete Pallet or Item</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>

                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>DELETE A PALLET OR A NON-PALLET ITEM</h4>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {this.state.isResponse && (
                                        <Alert theme={this.state.theme}>
                                            {this.state.response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{fontSize:"large"}} htmlFor="#type">Select a type to delete :<span className="error-txt">*</span></label>
                                        <FormSelect size="lg" onChange={this.setTypeToDelete}>
                                            <option value="">Please select a type to delete</option>
                                            <option value="Pallet">Pallet</option>
                                            <option value="NonPallet">Non-Pallet item</option>                                            
                                        </FormSelect>
                                    </FormGroup>
                                </Col>
                            </Row>

                            {this.state.isNonPalletItem && (<>
                            <form >

                                <StorageLocation ref={this.StorageLocation}
                                    getSelectedPrimaryLocation={this.getUpdateOnSelectedPrimaryLocation}
                                    getPrimaryLocationValidStatus={this.getUpdateOnValidPrimaryLocation}
                                    getSecondaryLocationValidStatus={this.getUpdateOnValidSecondaryLocation}
                                    getSelectedSecondaryLocation={this.getUpdateOnSelectedSecondaryLocation}
                                    getSecondaryLocationLoadingStatus={this.getUpdateOnIsSecondaryLocationLoading}
                                />

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <Button theme="primary" size="lg" onClick={this.onSearchClick} type="button">Search</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {this.state.isSearchBtnPress && (
                                    <>

                                        {this.state.isNonPalletItemsInStockLoading && (
                                            <Row>
                                                <Col sm="12" md="10" lg="8">
                                                    <FormGroup>
                                                        <label><i class="fa fa-spinner fa-spin"></i>Loading</label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}

                                        {!this.state.isNonPalletItemsInStockLoading && (
                                            <>
                                                {this.state.nonPalletItemsInStock.length == 0 && (
                                                    <Alert theme="warning">Relevant location didn't have any non pallet item​ in stock</Alert>
                                                )}

                                                {this.state.nonPalletItemsInStock.length !== 0 && (
                                                    <>
                                                        <Row>
                                                            <Col sm="12" md="10" lg="8">
                                                                <FormGroup>
                                                                        <label style={{ fontSize: "large" }} htmlFor="#NonPalletItemsInStock">Non pallet items in stock :<span className="error-txt">*</span></label>
                                                                        <Typeahead size="lg" value={this.state.selectedNonPalletItemsInStock}
                                                                        id="Id"
                                                                        labelKey="displaydescription"
                                                                        onChange={this.setNonPalletItemsInStock}
                                                                        options={this.state.nonPalletItemsInStock}
                                                                        placeholder=""
                                                                        ref={this.nonPalletItemsInStock}
                                                                    />
                                                                    <span className="error-txt">{this.state.responseNonPalletItemsInStock}</span>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                            {this.state.selectedNonPalletItemsInStock.length != 0 && (<>
                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                <FormGroup>
                                                                            <label style={{ fontSize: "large" }} htmlFor="#NumberOfItem">Quantity :<span className="error-txt">*</span></label>
                                                                            <FormInput size="lg" type="number" id="#NumberOfItem" value={this.state.numberOfItem} onChange={this.setNumberOfItem} min="1" />
                                                                        <span className="error-txt">{this.state.responseNumberOfItem}</span>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
 
                                                            <Row>
                                                                <Col sm="12" md="10" lg="8">
                                                                    <FormGroup>
                                                                            <Button theme="primary" size="lg" onClick={this.onConfirmClick} type="submit">Delete</Button>
                                                                    </FormGroup>
                                                                </Col>
                                                                </Row>
                                                            </>
                                                            )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                    </>
                                )}
                            </form>
                            </>)}

                            {this.state.isPallet && (<>

                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#pallet">Which pallet do you want to delete?</label>
                                            <AsyncTypeahead size="lg" value={this.state.selectedPallet} onChange={this.setPallet} options={this.state.pallets} isLoading={this.state.isPalletsLoading}
                                                id="pallet-typeahead"
                                                labelKey="id"
                                                minLength={1}
                                                onInputChange={this.handlePalletInputChange}
                                                onSearch={this.handlePalletSearch}
                                                placeholder="Search for pallets"
                                                ref={this.palletNumber}
                                                renderMenuItemChildren={option => (
                                                    <div key={option.id}>

                                                        <span>{option.id}</span>
                                                    </div>
                                                )}
                                                useCache={false}
                                            />

                                            {(this.state.isErrorInPallet) && (
                                                <span className="error-txt">Please select a pallet</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Form>
                                    <Row>
                                        <Col sm="12" md="10" lg="8">
                                            <FormGroup>
                                                <Button theme="primary" size="lg" onClick={this.onPalletConfirmClick} type="submit">Delete</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </>)}

                        </CardBody>
                    </Card>

                </Col>

                <Col sm="12" md="1" lg="2" style={mystyle}></Col>

                {this.state.showConfirmation && (
                    <Modal open={this.state.showConfirmation} toggle={() => this.setState({ showConfirmation: this.state.showConfirmation })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>DELETE PALLET OR NON PALLET ITEMS - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Are you sure that you want to delete {this.state.numberOfItem} of {this.state.selectedNonPalletItemsInStock[0].description}
                                    &nbsp;in location {this.state.selectedPrimaryLocation[0].description} {this.state.selectedSecondaryLocation.length !== 0 && (<>({this.state.selectedSecondaryLocation[0].Name})</>)}                                   
                                    </label>
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.onYesClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={this.onNoClick} disabled={this.state.isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>

                            </Row>

                        </ModalBody>
                    </Modal>
                )}

                {this.state.showConfirm && (
                    <Modal open={this.state.showConfirm} toggle={() => this.setState({ showConfirm: !this.state.showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>DELETE PALLET OR NON PALLET ITEMS - CONFIRMATION</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <label style={{ fontSize: "x-large" }}>Are you sure that you want to delete {this.state.selectedPallet[0].id}</label>                                    
                                </Col>
                            </Row>

                            <br></br>

                            <Row>
                                <Col>
                                    <FormGroup> 
                                        <Button theme="success" size="lg" onClick={this.confirmClick} disabled={this.state.isYesBtnDisabled}>{this.state.yesBtnText}</Button>
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Button theme="secondary" style={{ float: "right" }} size="lg" onClick={() => this.setState({ showConfirm: false })} disabled={this.state.isYesBtnDisabled}>No</Button>
                                    </FormGroup>
                                </Col>

                            </Row>
       
                        </ModalBody>
                    </Modal>
                )}

            </>
        );
    }

    setTypeToDelete = (value) => {
        if (value.target.value == "Pallet") {
            this.setState({
                isPallet: true, isNonPalletItem: false,
                isResponse: false, theme: "", response: "",
                selectedPrimaryLocation: [], validPrimaryLocation: false,
                selectedSecondaryLocation: [], validSecondaryLocation: false,
                isSecondaryLocationLoading: false, isSearchBtnPress: false,
                nonPalletItemsInStock: [], selectedNonPalletItemsInStock: [],
                responseNonPalletItemsInStock: "", validNonPalletItemsInStock: false,
                isNonPalletItemsInStockLoading: false, numberOfItem: "",
                responseNumberOfItem: "", validNumberOfItem: false,
                showConfirmation: false, yesBtnText: "Yes",
                isYesBtnDisabled: false, selectedPallet: [], pallets: [],
                isPalletsLoading: false, isErrorInPallet: false,
                palletquery: "", showConfirm: false,
                isFinishPalletBtnDisabled: true, buttonText: "Confirm"
            });
        } else if (value.target.value == "NonPallet") {
            this.setState({
                isPallet: false, isNonPalletItem: true,
                isResponse: false, theme: "", response: "",
                selectedPrimaryLocation: [], validPrimaryLocation: false,
                selectedSecondaryLocation: [], validSecondaryLocation: false,
                isSecondaryLocationLoading: false, isSearchBtnPress: false,
                nonPalletItemsInStock: [], selectedNonPalletItemsInStock: [],
                responseNonPalletItemsInStock: "", validNonPalletItemsInStock: false,
                isNonPalletItemsInStockLoading: false, numberOfItem: "",
                responseNumberOfItem: "", validNumberOfItem: false,
                showConfirmation: false, yesBtnText: "Yes",
                isYesBtnDisabled: false, selectedPallet: [], pallets: [],
                isPalletsLoading: false, isErrorInPallet: false,
                palletquery: "", showConfirm: false,
                isFinishPalletBtnDisabled: true, buttonText: "Confirm"

            });
        } else {
            this.setState({
                isPallet: false, isNonPalletItem: false,
                isResponse: false, theme: "", response: "",
                selectedPrimaryLocation: [], validPrimaryLocation: false,
                selectedSecondaryLocation: [], validSecondaryLocation: false,
                isSecondaryLocationLoading: false, isSearchBtnPress: false,
                nonPalletItemsInStock: [], selectedNonPalletItemsInStock: [],
                responseNonPalletItemsInStock: "", validNonPalletItemsInStock: false,
                isNonPalletItemsInStockLoading: false, numberOfItem: "",
                responseNumberOfItem: "", validNumberOfItem: false,
                showConfirmation: false, yesBtnText: "Yes",
                isYesBtnDisabled: false, selectedPallet: [], pallets: [],
                isPalletsLoading: false, isErrorInPallet: false,
                palletquery: "", showConfirm: false,
                isFinishPalletBtnDisabled: true, buttonText: "Confirm"
            });
        }
    }

    getUpdateOnSelectedPrimaryLocation = (value) => {
        this.setState({isResponse:false});
        this.setState({ selectedPrimaryLocation: value });
    };

    getUpdateOnValidPrimaryLocation = (value) => {
        this.setState({ isResponse: false });
        this.setState({ validPrimaryLocation: value });
    };

    getUpdateOnValidSecondaryLocation = (value) => {
        this.setState({ validSecondaryLocation: value });
    };

    getUpdateOnSelectedSecondaryLocation = (value) => {
        this.setState({ selectedSecondaryLocation: value });
    };

    getUpdateOnIsSecondaryLocationLoading = (value) => {
        this.setState({ isSecondaryLocationLoading: value });
    };

    resetStorageLocation() {
        this.refs.StorageLocation.resetStorageLocation();
    }

    validationPrimaryLocation() {
        this.refs.StorageLocation.validatePrimaryLocation();
    }

    validationSecondaryLocation() {
        this.refs.StorageLocation.validateSecondaryLocation();
    }

    onSearchClick = () => {
        this.StorageLocation.current.validatePrimaryLocation();
        if (!this.state.isSecondaryLocationLoading && !this.state.validSecondaryLocation) {
            this.StorageLocation.current.validateSecondaryLocation();
        }
        const { validPrimaryLocation, validSecondaryLocation, isSecondaryLocationLoading } = this.state;
        if (validPrimaryLocation && validSecondaryLocation && !isSecondaryLocationLoading) {
            this.setState({ isNonPalletItemsInStockLoading: true, isSearchBtnPress: true });
            const { selectedPrimaryLocation, selectedSecondaryLocation } = this.state;
            var primaryLocationId, secondaryLocationId
            if (selectedSecondaryLocation.length !== 0) {
                primaryLocationId = selectedPrimaryLocation[0].id;
                secondaryLocationId = selectedSecondaryLocation[0].Id
            } else {
                primaryLocationId = selectedPrimaryLocation[0].id;
                secondaryLocationId = 0
            }
            axios.get(`${ApiUrls.getNonPalletItemsInStockByLocId}?primaryLocId=${primaryLocationId}&secondaryLocId=${secondaryLocationId}`, authorizationHeader).then((response) => {
                if (response.data.isSuccess) {
                    var nonPalletItemsInStock = JSON.parse(response.data.data);
                    var sortNonPalletItemsInStock = sortByAlphanumerically(nonPalletItemsInStock, 'displaydescription');
                    this.setState({
                        nonPalletItemsInStock: sortNonPalletItemsInStock, isNonPalletItemsInStockLoading: false,
                        numberOfItem: "", responseNumberOfItem: "", validNumberOfItem: false,
                    });
                }
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });
        }
    };

    setNonPalletItemsInStock = (value) => {
        this.setState({ isResponse: false });
        if (value !== null && value.length > 0) {
            this.setState({
                selectedNonPalletItemsInStock: value, validNonPalletItemsInStock: true,
                responseNonPalletItemsInStock: ""
            });
        } else {
            this.setState({
                selectedNonPalletItemsInStock: [], validNonPalletItemsInStock: false,
                responseNonPalletItemsInStock: "Please select a item"
            });
        }
    };

    validateNonPalletItemsInStock = () => {
        if (this.state.selectedNonPalletItemsInStock.length === 0) {
            this.setState({ responseNonPalletItemsInStock: "Please select a item", validNonPalletItemsInStock: false });
        } else {
            this.setState({ responseNonPalletItemsInStock: "", validNonPalletItemsInStock: true });
        }
    };

    setNumberOfItem = (value) => {
        this.setState({ numberOfItem: value.target.value, isResponse: false });
        if (!(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(value.target.value)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) > this.state.selectedNonPalletItemsInStock[0].numberofitem) {
            this.setState({ responseNumberOfItem: "Stock available less than requested amount", validNumberOfItem: false });
        } else if (parseFloat(value.target.value) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(value.target.value)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    validateNumberOfItem = () => {
        if (!(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter correct quantity", validNumberOfItem: false });
        } else if (isNaN(this.state.numberOfItem)) {
            this.setState({ responseNumberOfItem: "Please enter number", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) > this.state.selectedNonPalletItemsInStock[0].numberofitem) {
            this.setState({ responseNumberOfItem: "Stock available less than requested amount", validNumberOfItem: false });
        } else if (parseFloat(this.state.numberOfItem) <= 0) {
            this.setState({ responseNumberOfItem: "Please enter positive number", validNumberOfItem: false });
        } else if (Number.isInteger(parseFloat(this.state.numberOfItem)) == false) {
            this.setState({ responseNumberOfItem: "Please enter full number", validNumberOfItem: false });
        } else {
            this.setState({ responseNumberOfItem: "", validNumberOfItem: true });
        }
    }

    onConfirmClick = (event) => {
        event.preventDefault();
        this.validateNumberOfItem();
        
        const { validNumberOfItem } = this.state;
        if (validNumberOfItem ) {
            this.setState({ showConfirmation: true });
        }
    }

    onNoClick = () => {
        this.setState({ showConfirmation: false });
    }

    onYesClick = () => {       
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });
        if (this.state.selectedSecondaryLocation.length != 0) {
            var NonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.selectedNonPalletItemsInStock[0].nonpalletitemid),
                NumberOfItem: parseInt(this.state.numberOfItem),
                LocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryStorageLocationId: parseInt(this.state.selectedSecondaryLocation[0].Id),
            };
        }
        else {
            var NonPalletItemsInStockInfo = {
                NonPalletItemId: parseInt(this.state.selectedNonPalletItemsInStock[0].nonpalletitemid),
                NumberOfItem: parseInt(this.state.numberOfItem),
                LocationId: parseInt(this.state.selectedPrimaryLocation[0].id),
                SecondaryStorageLocationId: 0,
            };
        }
        
        console.log(NonPalletItemsInStockInfo)

        axios.post(ApiUrls.deleteNonPalletItemInStock, NonPalletItemsInStockInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.nonPalletItemsInStock.current.clear();
                this.StorageLocation.current.resetStorageLocation();
                this.setState({
                    isResponse: true, response: response.data.messageText, theme: "success",
                    isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false,
                    selectedPrimaryLocation: [], validPrimaryLocation: false,
                    selectedSecondaryLocation: [], validSecondaryLocation: false,
                    isSecondaryLocationLoading: false, isSearchBtnPress: false,
                    nonPalletItemsInStock: [], selectedNonPalletItemsInStock: [],
                    responseNonPalletItemsInStock: "", validNonPalletItemsInStock: false,
                    isNonPalletItemsInStockLoading: false, numberOfItem: "",
                    responseNumberOfItem: "", validNumberOfItem: false,                    
                });	
            }
            else {
                this.setState({ isResponse: true, response: response.data.messageText, theme: "danger", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false });
            }
        }).catch((error) => {
            console.log(error);
            this.showError(error);            
        });
    }

    //--------------------------------------------------------------------------------------------------
    onPalletConfirmClick = (value) => {
        value.preventDefault();
        if (this.state.selectedPallet.length === 0) {
            this.setState({ isErrorInPallet: true, isResponse: true, theme: "", response: "", isFinishPalletBtnDisabled: false, showConfirm: false })
        } else {
            this.setState({ isErrorInPallet: false, isResponse: false, theme: "", response: "", isFinishPalletBtnDisabled: true, showConfirm: true })
        }
    }

    setPallet = (value) => {
        this.setState({ selectedPallet: value, isErrorInPallet: false, isResponse: false, theme: "", response: "" })

        if (this.state.selectedPallet.length === 0) {
            this.setState({ isErrorInPallet: false, isResponse: false, theme: "", response: "", isFinishPalletBtnDisabled: false })
        } else {
            this.setState({ isErrorInPallet: true, isResponse: false, theme: "", response: "", isFinishPalletBtnDisabled: true })
        }
    };

    handlePalletInputChange = palletquery => {
        this.setState({ palletquery, isResponse: false, theme: "", response: "", isFinishPalletBtnDisabled: true });
    };

    handlePalletSearch = palletquery => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }

        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getEditablePallets}?query=${palletquery}`, authorizationHeader)
            .then(response => {
                var pallets = JSON.parse(response.data.data);
                var options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                var total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                console.log(error);
                this.showError(error);
            });
    }

    confirmClick = (value) => {
        this.setState({ yesBtnText: "Loading...", isYesBtnDisabled: true, isResponse: false });
        //this.setState({ showConfirm: false, buttonText: "Loading...", isFinishPalletBtnDisabled: true })
        var finishPalletInfo = {
            PalletNumber: this.state.selectedPallet[0].id.toString(),
        }

        axios.post(ApiUrls.deletePalletInStock, finishPalletInfo, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.palletNumber.current.clear();
                this._cache = {};
                this.setState({
                    selectedPallet: [], isResponse: true, theme: "success", response: response.data.messageText,
                    selectedPrimaryLocation: [], validPrimaryLocation: false,
                    selectedSecondaryLocation: [], validSecondaryLocation: false,
                    isSecondaryLocationLoading: false, isSearchBtnPress: false,
                    nonPalletItemsInStock: [], selectedNonPalletItemsInStock: [],
                    responseNonPalletItemsInStock: "", validNonPalletItemsInStock: false,
                    isNonPalletItemsInStockLoading: false, numberOfItem: "",
                    responseNumberOfItem: "", validNumberOfItem: false,
                    showConfirmation: false, yesBtnText: "Yes",
                    isYesBtnDisabled: false, pallets: [],
                    isPalletsLoading: false, isErrorInPallet: false,
                    palletquery: "", showConfirm: false,
                    isFinishPalletBtnDisabled: true, buttonText: "Confirm"})
            }
            else {
                this.setState({ selectedPallet: [], isResponse: true, theme: "danger", response: response.data.messageText, buttonText: "Confirm", isFinishPalletBtnDisabled: true })
            }
        }).catch((error) => {
            console.log(error);
            this.showError(error);
        });

    }

    showError = (error) => {
        if (error.response.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, response: "Something went wrong", theme: "danger", isYesBtnDisabled: false, yesBtnText: "Yes", showConfirmation: false,
                buttonText: "Confirm", isFinishPalletBtnDisabled: true, selectedPallet: []
            });
        }
    }
}