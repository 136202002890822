import React, { Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {
    Card, FormRadio, CardTitle, CardBody, Button,
    FormInput, FormGroup, Alert,
    FormCheckbox, Breadcrumb, BreadcrumbItem, Row, Col,
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class InviteUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInviteUserBtnDisabled: true,
            isUserEmailError: false,
            isSelectModulesError: false,
            isAdmin: false,
            isResponse: false,
            isSendInviteBtnDisabled: false,
            theme: "",
            response: "",
            userEmail: "",
            modules: [],
            selectModules: []
        };
    }

    componentDidMount() {
        axios.get(ApiUrls.getModules, authorizationHeader).then((response) => {
            if (response.data.isSuccess) {
                this.setState({ modules: sortByAlphanumerically(JSON.parse(response.data.data), 'modulename') });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    render() {
        const customStyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const { isResponse, theme, response, userEmail, isUserEmailError,
            isAdmin, modules, isSelectModulesError, isSendInviteBtnDisabled } = this.state;
        return (
            <>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>
                <Col sm="12" md="10" lg="8" style={customStyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Invite User</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>INVITE USER</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#userEmail">Email address :<span className="error-txt">*</span></label>
                                        <FormInput size="lg" id="#userEmail" type="email" value={userEmail} placeholder="Email Address" required onChange={this.handleUserEmail} />
                                        <span className="error-txt">{isUserEmailError ? "Please enter valid email address." : ""}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="isAdmin">Is admin user ?<span className="error-txt">*</span></label><br/>
                                        <FormRadio size="lg" inline name="isAdmin" checked={isAdmin} onChange={() => { this.changeAdminStatus(true); }}>True</FormRadio>
                                        <FormRadio size="lg" inline name="isAdmin" checked={!isAdmin} onChange={() => { this.changeAdminStatus(false); }}>False</FormRadio>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!isAdmin && (
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="isAdmin">Assign modules :<span className="error-txt">*</span></label>
                                            {modules.map((module) => {
                                                return (<FormCheckbox size="lg" key={module.moduleid} checked={module.ischecked} onChange={() => this.handleCheckBoxField(module)}  {...module} >{module.modulename}</FormCheckbox>)
                                            })}
                                            <div className="text-danger">{isSelectModulesError ? "Please select at least one module from above check box" : ""}</div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            )}
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button size="lg" onClick={this.handleInvite} disabled={isSendInviteBtnDisabled} type="submit" >{isSendInviteBtnDisabled ? "Loading..." : "Invite"}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={customStyle}></Col>               
            </>
        );
    }

    handleUserEmail = ({ target }) => {
        let userEmail = target.value;
        this.setState({ userEmail });
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(userEmail)) {
            this.setState({ isUserEmailError: true, sendInviteBtnDisabled: true, isResponse: false });
        }
        else {
            const { isAdmin, isSelectModulesError } = this.state;
            (!isAdmin && isSelectModulesError)
                ? this.setState({ isUserEmailError: false, sendInviteBtnDisabled: true, isResponse: false })
                : this.setState({ isUserEmailError: false, sendInviteBtnDisabled: false, isResponse: false });
        }
    }

    changeAdminStatus(status) {
        this.setState({ isAdmin: status});
        if (status) {
            const { modules } = this.state;
            modules.forEach(module => {
                module.ischecked = false;
            })
            this.setState({ isSelectModulesError: true });
        }
        else {
            this.setState({ isUserEmailError: false, sendInviteBtnDisabled: true });
        }
    }

    handleCheckBoxField = (event) => {
        const { modules, isAdmin } = this.state;
        modules.forEach(module => {
            if (module.moduleid === event.moduleid)
                event.ischecked = (event.ischecked === true) ? false : true;
        });
        this.setState({ modules, isResponse: false });
        let modulesCount = modules.reduce((a, { ischecked }) => a + ischecked, 0);
        (!isAdmin && modulesCount >= 1)
            ? this.setState({ isSelectModulesError: false, sendInviteBtnDisabled: false })
            : this.setState({ isSelectModulesError: true, sendInviteBtnDisabled: true });
    }

    handleInvite = (event) => {
        event.preventDefault();
        this.setState({ isSendInviteBtnDisabled: true });
        const { modules, selectModules, userEmail, isAdmin } = this.state;
        modules.forEach(module => {
            if (module.ischecked === true)
                selectModules.push(module.moduleid);
        });
        if (userEmail && isAdmin)
            this.sendUserInfo();
        else if (userEmail && !isAdmin && selectModules.length > 0)
            this.sendUserInfo();
        else {
            this.setState({ isResponse: true, response: "Please fill the form correctly", theme: "danger", isSendInviteBtnDisabled: false });
            event.preventDefault();
        }
    }

    sendUserInfo = () => {
        const { userEmail, isAdmin, selectModules, modules } = this.state;
        let invitedUserInfo = {
            UserName: userEmail,
            IsAdmin: isAdmin,
            Modules: selectModules.toString()
        }
        this.setState({ selectModules: [] });
        axios.post(ApiUrls.inviteUser, invitedUserInfo, authorizationHeader).then(({ data: result}) => {
            modules.forEach((m) => {
                m.ischecked = false;
            })
            result.isSuccess
                ? this.setState({
                    isResponse: true, response: result.messageText, theme: "success",
                    userEmail: "", isAdmin: false, isInviteUserBtnDisabled: true,
                    isErrorInviteUser: false, modules: modules,
                    selectModules: [], isSendInviteBtnDisabled: false
                })
                : this.setState({
                    isResponse: true, response: result.messageText, theme: "danger",
                    userEmail: "", isAdmin: false, isInviteUserBtnDisabled: true,
                    modules: modules, selectModules: [], isSendInviteBtnDisabled: false
                });
        }).catch((error) => {
            this.showError(error);  
        });
    }

    showError = (error) => {
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({ isResponse: true, response: "Something went wrong", theme: "danger", isSendInviteBtnDisabled: false });
        }
    }
}