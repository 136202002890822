import React, { Component } from 'react';
import { Container, Row } from "shards-react";
import MainNavbar from './MainNavbar';
import Footer from './Footer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        const pageContainer = {
            position: "relative",
            minHeight: "100vh",
        };

        const contentWrap = {
            paddingBottom: "2.5rem",
        };
        return (
            <div>
                <MainNavbar />
                <div style={pageContainer}>
                    <div style={contentWrap}>
                        <Container fluid>
                            <Row>
                                {this.props.children}
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}
