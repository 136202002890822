import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Form, FormInput, FormGroup,
    Alert
} from "shards-react";
import axios from "axios";
import { ApiUrls } from "../../environments/apiUrls";

export class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            EmailAddress: "",
            isResetPasswordBtnDisabled: true,
            isErrorInResetPassword: false,
            errorMessage: "",
            isSuccessInResetPassword: false,
            SuccessMessage: "",
            buttonText: "Send Password Reset Link"
           
        };
    }

    render() {
        return (
            <Card style={{ maxWidth: "25rem", marginTop: "8%", marginLeft: "auto", marginRight: "auto" }}>
                <CardHeader style={{ fontWeight: "bold", fontSize: "20px", textAlign: "center" }} class="align-items-center">Reset Password</CardHeader>
                <CardBody>
                    <Form>
                        {this.state.isErrorInResetPassword && (
                            <FormGroup>
                                <Alert theme="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            </FormGroup>
                        )}

                        {this.state.isSuccessInResetPassword && (
                            <FormGroup>
                                <Alert theme="success">
                                    {this.state.SuccessMessage}
                                </Alert>
                            </FormGroup>
                        )}

                        <FormGroup>
                            <label htmlFor="#EmailAddress">Email Address</label>
                            <FormInput id="#EmailAddress" type="email" value={this.state.EmailAddress} placeholder="Email Address" required onChange={this.onEmailAddresschanged} />
                        </FormGroup>
                        <FormGroup>
                            <FormGroup>
                                <a href="/" class="btn btn-link btn-pill align-self-center mr-2">Back to login</a>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup>
                            <Button style={{ width: "100%" }} disabled={this.state.isResetPasswordBtnDisabled} onClick={this.onResetPasswordBtnClick} type="submit">{this.state.buttonText}</Button>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        );
    }

    onEmailAddresschanged = (event) => {
        let EmailAddress = event.target.value;
        if (EmailAddress) {
            this.setState({
                EmailAddress: EmailAddress, isResetPasswordBtnDisabled: false,
                isErrorInResetPassword: false, errorMessage: "",
                isSuccessInResetPassword: false, SuccessMessage: ""
            });
        }
        else {
            this.setState({
                EmailAddress: EmailAddress, isResetPasswordBtnDisabled: true,
                isErrorInResetPassword: false, errorMessage: "",
                isSuccessInResetPassword: false, SuccessMessage: ""
            });
        }
    }

    onResetPasswordBtnClick = (event) => {
        event.preventDefault()
        this.setState({ buttonText: "Loading...", isResetPasswordBtnDisabled: true });
        var emailInfo = {
            EmailAddress: this.state.EmailAddress
        }
        axios.post(ApiUrls.passwordReset, emailInfo).then((response) => {
            if (response.data.isSuccess) {
                this.setState({
                    isSuccessInResetPassword: true, SuccessMessage: response.data.messageText,
                    isErrorInResetPassword: false, errorMessage: "",
                    EmailAddress: "", buttonText: "Send Password Reset Link"
                }); 
                setTimeout(() => this.setState({ isSuccessInResetPassword: false, isErrorInResetPassword: false }), 10000);
            }
            else {
                this.setState({
                    isErrorInResetPassword: true, errorMessage: response.data.messageText,
                    isSuccessInResetPassword: false, SuccessMessage: "",
                    EmailAddress: "", buttonText: "Send Password Reset Link"
                });
            }
        }).catch((error) => {
            this.setState({
                isErrorInResetPassword: true, errorMessage: "Something went wrong",
                isSuccessInResetPassword: false, SuccessMessage: "",
                buttonText: "Send Password Reset Link"
            });
        });  
    }
}