import React, { Component } from 'react';
import {
    Card, CardTitle, CardBody, Button, FormGroup,
    Container, Row, Col, Breadcrumb, BreadcrumbItem,
    Modal, ModalBody, FormInput, Alert
} from "shards-react";
import axios from "axios";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ApiUrls } from "../../environments/apiUrls";
import { sortByAlphanumerically } from "../../utils/sortAscending";
import { getDateInGivenFormat } from "../../utils/formatDate";
import { getPalletCategoryNameWithId, FeaturePalletTypes } from "../../helpers/palletHelper";

const authorizationHeader = { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("Token") } };

export class FinishPallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isResponse: false,
            validWeight: false,
            isBottleRoomPallet: false,
            printConfirm: false,
            isPalletsLoading: false,
            isErrorInPallet: false,
            showConfirm: false,
            isFinishPalletBtnDisabled: true,
            isBottleResponse: false,
            isPalletCategoriesLoading: false,
            theme: "",
            response: "",
            secondaryBreadCrumbItem: "",
            secondaryBreadCrumbUrl: "",
            locationId: "",
            location: "",
            palletquery: "",
            numberOfBoxes: "",
            totalWeight: "",
            palletNumber: "",
            earliestExpiryDate: "",
            today: "",
            weight: "",
            responseWeight: "",
            buttonText: "Confirm",
            selectedPallet: [],
            pallets: [],
            boxContents: [],
            palletInfo: [],
            palletCategories: new Map(),
            palletCategory: 0,
        };
        this.palletNumber = React.createRef();
    }

    _cache = {};

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const location = urlParams.get('location');
        this.setState({ location, isPalletCategoriesLoading: true });
        if (location == "warehouse") {
            this.setState({
                locationId: "", secondaryBreadCrumbItem: "Warehouse Interface",
                secondaryBreadCrumbUrl: "/warehouse"
            });
        }
        else if (location == "medical") {
            this.setState({
                locationId: 8, secondaryBreadCrumbItem: "Medical Disposable Interface",
                secondaryBreadCrumbUrl: "/medical-disposables"
            });
        }
        else if (location == "food") {
            this.setState({
                locationId: 9, secondaryBreadCrumbItem: "Food Interface",
                secondaryBreadCrumbUrl: "/food"
            });
        }
        else if (location == "bottle") {
            this.setState({
                locationId: 5, secondaryBreadCrumbItem: "Bottle Room Interface",
                secondaryBreadCrumbUrl: "/bottle-room"
            });
        }
        this.getTodayDate();
        this.getPalletCategories();
    }

    render() {
        const mystyle = {
            paddingRight: "0px",
            paddingLeft: "0px"
        };
        const {
            secondaryBreadCrumbUrl,
            secondaryBreadCrumbItem,
            isResponse,
            theme,
            response,
            isBottleResponse,
            selectedPallet,
            pallets,
            isPalletsLoading,
            isErrorInPallet,
            isBottleRoomPallet,
            responseWeight,
            weight,
            isFinishPalletBtnDisabled,
            buttonText,
            showConfirm,
            isProcessing,
            printConfirm,
            palletNumber,
            palletInfo,
            boxContents,
            numberOfBoxes,
            totalWeight,
            earliestExpiryDate,
            today,
            palletCategories,
            remainingPalletCategoriesWithIdOnly,
            isPalletCategoriesLoading
        } = this.state;

        return (
            <>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                <Col sm="12" md="10" lg="8" style={mystyle}>
                    <CardTitle style={{ marginTop: "2%" }}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/dashboard">Home</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <a href={secondaryBreadCrumbUrl}>{secondaryBreadCrumbItem}</a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Finish a Pallet</BreadcrumbItem>
                        </Breadcrumb>
                    </CardTitle>
                    <Card style={{ marginTop: "2%", paddingTop: "2%" }}>
                        <CardBody>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    <h4>FINISH A PALLET</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isResponse && (
                                        <Alert theme={theme}>
                                            {response}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {isBottleResponse && (
                                        <Alert theme={theme}>
                                            1)Now please  ensure that no more boxes are placed on this pallet. <br />
                                            2)Let the warehouse staff know that this pallet is ready for export.<br />
                                            3)Take the printed contents list to the office for the drugs audit trail.
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <label style={{ fontSize: "large" }} htmlFor="#pallet">Which pallet do you want to finish?</label>
                                        <AsyncTypeahead size="lg" value={selectedPallet} onChange={this.setPallet} options={pallets} isLoading={isPalletsLoading}
                                            id="pallet-typeahead"
                                            labelKey="id"
                                            minLength={1}
                                            onInputChange={this.handlePalletInputChange}
                                            onSearch={this.handlePalletSearch}
                                            placeholder="Search for pallets"
                                            ref={this.palletNumber}
                                            renderMenuItemChildren={option => (
                                                <div key={option.id}>
                                                    <span>{option.id}</span>
                                                </div>
                                            )}
                                            useCache={false}
                                        />
                                        {isErrorInPallet && (
                                            <span className="error-txt">Please select a pallet</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isBottleRoomPallet && (
                                <Row>
                                    <Col sm="12" md="10" lg="8">
                                        <FormGroup>
                                            <label style={{ fontSize: "large" }} htmlFor="#weight">Total weight(kg) :<span className="error-txt">*</span></label>
                                            <FormInput size="lg" type="number" id="#weight" value={weight} onChange={this.setWeight} min="1" />
                                            <span className="error-txt">{responseWeight}</span>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col sm="12" md="10" lg="8">
                                    <FormGroup>
                                        <Button theme="primary" size="lg" onClick={this.onConfirmClick} disabled={isFinishPalletBtnDisabled}>{buttonText}</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="1" lg="2" style={mystyle}></Col>
                {showConfirm && (
                    <Modal open={showConfirm} toggle={() => this.setState({ showConfirm: !showConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Container className="dr-example-container" fluid={true}>
                                <Row>
                                    <Col><h2>FINISH PALLET - CONFIRMATION</h2></Col>
                                </Row>
                                <hr />
                                <Row>
                                    {!isBottleRoomPallet && (
                                        <Col sm="12" md="12" lg="12">
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to finish pallet number {selectedPallet[0].id} ? </label>
                                        </Col>
                                    )}
                                    {isBottleRoomPallet && (
                                        <Col sm="12" md="12" lg="12">
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Do you want to finish pallet number {selectedPallet[0].id} total weight: {weight}kg ? </label>
                                        </Col>
                                    )}
                                </Row>
                                <br></br>
                                <Row>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button theme="success" size="lg" onClick={this.confirmClick} disabled={isProcessing ? true : false}>{isProcessing ? "Loading..." : "Yes"}</Button>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" lg="6">
                                        <FormGroup>
                                            <Button style={{ float: "right" }} theme="secondary" size="lg" disabled={isProcessing ? true : false} onClick={() => this.setState({ showConfirm: false })}>No</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </ModalBody>
                    </Modal>
                )}
                {printConfirm && (
                    <Modal open={printConfirm} toggle={() => this.setState({ printConfirm: !printConfirm })} centered={true} className={"custom-modal"}>
                        <ModalBody>
                            <Row>
                                <Col><h2>Pallet Number : {palletNumber}</h2></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col sm="12" md="12" lg="12">
                                    {!isPalletCategoriesLoading && (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicinesAndCreams) && (<>
                                        <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                            <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse" }}>
                                                <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                    <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Description</th>
                                                    <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Batch number</th>
                                                    <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Expiry date</th>
                                                </tr>
                                                {boxContents.map((box) => {
                                                    return (
                                                        <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                            <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{box.description}( {box.numberofboxes} )</td>
                                                            <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{box.batchnumber}</td>
                                                            <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{getDateInGivenFormat(box.expirydate?.slice(0, 10))}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </table>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Quantity : {numberOfBoxes} </label>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Total weight : {totalWeight} kg </label>
                                            {earliestExpiryDate && (
                                                <>
                                                    <br />
                                                    <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Earliest expiry date : {getDateInGivenFormat(earliestExpiryDate?.slice(0, 10))} </label>
                                                </>
                                            )}
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Date finished : {today}  </label>
                                        </div>
                                    </>)}
                                    {!isPalletCategoriesLoading &&
                                        ((palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicalDisposables)
                                            || (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Food)) &&
                                        (<>
                                            <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                                <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse" }}>
                                                    <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                        <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Description</th>
                                                        <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Expiry date</th>
                                                        <th style={{ border: "1px solid black", borderCollapse: "collapse" }}>Weight</th>
                                                    </tr>
                                                    {boxContents.map((box) => {
                                                        return (
                                                            <tr style={{ border: "1px solid black", borderCollapse: "collapse" }}>
                                                                <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{box.description}( {box.numberofboxes} )</td>
                                                                <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{getDateInGivenFormat(box.expirydate?.slice(0, 10))}</td>
                                                                <td style={{ border: "1px solid black", borderCollapse: "collapse" }}>{box.totalweight} Kg</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </table>
                                                <br />
                                                <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Number of boxes : {numberOfBoxes} </label>
                                                <br />
                                                <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Total weight : {totalWeight} kg </label>
                                                {earliestExpiryDate && (
                                                    <>
                                                        <br />
                                                        <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Earliest expiry date : {getDateInGivenFormat(earliestExpiryDate?.slice(0, 10))} </label>
                                                    </>
                                                )}
                                                <br />
                                                <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Date finished : {today}  </label>
                                            </div>
                                        </>)}
                                    {!isPalletCategoriesLoading && (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.Other) && (<>
                                        <div id="PrintArea" style={{ overflowY: "scroll", height: "50vh" }}>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Description : {palletInfo.Description} </label>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Total weight : {palletInfo.TotalWeight} kg </label>
                                            <br />
                                            <label style={{ fontSize: "x-large", textAlign: "justify", textJustify: "inter-word" }}>Date finished : {today}  </label>
                                        </div>
                                    </>)}
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Button theme="success" size="lg" onClick={this.printContents} >Print</Button>
                                    </FormGroup>
                                </Col>
                                <Col >
                                    <FormGroup>
                                        <Button style={{ float: "right" }} theme="secondary" size="lg" onClick={() => this.setState({ printConfirm: false })}>No</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                )}
            </>
        );
    }

    getPalletCategories = () => {
        axios.get(ApiUrls.getPalletCategories, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                let palletCategories = JSON.parse(result.data);
                console.log(palletCategories);
                let palletCategoryIdWithFeatureTypeId = getPalletCategoryNameWithId(palletCategories);
                if (palletCategoryIdWithFeatureTypeId.size !== 0) {
                    console.log(palletCategoryIdWithFeatureTypeId);
                    this.setState({ palletCategories: palletCategoryIdWithFeatureTypeId, isPalletCategoriesLoading: false });
                } else {
                    this.setState({ palletCategories: new Map(), isPalletCategoriesLoading: true });
                }
            }
        }).catch((error) => {
            this.showError(error);
        });
    };

    onConfirmClick = (value) => {
        value.preventDefault();
        const { isBottleRoomPallet, validWeight } = this.state;
        if (isBottleRoomPallet) {
            this.validateWeight();
            if (validWeight) this.setState({ showConfirm: true });
        }
        else {
            this.setState({ showConfirm: true });
        }
    }

    setPallet = (value) => {
        this.setState({ selectedPallet: value, isResponse: false, theme: "", response: "", isBottleResponse: false });
        if (value.length == 0) {
            this.setState({ isErrorInPallet: true, isFinishPalletBtnDisabled: true, palletNumber: "", isBottleRoomPallet: false });
        } else {
            this.setState({ isErrorInPallet: false, isFinishPalletBtnDisabled: false, palletNumber: value[0].id });
            const { location, isPalletCategoriesLoading, palletCategories } = this.state;
            axios.get(`${ApiUrls.viewPalletContents}?palletNumber=${value[0].id}&location=${location}`, authorizationHeader).then((response) => {
                let palletContent = JSON.parse(response.data.data);
                let palletInfo = palletContent.PalletInfo;
                this.setState({ palletCategory: palletInfo.PalletCategory });
                if (!isPalletCategoriesLoading &&
                    (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicinesAndCreams))
                    this.setState({ isBottleRoomPallet: true });
                else
                    this.setState({ isBottleRoomPallet: false });
            }).catch((error) => {
                this.showError(error);
            });
        }
    };

    handlePalletInputChange = (palletquery) => {
        this.setState({ palletquery, isResponse: false, theme: "", response: "", isFinishPalletBtnDisabled: true, isBottleResponse: false });
    };

    handlePalletSearch = (palletquery) => {
        if (this._cache[palletquery]) {
            this.setState({ pallets: this._cache[palletquery].options });
            return;
        }
        this.setState({ isPalletsLoading: true });
        this.makeAndHandleRequestToGetPallets(palletquery).then(resp => {
            this._cache[palletquery] = { ...resp, page: 1 };
            this.setState({
                isPalletsLoading: false,
                pallets: resp.options,
            });
        });
    };

    makeAndHandleRequestToGetPallets(palletquery, page = 1) {
        return axios.get(`${ApiUrls.getUnfinishedPalletsAsync}?query=${palletquery}&locId=${this.state.locationId}`, authorizationHeader)
            .then(response => {
                let pallets = JSON.parse(response.data.data);
                let options = pallets.map(i => ({
                    id: i
                }));
                options = sortByAlphanumerically(options, 'id');
                let total_count = options.length;
                return { options, total_count };
            }).catch((error) => {
                this.showError(error);
            });
    }

    confirmClick = () => {
        this.setState({ buttonText: "Loading...", isFinishPalletBtnDisabled: true, isProcessing: true });
        const { selectedPallet, isBottleRoomPallet, palletCategory, weight, location, palletCategories } = this.state;
        let finishPalletInfo = {
            PalletNumber: selectedPallet[0].id.toString(),
            TotalWeight: (isBottleRoomPallet) ? parseFloat(weight) : 0,
            PalletCategory: palletCategory,
        };
        axios.post(ApiUrls.finishPallet, finishPalletInfo, authorizationHeader).then(({ data: result }) => {
            if (result.isSuccess) {
                axios.get(`${ApiUrls.viewPalletContents}?palletNumber=${selectedPallet[0].id}&location=${location}`, authorizationHeader).then(({ data: result }) => {
                    let palletContent = JSON.parse(result.data);
                    let boxContents = palletContent.PalletBoxContents;
                    let palletInfo = palletContent.PalletInfo;
                    console.log(palletInfo);
                    if ([FeaturePalletTypes.MedicinesAndCreams, FeaturePalletTypes.MedicalDisposables, FeaturePalletTypes.Food]
                        .includes(palletCategories.get(palletInfo.PalletCategory))) {
                        let sortBoxContents = sortByAlphanumerically(boxContents, 'description');
                        let sortExpiryDateBoxContents = sortByAlphanumerically(boxContents, 'expirydate');
                        if (sortExpiryDateBoxContents.length >= 1) {
                            let earliestExpiryDate = sortExpiryDateBoxContents[0].expirydate;
                            this.setState({ earliestExpiryDate });
                        }
                        let numberOfBoxes = 0;
                        let totalWeight = 0;
                        boxContents.map((box) => {
                            numberOfBoxes += box.numberofboxes;
                            totalWeight += box.totalweight;
                        });
                        if (palletCategories.get(palletInfo.PalletCategory) == FeaturePalletTypes.MedicinesAndCreams) {
                            totalWeight = palletInfo.TotalWeight;
                        }
                        this.setState({ boxContents: sortBoxContents, palletInfo, printConfirm: true, numberOfBoxes, totalWeight, showConfirm: false, isProcessing: false });
                    } else {
                        this.setState({ printConfirm: true, palletInfo, showConfirm: false, isProcessing: false });
                    }
                }).catch((error) => {
                    this.showError(error);
                });
                this.palletNumber.current.clear();
                this._cache = {};
                this.setState({ weight: "", responseWeight: "", validWeight: false, isBottleRoomPallet: false });
                let messageText;
                if (location == "warehouse") {
                    messageText = result.messageText;
                    this.setState({
                        isResponse: true, theme: "success", response: messageText,
                        buttonText: "Confirm", isFinishPalletBtnDisabled: true
                    });
                }
                else if (location == "medical") {
                    messageText = "Now please ensure that no more boxes are placed on this pallet, and let the warehouse staff know that this pallet is ready for locating on the racking.";
                    this.setState({
                        isResponse: true, theme: "success", response: messageText,
                        buttonText: "Confirm", isFinishPalletBtnDisabled: true
                    });
                }
                else if (location == "food") {
                    messageText = "Now please  ensure that no more boxes are placed on this pallet, and let the warehouse staff know that this pallet is ready for export.";
                    this.setState({
                        isResponse: true, theme: "success", response: messageText,
                        buttonText: "Confirm", isFinishPalletBtnDisabled: true
                    });
                }
                else if (location == "bottle") {
                    this.setState({
                        isResponse: false, theme: "success", response: messageText, isBottleResponse: true,
                        buttonText: "Confirm", isFinishPalletBtnDisabled: true
                    });
                }
            }
            else {
                this.setState({
                    isResponse: true, theme: "danger", response: result.messageText,
                    buttonText: "Confirm", isFinishPalletBtnDisabled: true
                });
            }
        }).catch((error) => {
            this.showError(error);
        });
    }

    printContents = () => {
        let divContents = document.getElementById("PrintArea").innerHTML;
        const { selectedPallet } = this.state;
        let a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > <h1>Pallet Number :' + selectedPallet[0].id + '</h1><br>');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
        this.setState({ showConfirm: false });
    }

    getTodayDate = () => {
        let date = new Date(),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        let today = `${day}/${month}/${year}`;
        this.setState({ today });
    }

    setWeight = (value) => {
        const { value: weight } = value.target;
        this.setState({ weight, isResponse: false });
        if (!weight) {
            this.setState({ responseWeight: "Please enter weight", validWeight: false });
        } else if (isNaN(weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    validateWeight = () => {
        const { weight } = this.state;
        if (!weight) {
            this.setState({ responseWeight: "Please enter weight", validWeight: false });
        } else if (isNaN(weight)) {
            this.setState({ responseWeight: "Please enter number", validWeight: false });
        } else if (weight < 0) {
            this.setState({ responseWeight: "Please enter positive number", validWeight: false });
        } else {
            this.setState({ responseWeight: "", validWeight: true });
        }
    }

    showError = (error) => {
        console.log(error);
        if (error?.response?.status == 401) {
            window.localStorage.removeItem("Token");
            window.location.href = "/login";
        } else {
            this.setState({
                isResponse: true, theme: "danger", response: "Something went wrong",
                buttonText: "Confirm", isFinishPalletBtnDisabled: true
            });
        }
    }
}